import { CompanyBranchReadModel, useApiDeleteCompanyBranchMutation } from "@client/shared/api";
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from "@client/shared/toolkit";
import { safeMutation } from "@client/shared/utilities";
import { useTranslation } from "react-i18next";
import React from 'react';

interface BranchDeleteModalProps extends ModalOnCloseProps {
    branch?: CompanyBranchReadModel;
    outerClose: () => void;
  }

  export const BranchDeleteModal = ({ branch, onClose, outerClose }: BranchDeleteModalProps) => {
    const { t } = useTranslation();

    const [call, { isLoading }] = useApiDeleteCompanyBranchMutation();

    const handleOnClose = () => {
      onClose(false);
    };

    const handleDelete = async () => {
      try {
        await safeMutation(
          call,
          {
            companyId: branch?.companyId ?? '',
            companyBranchId: branch?.id ?? ''
          },
          isLoading
        );
        onClose(true);
        outerClose();
      } catch {
        /* left blank */
      }
    };

    return (
      <ConfirmModal
        title={t('app.companyBranchDelete')}
        onConfirm={handleDelete}
        onClose={handleOnClose}
        confirmVariant="danger"
        confirmText={t('common.delete')}
        isLoading={isLoading}
      >
        {isLoading && (
          <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} mode="overlay" />
        )}
        <HintBox hintType="warning">{t('projectTaxonomy.deleteBuildingElementHint')}</HintBox>
      </ConfirmModal>
    );
  };
