import { apiBase as api } from './apiBase';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiPostAcceptUserInvitation: build.mutation<
      ApiPostAcceptUserInvitationApiResponse,
      ApiPostAcceptUserInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/invitation/${queryArg.tokenId}/accept`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostAcceptTenantInvite: build.mutation<ApiPostAcceptTenantInviteApiResponse, ApiPostAcceptTenantInviteApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/invite/accept`, method: 'POST', body: queryArg.body }),
    }),
    apiPostActiveApiAccess: build.mutation<ApiPostActiveApiAccessApiResponse, ApiPostActiveApiAccessApiArg>({
      query: (queryArg) => ({
        url: `/api/tenant/externalapis/activate`,
        method: 'POST',
        params: { apiAccessId: queryArg.apiAccessId },
      }),
    }),
    apiAdminPostActivateLicenseGrant: build.mutation<
      ApiAdminPostActivateLicenseGrantApiResponse,
      ApiAdminPostActivateLicenseGrantApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/licenses/grants/activate`, method: 'POST', body: queryArg.body }),
    }),
    apiPostApiAccess: build.mutation<ApiPostApiAccessApiResponse, ApiPostApiAccessApiArg>({
      query: (queryArg) => ({
        url: `/api/tenant/externalapis`,
        method: 'POST',
        body: queryArg.body,
        params: { apiId: queryArg.apiId },
      }),
    }),
    apiGetExternalApis: build.query<ApiGetExternalApisApiResponse, ApiGetExternalApisApiArg>({
      query: () => ({ url: `/api/tenant/externalapis` }),
    }),
    apiRemoveApiAccess: build.mutation<ApiRemoveApiAccessApiResponse, ApiRemoveApiAccessApiArg>({
      query: (queryArg) => ({
        url: `/api/tenant/externalapis`,
        method: 'DELETE',
        params: { apiAccessId: queryArg.apiAccessId },
      }),
    }),
    apiUpdateApiAccess: build.mutation<ApiUpdateApiAccessApiResponse, ApiUpdateApiAccessApiArg>({
      query: (queryArg) => ({
        url: `/api/tenant/externalapis`,
        method: 'PUT',
        body: queryArg.body,
        params: { apiAccessId: queryArg.apiAccessId },
      }),
    }),
    apiPostCreateRentDealContact: build.mutation<
      ApiPostCreateRentDealContactApiResponse,
      ApiPostCreateRentDealContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/contacts`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateRentDealContractItem: build.mutation<
      ApiPostCreateRentDealContractItemApiResponse,
      ApiPostCreateRentDealContractItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/contractItems`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateRentDealCostItem: build.mutation<
      ApiPostCreateRentDealCostItemApiResponse,
      ApiPostCreateRentDealCostItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/costItems`,
        method: 'POST',
        params: { taxonomyItemId: queryArg.taxonomyItemId },
      }),
    }),
    apiPostCreateRentDealDocument: build.mutation<
      ApiPostCreateRentDealDocumentApiResponse,
      ApiPostCreateRentDealDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateSellDealContact: build.mutation<
      ApiPostCreateSellDealContactApiResponse,
      ApiPostCreateSellDealContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/contacts`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateSellDealContractItem: build.mutation<
      ApiPostCreateSellDealContractItemApiResponse,
      ApiPostCreateSellDealContractItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/contractItems`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateSellDealCostItem: build.mutation<
      ApiPostCreateSellDealCostItemApiResponse,
      ApiPostCreateSellDealCostItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/costItems`,
        method: 'POST',
        params: { taxonomyItemId: queryArg.taxonomyItemId },
      }),
    }),
    apiPostCreateSellDealDocument: build.mutation<
      ApiPostCreateSellDealDocumentApiResponse,
      ApiPostCreateSellDealDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostApplyCostElementForecastAsBudget: build.mutation<
      ApiPostApplyCostElementForecastAsBudgetApiResponse,
      ApiPostApplyCostElementForecastAsBudgetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.costElementId}/forecast`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostApproveInvoice: build.mutation<ApiPostApproveInvoiceApiResponse, ApiPostApproveInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/approve`,
        method: 'POST',
      }),
    }),
    apiPostCreateCalculationModelArchive: build.mutation<
      ApiPostCreateCalculationModelArchiveApiResponse,
      ApiPostCreateCalculationModelArchiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/archive`,
        method: 'POST',
      }),
    }),
    apiPostAuditInvoice: build.mutation<ApiPostAuditInvoiceApiResponse, ApiPostAuditInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/audit`,
        method: 'POST',
      }),
    }),
    apiGetAuthRoles: build.query<ApiGetAuthRolesApiResponse, ApiGetAuthRolesApiArg>({
      query: () => ({ url: `/api/auth/roles` }),
    }),
    apiPostCreateAuthRole: build.mutation<ApiPostCreateAuthRoleApiResponse, ApiPostCreateAuthRoleApiArg>({
      query: (queryArg) => ({ url: `/api/auth/roles`, method: 'POST', body: queryArg.body }),
    }),
    apiGetBaseReport: build.query<ApiGetBaseReportApiResponse, ApiGetBaseReportApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/reporting/base` }),
    }),
    apiGetBugetReport: build.query<ApiGetBugetReportApiResponse, ApiGetBugetReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/budget`,
      }),
    }),
    apiGetBudgetExcelReport: build.query<ApiGetBudgetExcelReportApiResponse, ApiGetBudgetExcelReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/budget/excel`,
      }),
    }),
    apiPostCalculateAllConsumptionsCost: build.mutation<
      ApiPostCalculateAllConsumptionsCostApiResponse,
      ApiPostCalculateAllConsumptionsCostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/calculate-all-consumptions-cost`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCalculateConsumptionCost: build.mutation<
      ApiPostCalculateConsumptionCostApiResponse,
      ApiPostCalculateConsumptionCostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/calculate-consumption-cost`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCalculateContractTitle: build.mutation<
      ApiPostCalculateContractTitleApiResponse,
      ApiPostCalculateContractTitleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/calculate-contract-title`,
        method: 'POST',
        body: queryArg.body,
        params: { contractId: queryArg.contractId, contractTitleId: queryArg.contractTitleId },
      }),
    }),
    apiPostCalculateFormula: build.mutation<ApiPostCalculateFormulaApiResponse, ApiPostCalculateFormulaApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/calculate-formula`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCalculateInvoiceValueDistribution: build.mutation<
      ApiPostCalculateInvoiceValueDistributionApiResponse,
      ApiPostCalculateInvoiceValueDistributionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculate-distribution`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiCancelAiEvalProcess: build.mutation<ApiCancelAiEvalProcessApiResponse, ApiCancelAiEvalProcessApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/aievaldocument/${queryArg.documentId}/cancel`,
        method: 'POST',
      }),
    }),
    apiGetCashFlowReport: build.query<ApiGetCashFlowReportApiResponse, ApiGetCashFlowReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/cash-flow`,
      }),
    }),
    apiPostUpdateCalculationModelVersion: build.mutation<
      ApiPostUpdateCalculationModelVersionApiResponse,
      ApiPostUpdateCalculationModelVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/version`,
        method: 'POST',
      }),
    }),
    apiPostUpdateUserEmail: build.mutation<ApiPostUpdateUserEmailApiResponse, ApiPostUpdateUserEmailApiArg>({
      query: (queryArg) => ({ url: `/api/user/change-email`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateUserLanguage: build.mutation<ApiPostUpdateUserLanguageApiResponse, ApiPostUpdateUserLanguageApiArg>({
      query: (queryArg) => ({ url: `/api/user/change-language`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateUserName: build.mutation<ApiPostUpdateUserNameApiResponse, ApiPostUpdateUserNameApiArg>({
      query: (queryArg) => ({ url: `/api/user/change-name`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdatePassword: build.mutation<ApiPostUpdatePasswordApiResponse, ApiPostUpdatePasswordApiArg>({
      query: (queryArg) => ({ url: `/api/auth/change-password`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCheckSession: build.mutation<ApiPostCheckSessionApiResponse, ApiPostCheckSessionApiArg>({
      query: () => ({ url: `/api/auth/check-session`, method: 'POST' }),
    }),
    apiGetDevelopmentCommandList: build.query<
      ApiGetDevelopmentCommandListApiResponse,
      ApiGetDevelopmentCommandListApiArg
    >({
      query: () => ({ url: `/api/development/commandlist` }),
    }),
    apiGetComparisonReport: build.query<ApiGetComparisonReportApiResponse, ApiGetComparisonReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/comparison/${queryArg.targetId}`,
      }),
    }),
    apiGetComparisonExcelReport: build.query<ApiGetComparisonExcelReportApiResponse, ApiGetComparisonExcelReportApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/comparison/${queryArg.targetId}/excel`,
        }),
      }
    ),
    apiPostReviewInvoiceWorkflowTask: build.mutation<
      ApiPostReviewInvoiceWorkflowTaskApiResponse,
      ApiPostReviewInvoiceWorkflowTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/workflows/review-invoice`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetImportContractFileTemplate: build.query<
      ApiGetImportContractFileTemplateApiResponse,
      ApiGetImportContractFileTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/import/templates/contracts`,
        params: { fileType: queryArg.fileType },
      }),
    }),
    apiGetContractReport: build.query<ApiGetContractReportApiResponse, ApiGetContractReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/contract`,
      }),
    }),
    apiAdminPostConvertContracts: build.mutation<
      ApiAdminPostConvertContractsApiResponse,
      ApiAdminPostConvertContractsApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/contracts/convert`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCopyAuthRole: build.mutation<ApiPostCopyAuthRoleApiResponse, ApiPostCopyAuthRoleApiArg>({
      query: (queryArg) => ({ url: `/api/auth/roles/${queryArg.roleId}/copy`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreateCalculationModelCopy: build.mutation<
      ApiPostCreateCalculationModelCopyApiResponse,
      ApiPostCreateCalculationModelCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/copy`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCopyContract: build.mutation<ApiPostCopyContractApiResponse, ApiPostCopyContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/copy`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateProjectCopy: build.mutation<ApiPostCreateProjectCopyApiResponse, ApiPostCreateProjectCopyApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/copy`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCopyProjectInvoiceCalculationScheme: build.mutation<
      ApiPostCopyProjectInvoiceCalculationSchemeApiResponse,
      ApiPostCopyProjectInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/copy`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminPostCopyProjectToTenant: build.mutation<
      ApiAdminPostCopyProjectToTenantApiResponse,
      ApiAdminPostCopyProjectToTenantApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/project/copy`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCopyTenantInvoiceCalculationScheme: build.mutation<
      ApiPostCopyTenantInvoiceCalculationSchemeApiResponse,
      ApiPostCopyTenantInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/copy`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCopyTenantInvoiceCalculationSchemeToProject: build.mutation<
      ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiResponse,
      ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/copyToProject/${queryArg.projectId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminPostCopyWorkflow: build.mutation<ApiAdminPostCopyWorkflowApiResponse, ApiAdminPostCopyWorkflowApiArg>({
      query: (queryArg) => ({ url: `/api/admin/elsa/copy-workflow`, method: 'POST', body: queryArg.body }),
    }),
    apiGetImportCostElementFileTemplate: build.query<
      ApiGetImportCostElementFileTemplateApiResponse,
      ApiGetImportCostElementFileTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/import/templates/cost-elements`,
        params: { fileType: queryArg.fileType },
      }),
    }),
    apiPostAssignableWorkflowActivities: build.mutation<
      ApiPostAssignableWorkflowActivitiesApiResponse,
      ApiPostAssignableWorkflowActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/workflows/assignable-activities`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetAssignableWorkflowActivities: build.query<
      ApiGetAssignableWorkflowActivitiesApiResponse,
      ApiGetAssignableWorkflowActivitiesApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/workflows/assignable-activities` }),
    }),
    apiPostCreateAuthGroup: build.mutation<ApiPostCreateAuthGroupApiResponse, ApiPostCreateAuthGroupApiArg>({
      query: (queryArg) => ({ url: `/api/auth/groups`, method: 'POST', body: queryArg.body }),
    }),
    apiGetAuthGroups: build.query<ApiGetAuthGroupsApiResponse, ApiGetAuthGroupsApiArg>({
      query: () => ({ url: `/api/auth/groups` }),
    }),
    apiPostCreateTaxonomy: build.mutation<ApiPostCreateTaxonomyApiResponse, ApiPostCreateTaxonomyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetTaxonomy: build.query<ApiGetTaxonomyApiResponse, ApiGetTaxonomyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy`,
      }),
    }),
    apiPostCreateCalculationModelDeliveryPhases: build.mutation<
      ApiPostCreateCalculationModelDeliveryPhasesApiResponse,
      ApiPostCreateCalculationModelDeliveryPhasesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/delivery-phases`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetCalculationModelDeliveryPhases: build.query<
      ApiGetCalculationModelDeliveryPhasesApiResponse,
      ApiGetCalculationModelDeliveryPhasesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/delivery-phases`,
      }),
    }),
    apiPostCreateCalculationModelMilestones: build.mutation<
      ApiPostCreateCalculationModelMilestonesApiResponse,
      ApiPostCreateCalculationModelMilestonesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/milestones`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetCalculationModelMilestones: build.query<
      ApiGetCalculationModelMilestonesApiResponse,
      ApiGetCalculationModelMilestonesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/milestones`,
      }),
    }),
    apiPostCreateCalculationModelSnapshot: build.mutation<
      ApiPostCreateCalculationModelSnapshotApiResponse,
      ApiPostCreateCalculationModelSnapshotApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/snapshot`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiCreateCatalogElement: build.mutation<ApiCreateCatalogElementApiResponse, ApiCreateCatalogElementApiArg>({
      query: (queryArg) => ({ url: `/api/catalog/element`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteCatalogElement: build.mutation<ApiDeleteCatalogElementApiResponse, ApiDeleteCatalogElementApiArg>({
      query: (queryArg) => ({ url: `/api/catalog/element`, method: 'DELETE', body: queryArg.body }),
    }),
    apiUpdateCatalogElement: build.mutation<ApiUpdateCatalogElementApiResponse, ApiUpdateCatalogElementApiArg>({
      query: (queryArg) => ({ url: `/api/catalog/element`, method: 'PATCH', body: queryArg.body }),
    }),
    apiCreateCatalog: build.mutation<ApiCreateCatalogApiResponse, ApiCreateCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/catalog`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteCatalog: build.mutation<ApiDeleteCatalogApiResponse, ApiDeleteCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/catalog`, method: 'DELETE', body: queryArg.body }),
    }),
    apiUpdateCatalog: build.mutation<ApiUpdateCatalogApiResponse, ApiUpdateCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/catalog`, method: 'PATCH', body: queryArg.body }),
    }),
    apiPostCreateCatalogTemplate: build.mutation<
      ApiPostCreateCatalogTemplateApiResponse,
      ApiPostCreateCatalogTemplateApiArg
    >({
      query: (queryArg) => ({ url: `/api/templates/catalogs`, method: 'POST', body: queryArg.body }),
    }),
    apiGetCatalogTemplates: build.query<ApiGetCatalogTemplatesApiResponse, ApiGetCatalogTemplatesApiArg>({
      query: () => ({ url: `/api/templates/catalogs` }),
    }),
    apiPostCreateClusterItemRequirementDocuments: build.mutation<
      ApiPostCreateClusterItemRequirementDocumentsApiResponse,
      ApiPostCreateClusterItemRequirementDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements/${queryArg.requirementId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateClusterItemRequirement: build.mutation<
      ApiPostCreateClusterItemRequirementApiResponse,
      ApiPostCreateClusterItemRequirementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateCommitment: build.mutation<ApiPostCreateCommitmentApiResponse, ApiPostCreateCommitmentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/commitments`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetCommitments: build.query<ApiGetCommitmentsApiResponse, ApiGetCommitmentsApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/commitments`,
      }),
    }),
    apiPostCreateCompanyBranch: build.mutation<ApiPostCreateCompanyBranchApiResponse, ApiPostCreateCompanyBranchApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.companyId}/branches`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiPostCreateCompany: build.mutation<ApiPostCreateCompanyApiResponse, ApiPostCreateCompanyApiArg>({
      query: (queryArg) => ({ url: `/api/companies/company`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreatePrivatePerson: build.mutation<ApiPostCreatePrivatePersonApiResponse, ApiPostCreatePrivatePersonApiArg>(
      {
        query: (queryArg) => ({ url: `/api/companies/privateperson`, method: 'POST', body: queryArg.body }),
      }
    ),
    apiPostCreateCompanyPerson: build.mutation<ApiPostCreateCompanyPersonApiResponse, ApiPostCreateCompanyPersonApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.companyId}/persons`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiPostCreateContract: build.mutation<ApiPostCreateContractApiResponse, ApiPostCreateContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetContracts: build.query<ApiGetContractsApiResponse, ApiGetContractsApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts`,
      }),
    }),
    apiPostCreateContractTitleDocument: build.mutation<
      ApiPostCreateContractTitleDocumentApiResponse,
      ApiPostCreateContractTitleDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/contract-titles/${queryArg.contractTitleId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetContractTitleDocuments: build.query<
      ApiGetContractTitleDocumentsApiResponse,
      ApiGetContractTitleDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/contract-titles/${queryArg.contractTitleId}/documents`,
      }),
    }),
    apiPostCreateContractTitle: build.mutation<ApiPostCreateContractTitleApiResponse, ApiPostCreateContractTitleApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/titles`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiPostCreateCostElementDocument: build.mutation<
      ApiPostCreateCostElementDocumentApiResponse,
      ApiPostCreateCostElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.costElementId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateCostElement: build.mutation<ApiPostCreateCostElementApiResponse, ApiPostCreateCostElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateCostElementTag: build.mutation<
      ApiPostCreateCostElementTagApiResponse,
      ApiPostCreateCostElementTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.id}/extension-elements/tag`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateDefaultValues: build.mutation<ApiPostCreateDefaultValuesApiResponse, ApiPostCreateDefaultValuesApiArg>(
      {
        query: (queryArg) => ({ url: `/api/default-values`, method: 'POST', body: queryArg.body }),
      }
    ),
    apiGetDefaultValues: build.query<ApiGetDefaultValuesApiResponse, ApiGetDefaultValuesApiArg>({
      query: () => ({ url: `/api/default-values` }),
    }),
    apiPostCreateDeliveryPhaseCatalog: build.mutation<
      ApiPostCreateDeliveryPhaseCatalogApiResponse,
      ApiPostCreateDeliveryPhaseCatalogApiArg
    >({
      query: (queryArg) => ({
        url: `/api/delivery-phases-catalog/${queryArg.catalogId}/delivery-phase`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateDeliveryPhase: build.mutation<ApiPostCreateDeliveryPhaseApiResponse, ApiPostCreateDeliveryPhaseApiArg>(
      {
        query: (queryArg) => ({ url: `/api/delivery-phases-catalog`, method: 'POST', body: queryArg.body }),
      }
    ),
    apiGetCatalogsDeliveryPhases: build.query<
      ApiGetCatalogsDeliveryPhasesApiResponse,
      ApiGetCatalogsDeliveryPhasesApiArg
    >({
      query: () => ({ url: `/api/delivery-phases-catalog` }),
    }),
    apiPostCreateEarningsElementDocument: build.mutation<
      ApiPostCreateEarningsElementDocumentApiResponse,
      ApiPostCreateEarningsElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements/${queryArg.earningsElementId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateEarningsElement: build.mutation<
      ApiPostCreateEarningsElementApiResponse,
      ApiPostCreateEarningsElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateFinancingElementDocument: build.mutation<
      ApiPostCreateFinancingElementDocumentApiResponse,
      ApiPostCreateFinancingElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.financingElementId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateFinancingElement: build.mutation<
      ApiPostCreateFinancingElementApiResponse,
      ApiPostCreateFinancingElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoiceByContract: build.mutation<
      ApiPostCreateInvoiceByContractApiResponse,
      ApiPostCreateInvoiceByContractApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/invoices`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoiceByContractWithSourceDocument: build.mutation<
      ApiPostCreateInvoiceByContractWithSourceDocumentApiResponse,
      ApiPostCreateInvoiceByContractWithSourceDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/invoices/withSourceDocument`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoiceCalculationRule: build.mutation<
      ApiPostCreateInvoiceCalculationRuleApiResponse,
      ApiPostCreateInvoiceCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoiceCalculationRuleGroup: build.mutation<
      ApiPostCreateInvoiceCalculationRuleGroupApiResponse,
      ApiPostCreateInvoiceCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoiceDocuments: build.mutation<
      ApiPostCreateInvoiceDocumentsApiResponse,
      ApiPostCreateInvoiceDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoice: build.mutation<ApiPostCreateInvoiceApiResponse, ApiPostCreateInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetInvoices: build.query<ApiGetInvoicesApiResponse, ApiGetInvoicesApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices`,
      }),
    }),
    apiPostCreateInvoiceSourceDocument: build.mutation<
      ApiPostCreateInvoiceSourceDocumentApiResponse,
      ApiPostCreateInvoiceSourceDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/documents/source`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoiceTitle: build.mutation<ApiPostCreateInvoiceTitleApiResponse, ApiPostCreateInvoiceTitleApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/titles`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateInvoiceWithSourceDocument: build.mutation<
      ApiPostCreateInvoiceWithSourceDocumentApiResponse,
      ApiPostCreateInvoiceWithSourceDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/withSourceDocument`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminCreateKlippaConfigurations: build.mutation<
      ApiAdminCreateKlippaConfigurationsApiResponse,
      ApiAdminCreateKlippaConfigurationsApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/klippa-configurations`, method: 'POST', body: queryArg.body }),
    }),
    apiAdminGetKlippaConfigurations: build.query<
      ApiAdminGetKlippaConfigurationsApiResponse,
      ApiAdminGetKlippaConfigurationsApiArg
    >({
      query: () => ({ url: `/api/admin/klippa-configurations` }),
    }),
    apiAdminPostCreateLicense: build.mutation<ApiAdminPostCreateLicenseApiResponse, ApiAdminPostCreateLicenseApiArg>({
      query: (queryArg) => ({ url: `/api/admin/licenses/create`, method: 'POST', body: queryArg.body }),
    }),
    apiAdminPostCreateLicenseGrant: build.mutation<
      ApiAdminPostCreateLicenseGrantApiResponse,
      ApiAdminPostCreateLicenseGrantApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/licenses/grants/create`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreateLocalLawCostElement: build.mutation<
      ApiPostCreateLocalLawCostElementApiResponse,
      ApiPostCreateLocalLawCostElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/local-law`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreatePlot: build.mutation<ApiPostCreatePlotApiResponse, ApiPostCreatePlotApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetPlotsByCalculationModel: build.query<
      ApiGetPlotsByCalculationModelApiResponse,
      ApiGetPlotsByCalculationModelApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots`,
      }),
    }),
    apiPostCreateProjectCalculationRule: build.mutation<
      ApiPostCreateProjectCalculationRuleApiResponse,
      ApiPostCreateProjectCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateProjectCalculationRuleGroup: build.mutation<
      ApiPostCreateProjectCalculationRuleGroupApiResponse,
      ApiPostCreateProjectCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateEuriborRateProjection: build.mutation<
      ApiPostCreateEuriborRateProjectionApiResponse,
      ApiPostCreateEuriborRateProjectionApiArg
    >({
      query: (queryArg) => ({ url: `/api/euribor-rate-projection`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreateProject: build.mutation<ApiPostCreateProjectApiResponse, ApiPostCreateProjectApiArg>({
      query: (queryArg) => ({ url: `/api/projects`, method: 'POST', body: queryArg.body }),
    }),
    apiGetProjects: build.query<ApiGetProjectsApiResponse, ApiGetProjectsApiArg>({
      query: () => ({ url: `/api/projects` }),
    }),
    apiPostCreateProjectInvoiceCalculationScheme: build.mutation<
      ApiPostCreateProjectInvoiceCalculationSchemeApiResponse,
      ApiPostCreateProjectInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetProjectInvoiceCalculationSchemes: build.query<
      ApiGetProjectInvoiceCalculationSchemesApiResponse,
      ApiGetProjectInvoiceCalculationSchemesApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes` }),
    }),
    apiPostCreateProjectConstructionPhase: build.mutation<
      ApiPostCreateProjectConstructionPhaseApiResponse,
      ApiPostCreateProjectConstructionPhaseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/construction-phase`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateProjectStatus: build.mutation<ApiPostCreateProjectStatusApiResponse, ApiPostCreateProjectStatusApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/settings/status`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiPostCreateProjectTag: build.mutation<ApiPostCreateProjectTagApiResponse, ApiPostCreateProjectTagApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/tag`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateProjectVat: build.mutation<ApiPostCreateProjectVatApiResponse, ApiPostCreateProjectVatApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/vat`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostProjectValuation: build.mutation<ApiPostProjectValuationApiResponse, ApiPostProjectValuationApiArg>({
      query: (queryArg) => ({
        url: `/api/pricehubble/projectvaluation`,
        method: 'POST',
        params: { projectId: queryArg.projectId },
      }),
    }),
    apiPostCreateRentDeal: build.mutation<ApiPostCreateRentDealApiResponse, ApiPostCreateRentDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetRentDeals: build.query<ApiGetRentDealsApiResponse, ApiGetRentDealsApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/rent/deals` }),
    }),
    apiPostCreateRiskElementDocument: build.mutation<
      ApiPostCreateRiskElementDocumentApiResponse,
      ApiPostCreateRiskElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/risk-elements/${queryArg.riskElementId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateRiskElement: build.mutation<ApiPostCreateRiskElementApiResponse, ApiPostCreateRiskElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/risk-elements`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateSellDeal: build.mutation<ApiPostCreateSellDealApiResponse, ApiPostCreateSellDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetSellDeals: build.query<ApiGetSellDealsApiResponse, ApiGetSellDealsApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/sell/deals` }),
    }),
    apiPostCreateTenantCalculationRule: build.mutation<
      ApiPostCreateTenantCalculationRuleApiResponse,
      ApiPostCreateTenantCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateTenantCalculationRuleGroup: build.mutation<
      ApiPostCreateTenantCalculationRuleGroupApiResponse,
      ApiPostCreateTenantCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminPostCreateTenant: build.mutation<ApiAdminPostCreateTenantApiResponse, ApiAdminPostCreateTenantApiArg>({
      query: (queryArg) => ({ url: `/api/admin/tenant`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreateTenantInvoiceCalculationScheme: build.mutation<
      ApiPostCreateTenantInvoiceCalculationSchemeApiResponse,
      ApiPostCreateTenantInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({ url: `/api/invoice-calculation-schemes`, method: 'POST', body: queryArg.body }),
    }),
    apiGetTenantInvoiceCalculationSchemes: build.query<
      ApiGetTenantInvoiceCalculationSchemesApiResponse,
      ApiGetTenantInvoiceCalculationSchemesApiArg
    >({
      query: () => ({ url: `/api/invoice-calculation-schemes` }),
    }),
    apiPostCreateTenantUserDefinedFieldDefinition: build.mutation<
      ApiPostCreateTenantUserDefinedFieldDefinitionApiResponse,
      ApiPostCreateTenantUserDefinedFieldDefinitionApiArg
    >({
      query: (queryArg) => ({ url: `/api/tenant/user-defined-fields-definition`, method: 'POST', body: queryArg.body }),
    }),
    apiGetUserDefinedFieldsDefinition: build.query<
      ApiGetUserDefinedFieldsDefinitionApiResponse,
      ApiGetUserDefinedFieldsDefinitionApiArg
    >({
      query: () => ({ url: `/api/tenant/user-defined-fields-definition` }),
    }),
    apiAdminPostCreateUser: build.mutation<ApiAdminPostCreateUserApiResponse, ApiAdminPostCreateUserApiArg>({
      query: (queryArg) => ({ url: `/api/admin/user`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreateVatCostElement: build.mutation<
      ApiPostCreateVatCostElementApiResponse,
      ApiPostCreateVatCostElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/vat`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateProjectVersion: build.mutation<
      ApiPostCreateProjectVersionApiResponse,
      ApiPostCreateProjectVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/version`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetCrossTenantAvatar: build.query<ApiGetCrossTenantAvatarApiResponse, ApiGetCrossTenantAvatarApiArg>({
      query: (queryArg) => ({ url: `/api/cross-tenant/${queryArg.crossTenantId}/avatar` }),
    }),
    apiPostCrossTenantUploadAiEvalDocument: build.mutation<
      ApiPostCrossTenantUploadAiEvalDocumentApiResponse,
      ApiPostCrossTenantUploadAiEvalDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cross-tenant/${queryArg.crossTenantId}/projects/${queryArg.crossTenantProjectId}/aievaldocument/${queryArg.contractId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetExchangeDataImports: build.query<ApiGetExchangeDataImportsApiResponse, ApiGetExchangeDataImportsApiArg>({
      query: (queryArg) => ({ url: `/api/data/getImports/${queryArg.proId}` }),
    }),
    apiGetExchangeDataHasNewImports: build.query<
      ApiGetExchangeDataHasNewImportsApiResponse,
      ApiGetExchangeDataHasNewImportsApiArg
    >({
      query: (queryArg) => ({ url: `/api/data/hasNewImports/${queryArg.proId}/${queryArg.calcModelId}` }),
    }),
    apiPostExchangeDataProcessImport: build.mutation<
      ApiPostExchangeDataProcessImportApiResponse,
      ApiPostExchangeDataProcessImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/data/processImport/${queryArg.proId}/${queryArg.calcModelId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminPostDeactivateLicenseGrant: build.mutation<
      ApiAdminPostDeactivateLicenseGrantApiResponse,
      ApiAdminPostDeactivateLicenseGrantApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/licenses/grants/deactivate`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteAiEvalDocument: build.mutation<ApiDeleteAiEvalDocumentApiResponse, ApiDeleteAiEvalDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/aievaldocument/${queryArg.documentId}`,
        method: 'DELETE',
        params: { invoiceId: queryArg.invoiceId },
      }),
    }),
    apiGetAiEvalResult: build.query<ApiGetAiEvalResultApiResponse, ApiGetAiEvalResultApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/aievaldocument/${queryArg.documentId}`,
        params: { calculationModelId: queryArg.calculationModelId },
      }),
    }),
    apiDeleteAuthGroup: build.mutation<ApiDeleteAuthGroupApiResponse, ApiDeleteAuthGroupApiArg>({
      query: (queryArg) => ({ url: `/api/auth/groups/${queryArg.groupId}`, method: 'DELETE' }),
    }),
    apiPostUpdateAuthGroup: build.mutation<ApiPostUpdateAuthGroupApiResponse, ApiPostUpdateAuthGroupApiArg>({
      query: (queryArg) => ({ url: `/api/auth/groups/${queryArg.groupId}`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteAuthRole: build.mutation<ApiDeleteAuthRoleApiResponse, ApiDeleteAuthRoleApiArg>({
      query: (queryArg) => ({ url: `/api/auth/roles/${queryArg.roleId}`, method: 'DELETE' }),
    }),
    apiPostUpdateAuthRole: build.mutation<ApiPostUpdateAuthRoleApiResponse, ApiPostUpdateAuthRoleApiArg>({
      query: (queryArg) => ({ url: `/api/auth/roles/${queryArg.roleId}`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteCalculationModelDeliveryPhases: build.mutation<
      ApiDeleteCalculationModelDeliveryPhasesApiResponse,
      ApiDeleteCalculationModelDeliveryPhasesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/delivery-phases/${queryArg.deliveryPhaseId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateCalculationModelDeliveryPhases: build.mutation<
      ApiPostUpdateCalculationModelDeliveryPhasesApiResponse,
      ApiPostUpdateCalculationModelDeliveryPhasesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/delivery-phases/${queryArg.deliveryPhaseId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteCalculationModel: build.mutation<ApiDeleteCalculationModelApiResponse, ApiDeleteCalculationModelApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}`,
        method: 'DELETE',
      }),
    }),
    apiGetCalculationModel: build.query<ApiGetCalculationModelApiResponse, ApiGetCalculationModelApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}`,
        params: { unitSystem: queryArg.unitSystem },
      }),
    }),
    apiDeleteCalculationModelMilestones: build.mutation<
      ApiDeleteCalculationModelMilestonesApiResponse,
      ApiDeleteCalculationModelMilestonesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/milestones/${queryArg.milestoneId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateCalculationModelMilestones: build.mutation<
      ApiPostUpdateCalculationModelMilestonesApiResponse,
      ApiPostUpdateCalculationModelMilestonesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/milestones/${queryArg.milestoneId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteCatalogTemplate: build.mutation<ApiDeleteCatalogTemplateApiResponse, ApiDeleteCatalogTemplateApiArg>({
      query: (queryArg) => ({ url: `/api/templates/catalogs/${queryArg.catalogTemplateId}`, method: 'DELETE' }),
    }),
    apiGetCatalogTemplate: build.query<ApiGetCatalogTemplateApiResponse, ApiGetCatalogTemplateApiArg>({
      query: (queryArg) => ({ url: `/api/templates/catalogs/${queryArg.catalogTemplateId}` }),
    }),
    apiPostUpdateCatalogTemplate: build.mutation<
      ApiPostUpdateCatalogTemplateApiResponse,
      ApiPostUpdateCatalogTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/templates/catalogs/${queryArg.catalogTemplateId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteClusterItemRequirementDocument: build.mutation<
      ApiDeleteClusterItemRequirementDocumentApiResponse,
      ApiDeleteClusterItemRequirementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements/${queryArg.requirementId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetClusterItemRequirementDocument: build.query<
      ApiGetClusterItemRequirementDocumentApiResponse,
      ApiGetClusterItemRequirementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements/${queryArg.requirementId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteClusterItemRequirement: build.mutation<
      ApiDeleteClusterItemRequirementApiResponse,
      ApiDeleteClusterItemRequirementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements/${queryArg.requirementId}`,
        method: 'DELETE',
      }),
    }),
    apiGetClusterItemRequirement: build.query<
      ApiGetClusterItemRequirementApiResponse,
      ApiGetClusterItemRequirementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements/${queryArg.requirementId}`,
      }),
    }),
    apiPostUpdateClusterItemRequirement: build.mutation<
      ApiPostUpdateClusterItemRequirementApiResponse,
      ApiPostUpdateClusterItemRequirementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements/${queryArg.requirementId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteCommitment: build.mutation<ApiDeleteCommitmentApiResponse, ApiDeleteCommitmentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/commitments/${queryArg.commitmentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetCommitment: build.query<ApiGetCommitmentApiResponse, ApiGetCommitmentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/commitments/${queryArg.commitmentId}`,
      }),
    }),
    apiPostUpdateCommitment: build.mutation<ApiPostUpdateCommitmentApiResponse, ApiPostUpdateCommitmentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/commitments/${queryArg.commitmentId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteCompanyBranch: build.mutation<ApiDeleteCompanyBranchApiResponse, ApiDeleteCompanyBranchApiArg>({
      query: (queryArg) => ({
        url: `/api/companies/${queryArg.companyId}/branches/${queryArg.companyBranchId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateCompanyBranch: build.mutation<ApiPostUpdateCompanyBranchApiResponse, ApiPostUpdateCompanyBranchApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.companyId}/branches/${queryArg.companyBranchId}`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiDeleteCompany: build.mutation<ApiDeleteCompanyApiResponse, ApiDeleteCompanyApiArg>({
      query: (queryArg) => ({ url: `/api/companies/${queryArg.companyId}`, method: 'DELETE' }),
    }),
    apiGetTenantCompany: build.query<ApiGetTenantCompanyApiResponse, ApiGetTenantCompanyApiArg>({
      query: (queryArg) => ({ url: `/api/companies/${queryArg.companyId}` }),
    }),
    apiDeleteCompanyPerson: build.mutation<ApiDeleteCompanyPersonApiResponse, ApiDeleteCompanyPersonApiArg>({
      query: (queryArg) => ({
        url: `/api/companies/${queryArg.companyId}/persons/${queryArg.companyPersonId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateCompanyPerson: build.mutation<ApiPostUpdateCompanyPersonApiResponse, ApiPostUpdateCompanyPersonApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.companyId}/persons/${queryArg.companyPersonId}`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiDeleteContractDocument: build.mutation<ApiDeleteContractDocumentApiResponse, ApiDeleteContractDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetContractDocument: build.query<ApiGetContractDocumentApiResponse, ApiGetContractDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteContract: build.mutation<ApiDeleteContractApiResponse, ApiDeleteContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}`,
        method: 'DELETE',
      }),
    }),
    apiGetContract: build.query<ApiGetContractApiResponse, ApiGetContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}`,
      }),
    }),
    apiPostUpdateContract: build.mutation<ApiPostUpdateContractApiResponse, ApiPostUpdateContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteContractTitleDocument: build.mutation<
      ApiDeleteContractTitleDocumentApiResponse,
      ApiDeleteContractTitleDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/contract-titles/${queryArg.contractTitleId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetContractTitleDocument: build.query<ApiGetContractTitleDocumentApiResponse, ApiGetContractTitleDocumentApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/contract-titles/${queryArg.contractTitleId}/documents/${queryArg.documentId}`,
        }),
      }
    ),
    apiDeleteContractTitle: build.mutation<ApiDeleteContractTitleApiResponse, ApiDeleteContractTitleApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/titles/${queryArg.contractTitleId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateContractTitle: build.mutation<ApiPostUpdateContractTitleApiResponse, ApiPostUpdateContractTitleApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/titles/${queryArg.contractTitleId}`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiDeleteCostElementDocument: build.mutation<
      ApiDeleteCostElementDocumentApiResponse,
      ApiDeleteCostElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.costElementId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetCostElementDocument: build.query<ApiGetCostElementDocumentApiResponse, ApiGetCostElementDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.costElementId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteCostElement: build.mutation<ApiDeleteCostElementApiResponse, ApiDeleteCostElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiGetCostElement: build.query<ApiGetCostElementApiResponse, ApiGetCostElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.id}`,
      }),
    }),
    apiPostUpdateCostElement: build.mutation<ApiPostUpdateCostElementApiResponse, ApiPostUpdateCostElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteCostElementTag: build.mutation<ApiDeleteCostElementTagApiResponse, ApiDeleteCostElementTagApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.id}/extension-elements/tag/${queryArg.tagId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateCostElementTag: build.mutation<
      ApiPostUpdateCostElementTagApiResponse,
      ApiPostUpdateCostElementTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.id}/extension-elements/tag/${queryArg.tagId}`,
        method: 'POST',
      }),
    }),
    apiDeleteDefaultValues: build.mutation<ApiDeleteDefaultValuesApiResponse, ApiDeleteDefaultValuesApiArg>({
      query: (queryArg) => ({ url: `/api/default-values/${queryArg.defaultValuesId}`, method: 'DELETE' }),
    }),
    apiPostUpdateDefaultValues: build.mutation<ApiPostUpdateDefaultValuesApiResponse, ApiPostUpdateDefaultValuesApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/default-values/${queryArg.defaultValuesId}`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiDeleteDeliveryPhaseCatalog: build.mutation<
      ApiDeleteDeliveryPhaseCatalogApiResponse,
      ApiDeleteDeliveryPhaseCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/delivery-phases-catalog/${queryArg.id}`, method: 'DELETE' }),
    }),
    apiGetDeliveryPhaseCatalog: build.query<ApiGetDeliveryPhaseCatalogApiResponse, ApiGetDeliveryPhaseCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/delivery-phases-catalog/${queryArg.id}` }),
    }),
    apiDeleteEarningsElementDocument: build.mutation<
      ApiDeleteEarningsElementDocumentApiResponse,
      ApiDeleteEarningsElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements/${queryArg.earningsElementId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetEarningsElementDocument: build.query<
      ApiGetEarningsElementDocumentApiResponse,
      ApiGetEarningsElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements/${queryArg.earningsElementId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteEarningsElement: build.mutation<ApiDeleteEarningsElementApiResponse, ApiDeleteEarningsElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiGetEarningsElement: build.query<ApiGetEarningsElementApiResponse, ApiGetEarningsElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements/${queryArg.id}`,
      }),
    }),
    apiPostUpdateEarningsElement: build.mutation<
      ApiPostUpdateEarningsElementApiResponse,
      ApiPostUpdateEarningsElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteEuriborRateProjection: build.mutation<
      ApiDeleteEuriborRateProjectionApiResponse,
      ApiDeleteEuriborRateProjectionApiArg
    >({
      query: (queryArg) => ({ url: `/api/euribor-rate-projection/${queryArg.id}`, method: 'DELETE' }),
    }),
    apiPostUpdateEuriborRateProjection: build.mutation<
      ApiPostUpdateEuriborRateProjectionApiResponse,
      ApiPostUpdateEuriborRateProjectionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/euribor-rate-projection/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteFinancingElementDocument: build.mutation<
      ApiDeleteFinancingElementDocumentApiResponse,
      ApiDeleteFinancingElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.financingElementId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetFinancingElementDocument: build.query<
      ApiGetFinancingElementDocumentApiResponse,
      ApiGetFinancingElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.financingElementId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteFinancingElement: build.mutation<ApiDeleteFinancingElementApiResponse, ApiDeleteFinancingElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiGetFinancingElement: build.query<ApiGetFinancingElementApiResponse, ApiGetFinancingElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.id}`,
      }),
    }),
    apiPostUpdateFinancingElement: build.mutation<
      ApiPostUpdateFinancingElementApiResponse,
      ApiPostUpdateFinancingElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteTenantInvite: build.mutation<ApiDeleteTenantInviteApiResponse, ApiDeleteTenantInviteApiArg>({
      query: (queryArg) => ({
        url: `/api/tenant/delete-invite`,
        method: 'DELETE',
        params: { inviteId: queryArg.inviteId },
      }),
    }),
    apiDeleteInvoiceByContract: build.mutation<ApiDeleteInvoiceByContractApiResponse, ApiDeleteInvoiceByContractApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/invoices/${queryArg.invoiceId}`,
          method: 'DELETE',
        }),
      }
    ),
    apiPostUpdateInvoiceByContract: build.mutation<
      ApiPostUpdateInvoiceByContractApiResponse,
      ApiPostUpdateInvoiceByContractApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/invoices/${queryArg.invoiceId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteInvoiceCalculationRule: build.mutation<
      ApiDeleteInvoiceCalculationRuleApiResponse,
      ApiDeleteInvoiceCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateInvoiceCalculationRule: build.mutation<
      ApiPostUpdateInvoiceCalculationRuleApiResponse,
      ApiPostUpdateInvoiceCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteInvoiceCalculationRuleGroup: build.mutation<
      ApiDeleteInvoiceCalculationRuleGroupApiResponse,
      ApiDeleteInvoiceCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateInvoiceCalculationRuleGroup: build.mutation<
      ApiPostUpdateInvoiceCalculationRuleGroupApiResponse,
      ApiPostUpdateInvoiceCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteInvoiceDocument: build.mutation<ApiDeleteInvoiceDocumentApiResponse, ApiDeleteInvoiceDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetInvoiceDocument: build.query<ApiGetInvoiceDocumentApiResponse, ApiGetInvoiceDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteInvoice: build.mutation<ApiDeleteInvoiceApiResponse, ApiDeleteInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}`,
        method: 'DELETE',
      }),
    }),
    apiGetInvoice: build.query<ApiGetInvoiceApiResponse, ApiGetInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}`,
      }),
    }),
    apiPostUpdateInvoice: build.mutation<ApiPostUpdateInvoiceApiResponse, ApiPostUpdateInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteInvoiceTitle: build.mutation<ApiDeleteInvoiceTitleApiResponse, ApiDeleteInvoiceTitleApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/titles/${queryArg.invoiceTitleId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateInvoiceTitle: build.mutation<ApiPostUpdateInvoiceTitleApiResponse, ApiPostUpdateInvoiceTitleApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/titles/${queryArg.invoiceTitleId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminDeleteLicense: build.mutation<ApiAdminDeleteLicenseApiResponse, ApiAdminDeleteLicenseApiArg>({
      query: (queryArg) => ({ url: `/api/admin/licenses`, method: 'DELETE', body: queryArg.body }),
    }),
    apiAdminGetLicenses: build.query<ApiAdminGetLicensesApiResponse, ApiAdminGetLicensesApiArg>({
      query: () => ({ url: `/api/admin/licenses` }),
    }),
    apiAdminDeleteLicenseGrant: build.mutation<ApiAdminDeleteLicenseGrantApiResponse, ApiAdminDeleteLicenseGrantApiArg>(
      {
        query: (queryArg) => ({ url: `/api/admin/licenses/grants`, method: 'DELETE', body: queryArg.body }),
      }
    ),
    apiDeletePlot: build.mutation<ApiDeletePlotApiResponse, ApiDeletePlotApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots/${queryArg.plotId}`,
        method: 'DELETE',
        params: { ShouldDeleteChildren: queryArg.shouldDeleteChildren },
      }),
    }),
    apiGetPlot: build.query<ApiGetPlotApiResponse, ApiGetPlotApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots/${queryArg.plotId}`,
      }),
    }),
    apiPostUpdatePlot: build.mutation<ApiPostUpdatePlotApiResponse, ApiPostUpdatePlotApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots/${queryArg.plotId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    apiDeletePlotItemFile: build.mutation<ApiDeletePlotItemFileApiResponse, ApiDeletePlotItemFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots/${queryArg.plotItemId}/file/${queryArg.fileId}`,
        method: 'DELETE',
      }),
    }),
    apiGetPlotItemFile: build.query<ApiGetPlotItemFileApiResponse, ApiGetPlotItemFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots/${queryArg.plotItemId}/file/${queryArg.fileId}`,
      }),
    }),
    apiDeleteProjectCalculationRule: build.mutation<
      ApiDeleteProjectCalculationRuleApiResponse,
      ApiDeleteProjectCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateProjectCalculationRule: build.mutation<
      ApiPostUpdateProjectCalculationRuleApiResponse,
      ApiPostUpdateProjectCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteProjectCalculationRuleGroup: build.mutation<
      ApiDeleteProjectCalculationRuleGroupApiResponse,
      ApiDeleteProjectCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateProjectCalculationRuleGroup: build.mutation<
      ApiPostUpdateProjectCalculationRuleGroupApiResponse,
      ApiPostUpdateProjectCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteProject: build.mutation<ApiDeleteProjectApiResponse, ApiDeleteProjectApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}`, method: 'DELETE' }),
    }),
    apiGetProject: build.query<ApiGetProjectApiResponse, ApiGetProjectApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}`,
        params: { unitSystem: queryArg.unitSystem },
      }),
    }),
    apiDeleteTenantProjectInvite: build.mutation<
      ApiDeleteTenantProjectInviteApiResponse,
      ApiDeleteTenantProjectInviteApiArg
    >({
      query: (queryArg) => ({ url: `/api/tenant/delete-project-invite`, method: 'DELETE', body: queryArg.body }),
    }),
    apiDeleteProjectInvoiceCalculationScheme: build.mutation<
      ApiDeleteProjectInvoiceCalculationSchemeApiResponse,
      ApiDeleteProjectInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateProjectInvoiceCalculationScheme: build.mutation<
      ApiPostUpdateProjectInvoiceCalculationSchemeApiResponse,
      ApiPostUpdateProjectInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteProjectConstructionPhase: build.mutation<
      ApiDeleteProjectConstructionPhaseApiResponse,
      ApiDeleteProjectConstructionPhaseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/construction-phase/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateProjectConstrutionPhase: build.mutation<
      ApiPostUpdateProjectConstrutionPhaseApiResponse,
      ApiPostUpdateProjectConstrutionPhaseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/construction-phase/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteProjectStatus: build.mutation<ApiDeleteProjectStatusApiResponse, ApiDeleteProjectStatusApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/status/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateProjectStatus: build.mutation<ApiPostUpdateProjectStatusApiResponse, ApiPostUpdateProjectStatusApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/settings/status/${queryArg.id}`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiDeleteProjectTag: build.mutation<ApiDeleteProjectTagApiResponse, ApiDeleteProjectTagApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/tag/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiDeleteProjectVat: build.mutation<ApiDeleteProjectVatApiResponse, ApiDeleteProjectVatApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/vat/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateProjectVat: build.mutation<ApiPostUpdateProjectVatApiResponse, ApiPostUpdateProjectVatApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/settings/vat/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteRentDealContact: build.mutation<ApiDeleteRentDealContactApiResponse, ApiDeleteRentDealContactApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/contacts/${queryArg.contactId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateRentDealContact: build.mutation<
      ApiPostUpdateRentDealContactApiResponse,
      ApiPostUpdateRentDealContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/contacts/${queryArg.contactId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteRentDealContractItem: build.mutation<
      ApiDeleteRentDealContractItemApiResponse,
      ApiDeleteRentDealContractItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/contractItems/${queryArg.contractItemId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateRentDealContractItem: build.mutation<
      ApiPostUpdateRentDealContractItemApiResponse,
      ApiPostUpdateRentDealContractItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/contractItems/${queryArg.contractItemId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteRentDealCostItem: build.mutation<ApiDeleteRentDealCostItemApiResponse, ApiDeleteRentDealCostItemApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/costItems/${queryArg.costItemId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateRentDealCostItem: build.mutation<
      ApiPostUpdateRentDealCostItemApiResponse,
      ApiPostUpdateRentDealCostItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/costItems/${queryArg.costItemId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteRentDealDocument: build.mutation<ApiDeleteRentDealDocumentApiResponse, ApiDeleteRentDealDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetRentDealDocument: build.query<ApiGetRentDealDocumentApiResponse, ApiGetRentDealDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteRentDeal: build.mutation<ApiDeleteRentDealApiResponse, ApiDeleteRentDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateRentDeal: build.mutation<ApiPostUpdateRentDealApiResponse, ApiPostUpdateRentDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteRiskElementDocument: build.mutation<
      ApiDeleteRiskElementDocumentApiResponse,
      ApiDeleteRiskElementDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/risk-elements/${queryArg.riskElementId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetRiskElementDocument: build.query<ApiGetRiskElementDocumentApiResponse, ApiGetRiskElementDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/risk-elements/${queryArg.riskElementId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteRiskElement: build.mutation<ApiDeleteRiskElementApiResponse, ApiDeleteRiskElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/risk-elements/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    apiGetRiskElement: build.query<ApiGetRiskElementApiResponse, ApiGetRiskElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/risk-elements/${queryArg.id}`,
      }),
    }),
    apiPostUpdateRiskElement: build.mutation<ApiPostUpdateRiskElementApiResponse, ApiPostUpdateRiskElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/risk-elements/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteSellDealContact: build.mutation<ApiDeleteSellDealContactApiResponse, ApiDeleteSellDealContactApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/contacts/${queryArg.contactId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateSellDealContact: build.mutation<
      ApiPostUpdateSellDealContactApiResponse,
      ApiPostUpdateSellDealContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/contacts/${queryArg.contactId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteSellDealContractItem: build.mutation<
      ApiDeleteSellDealContractItemApiResponse,
      ApiDeleteSellDealContractItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/contractItems/${queryArg.contractItemId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateSellDealContractItem: build.mutation<
      ApiPostUpdateSellDealContractItemApiResponse,
      ApiPostUpdateSellDealContractItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/contractItems/${queryArg.contractItemId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteSellDealCostItem: build.mutation<ApiDeleteSellDealCostItemApiResponse, ApiDeleteSellDealCostItemApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/costItems/${queryArg.costItemId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateSellDealCostItem: build.mutation<
      ApiPostUpdateSellDealCostItemApiResponse,
      ApiPostUpdateSellDealCostItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/costItems/${queryArg.costItemId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteSellDealDocument: build.mutation<ApiDeleteSellDealDocumentApiResponse, ApiDeleteSellDealDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/documents/${queryArg.documentId}`,
        method: 'DELETE',
      }),
    }),
    apiGetSellDealDocument: build.query<ApiGetSellDealDocumentApiResponse, ApiGetSellDealDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/documents/${queryArg.documentId}`,
      }),
    }),
    apiDeleteSellDeal: build.mutation<ApiDeleteSellDealApiResponse, ApiDeleteSellDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateSellDeal: build.mutation<ApiPostUpdateSellDealApiResponse, ApiPostUpdateSellDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteTaxonomy: build.mutation<ApiDeleteTaxonomyApiResponse, ApiDeleteTaxonomyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}`,
        method: 'DELETE',
        params: { ShouldDeleteChildren: queryArg.shouldDeleteChildren },
      }),
    }),
    apiPostUpdateTaxonomy: build.mutation<ApiPostUpdateTaxonomyApiResponse, ApiPostUpdateTaxonomyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteTaxonomyItemFile: build.mutation<ApiDeleteTaxonomyItemFileApiResponse, ApiDeleteTaxonomyItemFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}/file/${queryArg.fileId}`,
        method: 'DELETE',
      }),
    }),
    apiGetTaxonomyItemFile: build.query<ApiGetTaxonomyItemFileApiResponse, ApiGetTaxonomyItemFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}/file/${queryArg.fileId}`,
      }),
    }),
    apiDeleteTenantCalculationRule: build.mutation<
      ApiDeleteTenantCalculationRuleApiResponse,
      ApiDeleteTenantCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateTenantCalculationRule: build.mutation<
      ApiPostUpdateTenantCalculationRuleApiResponse,
      ApiPostUpdateTenantCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteTenantCalculationRuleGroup: build.mutation<
      ApiDeleteTenantCalculationRuleGroupApiResponse,
      ApiDeleteTenantCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateTenantCalculationRuleGroup: build.mutation<
      ApiPostUpdateTenantCalculationRuleGroupApiResponse,
      ApiPostUpdateTenantCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteTenantInvoiceCalculationScheme: build.mutation<
      ApiDeleteTenantInvoiceCalculationSchemeApiResponse,
      ApiDeleteTenantInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateTenantInvoiceCalculationScheme: build.mutation<
      ApiPostUpdateTenantInvoiceCalculationSchemeApiResponse,
      ApiPostUpdateTenantInvoiceCalculationSchemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteTenantUserDefinedFieldDefinition: build.mutation<
      ApiDeleteTenantUserDefinedFieldDefinitionApiResponse,
      ApiDeleteTenantUserDefinedFieldDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tenant/user-defined-fields-definition/${queryArg.userDefinedFieldDefinitionId}`,
        method: 'DELETE',
      }),
    }),
    apiPostUpdateTenantUserDefinedFieldDefinition: build.mutation<
      ApiPostUpdateTenantUserDefinedFieldDefinitionApiResponse,
      ApiPostUpdateTenantUserDefinedFieldDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tenant/user-defined-fields-definition/${queryArg.userDefinedFieldDefinitionId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetDetailReport: build.query<ApiGetDetailReportApiResponse, ApiGetDetailReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/detail`,
      }),
    }),
    apiGetDetailReportAxa: build.query<ApiGetDetailReportAxaApiResponse, ApiGetDetailReportAxaApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/detail/axa-udf`,
      }),
    }),
    apiPostTriggerJob: build.mutation<ApiPostTriggerJobApiResponse, ApiPostTriggerJobApiArg>({
      query: () => ({ url: `/api/job/trigger`, method: 'POST' }),
    }),
    apiPostDuplicateTaxonomy: build.mutation<ApiPostDuplicateTaxonomyApiResponse, ApiPostDuplicateTaxonomyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}/duplicate`,
        method: 'POST',
        params: { includeChildren: queryArg.includeChildren },
      }),
    }),
    apiAdminPostEditSubdomain: build.mutation<ApiAdminPostEditSubdomainApiResponse, ApiAdminPostEditSubdomainApiArg>({
      query: (queryArg) => ({ url: `/api/admin/tenant/subdomain`, method: 'POST', body: queryArg.body }),
    }),
    apiGetErrorCodes: build.query<ApiGetErrorCodesApiResponse, ApiGetErrorCodesApiArg>({
      query: () => ({ url: `/api/development/errorcodes` }),
    }),
    apiGetCalculationModelExcel: build.query<ApiGetCalculationModelExcelApiResponse, ApiGetCalculationModelExcelApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/excel`,
        }),
      }
    ),
    apiGetImportFileTemplate: build.query<ApiGetImportFileTemplateApiResponse, ApiGetImportFileTemplateApiArg>({
      query: (queryArg) => ({
        url: `/api/import/templates`,
        params: { contentType: queryArg.contentType, fileType: queryArg.fileType },
      }),
    }),
    apiGetForecastReport: build.query<ApiGetForecastReportApiResponse, ApiGetForecastReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/forecast`,
      }),
    }),
    apiGetForecastExcelReport: build.query<ApiGetForecastExcelReportApiResponse, ApiGetForecastExcelReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/reporting/forecast/excel`,
      }),
    }),
    apiPostForgotPassword: build.mutation<ApiPostForgotPasswordApiResponse, ApiPostForgotPasswordApiArg>({
      query: (queryArg) => ({ url: `/api/auth/forgot-password`, method: 'POST', body: queryArg.body }),
    }),
    apiPostGenerateNextProjectObjectCode: build.mutation<
      ApiPostGenerateNextProjectObjectCodeApiResponse,
      ApiPostGenerateNextProjectObjectCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/codes/generate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostGenerateNextTenantObjectCode: build.mutation<
      ApiPostGenerateNextTenantObjectCodeApiResponse,
      ApiPostGenerateNextTenantObjectCodeApiArg
    >({
      query: (queryArg) => ({ url: `/api/codes/generate`, method: 'POST', body: queryArg.body }),
    }),
    apiGetAiEvalDocuments: build.query<ApiGetAiEvalDocumentsApiResponse, ApiGetAiEvalDocumentsApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/aievaldocument` }),
    }),
    apiPostUploadAiEvalDocument: build.mutation<
      ApiPostUploadAiEvalDocumentApiResponse,
      ApiPostUploadAiEvalDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/aievaldocument`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetAllTasks: build.query<ApiGetAllTasksApiResponse, ApiGetAllTasksApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/tasks`,
      }),
    }),
    apiGetAssemblyVersion: build.query<ApiGetAssemblyVersionApiResponse, ApiGetAssemblyVersionApiArg>({
      query: () => ({ url: `/api/assembly-version` }),
    }),
    apiGetAuditLogsByKey: build.query<ApiGetAuditLogsByKeyApiResponse, ApiGetAuditLogsByKeyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/audit-logs/${queryArg.key}`,
        params: { target: queryArg.target },
      }),
    }),
    apiGetAuditLogsByTarget: build.query<ApiGetAuditLogsByTargetApiResponse, ApiGetAuditLogsByTargetApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/audit-logs`,
        params: { target: queryArg.target },
      }),
    }),
    apiGetAuthPermissionDefinitions: build.query<
      ApiGetAuthPermissionDefinitionsApiResponse,
      ApiGetAuthPermissionDefinitionsApiArg
    >({
      query: () => ({ url: `/api/auth/permissionDefinitions` }),
    }),
    apiGetAuthSubjects: build.query<ApiGetAuthSubjectsApiResponse, ApiGetAuthSubjectsApiArg>({
      query: () => ({ url: `/api/auth/subjects` }),
    }),
    apiPostCalculateAutomaticTimelineDistribution: build.mutation<
      ApiPostCalculateAutomaticTimelineDistributionApiResponse,
      ApiPostCalculateAutomaticTimelineDistributionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/distribution/automatic`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetCalculationModelCosts: build.query<ApiGetCalculationModelCostsApiResponse, ApiGetCalculationModelCostsApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/Costs`,
        }),
      }
    ),
    apiGetCalculationModelEarnings: build.query<
      ApiGetCalculationModelEarningsApiResponse,
      ApiGetCalculationModelEarningsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/Earnings`,
      }),
    }),
    apiGetCalculationModelRisks: build.query<ApiGetCalculationModelRisksApiResponse, ApiGetCalculationModelRisksApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/Risks`,
        }),
      }
    ),
    apiGetCalculationModelFinancing: build.query<
      ApiGetCalculationModelFinancingApiResponse,
      ApiGetCalculationModelFinancingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/Financing`,
      }),
    }),
    apiGetCalculationModelExport: build.query<
      ApiGetCalculationModelExportApiResponse,
      ApiGetCalculationModelExportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/export`,
      }),
    }),
    apiGetCalculationModelHistory: build.query<
      ApiGetCalculationModelHistoryApiResponse,
      ApiGetCalculationModelHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/history`,
        params: { unitSystem: queryArg.unitSystem },
      }),
    }),
    apiGetCatalogElementCode: build.query<ApiGetCatalogElementCodeApiResponse, ApiGetCatalogElementCodeApiArg>({
      query: (queryArg) => ({
        url: `/api/catalogs/code`,
        params: { catalogId: queryArg.catalogId, type: queryArg['type'], parentElementId: queryArg.parentElementId },
      }),
    }),
    apiGetClusterItems: build.query<ApiGetClusterItemsApiResponse, ApiGetClusterItemsApiArg>({
      query: (queryArg) => ({ url: `/api/portfolios/${queryArg.portfolioId}/cluster-items` }),
    }),
    apiGetClusterItemsTree: build.query<ApiGetClusterItemsTreeApiResponse, ApiGetClusterItemsTreeApiArg>({
      query: (queryArg) => ({ url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/tree` }),
    }),
    apiGetCompanyLogo: build.query<ApiGetCompanyLogoApiResponse, ApiGetCompanyLogoApiArg>({
      query: (queryArg) => ({ url: `/api/companies/${queryArg.companyId}/logo/${queryArg.fileId}` }),
    }),
    apiGetCatalog: build.query<ApiGetCatalogApiResponse, ApiGetCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/cost-catalogs/${queryArg.id}` }),
    }),
    apiGetCatalogs: build.query<ApiGetCatalogsApiResponse, ApiGetCatalogsApiArg>({
      query: () => ({ url: `/api/cost-catalogs` }),
    }),
    apiGetCostElementsDistribution: build.mutation<
      ApiGetCostElementsDistributionApiResponse,
      ApiGetCostElementsDistributionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/monthly-distribution`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetCrossTenantContracts: build.query<ApiGetCrossTenantContractsApiResponse, ApiGetCrossTenantContractsApiArg>({
      query: (queryArg) => ({
        url: `/api/cross-tenant/${queryArg.crossTenantId}/projects/${queryArg.crossTenantProjectId}/contracts`,
      }),
    }),
    apiGetCrossTenantProjectCover: build.query<
      ApiGetCrossTenantProjectCoverApiResponse,
      ApiGetCrossTenantProjectCoverApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cross-tenant/${queryArg.crossTenantId}/projects/${queryArg.crossTenantProjectId}/cover/${queryArg.imageId}`,
      }),
    }),
    apiGetAiEvalDocumentFile: build.query<ApiGetAiEvalDocumentFileApiResponse, ApiGetAiEvalDocumentFileApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/aievaldocument/${queryArg.documentId}/file` }),
    }),
    apiPostReprocessAiEvalDocument: build.mutation<
      ApiPostReprocessAiEvalDocumentApiResponse,
      ApiPostReprocessAiEvalDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/aievaldocument/${queryArg.documentId}/file`,
        method: 'POST',
      }),
    }),
    apiGetEarningsCatalog: build.query<ApiGetEarningsCatalogApiResponse, ApiGetEarningsCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/earnings-catalogs/${queryArg.id}` }),
    }),
    apiGetEarningsCatalogs: build.query<ApiGetEarningsCatalogsApiResponse, ApiGetEarningsCatalogsApiArg>({
      query: () => ({ url: `/api/earnings-catalogs` }),
    }),
    apiGetEffectiveTimelineDistribution: build.mutation<
      ApiGetEffectiveTimelineDistributionApiResponse,
      ApiGetEffectiveTimelineDistributionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/distribution/effective`,
        method: 'POST',
        params: { costElementId: queryArg.costElementId, riskElementId: queryArg.riskElementId },
      }),
    }),
    apiGetEmissionYearlyDistributions: build.query<
      ApiGetEmissionYearlyDistributionsApiResponse,
      ApiGetEmissionYearlyDistributionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/emission-yearly-distributions`,
      }),
    }),
    apiGetEuriborRates: build.query<ApiGetEuriborRatesApiResponse, ApiGetEuriborRatesApiArg>({
      query: (queryArg) => ({ url: `/api/euribor-rates`, params: { from: queryArg['from'], to: queryArg.to } }),
    }),
    apiGetFinancingCatalog: build.query<ApiGetFinancingCatalogApiResponse, ApiGetFinancingCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/financing-catalog`, params: { id: queryArg.id } }),
    }),
    apiGetFinancingCatalogs: build.query<ApiGetFinancingCatalogsApiResponse, ApiGetFinancingCatalogsApiArg>({
      query: () => ({ url: `/api/financing-catalogs` }),
    }),
    apiGetFinancingElementsDistributionSuggestion: build.mutation<
      ApiGetFinancingElementsDistributionSuggestionApiResponse,
      ApiGetFinancingElementsDistributionSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/monthly-distribution-suggestion`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetFinancingElementsMetadata: build.query<
      ApiGetFinancingElementsMetadataApiResponse,
      ApiGetFinancingElementsMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements-metadata`,
      }),
    }),
    apiGetFinancingPurposeOverview: build.query<
      ApiGetFinancingPurposeOverviewApiResponse,
      ApiGetFinancingPurposeOverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-purpose/monthly-overview`,
        params: { financingPurposeId: queryArg.financingPurposeId, financingElementId: queryArg.financingElementId },
      }),
    }),
    apiGetUserInvitation: build.query<ApiGetUserInvitationApiResponse, ApiGetUserInvitationApiArg>({
      query: (queryArg) => ({ url: `/api/user/invitation/${queryArg.tokenId}` }),
    }),
    apiGetTenantInvite: build.query<ApiGetTenantInviteApiResponse, ApiGetTenantInviteApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/invite/${queryArg.token}` }),
    }),
    apiGetInvoiceCalculationSample: build.query<
      ApiGetInvoiceCalculationSampleApiResponse,
      ApiGetInvoiceCalculationSampleApiArg
    >({
      query: (queryArg) => ({ url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/sample` }),
    }),
    apiGetInvoiceReport: build.query<ApiGetInvoiceReportApiResponse, ApiGetInvoiceReportApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/report`,
      }),
    }),
    apiGetInvoiceWorkflowDetail: build.query<ApiGetInvoiceWorkflowDetailApiResponse, ApiGetInvoiceWorkflowDetailApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/workflow-detail`,
        }),
      }
    ),
    apiGetJobRouterPdf: build.query<ApiGetJobRouterPdfApiResponse, ApiGetJobRouterPdfApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/job_router/${queryArg.invoiceId}`,
      }),
    }),
    apiGetLicenseFeatures: build.query<ApiGetLicenseFeaturesApiResponse, ApiGetLicenseFeaturesApiArg>({
      query: () => ({ url: `/api/licenses/features` }),
    }),
    apiAdminGetLicenseFeatures: build.query<ApiAdminGetLicenseFeaturesApiResponse, ApiAdminGetLicenseFeaturesApiArg>({
      query: () => ({ url: `/api/admin/licenses/features` }),
    }),
    apiGetLicenseGrants: build.query<ApiGetLicenseGrantsApiResponse, ApiGetLicenseGrantsApiArg>({
      query: () => ({ url: `/api/licenses/grants` }),
    }),
    apiAdminGetLicenseGrants: build.query<ApiAdminGetLicenseGrantsApiResponse, ApiAdminGetLicenseGrantsApiArg>({
      query: (queryArg) => ({ url: `/api/admin/licenses/grants/${queryArg.tenantId}` }),
    }),
    apiGetPasswordResetToken: build.query<ApiGetPasswordResetTokenApiResponse, ApiGetPasswordResetTokenApiArg>({
      query: (queryArg) => ({ url: `/api/user/password-reset/${queryArg.tokenId}` }),
    }),
    apiGetPlainAuthGroup: build.query<ApiGetPlainAuthGroupApiResponse, ApiGetPlainAuthGroupApiArg>({
      query: () => ({ url: `/api/auth/groups/plain` }),
    }),
    apiGetPlainAuthRoles: build.query<ApiGetPlainAuthRolesApiResponse, ApiGetPlainAuthRolesApiArg>({
      query: () => ({ url: `/api/auth/roles/plain` }),
    }),
    apiGetPlainAuthSubjects: build.query<ApiGetPlainAuthSubjectsApiResponse, ApiGetPlainAuthSubjectsApiArg>({
      query: () => ({ url: `/api/auth/subjects/plain` }),
    }),
    apiGetPlotItemThumbnail: build.query<ApiGetPlotItemThumbnailApiResponse, ApiGetPlotItemThumbnailApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots/${queryArg.plotItemId}/thumbnail/${queryArg.thumbnailId}`,
      }),
    }),
    apiGetPortfolio: build.query<ApiGetPortfolioApiResponse, ApiGetPortfolioApiArg>({
      query: (queryArg) => ({ url: `/api/portfolios/${queryArg.portfolioId}` }),
    }),
    apiGetPortfolioNextRequirementCode: build.query<
      ApiGetPortfolioNextRequirementCodeApiResponse,
      ApiGetPortfolioNextRequirementCodeApiArg
    >({
      query: (queryArg) => ({ url: `/api/portfolios/${queryArg.portfolioId}/code` }),
    }),
    apiGetPortfolios: build.query<ApiGetPortfoliosApiResponse, ApiGetPortfoliosApiArg>({
      query: () => ({ url: `/api/portfolios` }),
    }),
    apiGetProjectCompanies: build.query<ApiGetProjectCompaniesApiResponse, ApiGetProjectCompaniesApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/companies` }),
    }),
    apiGetProjectCompanyBranches: build.query<
      ApiGetProjectCompanyBranchesApiResponse,
      ApiGetProjectCompanyBranchesApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/companies/branches` }),
    }),
    apiGetProjectCover: build.query<ApiGetProjectCoverApiResponse, ApiGetProjectCoverApiArg>({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/cover/${queryArg.imageId}` }),
    }),
    apiGetProjectGroupCover: build.query<ApiGetProjectGroupCoverApiResponse, ApiGetProjectGroupCoverApiArg>({
      query: (queryArg) => ({ url: `/api/projectgroups/${queryArg.groupId}/cover/${queryArg.imageId}` }),
    }),
    apiGetProjectGroup: build.query<ApiGetProjectGroupApiResponse, ApiGetProjectGroupApiArg>({
      query: (queryArg) => ({
        url: `/api/projectgroups/${queryArg.groupId}`,
        params: { unitSystem: queryArg.unitSystem },
      }),
    }),
    apiDeleteProjectGroup: build.mutation<ApiDeleteProjectGroupApiResponse, ApiDeleteProjectGroupApiArg>({
      query: (queryArg) => ({ url: `/api/projectgroups/${queryArg.groupId}`, method: 'DELETE' }),
    }),
    apiGetProjectReadableCostCatalog: build.query<
      ApiGetProjectReadableCostCatalogApiResponse,
      ApiGetProjectReadableCostCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/cost-catalogs/${queryArg.id}/readable` }),
    }),
    apiGetProjectReadableEarningsCatalog: build.query<
      ApiGetProjectReadableEarningsCatalogApiResponse,
      ApiGetProjectReadableEarningsCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/earnings-catalogs/${queryArg.id}/readable` }),
    }),
    apiGetProjectReadableFinancingCatalog: build.query<
      ApiGetProjectReadableFinancingCatalogApiResponse,
      ApiGetProjectReadableFinancingCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/financing-catalogs/${queryArg.id}/readable` }),
    }),
    apiGetProjectReadableRiskCatalog: build.query<
      ApiGetProjectReadableRiskCatalogApiResponse,
      ApiGetProjectReadableRiskCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/risk-catalogs/${queryArg.id}/readable` }),
    }),
    apiProjectGetUserDefinedFieldsDefinitionByElement: build.query<
      ApiProjectGetUserDefinedFieldsDefinitionByElementApiResponse,
      ApiProjectGetUserDefinedFieldsDefinitionByElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/user-defined-fields-definition/elements`,
        params: {
          ElementId: queryArg.elementId,
          ElementType: queryArg.elementType,
          CalculateElementType: queryArg.calculateElementType,
        },
      }),
    }),
    apiProjectGetUserDefinedFieldsDefinition: build.query<
      ApiProjectGetUserDefinedFieldsDefinitionApiResponse,
      ApiProjectGetUserDefinedFieldsDefinitionApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/user-defined-fields-definition` }),
    }),
    apiGetProjectWritableCostCatalog: build.query<
      ApiGetProjectWritableCostCatalogApiResponse,
      ApiGetProjectWritableCostCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/cost-catalogs/${queryArg.id}/writable` }),
    }),
    apiGetProjectWritableEarningsCatalog: build.query<
      ApiGetProjectWritableEarningsCatalogApiResponse,
      ApiGetProjectWritableEarningsCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/earnings-catalogs/${queryArg.id}/writable` }),
    }),
    apiGetProjectWritableFinancingCatalog: build.query<
      ApiGetProjectWritableFinancingCatalogApiResponse,
      ApiGetProjectWritableFinancingCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/financing-catalogs/${queryArg.id}/writable` }),
    }),
    apiGetProjectWritableRiskCatalog: build.query<
      ApiGetProjectWritableRiskCatalogApiResponse,
      ApiGetProjectWritableRiskCatalogApiArg
    >({
      query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/risk-catalogs/${queryArg.id}/writable` }),
    }),
    apiPostCalculateRecurringPaymentDistribution: build.mutation<
      ApiPostCalculateRecurringPaymentDistributionApiResponse,
      ApiPostCalculateRecurringPaymentDistributionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/distribution/recurringPayments`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetRegionEmissionFactorsByRegion: build.query<
      ApiGetRegionEmissionFactorsByRegionApiResponse,
      ApiGetRegionEmissionFactorsByRegionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/region-emission-factors`,
        params: { region: queryArg.region, state: queryArg.state },
      }),
    }),
    apiGetRiskCatalog: build.query<ApiGetRiskCatalogApiResponse, ApiGetRiskCatalogApiArg>({
      query: (queryArg) => ({ url: `/api/risk-catalogs/${queryArg.id}` }),
    }),
    apiGetRiskCatalogs: build.query<ApiGetRiskCatalogsApiResponse, ApiGetRiskCatalogsApiArg>({
      query: () => ({ url: `/api/risk-catalogs` }),
    }),
    apiGetSelectableCommitments: build.query<ApiGetSelectableCommitmentsApiResponse, ApiGetSelectableCommitmentsApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/commitments/select`,
        }),
      }
    ),
    apiGetSelectableContracts: build.query<ApiGetSelectableContractsApiResponse, ApiGetSelectableContractsApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/select`,
      }),
    }),
    apiGetRentDealAvailableTaxonomy: build.query<
      ApiGetRentDealAvailableTaxonomyApiResponse,
      ApiGetRentDealAvailableTaxonomyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/availableTaxonomy`,
      }),
    }),
    apiGetSellDealAvailableTaxonomy: build.query<
      ApiGetSellDealAvailableTaxonomyApiResponse,
      ApiGetSellDealAvailableTaxonomyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/availableTaxonomy`,
      }),
    }),
    apiGetTaxonomyItemThumbnail: build.query<ApiGetTaxonomyItemThumbnailApiResponse, ApiGetTaxonomyItemThumbnailApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}/thumbnail/${queryArg.thumbnailId}`,
        }),
      }
    ),
    apiGetTaxonomyWithCostItems: build.query<ApiGetTaxonomyWithCostItemsApiResponse, ApiGetTaxonomyWithCostItemsApiArg>(
      {
        query: (queryArg) => ({ url: `/api/projects/${queryArg.projectId}/taxonomy/withCostItems` }),
      }
    ),
    apiAdminGetTenantAuthProjects: build.query<
      ApiAdminGetTenantAuthProjectsApiResponse,
      ApiAdminGetTenantAuthProjectsApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/tenant/${queryArg.tenantId}/auth/projects` }),
    }),
    apiAdminGetTenantAuthSubjects: build.query<
      ApiAdminGetTenantAuthSubjectsApiResponse,
      ApiAdminGetTenantAuthSubjectsApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/tenant/${queryArg.tenantId}/auth/subjects` }),
    }),
    apiGetTenantCatalogs: build.query<ApiGetTenantCatalogsApiResponse, ApiGetTenantCatalogsApiArg>({
      query: () => ({ url: `/api/catalogs` }),
    }),
    apiGetTenantCompanies: build.query<ApiGetTenantCompaniesApiResponse, ApiGetTenantCompaniesApiArg>({
      query: () => ({ url: `/api/companies` }),
    }),
    apiAdminGetTenants: build.query<ApiAdminGetTenantsApiResponse, ApiAdminGetTenantsApiArg>({
      query: () => ({ url: `/api/admin/tenants` }),
    }),
    apiGetTenantTrusts: build.query<ApiGetTenantTrustsApiResponse, ApiGetTenantTrustsApiArg>({
      query: () => ({ url: `/api/tenant/trusts` }),
    }),
    apiGetUserDefinedFieldsDefinitionByElement: build.query<
      ApiGetUserDefinedFieldsDefinitionByElementApiResponse,
      ApiGetUserDefinedFieldsDefinitionByElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tenant/user-defined-fields-definition/elements`,
        params: {
          ElementId: queryArg.elementId,
          ElementType: queryArg.elementType,
          CalculateElementType: queryArg.calculateElementType,
        },
      }),
    }),
    apiGetUserTenants: build.query<ApiGetUserTenantsApiResponse, ApiGetUserTenantsApiArg>({
      query: () => ({ url: `/api/user/tenants` }),
    }),
    apiGetUserWorkflowTasks: build.query<ApiGetUserWorkflowTasksApiResponse, ApiGetUserWorkflowTasksApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/user-tasks`,
      }),
    }),
    apiPostEarningsElementValuation: build.mutation<
      ApiPostEarningsElementValuationApiResponse,
      ApiPostEarningsElementValuationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/earnings-elements/valuation`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetVatReturnDistributionSuggestion: build.mutation<
      ApiGetVatReturnDistributionSuggestionApiResponse,
      ApiGetVatReturnDistributionSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/vat-return-suggestion`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiGetWorkflows: build.query<ApiGetWorkflowsApiResponse, ApiGetWorkflowsApiArg>({
      query: () => ({ url: `/api/workflows` }),
    }),
    apiAdminGetTenantWorkflows: build.query<ApiAdminGetTenantWorkflowsApiResponse, ApiAdminGetTenantWorkflowsApiArg>({
      query: (queryArg) => ({ url: `/api/admin/elsa/workflows/tenant/${queryArg.tenantId}` }),
    }),
    apiPostCatalogImportFile: build.mutation<ApiPostCatalogImportFileApiResponse, ApiPostCatalogImportFileApiArg>({
      query: (queryArg) => ({ url: `/api/catalog/parse`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCatalogImportData: build.mutation<ApiPostCatalogImportDataApiResponse, ApiPostCatalogImportDataApiArg>({
      query: (queryArg) => ({ url: `/api/catalog/import`, method: 'POST', body: queryArg.body }),
    }),
    apiPostImportContractFile: build.mutation<ApiPostImportContractFileApiResponse, ApiPostImportContractFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostImportCostElementsFile: build.mutation<
      ApiPostImportCostElementsFileApiResponse,
      ApiPostImportCostElementsFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostImportInvoiceFile: build.mutation<ApiPostImportInvoiceFileApiResponse, ApiPostImportInvoiceFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCompaniesImportFile: build.mutation<ApiPostCompaniesImportFileApiResponse, ApiPostCompaniesImportFileApiArg>(
      {
        query: (queryArg) => ({ url: `/api/companies/parse`, method: 'POST', body: queryArg.body }),
      }
    ),
    apiPostCompanyImportData: build.mutation<ApiPostCompanyImportDataApiResponse, ApiPostCompanyImportDataApiArg>({
      query: (queryArg) => ({ url: `/api/companies/import`, method: 'POST', body: queryArg.body }),
    }),
    apiPostInitializeTaxonomy: build.mutation<ApiPostInitializeTaxonomyApiResponse, ApiPostInitializeTaxonomyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/init`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostInviteSetEnabled: build.mutation<ApiPostInviteSetEnabledApiResponse, ApiPostInviteSetEnabledApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/invite/set-enabled`, method: 'POST', body: queryArg.body }),
    }),
    apiPostInviteSignup: build.mutation<ApiPostInviteSignupApiResponse, ApiPostInviteSignupApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/invite/signup`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreateUserInvitation: build.mutation<
      ApiPostCreateUserInvitationApiResponse,
      ApiPostCreateUserInvitationApiArg
    >({
      query: (queryArg) => ({ url: `/api/user/invitation`, method: 'POST', body: queryArg.body }),
    }),
    apiGetImportInvoiceFileTemplate: build.query<
      ApiGetImportInvoiceFileTemplateApiResponse,
      ApiGetImportInvoiceFileTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/import/templates/invoices`,
        params: { fileType: queryArg.fileType },
      }),
    }),
    apiPostLockFinancingElement: build.mutation<
      ApiPostLockFinancingElementApiResponse,
      ApiPostLockFinancingElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.id}/lock`,
        method: 'POST',
      }),
    }),
    apiPostLogin: build.mutation<ApiPostLoginApiResponse, ApiPostLoginApiArg>({
      query: (queryArg) => ({ url: `/api/auth/login`, method: 'POST', body: queryArg.body }),
    }),
    apiPostLogoutSessions: build.mutation<ApiPostLogoutSessionsApiResponse, ApiPostLogoutSessionsApiArg>({
      query: () => ({ url: `/api/auth/logoutSessions`, method: 'POST' }),
    }),
    apiPostLogout: build.mutation<ApiPostLogoutApiResponse, ApiPostLogoutApiArg>({
      query: () => ({ url: `/api/auth/logout`, method: 'POST' }),
    }),
    apiMoveCatalogElement: build.mutation<ApiMoveCatalogElementApiResponse, ApiMoveCatalogElementApiArg>({
      query: (queryArg) => ({ url: `/api/catalog/element/move`, method: 'POST', body: queryArg.body }),
    }),
    apiPostMoveInvoiceCalculationRule: build.mutation<
      ApiPostMoveInvoiceCalculationRuleApiResponse,
      ApiPostMoveInvoiceCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}/move`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostMoveInvoiceCalculationRuleGroup: build.mutation<
      ApiPostMoveInvoiceCalculationRuleGroupApiResponse,
      ApiPostMoveInvoiceCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/move`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostMoveProjectCalculationRule: build.mutation<
      ApiPostMoveProjectCalculationRuleApiResponse,
      ApiPostMoveProjectCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}/move`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostMoveProjectCalculationRuleGroup: build.mutation<
      ApiPostMoveProjectCalculationRuleGroupApiResponse,
      ApiPostMoveProjectCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/move`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostMoveTaxonomy: build.mutation<ApiPostMoveTaxonomyApiResponse, ApiPostMoveTaxonomyApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}/move`,
        method: 'POST',
        params: { newParentId: queryArg.newParentId, orderWithinParent: queryArg.orderWithinParent },
      }),
    }),
    apiPostMoveTenantCalculationRule: build.mutation<
      ApiPostMoveTenantCalculationRuleApiResponse,
      ApiPostMoveTenantCalculationRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/calculation-rules/${queryArg.calculationRuleId}/move`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostMoveTenantCalculationRuleGroup: build.mutation<
      ApiPostMoveTenantCalculationRuleGroupApiResponse,
      ApiPostMoveTenantCalculationRuleGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice-calculation-schemes/${queryArg.invoiceCalculationSchemeId}/calculation-rule-groups/${queryArg.calculationRuleGroupId}/move`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostParseContractFile: build.mutation<ApiPostParseContractFileApiResponse, ApiPostParseContractFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/parse`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostParseCostElementFile: build.mutation<
      ApiPostParseCostElementFileApiResponse,
      ApiPostParseCostElementFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/parse`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostParseInvoiceFile: build.mutation<ApiPostParseInvoiceFileApiResponse, ApiPostParseInvoiceFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/parse`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostPayContract: build.mutation<ApiPostPayContractApiResponse, ApiPostPayContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/pay`,
        method: 'POST',
      }),
    }),
    apiPostPayInvoice: build.mutation<ApiPostPayInvoiceApiResponse, ApiPostPayInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/pay`,
        method: 'POST',
      }),
    }),
    apiPostProjectGroupCover: build.mutation<ApiPostProjectGroupCoverApiResponse, ApiPostProjectGroupCoverApiArg>({
      query: (queryArg) => ({
        url: `/api/projectgroups/${queryArg.groupId}/cover`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiDeleteProjectGroupCover: build.mutation<ApiDeleteProjectGroupCoverApiResponse, ApiDeleteProjectGroupCoverApiArg>(
      {
        query: (queryArg) => ({ url: `/api/projectgroups/${queryArg.groupId}/cover`, method: 'DELETE' }),
      }
    ),
    apiPostGroupProjects: build.mutation<ApiPostGroupProjectsApiResponse, ApiPostGroupProjectsApiArg>({
      query: (queryArg) => ({ url: `/api/projectgroups/projects`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteProjectsFromGroup: build.mutation<ApiDeleteProjectsFromGroupApiResponse, ApiDeleteProjectsFromGroupApiArg>(
      {
        query: (queryArg) => ({ url: `/api/projectgroups/projects`, method: 'DELETE', body: queryArg.body }),
      }
    ),
    apiPostCreateProjectGroup: build.mutation<ApiPostCreateProjectGroupApiResponse, ApiPostCreateProjectGroupApiArg>({
      query: (queryArg) => ({ url: `/api/projectgroups`, method: 'POST', body: queryArg.body }),
    }),
    apiPatchProjectGroup: build.mutation<ApiPatchProjectGroupApiResponse, ApiPatchProjectGroupApiArg>({
      query: (queryArg) => ({ url: `/api/projectgroups`, method: 'PATCH', body: queryArg.body }),
    }),
    apiPostQuickCreateCompany: build.mutation<ApiPostQuickCreateCompanyApiResponse, ApiPostQuickCreateCompanyApiArg>({
      query: (queryArg) => ({ url: `/api/companies/company/quick`, method: 'POST', body: queryArg.body }),
    }),
    apiRemoveBranchFormCompanyPerson: build.mutation<
      ApiRemoveBranchFormCompanyPersonApiResponse,
      ApiRemoveBranchFormCompanyPersonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/companies/${queryArg.companyId}/persons/${queryArg.companyPersonId}/removeBranch`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostReopenContract: build.mutation<ApiPostReopenContractApiResponse, ApiPostReopenContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/reopen`,
        method: 'POST',
      }),
    }),
    apiPostReopenInvoice: build.mutation<ApiPostReopenInvoiceApiResponse, ApiPostReopenInvoiceApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/invoices/${queryArg.invoiceId}/reopen`,
        method: 'POST',
      }),
    }),
    apiPostReopenRentDeal: build.mutation<ApiPostReopenRentDealApiResponse, ApiPostReopenRentDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/reopen`,
        method: 'POST',
      }),
    }),
    apiPostReopenSellDeal: build.mutation<ApiPostReopenSellDealApiResponse, ApiPostReopenSellDealApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/sell/deals/${queryArg.dealId}/reopen`,
        method: 'POST',
      }),
    }),
    apiPostResetPassword: build.mutation<ApiPostResetPasswordApiResponse, ApiPostResetPasswordApiArg>({
      query: (queryArg) => ({ url: `/api/auth/reset-password`, method: 'POST', body: queryArg.body }),
    }),
    apiPostSearch: build.mutation<ApiPostSearchApiResponse, ApiPostSearchApiArg>({
      query: (queryArg) => ({ url: `/api/search`, method: 'POST', body: queryArg.body }),
    }),
    apiGetSentrySentry: build.query<ApiGetSentrySentryApiResponse, ApiGetSentrySentryApiArg>({
      query: () => ({ url: `/api/sentry/sentry` }),
    }),
    apiGetSentryException: build.query<ApiGetSentryExceptionApiResponse, ApiGetSentryExceptionApiArg>({
      query: () => ({ url: `/api/sentry/exception` }),
    }),
    apiPostSettleContract: build.mutation<ApiPostSettleContractApiResponse, ApiPostSettleContractApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/settle`,
        method: 'POST',
      }),
    }),
    apiStartInvoiceWorkflow: build.mutation<ApiStartInvoiceWorkflowApiResponse, ApiStartInvoiceWorkflowApiArg>({
      query: (queryArg) => ({ url: `/api/workflows/start-invoice`, method: 'POST', body: queryArg.body }),
    }),
    apiPostTakeoverInvoiceByContract: build.mutation<
      ApiPostTakeoverInvoiceByContractApiResponse,
      ApiPostTakeoverInvoiceByContractApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/${queryArg.originalContractId}/invoices/${queryArg.invoiceId}/takeover`,
        method: 'POST',
      }),
    }),
    apiPostUpdateTenantBillingAddress: build.mutation<
      ApiPostUpdateTenantBillingAddressApiResponse,
      ApiPostUpdateTenantBillingAddressApiArg
    >({
      query: (queryArg) => ({ url: `/api/tenant/billing-address`, method: 'POST', body: queryArg.body }),
    }),
    apiPostCreateTenantAvatar: build.mutation<ApiPostCreateTenantAvatarApiResponse, ApiPostCreateTenantAvatarApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/avatar`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteTenantAvatar: build.mutation<ApiDeleteTenantAvatarApiResponse, ApiDeleteTenantAvatarApiArg>({
      query: () => ({ url: `/api/tenant/avatar`, method: 'DELETE' }),
    }),
    apiGetTenantAvatar: build.query<ApiGetTenantAvatarApiResponse, ApiGetTenantAvatarApiArg>({
      query: () => ({ url: `/api/tenant/avatar` }),
    }),
    apiPostInviteTenant: build.mutation<ApiPostInviteTenantApiResponse, ApiPostInviteTenantApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/invite`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateTenantNames: build.mutation<ApiPostUpdateTenantNamesApiResponse, ApiPostUpdateTenantNamesApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/names`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateTenantOwner: build.mutation<ApiPostUpdateTenantOwnerApiResponse, ApiPostUpdateTenantOwnerApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/owner`, method: 'POST', body: queryArg.body }),
    }),
    apiPostReinviteTenant: build.mutation<ApiPostReinviteTenantApiResponse, ApiPostReinviteTenantApiArg>({
      query: (queryArg) => ({ url: `/api/tenant/reinvite`, method: 'POST', params: { inviteId: queryArg.inviteId } }),
    }),
    apiPostTestApiAccess: build.mutation<ApiPostTestApiAccessApiResponse, ApiPostTestApiAccessApiArg>({
      query: (queryArg) => ({
        url: `/api/tenant/externalapis/test`,
        method: 'POST',
        params: { accessId: queryArg.accessId },
      }),
    }),
    apiPostUnlockFinancingElement: build.mutation<
      ApiPostUnlockFinancingElementApiResponse,
      ApiPostUnlockFinancingElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/financing-elements/${queryArg.id}/unlock`,
        method: 'POST',
      }),
    }),
    apiPostUpdateAssignableWorkflowActivities: build.mutation<
      ApiPostUpdateAssignableWorkflowActivitiesApiResponse,
      ApiPostUpdateAssignableWorkflowActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/workflows/assignable-activities/${queryArg.assignableActivityId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateAuthSubjectMembership: build.mutation<
      ApiPostUpdateAuthSubjectMembershipApiResponse,
      ApiPostUpdateAuthSubjectMembershipApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/subjects/${queryArg.subjectId}/membership`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateAuthSubject: build.mutation<ApiPostUpdateAuthSubjectApiResponse, ApiPostUpdateAuthSubjectApiArg>({
      query: (queryArg) => ({ url: `/api/auth/subjects/${queryArg.subjectId}`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateAutomaticWorkflowFlag: build.mutation<
      ApiPostUpdateAutomaticWorkflowFlagApiResponse,
      ApiPostUpdateAutomaticWorkflowFlagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/workflows/update-automatic-workflow-flag`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateCalculationModelCashFlowSettings: build.mutation<
      ApiPostUpdateCalculationModelCashFlowSettingsApiResponse,
      ApiPostUpdateCalculationModelCashFlowSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cashflow-settings`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateCaluclationModelFavorite: build.mutation<
      ApiPostUpdateCaluclationModelFavoriteApiResponse,
      ApiPostUpdateCaluclationModelFavoriteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/favorite`,
        method: 'POST',
        params: { favorite: queryArg.favorite },
      }),
    }),
    apiPostUpdateCalculationModelKpis: build.mutation<
      ApiPostUpdateCalculationModelKpisApiResponse,
      ApiPostUpdateCalculationModelKpisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/kpis`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateCalculationModelMetadata: build.mutation<
      ApiPostUpdateCalculationModelMetadataApiResponse,
      ApiPostUpdateCalculationModelMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/metadata`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateClusterItemRequirementStatus: build.mutation<
      ApiPostUpdateClusterItemRequirementStatusApiResponse,
      ApiPostUpdateClusterItemRequirementStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolios/${queryArg.portfolioId}/cluster-items/${queryArg.clusterItemId}/requirements/${queryArg.requirementId}/state`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateCompany: build.mutation<ApiPostUpdateCompanyApiResponse, ApiPostUpdateCompanyApiArg>({
      query: (queryArg) => ({
        url: `/api/companies/company/${queryArg.companyId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdatePrivatePerson: build.mutation<ApiPostUpdatePrivatePersonApiResponse, ApiPostUpdatePrivatePersonApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/companies/privateperson/${queryArg.personId}`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    apiPostContractDocuments: build.mutation<ApiPostContractDocumentsApiResponse, ApiPostContractDocumentsApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/documents`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateCostElementExtensionSettings: build.mutation<
      ApiPostUpdateCostElementExtensionSettingsApiResponse,
      ApiPostUpdateCostElementExtensionSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/${queryArg.id}/extension-element-setting`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateInvoiceTitleByContractTitle: build.mutation<
      ApiPostUpdateInvoiceTitleByContractTitleApiResponse,
      ApiPostUpdateInvoiceTitleByContractTitleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/contracts/${queryArg.contractId}/invoices/${queryArg.invoiceId}/contract-titles/${queryArg.contractTitleId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminUpdateKlippaConfigurations: build.mutation<
      ApiAdminUpdateKlippaConfigurationsApiResponse,
      ApiAdminUpdateKlippaConfigurationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/klippa-configurations/${queryArg.configId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminPostUpdateLicense: build.mutation<ApiAdminPostUpdateLicenseApiResponse, ApiAdminPostUpdateLicenseApiArg>({
      query: (queryArg) => ({ url: `/api/admin/licenses/update`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateLicenseGrant: build.mutation<ApiPostUpdateLicenseGrantApiResponse, ApiPostUpdateLicenseGrantApiArg>({
      query: (queryArg) => ({
        url: `/api/licenses/grants/${queryArg.licenseGrantId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiAdminPostUpdateLicenseGrant: build.mutation<
      ApiAdminPostUpdateLicenseGrantApiResponse,
      ApiAdminPostUpdateLicenseGrantApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/licenses/grants/update`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateLocalLawCostElement: build.mutation<
      ApiPostUpdateLocalLawCostElementApiResponse,
      ApiPostUpdateLocalLawCostElementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/local-law/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiDataUpdateProjectById: build.mutation<
      PostApiDataUpdateProjectByIdApiResponse,
      PostApiDataUpdateProjectByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/data/updateProject/${queryArg.id}`, method: 'POST', body: queryArg.body }),
    }),
    apiPostUpdateProjectMetadata: build.mutation<
      ApiPostUpdateProjectMetadataApiResponse,
      ApiPostUpdateProjectMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/metadata`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateRentDealCondition: build.mutation<
      ApiPostUpdateRentDealConditionApiResponse,
      ApiPostUpdateRentDealConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/rent/deals/${queryArg.dealId}/rentDealConditions`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUpdateVatElement: build.mutation<ApiPostUpdateVatElementApiResponse, ApiPostUpdateVatElementApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/cost-elements/vat/${queryArg.id}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUploadPlotFile: build.mutation<ApiPostUploadPlotFileApiResponse, ApiPostUploadPlotFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/plots/${queryArg.plotItemId}/file`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUploadProjectCover: build.mutation<ApiPostUploadProjectCoverApiResponse, ApiPostUploadProjectCoverApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/cover/upload`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostUploadTaxonomyFile: build.mutation<ApiPostUploadTaxonomyFileApiResponse, ApiPostUploadTaxonomyFileApiArg>({
      query: (queryArg) => ({
        url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/taxonomy/${queryArg.taxonomyItemId}/file`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    apiPostCreateUserAvatar: build.mutation<ApiPostCreateUserAvatarApiResponse, ApiPostCreateUserAvatarApiArg>({
      query: (queryArg) => ({ url: `/api/user/avatar`, method: 'POST', body: queryArg.body }),
    }),
    apiDeleteUserAvatar: build.mutation<ApiDeleteUserAvatarApiResponse, ApiDeleteUserAvatarApiArg>({
      query: () => ({ url: `/api/user/avatar/remove`, method: 'DELETE' }),
    }),
    apiGetUserAvatar: build.query<ApiGetUserAvatarApiResponse, ApiGetUserAvatarApiArg>({
      query: (queryArg) => ({ url: `/api/user/${queryArg.userId}/avatar` }),
    }),
    apiPostValidateIban: build.mutation<ApiPostValidateIbanApiResponse, ApiPostValidateIbanApiArg>({
      query: (queryArg) => ({ url: `/api/validate/iban`, method: 'POST', body: queryArg.body }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiGenerated };
export type ApiPostAcceptUserInvitationApiResponse = /** status 200 Success */ undefined;
export type ApiPostAcceptUserInvitationApiArg = {
  tokenId: string;
  body: AcceptInvitationRequest;
};
export type ApiPostAcceptTenantInviteApiResponse = /** status 200 Success */ undefined;
export type ApiPostAcceptTenantInviteApiArg = {
  body: AcceptTenantInvitePayload;
};
export type ApiPostActiveApiAccessApiResponse = /** status 200 Success */ undefined;
export type ApiPostActiveApiAccessApiArg = {
  apiAccessId?: string;
};
export type ApiAdminPostActivateLicenseGrantApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostActivateLicenseGrantApiArg = {
  body: ActivateLicenseGrantPayload;
};
export type ApiPostApiAccessApiResponse = /** status 200 Success */ undefined;
export type ApiPostApiAccessApiArg = {
  apiId?: string;
  body: ApiCredentialsPayload;
};
export type ApiGetExternalApisApiResponse = /** status 200 Success */ ExternalApiReadModel[];
export type ApiGetExternalApisApiArg = void;
export type ApiRemoveApiAccessApiResponse = /** status 200 Success */ undefined;
export type ApiRemoveApiAccessApiArg = {
  apiAccessId?: string;
};
export type ApiUpdateApiAccessApiResponse = /** status 200 Success */ undefined;
export type ApiUpdateApiAccessApiArg = {
  apiAccessId?: string;
  body: ApiCredentialsPayload;
};
export type ApiPostCreateRentDealContactApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateRentDealContactApiArg = {
  projectId: string;
  dealId: string;
  body: ContactPayload;
};
export type ApiPostCreateRentDealContractItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateRentDealContractItemApiArg = {
  projectId: string;
  dealId: string;
  body: ContractItemPayload;
};
export type ApiPostCreateRentDealCostItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateRentDealCostItemApiArg = {
  taxonomyItemId?: string;
  projectId: string;
  dealId: string;
};
export type ApiPostCreateRentDealDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateRentDealDocumentApiArg = {
  projectId: string;
  dealId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostCreateSellDealContactApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateSellDealContactApiArg = {
  projectId: string;
  dealId: string;
  body: ContactPayload;
};
export type ApiPostCreateSellDealContractItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateSellDealContractItemApiArg = {
  projectId: string;
  dealId: string;
  body: ContractItemPayload;
};
export type ApiPostCreateSellDealCostItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateSellDealCostItemApiArg = {
  taxonomyItemId?: string;
  projectId: string;
  dealId: string;
};
export type ApiPostCreateSellDealDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateSellDealDocumentApiArg = {
  projectId: string;
  dealId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostApplyCostElementForecastAsBudgetApiResponse = /** status 201 Created */ undefined;
export type ApiPostApplyCostElementForecastAsBudgetApiArg = {
  costElementId: string;
  projectId: string;
  calculationModelId: string;
  body: ApplyCostElementForecastAsBudgetPayload;
};
export type ApiPostApproveInvoiceApiResponse = /** status 200 Success */ undefined;
export type ApiPostApproveInvoiceApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateCalculationModelArchiveApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateCalculationModelArchiveApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostAuditInvoiceApiResponse = /** status 200 Success */ undefined;
export type ApiPostAuditInvoiceApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetAuthRolesApiResponse = /** status 200 Success */ AuthRoleReadModel[];
export type ApiGetAuthRolesApiArg = void;
export type ApiPostCreateAuthRoleApiResponse = /** status 201 Created */ CreateRoleResponse;
export type ApiPostCreateAuthRoleApiArg = {
  body: CreateRolePayload;
};
export type ApiGetBaseReportApiResponse = /** status 200 Success */ BaseReportResponse;
export type ApiGetBaseReportApiArg = {
  projectId: string;
};
export type ApiGetBugetReportApiResponse = /** status 200 Success */ BudgetReportResponse;
export type ApiGetBugetReportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetBudgetExcelReportApiResponse = /** status 200 Success */ string;
export type ApiGetBudgetExcelReportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCalculateAllConsumptionsCostApiResponse =
  /** status 200 Success */ CalculateConsumptionOverviewResponse;
export type ApiPostCalculateAllConsumptionsCostApiArg = {
  projectId: string;
  calculationModelId: string;
  body: ConsumptionPayload[];
};
export type ApiPostCalculateConsumptionCostApiResponse = /** status 200 Success */ CalculateConsumptionOverviewResponse;
export type ApiPostCalculateConsumptionCostApiArg = {
  projectId: string;
  calculationModelId: string;
  body: ConsumptionPayload;
};
export type ApiPostCalculateContractTitleApiResponse = /** status 200 Success */ ContractTitleCalculationReadModel;
export type ApiPostCalculateContractTitleApiArg = {
  contractId?: string;
  contractTitleId?: string;
  projectId: string;
  calculationModelId: string;
  body: CalculateContractTitlePayload;
};
export type ApiPostCalculateFormulaApiResponse = /** status 200 Success */ CalculateFormulaResult;
export type ApiPostCalculateFormulaApiArg = {
  projectId: string;
  calculationModelId: string;
  body: FormulaPayload;
};
export type ApiPostCalculateInvoiceValueDistributionApiResponse =
  /** status 200 Success */ InvoiceTitleDistributionReadModel[];
export type ApiPostCalculateInvoiceValueDistributionApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
  body: CalculateInvoiceValueDistributionPayload;
};
export type ApiCancelAiEvalProcessApiResponse = /** status 200 Success */ undefined;
export type ApiCancelAiEvalProcessApiArg = {
  documentId: string;
  projectId: string;
};
export type ApiGetCashFlowReportApiResponse = /** status 200 Success */ CashFlowReportResponse;
export type ApiGetCashFlowReportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateCalculationModelVersionApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCalculationModelVersionApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateUserEmailApiResponse = unknown;
export type ApiPostUpdateUserEmailApiArg = {
  body: ChangeEmailCommand;
};
export type ApiPostUpdateUserLanguageApiResponse = unknown;
export type ApiPostUpdateUserLanguageApiArg = {
  body: ChangeLanguageCommand;
};
export type ApiPostUpdateUserNameApiResponse = unknown;
export type ApiPostUpdateUserNameApiArg = {
  body: ChangeNameCommand;
};
export type ApiPostUpdatePasswordApiResponse = unknown;
export type ApiPostUpdatePasswordApiArg = {
  body: ChangePasswordCommand;
};
export type ApiPostCheckSessionApiResponse = /** status 200 Success */ CheckSessionCommandResponse;
export type ApiPostCheckSessionApiArg = void;
export type ApiGetDevelopmentCommandListApiResponse = /** status 200 Success */ CommandDescriptorDto[];
export type ApiGetDevelopmentCommandListApiArg = void;
export type ApiGetComparisonReportApiResponse = /** status 200 Success */ ComparisonReportResponse;
export type ApiGetComparisonReportApiArg = {
  targetId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetComparisonExcelReportApiResponse = /** status 200 Success */ string;
export type ApiGetComparisonExcelReportApiArg = {
  targetId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostReviewInvoiceWorkflowTaskApiResponse = /** status 200 Success */ undefined;
export type ApiPostReviewInvoiceWorkflowTaskApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CompleteReviewInvoiceWorkflowTaskPayload;
};
export type ApiGetImportContractFileTemplateApiResponse = unknown;
export type ApiGetImportContractFileTemplateApiArg = {
  fileType?: TemplateFileType;
  projectId: string;
};
export type ApiGetContractReportApiResponse = /** status 200 Success */ ContractReportReadModel[];
export type ApiGetContractReportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiAdminPostConvertContractsApiResponse = /** status 200 Success */ ContractConversionReadModel[];
export type ApiAdminPostConvertContractsApiArg = {
  body: ConvertContractsPayload;
};
export type ApiPostCopyAuthRoleApiResponse = /** status 200 Success */ CopyRoleResponse;
export type ApiPostCopyAuthRoleApiArg = {
  roleId: string;
  body: CopyRolePayload;
};
export type ApiPostCreateCalculationModelCopyApiResponse =
  /** status 200 Success */ CopyCalculationModelCommandResponse;
export type ApiPostCreateCalculationModelCopyApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CopyCalculationModelPayload;
};
export type ApiPostCopyContractApiResponse = /** status 200 Success */ CopyContractResponse;
export type ApiPostCopyContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
  body: CopyContractPayload;
};
export type ApiPostCreateProjectCopyApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateProjectCopyApiArg = {
  projectId: string;
  body: CopyProjectPayload;
};
export type ApiPostCopyProjectInvoiceCalculationSchemeApiResponse =
  /** status 200 Success */ CopyInvoiceCalculationSchemeResponse;
export type ApiPostCopyProjectInvoiceCalculationSchemeApiArg = {
  invoiceCalculationSchemeId: string;
  projectId: string;
  body: CopyInvoiceCalculationSchemePayload;
};
export type ApiAdminPostCopyProjectToTenantApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostCopyProjectToTenantApiArg = {
  body: CopyProjectToTenantPayload;
};
export type ApiPostCopyTenantInvoiceCalculationSchemeApiResponse =
  /** status 200 Success */ CopyInvoiceCalculationSchemeResponse;
export type ApiPostCopyTenantInvoiceCalculationSchemeApiArg = {
  invoiceCalculationSchemeId: string;
  body: CopyInvoiceCalculationSchemePayload;
};
export type ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiResponse =
  /** status 200 Success */ CopyInvoiceCalculationSchemeResponse;
export type ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiArg = {
  invoiceCalculationSchemeId: string;
  projectId: string;
  body: CopyInvoiceCalculationSchemePayload;
};
export type ApiAdminPostCopyWorkflowApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostCopyWorkflowApiArg = {
  body: CopyWorkflowPayload;
};
export type ApiGetImportCostElementFileTemplateApiResponse = unknown;
export type ApiGetImportCostElementFileTemplateApiArg = {
  fileType?: TemplateFileType;
  projectId: string;
};
export type ApiPostAssignableWorkflowActivitiesApiResponse =
  /** status 200 Success */ CreateAssignableWorkflowActivityResponse;
export type ApiPostAssignableWorkflowActivitiesApiArg = {
  projectId: string;
  body: CreateWorkflowAssignableActivityPayload;
};
export type ApiGetAssignableWorkflowActivitiesApiResponse =
  /** status 200 Success */ GetAssignableWorkflowActivitiesQueryResponse;
export type ApiGetAssignableWorkflowActivitiesApiArg = {
  projectId: string;
};
export type ApiPostCreateAuthGroupApiResponse = /** status 201 Created */ CreateGroupResponse;
export type ApiPostCreateAuthGroupApiArg = {
  body: GroupPayload;
};
export type ApiGetAuthGroupsApiResponse = /** status 200 Success */ AuthGroupReadModel[];
export type ApiGetAuthGroupsApiArg = void;
export type ApiPostCreateTaxonomyApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateTaxonomyApiArg = {
  projectId: string;
  calculationModelId: string;
  body: TaxonomyItemPayload;
};
export type ApiGetTaxonomyApiResponse = /** status 200 Success */ TaxonomyReadModel;
export type ApiGetTaxonomyApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateCalculationModelDeliveryPhasesApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateCalculationModelDeliveryPhasesApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CalculationModelDeliveryPhasePayload;
};
export type ApiGetCalculationModelDeliveryPhasesApiResponse =
  /** status 200 Success */ GetCalculationModelDeliveryPhasesQueryResponse;
export type ApiGetCalculationModelDeliveryPhasesApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateCalculationModelMilestonesApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateCalculationModelMilestonesApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CalculationModelMilestonePayload;
};
export type ApiGetCalculationModelMilestonesApiResponse =
  /** status 200 Success */ GetCalculationModelMilestonesQueryResponse;
export type ApiGetCalculationModelMilestonesApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateCalculationModelSnapshotApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateCalculationModelSnapshotApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CreateCalculationModelUserSnapshotPayload;
};
export type ApiCreateCatalogElementApiResponse = /** status 200 Success */ undefined;
export type ApiCreateCatalogElementApiArg = {
  body: CreateTenantCatalogElementPayload;
};
export type ApiDeleteCatalogElementApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteCatalogElementApiArg = {
  body: DeleteTenantCatalogElementPayload;
};
export type ApiUpdateCatalogElementApiResponse = /** status 200 Success */ undefined;
export type ApiUpdateCatalogElementApiArg = {
  body: UpdateTenantCatalogElementPayload;
};
export type ApiCreateCatalogApiResponse = /** status 200 Success */ undefined;
export type ApiCreateCatalogApiArg = {
  body: CreateTenantCatalogPayload;
};
export type ApiDeleteCatalogApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteCatalogApiArg = {
  body: DeleteTenantCatalogPayload;
};
export type ApiUpdateCatalogApiResponse = /** status 200 Success */ undefined;
export type ApiUpdateCatalogApiArg = {
  body: UpdateTenantCatalogPayload;
};
export type ApiPostCreateCatalogTemplateApiResponse = /** status 201 Created */ CreateCatalogTemplateResponse;
export type ApiPostCreateCatalogTemplateApiArg = {
  body: CatalogTemplatePayload;
};
export type ApiGetCatalogTemplatesApiResponse = /** status 200 Success */ CatalogTemplateReadModel[];
export type ApiGetCatalogTemplatesApiArg = void;
export type ApiPostCreateClusterItemRequirementDocumentsApiResponse = /** status 200 Success */ string;
export type ApiPostCreateClusterItemRequirementDocumentsApiArg = {
  requirementId: string;
  clusterItemId: string;
  portfolioId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostCreateClusterItemRequirementApiResponse =
  /** status 200 Success */ CreateClusterItemRequirementResponse;
export type ApiPostCreateClusterItemRequirementApiArg = {
  clusterItemId: string;
  portfolioId: string;
  body: CreateClusterItemRequirementPayload;
};
export type ApiPostCreateCommitmentApiResponse = /** status 200 Success */ CreateCommitmentResponse;
export type ApiPostCreateCommitmentApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CreateCommitmentPayload;
};
export type ApiGetCommitmentsApiResponse = /** status 200 Success */ ShortCommitmentReadModel[];
export type ApiGetCommitmentsApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateCompanyBranchApiResponse = /** status 200 Success */ CreateCompanyBranchResponse;
export type ApiPostCreateCompanyBranchApiArg = {
  companyId: string;
  body: CreateCompanyBranchPayload;
};
export type ApiPostCreateCompanyApiResponse = /** status 200 Success */ CreateCompanyResponse;
export type ApiPostCreateCompanyApiArg = {
  body: {
    Logo?: Blob;
    Name?: string;
    TaxId?: string;
    CompanyRegistrationNumber?: string;
    Link?: string;
  };
};
export type ApiPostCreatePrivatePersonApiResponse = /** status 200 Success */ CreatePrivatePersonResponse;
export type ApiPostCreatePrivatePersonApiArg = {
  body: PrivatePersonPayload;
};
export type ApiPostCreateCompanyPersonApiResponse = /** status 200 Success */ CreateCompanyPersonResponse;
export type ApiPostCreateCompanyPersonApiArg = {
  companyId: string;
  body: CompanyPersonPayload;
};
export type ApiPostCreateContractApiResponse = /** status 200 Success */ CreateContractResponse;
export type ApiPostCreateContractApiArg = {
  projectId: string;
  calculationModelId: string;
  body: {
    Code?: string;
    Name?: string;
    Description?: string;
    ContractDate?: string;
    Vat?: number;
    Discount?: number;
    DueDateDeadline?: number;
    CashDiscountDeadline?: number;
    CommitmentId?: string;
    ContractorId?: string;
    ClientId?: string;
    'CumulatedInvoiceCalculationScheme.InvoiceCalculationSchemeId'?: string;
    'CumulatedInvoiceCalculationScheme.ValueOverrides'?: CalculationRuleValueOverridePayload[];
    'SingleInvoiceCalculationScheme.InvoiceCalculationSchemeId'?: string;
    'SingleInvoiceCalculationScheme.ValueOverrides'?: CalculationRuleValueOverridePayload[];
    'AdvancePaymentInvoiceCalculationScheme.InvoiceCalculationSchemeId'?: string;
    'AdvancePaymentInvoiceCalculationScheme.ValueOverrides'?: CalculationRuleValueOverridePayload[];
    Files?: Blob[];
    'UserDefinedFields.UserDefinedFields'?: UserDefinedFieldPayload[];
  };
};
export type ApiGetContractsApiResponse = /** status 200 Success */ GetContractsQueryResponse;
export type ApiGetContractsApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateContractTitleDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateContractTitleDocumentApiArg = {
  contractId: string;
  contractTitleId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiGetContractTitleDocumentsApiResponse = /** status 200 Success */ DocumentReadModel[];
export type ApiGetContractTitleDocumentsApiArg = {
  contractId: string;
  contractTitleId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateContractTitleApiResponse = /** status 200 Success */ CreateContractTitleResponse;
export type ApiPostCreateContractTitleApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
  body: CreateContractTitlePayload;
};
export type ApiPostCreateCostElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateCostElementDocumentApiArg = {
  costElementId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostCreateCostElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostCreateCostElementApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CostElementPayload;
};
export type ApiPostCreateCostElementTagApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateCostElementTagApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: TagPayload;
};
export type ApiPostCreateDefaultValuesApiResponse = /** status 200 Success */ CreateDefaultValuesResponse;
export type ApiPostCreateDefaultValuesApiArg = {
  body: DefaultValuesPayload;
};
export type ApiGetDefaultValuesApiResponse = /** status 200 Success */ DefaultValuesReadModel;
export type ApiGetDefaultValuesApiArg = void;
export type ApiPostCreateDeliveryPhaseCatalogApiResponse = /** status 200 Success */ CreateDeliveryPhaseResponse;
export type ApiPostCreateDeliveryPhaseCatalogApiArg = {
  catalogId: string;
  body: DeliveryPhasePayload;
};
export type ApiPostCreateDeliveryPhaseApiResponse = /** status 201 Created */ CreateDeliveryPhasesCatalogResponse;
export type ApiPostCreateDeliveryPhaseApiArg = {
  body: DeliveryPhasesCatalogPayload;
};
export type ApiGetCatalogsDeliveryPhasesApiResponse = /** status 200 Success */ GetDeliveryPhasesCatalogsQueryResponse;
export type ApiGetCatalogsDeliveryPhasesApiArg = void;
export type ApiPostCreateEarningsElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateEarningsElementDocumentApiArg = {
  earningsElementId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostCreateEarningsElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostCreateEarningsElementApiArg = {
  projectId: string;
  calculationModelId: string;
  body: ModifyEarningsElementPayload;
};
export type ApiPostCreateFinancingElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateFinancingElementDocumentApiArg = {
  financingElementId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostCreateFinancingElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostCreateFinancingElementApiArg = {
  projectId: string;
  calculationModelId: string;
  body: FinancingElementPayload;
};
export type ApiPostCreateInvoiceByContractApiResponse = /** status 200 Success */ CreateInvoiceByContractResponse;
export type ApiPostCreateInvoiceByContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
  body: CreateInvoiceByContractDto;
};
export type ApiPostCreateInvoiceByContractWithSourceDocumentApiResponse =
  /** status 201 Created */ CreateInvoiceByContractResponse;
export type ApiPostCreateInvoiceByContractWithSourceDocumentApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    InvoiceDocumentId?: string;
    CalculationSchemeId?: string;
    Code?: string;
    ExternalCode?: string;
    Type?: InvoiceType;
    Vat?: number;
    Claim?: number;
    InvoiceDate?: string;
    DateOfReceipt?: string;
    DueDate?: string;
    CashDiscountDate?: string;
    Comment?: string;
    InvoiceSourceDocument?: Blob;
    UserDefinedFieldsPayload?: string;
  };
};
export type ApiPostCreateInvoiceCalculationRuleApiResponse = /** status 200 Success */ CreateCalculationRuleResponse;
export type ApiPostCreateInvoiceCalculationRuleApiArg = {
  invoiceId: string;
  calculationRuleGroupId: string;
  projectId: string;
  calculationModelId: string;
  body: CreateCalculationRulePayload;
};
export type ApiPostCreateInvoiceCalculationRuleGroupApiResponse =
  /** status 200 Success */ CreateCalculationRuleGroupResponse;
export type ApiPostCreateInvoiceCalculationRuleGroupApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
  body: CreateCalculationRuleGroupPayload;
};
export type ApiPostCreateInvoiceDocumentsApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateInvoiceDocumentsApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostCreateInvoiceApiResponse = /** status 200 Success */ CreateInvoiceResponse;
export type ApiPostCreateInvoiceApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CreateInvoiceDto;
};
export type ApiGetInvoicesApiResponse = /** status 200 Success */ GetInvoicesQueryResponse;
export type ApiGetInvoicesApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateInvoiceSourceDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateInvoiceSourceDocumentApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    file?: Blob;
  };
};
export type ApiPostCreateInvoiceTitleApiResponse = /** status 200 Success */ CreateInvoiceTitleResponse;
export type ApiPostCreateInvoiceTitleApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
  body: InvoiceTitlePayload;
};
export type ApiPostCreateInvoiceWithSourceDocumentApiResponse = /** status 201 Created */ CreateInvoiceResponse;
export type ApiPostCreateInvoiceWithSourceDocumentApiArg = {
  projectId: string;
  calculationModelId: string;
  body: {
    InvoiceDocumentId?: string;
    CalculationSchemeId?: string;
    InvoicingPartyId?: string;
    InvoiceRecipientId?: string;
    Code?: string;
    ExternalCode?: string;
    Type?: InvoiceType;
    Vat?: number;
    Claim?: number;
    InvoiceDate?: string;
    DateOfReceipt?: string;
    DueDate?: string;
    CashDiscountDate?: string;
    CostElementId?: string;
    Comment?: string;
    InvoiceSourceDocument?: Blob;
    UserDefinedFieldsPayload?: string;
  };
};
export type ApiAdminCreateKlippaConfigurationsApiResponse = /** status 200 Success */ undefined;
export type ApiAdminCreateKlippaConfigurationsApiArg = {
  body: UpdateKlippaConfigurationRequest;
};
export type ApiAdminGetKlippaConfigurationsApiResponse = /** status 200 Success */ GetKlippaApiConfigurationsResponse;
export type ApiAdminGetKlippaConfigurationsApiArg = void;
export type ApiAdminPostCreateLicenseApiResponse = /** status 200 Success */ AdminCreateLicenseResponse;
export type ApiAdminPostCreateLicenseApiArg = {
  body: CreateLicensePayload;
};
export type ApiAdminPostCreateLicenseGrantApiResponse = /** status 200 Success */ AdminCreateLicenseGrantResponse;
export type ApiAdminPostCreateLicenseGrantApiArg = {
  body: CreateLicenseGrantPayload;
};
export type ApiPostCreateLocalLawCostElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostCreateLocalLawCostElementApiArg = {
  projectId: string;
  calculationModelId: string;
  body: LocalLawElementPayload;
};
export type ApiPostCreatePlotApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreatePlotApiArg = {
  projectId: string;
  calculationModelId: string;
  body: PlotPayload;
};
export type ApiGetPlotsByCalculationModelApiResponse = /** status 200 Success */ PlotReadModel;
export type ApiGetPlotsByCalculationModelApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostCreateProjectCalculationRuleApiResponse = /** status 200 Success */ CreateCalculationRuleResponse;
export type ApiPostCreateProjectCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  projectId: string;
  body: CreateCalculationRulePayload;
};
export type ApiPostCreateProjectCalculationRuleGroupApiResponse =
  /** status 200 Success */ CreateCalculationRuleGroupResponse;
export type ApiPostCreateProjectCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  projectId: string;
  body: CreateCalculationRuleGroupPayload;
};
export type ApiPostCreateEuriborRateProjectionApiResponse = /** status 201 Created */ undefined;
export type ApiPostCreateEuriborRateProjectionApiArg = {
  body: CreateEuriborPayload;
};
export type ApiPostCreateProjectApiResponse = /** status 200 Success */ CreateProjectResponse;
export type ApiPostCreateProjectApiArg = {
  body: {
    ProjectId?: string;
    Name?: string;
    Street?: string;
    HouseNumber?: string;
    PostalCode?: string;
    City?: string;
    CountryCode?: string;
    Vat?: number;
    PlotSize?: number;
    Gfz?: number;
    Grz?: number;
    StartDate?: string;
    CostCatalogId?: string;
    DeliveryPhasesCatalogId?: string;
    RiskCatalogId?: string;
    EarningsCatalogId?: string;
    FinancingCatalogId?: string;
    CoverImage?: Blob;
    ProjectGroupId?: string;
    UnitSystem?: UnitSystem;
    UserDefinedFieldsPayload?: string;
  };
};
export type ApiGetProjectsApiResponse = /** status 200 Success */ GetProjectsQueryResponse;
export type ApiGetProjectsApiArg = void;
export type ApiPostCreateProjectInvoiceCalculationSchemeApiResponse =
  /** status 200 Success */ CreateInvoiceCalculationSchemeResponse;
export type ApiPostCreateProjectInvoiceCalculationSchemeApiArg = {
  projectId: string;
  body: InvoiceCalculationSchemePayload;
};
export type ApiGetProjectInvoiceCalculationSchemesApiResponse =
  /** status 200 Success */ InvoiceCalculationSchemeReadModel[];
export type ApiGetProjectInvoiceCalculationSchemesApiArg = {
  projectId: string;
};
export type ApiPostCreateProjectConstructionPhaseApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateProjectConstructionPhaseApiArg = {
  projectId: string;
  body: ElementPayload;
};
export type ApiPostCreateProjectStatusApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateProjectStatusApiArg = {
  projectId: string;
  body: ElementPayload;
};
export type ApiPostCreateProjectTagApiResponse = /** status 200 Success */ CreateProjectSettingTagCommandResponse;
export type ApiPostCreateProjectTagApiArg = {
  projectId: string;
  body: TagPayload;
};
export type ApiPostCreateProjectVatApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateProjectVatApiArg = {
  projectId: string;
  body: VatPayload;
};
export type ApiPostProjectValuationApiResponse = /** status 200 Success */ undefined;
export type ApiPostProjectValuationApiArg = {
  projectId?: string;
};
export type ApiPostCreateRentDealApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateRentDealApiArg = {
  projectId: string;
  body: DealPayload;
};
export type ApiGetRentDealsApiResponse = /** status 200 Success */ GetDealsResult;
export type ApiGetRentDealsApiArg = {
  projectId: string;
};
export type ApiPostCreateRiskElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateRiskElementDocumentApiArg = {
  riskElementId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostCreateRiskElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostCreateRiskElementApiArg = {
  projectId: string;
  calculationModelId: string;
  body: ModifyRiskElementPayload;
};
export type ApiPostCreateSellDealApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateSellDealApiArg = {
  projectId: string;
  body: DealPayload;
};
export type ApiGetSellDealsApiResponse = /** status 200 Success */ GetDealsResult;
export type ApiGetSellDealsApiArg = {
  projectId: string;
};
export type ApiPostCreateTenantCalculationRuleApiResponse = /** status 200 Success */ CreateCalculationRuleResponse;
export type ApiPostCreateTenantCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  body: CreateCalculationRulePayload;
};
export type ApiPostCreateTenantCalculationRuleGroupApiResponse =
  /** status 200 Success */ CreateCalculationRuleGroupResponse;
export type ApiPostCreateTenantCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  body: CreateCalculationRuleGroupPayload;
};
export type ApiAdminPostCreateTenantApiResponse = /** status 200 Success */ CreateTenantResponse;
export type ApiAdminPostCreateTenantApiArg = {
  body: CreateTenantPayload;
};
export type ApiPostCreateTenantInvoiceCalculationSchemeApiResponse =
  /** status 200 Success */ CreateInvoiceCalculationSchemeResponse;
export type ApiPostCreateTenantInvoiceCalculationSchemeApiArg = {
  body: InvoiceCalculationSchemePayload;
};
export type ApiGetTenantInvoiceCalculationSchemesApiResponse =
  /** status 200 Success */ InvoiceCalculationSchemeReadModel[];
export type ApiGetTenantInvoiceCalculationSchemesApiArg = void;
export type ApiPostCreateTenantUserDefinedFieldDefinitionApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateTenantUserDefinedFieldDefinitionApiArg = {
  body: UserDefinedFieldDefinitionPayload;
};
export type ApiGetUserDefinedFieldsDefinitionApiResponse =
  /** status 200 Success */ GetUserDefinedFieldsDefinitionQueryResponse;
export type ApiGetUserDefinedFieldsDefinitionApiArg = void;
export type ApiAdminPostCreateUserApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostCreateUserApiArg = {
  body: CreateUserPayload;
};
export type ApiPostCreateVatCostElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostCreateVatCostElementApiArg = {
  projectId: string;
  calculationModelId: string;
  body: VatElementPayload;
};
export type ApiPostCreateProjectVersionApiResponse = /** status 200 Success */ CreateVersionCommandResponse;
export type ApiPostCreateProjectVersionApiArg = {
  projectId: string;
  body: CreateVersionPayload;
};
export type ApiGetCrossTenantAvatarApiResponse = /** status 200 Success */ string;
export type ApiGetCrossTenantAvatarApiArg = {
  crossTenantId: string;
};
export type ApiPostCrossTenantUploadAiEvalDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostCrossTenantUploadAiEvalDocumentApiArg = {
  contractId: string;
  crossTenantId: string;
  crossTenantProjectId: string;
  body: {
    file?: Blob;
  };
};
export type ApiGetExchangeDataImportsApiResponse = /** status 200 Success */ ImportReadModel[];
export type ApiGetExchangeDataImportsApiArg = {
  proId: string;
};
export type ApiGetExchangeDataHasNewImportsApiResponse = /** status 200 Success */ boolean;
export type ApiGetExchangeDataHasNewImportsApiArg = {
  proId: string;
  calcModelId: string;
};
export type ApiPostExchangeDataProcessImportApiResponse = /** status 200 Success */ undefined;
export type ApiPostExchangeDataProcessImportApiArg = {
  proId: string;
  calcModelId: string;
  body: ImportProbisDataPayload;
};
export type ApiAdminPostDeactivateLicenseGrantApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostDeactivateLicenseGrantApiArg = {
  body: DeactivateLicenseGrantPayload;
};
export type ApiDeleteAiEvalDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteAiEvalDocumentApiArg = {
  invoiceId?: string;
  documentId: string;
  projectId: string;
};
export type ApiGetAiEvalResultApiResponse = /** status 200 Success */ AiEvalResultReadModel;
export type ApiGetAiEvalResultApiArg = {
  documentId: string;
  calculationModelId?: string;
  projectId: string;
};
export type ApiDeleteAuthGroupApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteAuthGroupApiArg = {
  groupId: string;
};
export type ApiPostUpdateAuthGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateAuthGroupApiArg = {
  groupId: string;
  body: UpdateGroupPayload;
};
export type ApiDeleteAuthRoleApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteAuthRoleApiArg = {
  roleId: string;
};
export type ApiPostUpdateAuthRoleApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateAuthRoleApiArg = {
  roleId: string;
  body: UpdateRolePayload;
};
export type ApiDeleteCalculationModelDeliveryPhasesApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteCalculationModelDeliveryPhasesApiArg = {
  deliveryPhaseId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateCalculationModelDeliveryPhasesApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCalculationModelDeliveryPhasesApiArg = {
  deliveryPhaseId: string;
  projectId: string;
  calculationModelId: string;
  body: CalculationModelDeliveryPhasePayload;
};
export type ApiDeleteCalculationModelApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteCalculationModelApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCalculationModelApiResponse = /** status 200 Success */ GetCalculationModelQueryResponse;
export type ApiGetCalculationModelApiArg = {
  unitSystem?: UnitSystem;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteCalculationModelMilestonesApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteCalculationModelMilestonesApiArg = {
  milestoneId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateCalculationModelMilestonesApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCalculationModelMilestonesApiArg = {
  milestoneId: string;
  projectId: string;
  calculationModelId: string;
  body: CalculationModelMilestonePayload;
};
export type ApiDeleteCatalogTemplateApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteCatalogTemplateApiArg = {
  catalogTemplateId: string;
};
export type ApiGetCatalogTemplateApiResponse = /** status 200 Success */ CatalogTemplateReadModel;
export type ApiGetCatalogTemplateApiArg = {
  catalogTemplateId: string;
};
export type ApiPostUpdateCatalogTemplateApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCatalogTemplateApiArg = {
  catalogTemplateId: string;
  body: UpdateCatalogTemplatePayload;
};
export type ApiDeleteClusterItemRequirementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteClusterItemRequirementDocumentApiArg = {
  clusterItemId: string;
  requirementId: string;
  documentId: string;
  portfolioId: string;
};
export type ApiGetClusterItemRequirementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetClusterItemRequirementDocumentApiArg = {
  clusterItemId: string;
  requirementId: string;
  documentId: string;
  portfolioId: string;
};
export type ApiDeleteClusterItemRequirementApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteClusterItemRequirementApiArg = {
  clusterItemId: string;
  requirementId: string;
  portfolioId: string;
};
export type ApiGetClusterItemRequirementApiResponse = /** status 200 Success */ GetClusterItemRequirementQueryResponse;
export type ApiGetClusterItemRequirementApiArg = {
  clusterItemId: string;
  requirementId: string;
  portfolioId: string;
};
export type ApiPostUpdateClusterItemRequirementApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateClusterItemRequirementApiArg = {
  requirementId: string;
  clusterItemId: string;
  portfolioId: string;
  body: UpdateClusterItemRequirementPayload;
};
export type ApiDeleteCommitmentApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteCommitmentApiArg = {
  commitmentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCommitmentApiResponse = /** status 200 Success */ CommitmentReadModel;
export type ApiGetCommitmentApiArg = {
  commitmentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateCommitmentApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCommitmentApiArg = {
  commitmentId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateCommitmentPayload;
};
export type ApiDeleteCompanyBranchApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteCompanyBranchApiArg = {
  companyId: string;
  companyBranchId: string;
};
export type ApiPostUpdateCompanyBranchApiResponse = /** status 204 No Content */ undefined;
export type ApiPostUpdateCompanyBranchApiArg = {
  companyId: string;
  companyBranchId: string;
  body: UpdateCompanyBranchPayload;
};
export type ApiDeleteCompanyApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteCompanyApiArg = {
  companyId: string;
};
export type ApiGetTenantCompanyApiResponse = /** status 200 Success */ CompanyReadModel;
export type ApiGetTenantCompanyApiArg = {
  companyId: string;
};
export type ApiDeleteCompanyPersonApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteCompanyPersonApiArg = {
  companyId: string;
  companyPersonId: string;
};
export type ApiPostUpdateCompanyPersonApiResponse = /** status 204 No Content */ undefined;
export type ApiPostUpdateCompanyPersonApiArg = {
  companyId: string;
  companyPersonId: string;
  body: CompanyPersonPayload;
};
export type ApiDeleteContractDocumentApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteContractDocumentApiArg = {
  contractId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetContractDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetContractDocumentApiArg = {
  contractId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteContractApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetContractApiResponse = /** status 200 Success */ ContractReadModel;
export type ApiGetContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateContractApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateContractPayload;
};
export type ApiDeleteContractTitleDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteContractTitleDocumentApiArg = {
  contractId: string;
  contractTitleId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetContractTitleDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetContractTitleDocumentApiArg = {
  contractId: string;
  contractTitleId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteContractTitleApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteContractTitleApiArg = {
  contractId: string;
  contractTitleId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateContractTitleApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateContractTitleApiArg = {
  contractId: string;
  contractTitleId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateContractTitlePayload;
};
export type ApiDeleteCostElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteCostElementDocumentApiArg = {
  costElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCostElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetCostElementDocumentApiArg = {
  costElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteCostElementApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteCostElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCostElementApiResponse = /** status 200 Success */ GetCostElementResult;
export type ApiGetCostElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateCostElementApiResponse = /** status 200 Success */ UpdateCostElementResponse;
export type ApiPostUpdateCostElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: CostElementPayload;
};
export type ApiDeleteCostElementTagApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteCostElementTagApiArg = {
  id: string;
  tagId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateCostElementTagApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCostElementTagApiArg = {
  id: string;
  tagId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteDefaultValuesApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteDefaultValuesApiArg = {
  defaultValuesId: string;
};
export type ApiPostUpdateDefaultValuesApiResponse = /** status 204 No Content */ undefined;
export type ApiPostUpdateDefaultValuesApiArg = {
  defaultValuesId: string;
  body: DefaultValuesPayload;
};
export type ApiDeleteDeliveryPhaseCatalogApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteDeliveryPhaseCatalogApiArg = {
  id: string;
};
export type ApiGetDeliveryPhaseCatalogApiResponse = /** status 200 Success */ DeliveryPhasesCatalogReadModel;
export type ApiGetDeliveryPhaseCatalogApiArg = {
  id: string;
};
export type ApiDeleteEarningsElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteEarningsElementDocumentApiArg = {
  earningsElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetEarningsElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetEarningsElementDocumentApiArg = {
  earningsElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteEarningsElementApiResponse = /** status 201 Created */ undefined;
export type ApiDeleteEarningsElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetEarningsElementApiResponse = /** status 200 Success */ GetEarningsElementResult;
export type ApiGetEarningsElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateEarningsElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostUpdateEarningsElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: ModifyEarningsElementPayload;
};
export type ApiDeleteEuriborRateProjectionApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteEuriborRateProjectionApiArg = {
  id: string;
};
export type ApiPostUpdateEuriborRateProjectionApiResponse = /** status 201 Created */ undefined;
export type ApiPostUpdateEuriborRateProjectionApiArg = {
  id: string;
  body: EuriborPayload;
};
export type ApiDeleteFinancingElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteFinancingElementDocumentApiArg = {
  financingElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetFinancingElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetFinancingElementDocumentApiArg = {
  financingElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteFinancingElementApiResponse = /** status 201 Created */ undefined;
export type ApiDeleteFinancingElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetFinancingElementApiResponse = /** status 200 Success */ GetFinancingElementQueryResult;
export type ApiGetFinancingElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateFinancingElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostUpdateFinancingElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: FinancingElementPayload;
};
export type ApiDeleteTenantInviteApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteTenantInviteApiArg = {
  inviteId?: string;
};
export type ApiDeleteInvoiceByContractApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteInvoiceByContractApiArg = {
  contractId: string;
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateInvoiceByContractApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateInvoiceByContractApiArg = {
  contractId: string;
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateInvoiceByContractPayload;
};
export type ApiDeleteInvoiceCalculationRuleApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteInvoiceCalculationRuleApiArg = {
  invoiceId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateInvoiceCalculationRuleApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateInvoiceCalculationRuleApiArg = {
  invoiceId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateCalculationRulePayload;
};
export type ApiDeleteInvoiceCalculationRuleGroupApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteInvoiceCalculationRuleGroupApiArg = {
  invoiceId: string;
  calculationRuleGroupId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateInvoiceCalculationRuleGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateInvoiceCalculationRuleGroupApiArg = {
  invoiceId: string;
  calculationRuleGroupId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateCalculationRuleGroupPayload;
};
export type ApiDeleteInvoiceDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteInvoiceDocumentApiArg = {
  invoiceId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetInvoiceDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetInvoiceDocumentApiArg = {
  invoiceId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteInvoiceApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteInvoiceApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetInvoiceApiResponse = /** status 200 Success */ GetInvoiceQueryResponse;
export type ApiGetInvoiceApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateInvoiceApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateInvoiceApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateInvoicePayload;
};
export type ApiDeleteInvoiceTitleApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteInvoiceTitleApiArg = {
  invoiceId: string;
  invoiceTitleId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateInvoiceTitleApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateInvoiceTitleApiArg = {
  invoiceId: string;
  invoiceTitleId: string;
  projectId: string;
  calculationModelId: string;
  body: InvoiceTitlePayload;
};
export type ApiAdminDeleteLicenseApiResponse = /** status 200 Success */ undefined;
export type ApiAdminDeleteLicenseApiArg = {
  body: DeleteLicensePayload;
};
export type ApiAdminGetLicensesApiResponse = /** status 200 Success */ LicenseReadModel[];
export type ApiAdminGetLicensesApiArg = void;
export type ApiAdminDeleteLicenseGrantApiResponse = /** status 200 Success */ undefined;
export type ApiAdminDeleteLicenseGrantApiArg = {
  body: DeleteLicenseGrantPayload;
};
export type ApiDeletePlotApiResponse = /** status 200 Success */ undefined;
export type ApiDeletePlotApiArg = {
  plotId: string;
  shouldDeleteChildren?: boolean;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetPlotApiResponse = /** status 200 Success */ PlotReadModel;
export type ApiGetPlotApiArg = {
  plotId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdatePlotApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdatePlotApiArg = {
  plotId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdatePlotPayload;
};
export type ApiDeletePlotItemFileApiResponse = /** status 200 Success */ undefined;
export type ApiDeletePlotItemFileApiArg = {
  plotItemId: string;
  fileId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetPlotItemFileApiResponse = /** status 200 Success */ string;
export type ApiGetPlotItemFileApiArg = {
  plotItemId: string;
  fileId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteProjectCalculationRuleApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  projectId: string;
};
export type ApiPostUpdateProjectCalculationRuleApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateProjectCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  projectId: string;
  body: UpdateCalculationRulePayload;
};
export type ApiDeleteProjectCalculationRuleGroupApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  projectId: string;
};
export type ApiPostUpdateProjectCalculationRuleGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateProjectCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  projectId: string;
  body: UpdateCalculationRuleGroupPayload;
};
export type ApiDeleteProjectApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectApiArg = {
  projectId: string;
};
export type ApiGetProjectApiResponse = /** status 200 Success */ GetProjectQueryResponse;
export type ApiGetProjectApiArg = {
  unitSystem?: UnitSystem;
  projectId: string;
};
export type ApiDeleteTenantProjectInviteApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteTenantProjectInviteApiArg = {
  body: DeleteProjectInvitePayload;
};
export type ApiDeleteProjectInvoiceCalculationSchemeApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectInvoiceCalculationSchemeApiArg = {
  invoiceCalculationSchemeId: string;
  projectId: string;
};
export type ApiPostUpdateProjectInvoiceCalculationSchemeApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateProjectInvoiceCalculationSchemeApiArg = {
  invoiceCalculationSchemeId: string;
  projectId: string;
  body: InvoiceCalculationSchemePayload;
};
export type ApiDeleteProjectConstructionPhaseApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectConstructionPhaseApiArg = {
  id: string;
  projectId: string;
};
export type ApiPostUpdateProjectConstrutionPhaseApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateProjectConstrutionPhaseApiArg = {
  id: string;
  projectId: string;
  body: ElementPayload;
};
export type ApiDeleteProjectStatusApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectStatusApiArg = {
  id: string;
  projectId: string;
};
export type ApiPostUpdateProjectStatusApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateProjectStatusApiArg = {
  id: string;
  projectId: string;
  body: ElementPayload;
};
export type ApiDeleteProjectTagApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectTagApiArg = {
  id: string;
  projectId: string;
};
export type ApiDeleteProjectVatApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteProjectVatApiArg = {
  id: string;
  projectId: string;
};
export type ApiPostUpdateProjectVatApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateProjectVatApiArg = {
  id: string;
  projectId: string;
  body: VatPayload;
};
export type ApiDeleteRentDealContactApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteRentDealContactApiArg = {
  contactId: string;
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateRentDealContactApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateRentDealContactApiArg = {
  contactId: string;
  projectId: string;
  dealId: string;
  body: ContactPayload;
};
export type ApiDeleteRentDealContractItemApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteRentDealContractItemApiArg = {
  contractItemId: string;
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateRentDealContractItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateRentDealContractItemApiArg = {
  contractItemId: string;
  projectId: string;
  dealId: string;
  body: ContractItemPayload;
};
export type ApiDeleteRentDealCostItemApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteRentDealCostItemApiArg = {
  costItemId: string;
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateRentDealCostItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateRentDealCostItemApiArg = {
  costItemId: string;
  projectId: string;
  dealId: string;
  body: CostItemPayload;
};
export type ApiDeleteRentDealDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteRentDealDocumentApiArg = {
  documentId: string;
  projectId: string;
  dealId: string;
};
export type ApiGetRentDealDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetRentDealDocumentApiArg = {
  documentId: string;
  projectId: string;
  dealId: string;
};
export type ApiDeleteRentDealApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteRentDealApiArg = {
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateRentDealApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateRentDealApiArg = {
  projectId: string;
  dealId: string;
  body: DealPayload;
};
export type ApiDeleteRiskElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteRiskElementDocumentApiArg = {
  riskElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetRiskElementDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetRiskElementDocumentApiArg = {
  riskElementId: string;
  documentId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteRiskElementApiResponse = /** status 201 Created */ undefined;
export type ApiDeleteRiskElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetRiskElementApiResponse = /** status 200 Success */ GetRiskElementResult;
export type ApiGetRiskElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateRiskElementApiResponse = /** status 201 Created */ undefined;
export type ApiPostUpdateRiskElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: ModifyRiskElementPayload;
};
export type ApiDeleteSellDealContactApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteSellDealContactApiArg = {
  contactId: string;
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateSellDealContactApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateSellDealContactApiArg = {
  contactId: string;
  projectId: string;
  dealId: string;
  body: ContactPayload;
};
export type ApiDeleteSellDealContractItemApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteSellDealContractItemApiArg = {
  contractItemId: string;
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateSellDealContractItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateSellDealContractItemApiArg = {
  contractItemId: string;
  projectId: string;
  dealId: string;
  body: ContractItemPayload;
};
export type ApiDeleteSellDealCostItemApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteSellDealCostItemApiArg = {
  costItemId: string;
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateSellDealCostItemApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateSellDealCostItemApiArg = {
  costItemId: string;
  projectId: string;
  dealId: string;
  body: CostItemPayload;
};
export type ApiDeleteSellDealDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteSellDealDocumentApiArg = {
  documentId: string;
  projectId: string;
  dealId: string;
};
export type ApiGetSellDealDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiGetSellDealDocumentApiArg = {
  documentId: string;
  projectId: string;
  dealId: string;
};
export type ApiDeleteSellDealApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteSellDealApiArg = {
  projectId: string;
  dealId: string;
};
export type ApiPostUpdateSellDealApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateSellDealApiArg = {
  projectId: string;
  dealId: string;
  body: DealPayload;
};
export type ApiDeleteTaxonomyApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteTaxonomyApiArg = {
  taxonomyItemId: string;
  shouldDeleteChildren?: boolean;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateTaxonomyApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTaxonomyApiArg = {
  taxonomyItemId: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateTaxonomyItemPayload;
};
export type ApiDeleteTaxonomyItemFileApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteTaxonomyItemFileApiArg = {
  taxonomyItemId: string;
  fileId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetTaxonomyItemFileApiResponse = /** status 200 Success */ string;
export type ApiGetTaxonomyItemFileApiArg = {
  taxonomyItemId: string;
  fileId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiDeleteTenantCalculationRuleApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteTenantCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
};
export type ApiPostUpdateTenantCalculationRuleApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTenantCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  body: UpdateCalculationRulePayload;
};
export type ApiDeleteTenantCalculationRuleGroupApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteTenantCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
};
export type ApiPostUpdateTenantCalculationRuleGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTenantCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  body: UpdateCalculationRuleGroupPayload;
};
export type ApiDeleteTenantInvoiceCalculationSchemeApiResponse = /** status 204 No Content */ undefined;
export type ApiDeleteTenantInvoiceCalculationSchemeApiArg = {
  invoiceCalculationSchemeId: string;
};
export type ApiPostUpdateTenantInvoiceCalculationSchemeApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTenantInvoiceCalculationSchemeApiArg = {
  invoiceCalculationSchemeId: string;
  body: InvoiceCalculationSchemePayload;
};
export type ApiDeleteTenantUserDefinedFieldDefinitionApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteTenantUserDefinedFieldDefinitionApiArg = {
  userDefinedFieldDefinitionId: string;
};
export type ApiPostUpdateTenantUserDefinedFieldDefinitionApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTenantUserDefinedFieldDefinitionApiArg = {
  userDefinedFieldDefinitionId: string;
  body: UserDefinedFieldDefinitionPayload;
};
export type ApiGetDetailReportApiResponse = /** status 200 Success */ DetailReportResponse;
export type ApiGetDetailReportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetDetailReportAxaApiResponse = /** status 200 Success */ DetailReportResponse;
export type ApiGetDetailReportAxaApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostTriggerJobApiResponse = unknown;
export type ApiPostTriggerJobApiArg = void;
export type ApiPostDuplicateTaxonomyApiResponse = /** status 200 Success */ undefined;
export type ApiPostDuplicateTaxonomyApiArg = {
  taxonomyItemId: string;
  includeChildren?: boolean;
  projectId: string;
  calculationModelId: string;
};
export type ApiAdminPostEditSubdomainApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostEditSubdomainApiArg = {
  body: ChangeSubdomainCommand;
};
export type ApiGetErrorCodesApiResponse = /** status 200 Success */ ErrorCodeModel;
export type ApiGetErrorCodesApiArg = void;
export type ApiGetCalculationModelExcelApiResponse = /** status 200 Success */ string;
export type ApiGetCalculationModelExcelApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetImportFileTemplateApiResponse = unknown;
export type ApiGetImportFileTemplateApiArg = {
  contentType?: TemplateContentType;
  fileType?: TemplateFileType;
};
export type ApiGetForecastReportApiResponse = /** status 200 Success */ ForecastReportResponse;
export type ApiGetForecastReportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetForecastExcelReportApiResponse = /** status 200 Success */ string;
export type ApiGetForecastExcelReportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostForgotPasswordApiResponse = unknown;
export type ApiPostForgotPasswordApiArg = {
  body: ForgotPasswordCommand;
};
export type ApiPostGenerateNextProjectObjectCodeApiResponse =
  /** status 200 Success */ GenerateNextProjectObjectCodeResponse;
export type ApiPostGenerateNextProjectObjectCodeApiArg = {
  projectId: string;
  calculationModelId: string;
  body: GenerateProjectObjectCodePayload;
};
export type ApiPostGenerateNextTenantObjectCodeApiResponse =
  /** status 200 Success */ GenerateNextTenantObjectCodeResponse;
export type ApiPostGenerateNextTenantObjectCodeApiArg = {
  body: GenerateTenantObjectCodePayload;
};
export type ApiGetAiEvalDocumentsApiResponse = /** status 200 Success */ AiEvalDocumentReadModel[];
export type ApiGetAiEvalDocumentsApiArg = {
  projectId: string;
};
export type ApiPostUploadAiEvalDocumentApiResponse = /** status 200 Success */ CreateAiEvalDocumentResponse;
export type ApiPostUploadAiEvalDocumentApiArg = {
  projectId: string;
  body: {
    file?: Blob;
  };
};
export type ApiGetAllTasksApiResponse = /** status 200 Success */ GetWorkflowTasksQueryResponse;
export type ApiGetAllTasksApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetAssemblyVersionApiResponse = /** status 200 Success */ GetAssemblyVersionQueryResponse;
export type ApiGetAssemblyVersionApiArg = void;
export type ApiGetAuditLogsByKeyApiResponse = /** status 200 Success */ AuditLogReadModel[];
export type ApiGetAuditLogsByKeyApiArg = {
  key: string;
  target?: AuditLogTarget;
  projectId: string;
};
export type ApiGetAuditLogsByTargetApiResponse = /** status 200 Success */ AuditLogReadModel[];
export type ApiGetAuditLogsByTargetApiArg = {
  target?: AuditLogTarget;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetAuthPermissionDefinitionsApiResponse = /** status 200 Success */ AuthPermissionDefinitionReadModel[];
export type ApiGetAuthPermissionDefinitionsApiArg = void;
export type ApiGetAuthSubjectsApiResponse = /** status 200 Success */ AuthSubjectReadModel[];
export type ApiGetAuthSubjectsApiArg = void;
export type ApiPostCalculateAutomaticTimelineDistributionApiResponse =
  /** status 200 Success */ TimelineValueReadModel[];
export type ApiPostCalculateAutomaticTimelineDistributionApiArg = {
  projectId: string;
  calculationModelId: string;
  body: AutomaticDistributionPayload;
};
export type ApiGetCalculationModelCostsApiResponse = /** status 200 Success */ CalculationModelCostsQueryResponse;
export type ApiGetCalculationModelCostsApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCalculationModelEarningsApiResponse = /** status 200 Success */ CalculationModelEarningsQueryResponse;
export type ApiGetCalculationModelEarningsApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCalculationModelRisksApiResponse = /** status 200 Success */ CalculationModelRisksQueryResponse;
export type ApiGetCalculationModelRisksApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCalculationModelFinancingApiResponse =
  /** status 200 Success */ CalculationModelFinancingQueryResponse;
export type ApiGetCalculationModelFinancingApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCalculationModelExportApiResponse = /** status 200 Success */ GetCalculationModelExportQueryResponse;
export type ApiGetCalculationModelExportApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCalculationModelHistoryApiResponse = /** status 200 Success */ CalculationModelHistory;
export type ApiGetCalculationModelHistoryApiArg = {
  unitSystem?: UnitSystem;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetCatalogElementCodeApiResponse = /** status 200 Success */ GenerateElementCodeResponse;
export type ApiGetCatalogElementCodeApiArg = {
  catalogId?: string;
  type?: TenantCatalogType;
  parentElementId?: string;
};
export type ApiGetClusterItemsApiResponse = /** status 200 Success */ GetClusterItemsQueryResponse;
export type ApiGetClusterItemsApiArg = {
  portfolioId: string;
};
export type ApiGetClusterItemsTreeApiResponse = /** status 200 Success */ GetClusterItemsTreeQueryResponse;
export type ApiGetClusterItemsTreeApiArg = {
  portfolioId: string;
};
export type ApiGetCompanyLogoApiResponse = /** status 200 Success */ string;
export type ApiGetCompanyLogoApiArg = {
  companyId: string;
  fileId: string;
};
export type ApiGetCatalogApiResponse = /** status 200 Success */ GetCostCatalogQueryResponse;
export type ApiGetCatalogApiArg = {
  id: string;
};
export type ApiGetCatalogsApiResponse = /** status 200 Success */ GetCostCatalogsQueryResponse;
export type ApiGetCatalogsApiArg = void;
export type ApiGetCostElementsDistributionApiResponse =
  /** status 200 Success */ GetCostElementsMonthlyDistributionQueryResult;
export type ApiGetCostElementsDistributionApiArg = {
  projectId: string;
  calculationModelId: string;
  body: string[];
};
export type ApiGetCrossTenantContractsApiResponse = /** status 200 Success */ GetCrossTenantContractsQueryResponse;
export type ApiGetCrossTenantContractsApiArg = {
  crossTenantId: string;
  crossTenantProjectId: string;
};
export type ApiGetCrossTenantProjectCoverApiResponse = /** status 200 Success */ string;
export type ApiGetCrossTenantProjectCoverApiArg = {
  imageId: string;
  crossTenantId: string;
  crossTenantProjectId: string;
};
export type ApiGetAiEvalDocumentFileApiResponse = /** status 200 Success */ undefined;
export type ApiGetAiEvalDocumentFileApiArg = {
  documentId: string;
  projectId: string;
};
export type ApiPostReprocessAiEvalDocumentApiResponse = /** status 200 Success */ undefined;
export type ApiPostReprocessAiEvalDocumentApiArg = {
  documentId: string;
  projectId: string;
};
export type ApiGetEarningsCatalogApiResponse = /** status 200 Success */ GetEarningsCatalogQueryResponse;
export type ApiGetEarningsCatalogApiArg = {
  id: string;
};
export type ApiGetEarningsCatalogsApiResponse = /** status 200 Success */ GetEarningsCatalogsQueryResponse;
export type ApiGetEarningsCatalogsApiArg = void;
export type ApiGetEffectiveTimelineDistributionApiResponse = /** status 200 Success */ TimelineValueReadModel[];
export type ApiGetEffectiveTimelineDistributionApiArg = {
  costElementId?: string;
  riskElementId?: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetEmissionYearlyDistributionsApiResponse =
  /** status 200 Success */ GetEmissionYearlyDistributionsQueryResponse;
export type ApiGetEmissionYearlyDistributionsApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetEuriborRatesApiResponse = /** status 201 Created */ GetEuriborRatesResult;
export type ApiGetEuriborRatesApiArg = {
  from?: string;
  to?: string;
};
export type ApiGetFinancingCatalogApiResponse = /** status 200 Success */ GetFinancingCatalogQueryResponse;
export type ApiGetFinancingCatalogApiArg = {
  id?: string;
};
export type ApiGetFinancingCatalogsApiResponse = /** status 200 Success */ GetFinancingCatalogsQueryResponse;
export type ApiGetFinancingCatalogsApiArg = void;
export type ApiGetFinancingElementsDistributionSuggestionApiResponse =
  /** status 200 Success */ GetSuggestedFinancingElementDistributionQueryResult;
export type ApiGetFinancingElementsDistributionSuggestionApiArg = {
  projectId: string;
  calculationModelId: string;
  body: GetFinancingSuggestedDistributionDto;
};
export type ApiGetFinancingElementsMetadataApiResponse =
  /** status 200 Success */ GetFinancingElementsMetadataQueryResult;
export type ApiGetFinancingElementsMetadataApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetFinancingPurposeOverviewApiResponse = /** status 200 Success */ GetFinancingCostsOverviewQueryResult;
export type ApiGetFinancingPurposeOverviewApiArg = {
  financingPurposeId?: string;
  financingElementId?: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetUserInvitationApiResponse = /** status 200 Success */ UserInvitationReadModel;
export type ApiGetUserInvitationApiArg = {
  tokenId: string;
};
export type ApiGetTenantInviteApiResponse = /** status 200 Success */ GetTenantTrustInviteQueryResponse;
export type ApiGetTenantInviteApiArg = {
  token: string;
};
export type ApiGetInvoiceCalculationSampleApiResponse = /** status 200 Success */ GetInvoiceCalculationSampleResponse;
export type ApiGetInvoiceCalculationSampleApiArg = {
  invoiceCalculationSchemeId: string;
};
export type ApiGetInvoiceReportApiResponse = /** status 200 Success */ GetInvoiceReportQueryResponse;
export type ApiGetInvoiceReportApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetInvoiceWorkflowDetailApiResponse = /** status 200 Success */ WorkflowDetailReadModel;
export type ApiGetInvoiceWorkflowDetailApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetJobRouterPdfApiResponse = /** status 200 Success */ string;
export type ApiGetJobRouterPdfApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetLicenseFeaturesApiResponse = /** status 200 Success */ LicenseFeatureReadModel[];
export type ApiGetLicenseFeaturesApiArg = void;
export type ApiAdminGetLicenseFeaturesApiResponse = /** status 200 Success */ LicenseFeatureReadModel[];
export type ApiAdminGetLicenseFeaturesApiArg = void;
export type ApiGetLicenseGrantsApiResponse = /** status 200 Success */ LicenseGrantReadModel[];
export type ApiGetLicenseGrantsApiArg = void;
export type ApiAdminGetLicenseGrantsApiResponse = /** status 200 Success */ LicenseGrantReadModel[];
export type ApiAdminGetLicenseGrantsApiArg = {
  tenantId: string;
};
export type ApiGetPasswordResetTokenApiResponse = /** status 200 Success */ UserInvitationReadModel;
export type ApiGetPasswordResetTokenApiArg = {
  tokenId: string;
};
export type ApiGetPlainAuthGroupApiResponse = /** status 200 Success */ AuthGroupReadModel[];
export type ApiGetPlainAuthGroupApiArg = void;
export type ApiGetPlainAuthRolesApiResponse = /** status 200 Success */ AuthRoleReadModel[];
export type ApiGetPlainAuthRolesApiArg = void;
export type ApiGetPlainAuthSubjectsApiResponse = /** status 200 Success */ AuthSubjectReadModel[];
export type ApiGetPlainAuthSubjectsApiArg = void;
export type ApiGetPlotItemThumbnailApiResponse = /** status 200 Success */ string;
export type ApiGetPlotItemThumbnailApiArg = {
  plotItemId: string;
  thumbnailId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetPortfolioApiResponse = /** status 200 Success */ GetPortfolioQueryResponse;
export type ApiGetPortfolioApiArg = {
  portfolioId: string;
};
export type ApiGetPortfolioNextRequirementCodeApiResponse =
  /** status 200 Success */ GetPortfolioNextRequirementCodeQueryResponse;
export type ApiGetPortfolioNextRequirementCodeApiArg = {
  portfolioId: string;
};
export type ApiGetPortfoliosApiResponse = /** status 200 Success */ GetPortfoliosQueryResponse;
export type ApiGetPortfoliosApiArg = void;
export type ApiGetProjectCompaniesApiResponse = /** status 200 Success */ CompanyReadModel[];
export type ApiGetProjectCompaniesApiArg = {
  projectId: string;
};
export type ApiGetProjectCompanyBranchesApiResponse = /** status 200 Success */ CompanyBranchReadModel[];
export type ApiGetProjectCompanyBranchesApiArg = {
  projectId: string;
};
export type ApiGetProjectCoverApiResponse = /** status 200 Success */ string;
export type ApiGetProjectCoverApiArg = {
  imageId: string;
  projectId: string;
};
export type ApiGetProjectGroupCoverApiResponse = /** status 200 Success */ string;
export type ApiGetProjectGroupCoverApiArg = {
  groupId: string;
  imageId: string;
};
export type ApiGetProjectGroupApiResponse = /** status 200 Success */ GetProjectGroupQueryResponse;
export type ApiGetProjectGroupApiArg = {
  groupId: string;
  unitSystem?: UnitSystem;
};
export type ApiDeleteProjectGroupApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteProjectGroupApiArg = {
  groupId: string;
};
export type ApiGetProjectReadableCostCatalogApiResponse = /** status 200 Success */ CostCatalogReadModel;
export type ApiGetProjectReadableCostCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiGetProjectReadableEarningsCatalogApiResponse = /** status 200 Success */ EarningsCatalogReadModel;
export type ApiGetProjectReadableEarningsCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiGetProjectReadableFinancingCatalogApiResponse = /** status 200 Success */ FinancingCatalogReadModel;
export type ApiGetProjectReadableFinancingCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiGetProjectReadableRiskCatalogApiResponse = /** status 200 Success */ RiskCatalogReadModel;
export type ApiGetProjectReadableRiskCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiProjectGetUserDefinedFieldsDefinitionByElementApiResponse =
  /** status 200 Success */ GetUserDefinedFieldsDefinitionByElementQueryResponse;
export type ApiProjectGetUserDefinedFieldsDefinitionByElementApiArg = {
  elementId?: string;
  elementType?: UserDefinedFieldElementType;
  calculateElementType?: UserDefinedFieldCalculateElementType;
  projectId: string;
};
export type ApiProjectGetUserDefinedFieldsDefinitionApiResponse =
  /** status 200 Success */ GetUserDefinedFieldsDefinitionQueryResponse;
export type ApiProjectGetUserDefinedFieldsDefinitionApiArg = {
  projectId: string;
};
export type ApiGetProjectWritableCostCatalogApiResponse = /** status 200 Success */ CostCatalogReadModel;
export type ApiGetProjectWritableCostCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiGetProjectWritableEarningsCatalogApiResponse = /** status 200 Success */ EarningsCatalogReadModel;
export type ApiGetProjectWritableEarningsCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiGetProjectWritableFinancingCatalogApiResponse = /** status 200 Success */ FinancingCatalogReadModel;
export type ApiGetProjectWritableFinancingCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiGetProjectWritableRiskCatalogApiResponse = /** status 200 Success */ RiskCatalogReadModel;
export type ApiGetProjectWritableRiskCatalogApiArg = {
  id: string;
  projectId: string;
};
export type ApiPostCalculateRecurringPaymentDistributionApiResponse =
  /** status 200 Success */ TimelineValueReadModel[];
export type ApiPostCalculateRecurringPaymentDistributionApiArg = {
  projectId: string;
  calculationModelId: string;
  body: RecurringPaymentDistributionPayload;
};
export type ApiGetRegionEmissionFactorsByRegionApiResponse = /** status 200 Success */ RegionEmissionFactorsReadModel;
export type ApiGetRegionEmissionFactorsByRegionApiArg = {
  region?: string;
  state?: string;
};
export type ApiGetRiskCatalogApiResponse = /** status 200 Success */ GetRiskCatalogQueryResponse;
export type ApiGetRiskCatalogApiArg = {
  id: string;
};
export type ApiGetRiskCatalogsApiResponse = /** status 200 Success */ GetRiskCatalogsQueryResponse;
export type ApiGetRiskCatalogsApiArg = void;
export type ApiGetSelectableCommitmentsApiResponse = /** status 200 Success */ SelectCommitmentReadModel[];
export type ApiGetSelectableCommitmentsApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetSelectableContractsApiResponse = /** status 200 Success */ SelectContractReadModel[];
export type ApiGetSelectableContractsApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiGetRentDealAvailableTaxonomyApiResponse = /** status 200 Success */ GetDealSelectableTaxonomyItemsResult;
export type ApiGetRentDealAvailableTaxonomyApiArg = {
  projectId: string;
  dealId: string;
};
export type ApiGetSellDealAvailableTaxonomyApiResponse = /** status 200 Success */ GetDealSelectableTaxonomyItemsResult;
export type ApiGetSellDealAvailableTaxonomyApiArg = {
  projectId: string;
  dealId: string;
};
export type ApiGetTaxonomyItemThumbnailApiResponse = /** status 200 Success */ string;
export type ApiGetTaxonomyItemThumbnailApiArg = {
  taxonomyItemId: string;
  thumbnailId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiGetTaxonomyWithCostItemsApiResponse = /** status 200 Success */ TaxonomyCostItemsReadModel;
export type ApiGetTaxonomyWithCostItemsApiArg = {
  projectId: string;
};
export type ApiAdminGetTenantAuthProjectsApiResponse = /** status 200 Success */ GetTenantAuthProjectsQueryResponse;
export type ApiAdminGetTenantAuthProjectsApiArg = {
  tenantId: string;
};
export type ApiAdminGetTenantAuthSubjectsApiResponse = /** status 200 Success */ GetTenantAuthSubjectsQueryResponse;
export type ApiAdminGetTenantAuthSubjectsApiArg = {
  tenantId: string;
};
export type ApiGetTenantCatalogsApiResponse = /** status 200 Success */ GetTenantCatalogsQueryResponse;
export type ApiGetTenantCatalogsApiArg = void;
export type ApiGetTenantCompaniesApiResponse = /** status 200 Success */ CompanyReadModel[];
export type ApiGetTenantCompaniesApiArg = void;
export type ApiAdminGetTenantsApiResponse = /** status 200 Success */ GetTenantsQueryResponse;
export type ApiAdminGetTenantsApiArg = void;
export type ApiGetTenantTrustsApiResponse = /** status 200 Success */ GetTenantTrustsQueryResponse;
export type ApiGetTenantTrustsApiArg = void;
export type ApiGetUserDefinedFieldsDefinitionByElementApiResponse =
  /** status 200 Success */ GetUserDefinedFieldsDefinitionByElementQueryResponse;
export type ApiGetUserDefinedFieldsDefinitionByElementApiArg = {
  elementId?: string;
  elementType?: UserDefinedFieldElementType;
  calculateElementType?: UserDefinedFieldCalculateElementType;
};
export type ApiGetUserTenantsApiResponse = /** status 200 Success */ UserTenantReadModel[];
export type ApiGetUserTenantsApiArg = void;
export type ApiGetUserWorkflowTasksApiResponse = /** status 200 Success */ GetWorkflowTasksQueryResponse;
export type ApiGetUserWorkflowTasksApiArg = {
  projectId: string;
  calculationModelId: string;
};
export type ApiPostEarningsElementValuationApiResponse = /** status 200 Success */ EarningsElementValuationResult;
export type ApiPostEarningsElementValuationApiArg = {
  projectId: string;
  calculationModelId: string;
  body: GetEarningsElementValuationPayload;
};
export type ApiGetVatReturnDistributionSuggestionApiResponse =
  /** status 200 Success */ GetSuggestedVatReturnDistributionQueryResult;
export type ApiGetVatReturnDistributionSuggestionApiArg = {
  projectId: string;
  calculationModelId: string;
  body: GetVatReturnDistributionDto;
};
export type ApiGetWorkflowsApiResponse = /** status 200 Success */ GetWorkflowsQueryResponse;
export type ApiGetWorkflowsApiArg = void;
export type ApiAdminGetTenantWorkflowsApiResponse = /** status 200 Success */ GetWorkflowsQueryResponse;
export type ApiAdminGetTenantWorkflowsApiArg = {
  tenantId: string;
};
export type ApiPostCatalogImportFileApiResponse = /** status 200 Success */ ParseCatalogFileResponse;
export type ApiPostCatalogImportFileApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiPostCatalogImportDataApiResponse = /** status 200 Success */ undefined;
export type ApiPostCatalogImportDataApiArg = {
  body: ImportCatalogDataPayload;
};
export type ApiPostImportContractFileApiResponse = /** status 200 Success */ ImportContractDataResponse;
export type ApiPostImportContractFileApiArg = {
  projectId: string;
  calculationModelId: string;
  body: ContractElementData[];
};
export type ApiPostImportCostElementsFileApiResponse = /** status 200 Success */ ImportCostElementDataResponse;
export type ApiPostImportCostElementsFileApiArg = {
  projectId: string;
  calculationModelId: string;
  body: CostElementData[];
};
export type ApiPostImportInvoiceFileApiResponse = /** status 200 Success */ ImportInvoiceDataResponse;
export type ApiPostImportInvoiceFileApiArg = {
  projectId: string;
  calculationModelId: string;
  body: InvoiceElementData[];
};
export type ApiPostCompaniesImportFileApiResponse = /** status 200 Success */ ParseCompanyFileResponse;
export type ApiPostCompaniesImportFileApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiPostCompanyImportDataApiResponse = /** status 200 Success */ ImportCompanyDataResponse;
export type ApiPostCompanyImportDataApiArg = {
  body: CompanyElementData[];
};
export type ApiPostInitializeTaxonomyApiResponse = /** status 200 Success */ undefined;
export type ApiPostInitializeTaxonomyApiArg = {
  projectId: string;
  calculationModelId: string;
  body: InitializeBuildingTaxonomyPayload;
};
export type ApiPostInviteSetEnabledApiResponse = /** status 200 Success */ undefined;
export type ApiPostInviteSetEnabledApiArg = {
  body: SetInviteEnabledPayload;
};
export type ApiPostInviteSignupApiResponse = /** status 200 Success */ undefined;
export type ApiPostInviteSignupApiArg = {
  body: SignupForInviteCommand;
};
export type ApiPostCreateUserInvitationApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateUserInvitationApiArg = {
  body: InviteUserRequest;
};
export type ApiGetImportInvoiceFileTemplateApiResponse = unknown;
export type ApiGetImportInvoiceFileTemplateApiArg = {
  fileType?: TemplateFileType;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostLockFinancingElementApiResponse = /** status 200 Success */ undefined;
export type ApiPostLockFinancingElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostLoginApiResponse = /** status 200 Success */ LoginUserResponse;
export type ApiPostLoginApiArg = {
  body: LoginUserRequest;
};
export type ApiPostLogoutSessionsApiResponse = unknown;
export type ApiPostLogoutSessionsApiArg = void;
export type ApiPostLogoutApiResponse = unknown;
export type ApiPostLogoutApiArg = void;
export type ApiMoveCatalogElementApiResponse = /** status 200 Success */ undefined;
export type ApiMoveCatalogElementApiArg = {
  body: MoveTenantCatalogElementPayload;
};
export type ApiPostMoveInvoiceCalculationRuleApiResponse = /** status 200 Success */ undefined;
export type ApiPostMoveInvoiceCalculationRuleApiArg = {
  invoiceId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  projectId: string;
  calculationModelId: string;
  body: MovePayload;
};
export type ApiPostMoveInvoiceCalculationRuleGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPostMoveInvoiceCalculationRuleGroupApiArg = {
  invoiceId: string;
  calculationRuleGroupId: string;
  projectId: string;
  calculationModelId: string;
  body: MovePayload;
};
export type ApiPostMoveProjectCalculationRuleApiResponse = /** status 200 Success */ undefined;
export type ApiPostMoveProjectCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  projectId: string;
  body: MovePayload;
};
export type ApiPostMoveProjectCalculationRuleGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPostMoveProjectCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  projectId: string;
  body: MovePayload;
};
export type ApiPostMoveTaxonomyApiResponse = /** status 200 Success */ undefined;
export type ApiPostMoveTaxonomyApiArg = {
  taxonomyItemId: string;
  newParentId?: string;
  orderWithinParent?: number;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostMoveTenantCalculationRuleApiResponse = /** status 200 Success */ undefined;
export type ApiPostMoveTenantCalculationRuleApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  calculationRuleId: string;
  body: MovePayload;
};
export type ApiPostMoveTenantCalculationRuleGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPostMoveTenantCalculationRuleGroupApiArg = {
  invoiceCalculationSchemeId: string;
  calculationRuleGroupId: string;
  body: MovePayload;
};
export type ApiPostParseContractFileApiResponse = /** status 200 Success */ ParseContractFileResponse;
export type ApiPostParseContractFileApiArg = {
  projectId: string;
  calculationModelId: string;
  body: {
    file?: Blob;
  };
};
export type ApiPostParseCostElementFileApiResponse = /** status 200 Success */ ParseCostElementFileResponse;
export type ApiPostParseCostElementFileApiArg = {
  projectId: string;
  calculationModelId: string;
  body: {
    file?: Blob;
  };
};
export type ApiPostParseInvoiceFileApiResponse = /** status 200 Success */ ParseInvoiceFileResponse;
export type ApiPostParseInvoiceFileApiArg = {
  projectId: string;
  calculationModelId: string;
  body: {
    file?: Blob;
  };
};
export type ApiPostPayContractApiResponse = /** status 200 Success */ undefined;
export type ApiPostPayContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostPayInvoiceApiResponse = /** status 200 Success */ undefined;
export type ApiPostPayInvoiceApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostProjectGroupCoverApiResponse = /** status 200 Success */ undefined;
export type ApiPostProjectGroupCoverApiArg = {
  groupId: string;
  body: {
    file?: Blob;
  };
};
export type ApiDeleteProjectGroupCoverApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteProjectGroupCoverApiArg = {
  groupId: string;
};
export type ApiPostGroupProjectsApiResponse = /** status 200 Success */ undefined;
export type ApiPostGroupProjectsApiArg = {
  body: AddGroupProjectsPayload;
};
export type ApiDeleteProjectsFromGroupApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteProjectsFromGroupApiArg = {
  body: RemoveGroupProjectsPayload;
};
export type ApiPostCreateProjectGroupApiResponse = /** status 200 Success */ CreateProjectGroupResponse;
export type ApiPostCreateProjectGroupApiArg = {
  body: {
    Name?: string;
    Description?: string;
    ExternalId?: string;
    ProjectIds?: string[];
    CoverImage?: Blob;
  };
};
export type ApiPatchProjectGroupApiResponse = /** status 200 Success */ undefined;
export type ApiPatchProjectGroupApiArg = {
  body: UpdateProjectGroupPayload;
};
export type ApiPostQuickCreateCompanyApiResponse = /** status 200 Success */ QuickCreateCompanyResponse;
export type ApiPostQuickCreateCompanyApiArg = {
  body: QuickCreateCompanyPayload;
};
export type ApiRemoveBranchFormCompanyPersonApiResponse = /** status 204 No Content */ undefined;
export type ApiRemoveBranchFormCompanyPersonApiArg = {
  companyId: string;
  companyPersonId: string;
  body: RemoveCompanyFromPersonPayload;
};
export type ApiPostReopenContractApiResponse = /** status 200 Success */ undefined;
export type ApiPostReopenContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostReopenInvoiceApiResponse = /** status 200 Success */ undefined;
export type ApiPostReopenInvoiceApiArg = {
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostReopenRentDealApiResponse = /** status 200 Success */ undefined;
export type ApiPostReopenRentDealApiArg = {
  projectId: string;
  dealId: string;
};
export type ApiPostReopenSellDealApiResponse = /** status 200 Success */ undefined;
export type ApiPostReopenSellDealApiArg = {
  projectId: string;
  dealId: string;
};
export type ApiPostResetPasswordApiResponse = unknown;
export type ApiPostResetPasswordApiArg = {
  body: ResetPasswordCommand;
};
export type ApiPostSearchApiResponse = /** status 200 Success */ SearchResponse;
export type ApiPostSearchApiArg = {
  body: SearchPayload;
};
export type ApiGetSentrySentryApiResponse = unknown;
export type ApiGetSentrySentryApiArg = void;
export type ApiGetSentryExceptionApiResponse = unknown;
export type ApiGetSentryExceptionApiArg = void;
export type ApiPostSettleContractApiResponse = /** status 200 Success */ undefined;
export type ApiPostSettleContractApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiStartInvoiceWorkflowApiResponse = /** status 200 Success */ undefined;
export type ApiStartInvoiceWorkflowApiArg = {
  body: StartInvoiceWorkflowPayload;
};
export type ApiPostTakeoverInvoiceByContractApiResponse = /** status 200 Success */ undefined;
export type ApiPostTakeoverInvoiceByContractApiArg = {
  contractId: string;
  originalContractId: string;
  invoiceId: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateTenantBillingAddressApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTenantBillingAddressApiArg = {
  body: TenantAddressPayload;
};
export type ApiPostCreateTenantAvatarApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateTenantAvatarApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiDeleteTenantAvatarApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteTenantAvatarApiArg = void;
export type ApiGetTenantAvatarApiResponse = /** status 200 Success */ string;
export type ApiGetTenantAvatarApiArg = void;
export type ApiPostInviteTenantApiResponse = /** status 200 Success */ InviteTenantResponse;
export type ApiPostInviteTenantApiArg = {
  body: InviteTenantPayload;
};
export type ApiPostUpdateTenantNamesApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTenantNamesApiArg = {
  body: TenantNamePayload;
};
export type ApiPostUpdateTenantOwnerApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateTenantOwnerApiArg = {
  body: TenantOwnerPayload;
};
export type ApiPostReinviteTenantApiResponse = /** status 200 Success */ undefined;
export type ApiPostReinviteTenantApiArg = {
  inviteId?: string;
};
export type ApiPostTestApiAccessApiResponse = /** status 200 Success */ undefined;
export type ApiPostTestApiAccessApiArg = {
  accessId?: string;
};
export type ApiPostUnlockFinancingElementApiResponse = /** status 200 Success */ undefined;
export type ApiPostUnlockFinancingElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateAssignableWorkflowActivitiesApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateAssignableWorkflowActivitiesApiArg = {
  assignableActivityId: string;
  projectId: string;
  body: UpdateWorkflowAssignableActivityPayload;
};
export type ApiPostUpdateAuthSubjectMembershipApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateAuthSubjectMembershipApiArg = {
  subjectId: string;
  body: MembershipPayload;
};
export type ApiPostUpdateAuthSubjectApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateAuthSubjectApiArg = {
  subjectId: string;
  body: UpdateSubjectPayload;
};
export type ApiPostUpdateAutomaticWorkflowFlagApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateAutomaticWorkflowFlagApiArg = {
  projectId: string;
  body: UpdateAutomaticWorkflowFlagPayload;
};
export type ApiPostUpdateCalculationModelCashFlowSettingsApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCalculationModelCashFlowSettingsApiArg = {
  projectId: string;
  calculationModelId: string;
  body: UpdateCalculationModelCashFlowSettingsPayload;
};
export type ApiPostUpdateCaluclationModelFavoriteApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCaluclationModelFavoriteApiArg = {
  favorite?: boolean;
  projectId: string;
  calculationModelId: string;
};
export type ApiPostUpdateCalculationModelKpisApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCalculationModelKpisApiArg = {
  projectId: string;
  calculationModelId: string;
  body: UpdateCalculationModelKpisPayload;
};
export type ApiPostUpdateCalculationModelMetadataApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateCalculationModelMetadataApiArg = {
  projectId: string;
  calculationModelId: string;
  body: UpdateCalculationModelMetadataPayload;
};
export type ApiPostUpdateClusterItemRequirementStatusApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateClusterItemRequirementStatusApiArg = {
  requirementId: string;
  clusterItemId: string;
  portfolioId: string;
  body: UpdateClusterItemRequirementStatusPayload;
};
export type ApiPostUpdateCompanyApiResponse = /** status 204 No Content */ undefined;
export type ApiPostUpdateCompanyApiArg = {
  companyId: string;
  body: {
    Logo?: Blob;
    Name?: string;
    TaxId?: string;
    CompanyRegistrationNumber?: string;
    Link?: string;
  };
};
export type ApiPostUpdatePrivatePersonApiResponse = /** status 204 No Content */ undefined;
export type ApiPostUpdatePrivatePersonApiArg = {
  personId: string;
  body: PrivatePersonPayload;
};
export type ApiPostContractDocumentsApiResponse = /** status 200 Success */ undefined;
export type ApiPostContractDocumentsApiArg = {
  contractId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    files?: Blob[];
  };
};
export type ApiPostUpdateCostElementExtensionSettingsApiResponse =
  /** status 200 Success */ UpdateCostExtensionElementSettingResponse;
export type ApiPostUpdateCostElementExtensionSettingsApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: ElementSettingPayload;
};
export type ApiPostUpdateInvoiceTitleByContractTitleApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateInvoiceTitleByContractTitleApiArg = {
  contractId: string;
  invoiceId: string;
  contractTitleId: string;
  projectId: string;
  calculationModelId: string;
  body: InvoiceTitleByContractTitlePayload;
};
export type ApiAdminUpdateKlippaConfigurationsApiResponse = /** status 200 Success */ undefined;
export type ApiAdminUpdateKlippaConfigurationsApiArg = {
  configId: string;
  body: UpdateKlippaConfigurationRequest;
};
export type ApiAdminPostUpdateLicenseApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostUpdateLicenseApiArg = {
  body: UpdateLicensePayload;
};
export type ApiPostUpdateLicenseGrantApiResponse = unknown;
export type ApiPostUpdateLicenseGrantApiArg = {
  licenseGrantId: string;
  body: LicenseGrantAssigneesPayload;
};
export type ApiAdminPostUpdateLicenseGrantApiResponse = /** status 200 Success */ undefined;
export type ApiAdminPostUpdateLicenseGrantApiArg = {
  body: UpdateLicenseGrantPayload;
};
export type ApiPostUpdateLocalLawCostElementApiResponse = /** status 200 Success */ UpdateCostElementResponse;
export type ApiPostUpdateLocalLawCostElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: LocalLawElementPayload;
};
export type PostApiDataUpdateProjectByIdApiResponse = /** status 200 Success */ undefined;
export type PostApiDataUpdateProjectByIdApiArg = {
  id: string;
  body: ProbisDataPayload;
};
export type ApiPostUpdateProjectMetadataApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateProjectMetadataApiArg = {
  projectId: string;
  body: UpdateProjectMetadataPayload;
};
export type ApiPostUpdateRentDealConditionApiResponse = /** status 200 Success */ undefined;
export type ApiPostUpdateRentDealConditionApiArg = {
  projectId: string;
  dealId: string;
  body: RentConditionPayload;
};
export type ApiPostUpdateVatElementApiResponse = /** status 201 Created */ UpdateVatCostElementCommandResponse;
export type ApiPostUpdateVatElementApiArg = {
  id: string;
  projectId: string;
  calculationModelId: string;
  body: UpdateVatElementPayload;
};
export type ApiPostUploadPlotFileApiResponse = /** status 200 Success */ undefined;
export type ApiPostUploadPlotFileApiArg = {
  plotItemId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    File?: Blob;
    FileType?: PlotItemFileTypeEnum;
  };
};
export type ApiPostUploadProjectCoverApiResponse = /** status 200 Success */ undefined;
export type ApiPostUploadProjectCoverApiArg = {
  projectId: string;
  body: {
    file?: Blob;
  };
};
export type ApiPostUploadTaxonomyFileApiResponse = /** status 200 Success */ undefined;
export type ApiPostUploadTaxonomyFileApiArg = {
  taxonomyItemId: string;
  projectId: string;
  calculationModelId: string;
  body: {
    File?: Blob;
    FileType?: TaxonomyItemFileTypeEnum;
  };
};
export type ApiPostCreateUserAvatarApiResponse = /** status 200 Success */ undefined;
export type ApiPostCreateUserAvatarApiArg = {
  body: {
    file?: Blob;
  };
};
export type ApiDeleteUserAvatarApiResponse = /** status 200 Success */ undefined;
export type ApiDeleteUserAvatarApiArg = void;
export type ApiGetUserAvatarApiResponse = /** status 200 Success */ string;
export type ApiGetUserAvatarApiArg = {
  userId: string;
};
export type ApiPostValidateIbanApiResponse = /** status 204 No Content */ undefined;
export type ApiPostValidateIbanApiArg = {
  body: ValidateIbanPayload;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type AcceptInvitationRequest = {
  firstName: string;
  lastName: string;
  password: string;
};
export type AcceptTenantInvitePayload = {
  inviteToken: string;
};
export type ActivateLicenseGrantPayload = {
  tenantId: string;
  licenseGrantId: string;
};
export type ApiCredentialsPayload = {
  isActive: boolean;
  apiUrl?: string | null;
  apiKey?: string | null;
  userName?: string | null;
  password?: string | null;
  customConfig?: string | null;
};
export type ApiReadModel = {
  id: string;
  isEnabled: boolean;
  isActive: boolean;
  name: string;
  description?: string | null;
  comment?: string | null;
  url?: string | null;
  needsApiUrl: boolean;
  needsApiKey: boolean;
  needsUserName: boolean;
  needsPassword: boolean;
  needsCustomConfig: boolean;
  apiIsTestable: boolean;
};
export type ApiAccessReadModel = {
  id: string;
  apiId: string;
  tenantId: string;
  isActive: boolean;
  apiUrl?: string | null;
  apiKey?: string | null;
  userName?: string | null;
  password?: string | null;
  customConfig?: string | null;
};
export type ExternalApiReadModel = {
  api: ApiReadModel;
  accessItems: ApiAccessReadModel[];
};
export type ContactPayload = {
  name: string;
  role?: string | null;
  company?: string | null;
  email?: string | null;
  mobile?: string | null;
};
export type ContractItemCategory = 'None' | 'Rent' | 'Sell' | 'Utility' | 'Additional' | 'Special';
export type CalculationTarget = 'None' | 'RentalSpace' | 'CommonSpace' | 'Amount' | 'Value';
export type ContractItemPayload = {
  name: string;
  category: ContractItemCategory;
  calculation: CalculationTarget;
  factor: number;
  value: number;
  vat: number;
};
export type ApplyCostElementForecastAsBudgetPayload = {
  applyRecursivly: boolean;
};
export type SystemRoleType = 'None' | 'TenantAdmin' | 'ProjectAdmin' | 'ProjectContributer' | 'ProjectReader';
export type PermissionScope = 'Tenant' | 'Project';
export type ProbisPermission =
  | 'TENANT_PROJECT_CREATE'
  | 'TENANT_PROJECT_DELETE'
  | 'TENANT_PROJECT_COPY'
  | 'TENANT_USER_CREATE'
  | 'TENANT_USER_DELETE'
  | 'PROJECT_READ'
  | 'PROJECT_WRITE'
  | 'PROJECT_DELETE'
  | 'TENANT_MANAGE'
  | 'COSTS_READ'
  | 'COSTS_WRITE'
  | 'COSTS_DELETE'
  | 'EARNINGS_READ'
  | 'EARNINGS_WRITE'
  | 'EARNINGS_DELETE'
  | 'RISKS_READ'
  | 'RISKS_WRITE'
  | 'RISKS_DELETE'
  | 'FINANCING_READ'
  | 'FINANCING_WRITE'
  | 'FINANCING_DELETE'
  | 'PLOT_AND_TAXONOMY_READ'
  | 'PLOT_AND_TAXONOMY_WRITE'
  | 'PLOT_AND_TAXONOMY_DELETE'
  | 'RENT_READ'
  | 'RENT_WRITE'
  | 'RENT_DELETE'
  | 'RENT_REOPEN'
  | 'SELL_READ'
  | 'SELL_WRITE'
  | 'SELL_DELETE'
  | 'SELL_REOPEN'
  | 'CONTRACT_READ'
  | 'CONTRACT_WRITE'
  | 'CONTRACT_DELETE'
  | 'INVOICE_READ'
  | 'INVOICE_WRITE'
  | 'INVOICE_DELETE'
  | 'INVOICE_APPROVE';
export type AuthPermissionReadModel = {
  id: string;
  name: ProbisPermission;
  scope: PermissionScope;
};
export type TenantUserState = 'Active' | 'Disabled' | 'Removed' | 'Deleted';
export type AuthPlainEntityReadModel = {
  id: string;
  name: string;
};
export type AuthAssignmentReadModel = {
  id: string;
  role: AuthPlainEntityReadModel;
  user?: AuthPlainEntityReadModel | null;
  group?: AuthPlainEntityReadModel | null;
  project?: AuthPlainEntityReadModel | null;
};
export type AuthRoleAssignmentReadModel = {
  roleId: string;
  name: string;
  isSystemRole: boolean;
  systemRoleType: SystemRoleType;
  scope: PermissionScope;
  assignments: AuthAssignmentReadModel[];
  permissions: AuthPermissionReadModel[];
};
export type AuthRoles = {
  assigned: AuthRoleAssignmentReadModel[];
  inherited: AuthRoleAssignmentReadModel[];
};
export type AuthEffectivePermissionReadModel = {
  permission: AuthPermissionReadModel;
  roleAssignments: AuthRoleAssignmentReadModel[];
};
export type AuthEffectivePermissions = {
  tenantPermissions: AuthEffectivePermissionReadModel[];
  projectPermissions: AuthEffectivePermissionReadModel[];
};
export type CatalogCategory = 'Cost' | 'Risk' | 'Earnings' | 'Financing';
export type AuthCatalogElementReadModel = {
  id: string;
  code: string;
  description: string;
  children: AuthCatalogElementReadModel[];
};
export type AuthCatalogReadModel = {
  id: string;
  name: string;
  category: CatalogCategory;
  elements: AuthCatalogElementReadModel[];
};
export type AuthEffectiveCatalogElementPermissionReadModel = {
  catalogElementId: string;
  canReadElement: boolean;
  canWriteElement: boolean;
};
export type AuthEffectiveCatalogReadModel = {
  catalog: AuthCatalogReadModel;
  project?: AuthPlainEntityReadModel | null;
  elementPermissions: AuthEffectiveCatalogElementPermissionReadModel[];
};
export type AuthGroupReadModel = {
  id: string;
  name: string;
  comment: string;
  assignedProjectCount: number;
  roles: AuthRoles;
  effectivePermissions: AuthEffectivePermissions;
  effectiveCatalogs: AuthEffectiveCatalogReadModel[];
  subjects: AuthSubjectReadModel[];
};
export type LicenseAssigneeReadModel = {
  tenantUserId: string;
  fullname: string;
  mail: string;
};
export type LicenseGrantReadModel = {
  id: string;
  licenseId: string;
  name: string;
  description: string;
  isActive: boolean;
  assignees: LicenseAssigneeReadModel[];
  seats?: number | null;
  seatPricePerMonth: number;
  durationInMonths: number;
  activeFrom?: string | null;
  activeTo?: string | null;
  created: string;
  activated: string;
  deactivated?: string | null;
};
export type AuthSubjectReadModel = {
  userId: string;
  tenantUserId: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  hasAvatar: boolean;
  lastUpdate: string;
  isTenantOwner: boolean;
  invitePending: boolean;
  membership: TenantUserState;
  roles: AuthRoles;
  effectivePermissions: AuthEffectivePermissions;
  effectiveCatalogs: AuthEffectiveCatalogReadModel[];
  groups: AuthGroupReadModel[];
  licenses: LicenseGrantReadModel[];
};
export type CostCatalogElementReadModel = {
  id: string;
  parentId?: string | null;
  code: string;
  description: string;
  longDescription?: string | null;
  order: number;
  children: CostCatalogElementReadModel[];
};
export type CostCatalogReadModel = {
  id: string;
  name: string;
  elements: CostCatalogElementReadModel[];
};
export type RiskCatalogElementReadModel = {
  id: string;
  parentId?: string | null;
  code: string;
  description: string;
  longDescription?: string | null;
  children: RiskCatalogElementReadModel[];
};
export type RiskCatalogReadModel = {
  id: string;
  name: string;
  elements: RiskCatalogElementReadModel[];
};
export type EarningsType = 'SaleRevenue' | 'RentRevenue' | 'OperatingRevenue' | 'ServiceRevenue';
export type EarningsCatalogElementReadModel = {
  id: string;
  parentId?: string | null;
  code: string;
  description: string;
  longDescription?: string | null;
  earningsType: EarningsType;
  children: EarningsCatalogElementReadModel[];
};
export type EarningsCatalogReadModel = {
  id: string;
  name: string;
  elements: EarningsCatalogElementReadModel[];
};
export type FinancingCatalogElementReadModel = {
  id: string;
  parentId?: string | null;
  code: string;
  description: string;
  longDescription?: string | null;
  assignable: boolean;
  children: FinancingCatalogElementReadModel[];
};
export type FinancingCatalogReadModel = {
  id: string;
  name: string;
  elements: FinancingCatalogElementReadModel[];
};
export type CatalogTemplateReadModel = {
  catalogTemplateId: string;
  name: string;
  comment?: string | null;
  costCatalog: CostCatalogReadModel;
  riskCatalog?: RiskCatalogReadModel | null;
  earningsCatalog?: EarningsCatalogReadModel | null;
  financingCatalog?: FinancingCatalogReadModel | null;
};
export type AuthCatalogElementPermissionReadModel = {
  id: string;
  roleCatalogTemplateId: string;
  canReadElement: boolean;
  canWriteElement: boolean;
  costCatalogElement?: CostCatalogElementReadModel | null;
  riskCatalogElement?: RiskCatalogElementReadModel | null;
  earningsCatalogElement?: EarningsCatalogElementReadModel | null;
  financingCatalogElement?: FinancingCatalogElementReadModel | null;
};
export type AuthRoleCatalogTemplateReadModel = {
  id: string;
  roleId: string;
  catalogTemplate: CatalogTemplateReadModel;
  catalogElementPermissions: AuthCatalogElementPermissionReadModel[];
};
export type AuthRoleReadModel = {
  id: string;
  name: string;
  isSystemRole: boolean;
  systemRoleType: SystemRoleType;
  comment?: string | null;
  scope: PermissionScope;
  permissions: AuthPermissionReadModel[];
  subjects: AuthSubjectReadModel[];
  groups: AuthGroupReadModel[];
  catalogTemplates: AuthRoleCatalogTemplateReadModel[];
};
export type CreateRoleResponse = {
  roleId: string;
};
export type CreateCatalogElementPermissionPayload = {
  costCatalogElementId?: string | null;
  riskCatalogElementId?: string | null;
  earningsCatalogElementId?: string | null;
  financingCatalogElementId?: string | null;
  canReadPermission: boolean;
  canWritePermission: boolean;
};
export type CreateRoleCatalogTemplatePayload = {
  catalogTemplateId: string;
  added: CreateCatalogElementPermissionPayload[];
};
export type CreateRolePayload = {
  name: string;
  comment?: string | null;
  scope: PermissionScope;
  addedPermissions: ProbisPermission[];
  addedTemplates: CreateRoleCatalogTemplatePayload[];
};
export type FileDto = {
  data: string;
  contentType: string;
  fileName: string;
};
export type BaseReportMilestoneReadModel = {
  title: string;
  date?: string | null;
};
export type BaseReportColumnReadModel = {
  title: string;
  statusDate?: string | null;
  totalEarnings: number;
  totalCosts: number;
  totalRisks: number;
  totalEquityCapital: number;
  absoluteRoi: number;
  percentageRoi: number;
  percentageIrr: number;
  projectStart: string;
  projectEnd: string;
  projectDuration: number;
  milestones: BaseReportMilestoneReadModel[];
};
export type BaseReportReadModel = {
  columns: BaseReportColumnReadModel[];
};
export type BaseReportResponse = {
  projectCover?: FileDto | null;
  report: BaseReportReadModel;
};
export type BudgetReportCostRowReadModel = {
  code?: string | null;
  description?: string | null;
  unit?: string | null;
  amount?: number | null;
  unitPrice?: number | null;
  total?: number | null;
  totalDifference?: number | null;
  isRootGroup: boolean;
  children?: BudgetReportCostRowReadModel[] | null;
  rowIdentifier: string;
};
export type BudgetReportRiskRowReadModel = {
  code?: string | null;
  description?: string | null;
  value?: number | null;
  probability?: number | null;
  total?: number | null;
  isRootGroup: boolean;
  children?: BudgetReportRiskRowReadModel[] | null;
  rowIdentifier: string;
};
export type BudgetReportEarningsRowReadModel = {
  code?: string | null;
  description?: string | null;
  unit?: string | null;
  amount?: number | null;
  unitPrice?: number | null;
  total?: number | null;
  isRootGroup: boolean;
  children?: BudgetReportEarningsRowReadModel[] | null;
  rowIdentifier: string;
};
export type BudgetReportFinancingRowReadModel = {
  code?: string | null;
  description?: string | null;
  totalCost?: number | null;
  totalValue?: number | null;
  isRootGroup: boolean;
  children?: BudgetReportFinancingRowReadModel[] | null;
  rowIdentifier: string;
};
export type BudgetReportReadModel = {
  projectName: string;
  startDate: string;
  endDate: string;
  costs: BudgetReportCostRowReadModel[];
  risks: BudgetReportRiskRowReadModel[];
  earnings: BudgetReportEarningsRowReadModel[];
  financing: BudgetReportFinancingRowReadModel[];
  totalCosts: number;
  totalRisks: number;
  totalEarnings: number;
  totalFinancing: number;
};
export type BudgetReportResponse = {
  report: BudgetReportReadModel;
};
export type QuantityReadModel = {
  value?: number | null;
  unit: string;
};
export type CalculateConsumptionOverviewResponse = {
  totalArea: QuantityReadModel;
  totalConsumption: number;
  totalConsumptionLimit: number;
  overAllPenaltyCost: number;
  penaltyCost: number;
};
export type UtilityType = 'Electricity' | 'NaturalGas' | 'Propane' | 'FuelOilNo2' | 'FuelOilNo4' | 'DistrictSteam';
export type ConsumptionUsagePayload = {
  year: number;
  usage: number;
};
export type ConsumptionPayload = {
  utilityType: UtilityType;
  propertyTypeFactorId: string;
  consumptionUsages: ConsumptionUsagePayload[];
  taxonomyItemsId: string[];
};
export type ContractTitleCalculationReadModel = {
  contractValueNet: number;
  contractValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
};
export type ContractTitleState =
  | 'Expected'
  | 'Announced'
  | 'Budgeted'
  | 'Optional'
  | 'Received'
  | 'Approved'
  | 'Commissioned'
  | 'Rejected'
  | 'Canceled'
  | 'Reserve'
  | 'None';
export type CalculateContractTitlePayload = {
  state: ContractTitleState;
  discount: number;
  vat: number;
  budgetNet: number;
  offerNet: number;
  approvedOfferNet: number;
  adjustmentValueNet: number;
};
export type CalculateFormulaResult = {
  calculatedValue?: any | null;
};
export type FormulaExpressionParameterPayload = {
  position: number;
  costCatalogElementId?: string | null;
  riskCatalogElementId?: string | null;
  taxonomyElementId?: string | null;
  plotId?: string | null;
  calculationModelDeliveryPhaseId?: string | null;
  earningsCatalogElementId?: string | null;
};
export type FormulaElementType =
  | 'CostElement'
  | 'RiskElement'
  | 'TaxonomyItem'
  | 'Plot'
  | 'CalculationModelDeliveryPhase'
  | 'EarningsElement';
export type FormulaPayload = {
  expression: string;
  expressionParameterPayloads?: FormulaExpressionParameterPayload[] | null;
  catalogElementId?: string | null;
  elementType: FormulaElementType;
};
export type InvoiceTitleDistributionReadModel = {
  id: string;
  value: number;
};
export type InvoiceValueDistributionType = 'Manual' | 'Equal' | 'ByTitleForecast' | 'ByPreviousInvoice';
export type CalculateInvoiceValueDistributionPayload = {
  distributionType: InvoiceValueDistributionType;
  invoiceValue: number;
};
export type CashFlowItemType = 'Catalog' | 'CostElement' | 'Commitment' | 'Contract';
export type YearValue = {
  year: number;
  planned?: number | null;
  payed?: number | null;
};
export type QuarterValue = {
  year: number;
  quarter: number;
  planned?: number | null;
  payed?: number | null;
};
export type MonthValue = {
  date: string;
  planned?: number | null;
  payed?: number | null;
};
export type CashFlowValues = {
  years: YearValue[];
  quarters: QuarterValue[];
  months: MonthValue[];
};
export type CashFlowReportReadModel = {
  uid: string;
  itemId: string;
  itemParentId?: string | null;
  itemType: CashFlowItemType;
  code: string;
  name: string;
  children: CashFlowReportReadModel[];
  budgetAvailable?: number | null;
  budgetAssigned: number;
  forecast: number;
  values: CashFlowValues;
};
export type CashFlowReportResponse = {
  projectImage?: FileDto | null;
  tenantLogo?: FileDto | null;
  data: CashFlowReportReadModel[];
  start?: string | null;
  end?: string | null;
  budgetAvailable?: number | null;
  budgetAssigned?: number | null;
  forecast?: number | null;
  summary: CashFlowValues;
};
export type ChangeEmailCommand = {
  newEmail: string;
  password: string;
};
export type ChangeLanguageCommand = {
  language: string;
};
export type ChangeNameCommand = {
  firstName: string;
  lastName: string;
};
export type ChangePasswordCommand = {
  currentPassword: string;
  newPassword: string;
};
export type UserAclModel = {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  superuser: boolean;
  hasAvatar: boolean;
  language?: string | null;
  lastUpdate: string;
};
export type Address = {
  name: string;
  street: string;
  number?: string | null;
  suffix?: string | null;
  postalCode: string;
  city: string;
  state?: string | null;
  country: string;
  email: string;
};
export type AclTenant = {
  tenantId: string;
  name: string;
  name2?: string | null;
  subdomain: string;
  isOwner: boolean;
  hasAvatar: boolean;
  billingAddress: Address;
  membership: TenantUserState;
};
export type LicenseFeature =
  | 'PLOT_AND_TAXONOMY'
  | 'CALCULATE'
  | 'RENT_AND_SELL'
  | 'CONTRACT_AND_CONTROL'
  | 'MANAGE'
  | 'REPORTING'
  | 'CARESTONE_REPORTING'
  | 'BMW_REPORTING'
  | 'JLL_REPORTING'
  | 'GIAG_REPORTING'
  | 'ACCUMULATA_REPORTING'
  | 'AXA_REPORTING';
export type LicenseFeatureType = 'System' | 'Custom';
export type AclLicense = {
  feature: LicenseFeature;
  type: LicenseFeatureType;
};
export type AclPermission = {
  permission: ProbisPermission;
  isProjectSpecific: boolean;
  effectedProjects: string[];
};
export type AclCatalogElementPermission = {
  catalogElementId: string;
  canReadElement: boolean;
  canWriteElement: boolean;
};
export type AclCatalogTemplate = {
  catalogId: string;
  effectedProject?: string | null;
  canSeeAllElements: boolean;
  catalogElementPermissions: AclCatalogElementPermission[];
};
export type AclSet = {
  licenses: AclLicense[];
  permissions: AclPermission[];
  catalogTemplates: AclCatalogTemplate[];
};
export type CheckSessionCommandResponse = {
  user: UserAclModel;
  tenants: AclTenant[];
  tenantPermissions?: AclSet | null;
  tenant?: AclTenant | null;
};
export type CommandDescriptorDto = {
  fullName: string;
  baseClass: string;
  handler: string;
  permissions: string[];
  features: string[];
  requiresOwnership: boolean;
  requiresSuperuser: boolean;
};
export type ComparisonReportProjectReadModel = {
  projectName: string;
  startDate: string;
  endDate: string;
};
export type ComparisonReportTotalReadModel = {
  sourceTotal?: number | null;
  targetTotal?: number | null;
  differenceAbsolute?: number | null;
  differencePercent?: number | null;
};
export type IComparisonReportRowReadModel = {
  code: string;
  description: string;
  totals: ComparisonReportTotalReadModel;
  children?: IComparisonReportRowReadModel[] | null;
  rowIdentifier: string;
};
export type ComparisonReportSectionReadModel = {
  entries: IComparisonReportRowReadModel[];
  totals: ComparisonReportTotalReadModel;
};
export type ComparisonReportReadModel = {
  source: ComparisonReportProjectReadModel;
  target: ComparisonReportProjectReadModel;
  costs: ComparisonReportSectionReadModel;
  risks: ComparisonReportSectionReadModel;
  earnings: ComparisonReportSectionReadModel;
  financing: ComparisonReportSectionReadModel;
};
export type ComparisonReportResponse = {
  report: ComparisonReportReadModel;
};
export type WorkflowTaskStatus = 'New' | 'InProgress' | 'Accepted' | 'Rejected';
export type CompleteReviewInvoiceWorkflowTaskPayload = {
  invoiceId: string;
  comment?: string | null;
  taskCompletionStatus: WorkflowTaskStatus;
};
export type TemplateFileType = 'Csv' | 'Xlsx';
export type ContractTitleType = 'MainContract' | 'Supplement' | 'Reserve';
export type ContractReportReadModel = {
  contractId: string;
  contractCode: string;
  contractName: string;
  commitmentId: string;
  commitmentCode: string;
  commitmentName: string;
  clientId: string;
  clientName: string;
  titleId: string;
  titleCode: string;
  titleName: string;
  type: ContractTitleType;
  state: ContractTitleState;
  budget: number;
  contract: number;
  adjustment: number;
  forecast: number;
};
export type ConversionBaseReadModel = {
  id: string;
  code: string;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
  originalInvoiceValueNet: number;
  originalInvoiceValueGross: number;
  originalPaymentValueNet: number;
  originalPaymentValueGross: number;
  originalTotalPaymentValueNet: number;
  originalTotalPaymentValueGross: number;
};
export type InvoiceState = 'Pending' | 'Audited' | 'Approved' | 'Paid';
export type InvoiceConversionReadModel = {
  id: string;
  code: string;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
  originalInvoiceValueNet: number;
  originalInvoiceValueGross: number;
  originalPaymentValueNet: number;
  originalPaymentValueGross: number;
  originalTotalPaymentValueNet: number;
  originalTotalPaymentValueGross: number;
  state: InvoiceState;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  originalState: InvoiceState;
  originalDateOfApproval?: string | null;
  originalPaymentDate?: string | null;
  invoiceTitles: ConversionBaseReadModel[];
};
export type ContractConversionReadModel = {
  id: string;
  code: string;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
  originalInvoiceValueNet: number;
  originalInvoiceValueGross: number;
  originalPaymentValueNet: number;
  originalPaymentValueGross: number;
  originalTotalPaymentValueNet: number;
  originalTotalPaymentValueGross: number;
  name: string;
  contractTitles: ConversionBaseReadModel[];
  invoices: InvoiceConversionReadModel[];
};
export type ConvertContractsPayload = {
  tenantId: string;
  projectId: string;
};
export type CopyRoleResponse = {
  roleId: string;
};
export type CopyRolePayload = {
  copyName: string;
  copyComment?: string | null;
};
export type CopyCalculationModelCommandResponse = {
  calculationModelId: string;
};
export type CopyCalculationModelPayload = {
  name: string;
  description?: string | null;
};
export type CopyContractResponse = {
  contractId: string;
};
export type CopyContractPayload = {
  code: string;
  name: string;
};
export type CopyProjectPayload = {
  name: string;
  projectId: string;
  onlyCurrentVersion: boolean;
};
export type CopyInvoiceCalculationSchemeResponse = {
  calculationSchemeId: string;
};
export type CopyInvoiceCalculationSchemePayload = {
  isDefault: boolean;
  name: string;
  description?: string | null;
};
export type CopyProjectToTenantPayload = {
  tenantId: string;
  projectId: string;
  newTenantId: string;
  projectCopyId?: string | null;
};
export type CopyWorkflowPayload = {
  sourceTenantId: string;
  sourceWorkflowDefinitionId: string;
  sourceWorkflowName: string;
  targetTenantId: string;
  targetWorkflowName?: string | null;
};
export type CreateAssignableWorkflowActivityResponse = {
  workflowActivityId: string;
};
export type CreateWorkflowAssignableActivityPayload = {
  workflowDefinitionId: string;
  workflowName?: string | null;
  workflowType: string;
  activityId: string;
  activityName: string;
  activityType: string;
  authorizedUsers: string[];
  authorizedGroups: string[];
};
export type WorkflowAuthorizedUsersReadModel = {
  id: string;
  userName: string;
  email: string;
  hasAvatar: boolean;
};
export type WorkflowAuthorizedGroupReadModel = {
  id: string;
  groupName: string;
  groupUsers: WorkflowAuthorizedUsersReadModel[];
};
export type WorkflowAssignableActivityConfigurationType = 'EmailAttachment';
export type EmailAttachmentOption = 'Invoice';
export type WorkflowTaskConfigurationReadModel = {
  id: string;
  type: WorkflowAssignableActivityConfigurationType;
  value: EmailAttachmentOption;
};
export type AssignableActivityReadModel = {
  activityId: string;
  name: string;
  type: string;
  authorizedUsers?: WorkflowAuthorizedUsersReadModel[] | null;
  authorizedGroups?: WorkflowAuthorizedGroupReadModel[] | null;
  optionalConfigurations?: WorkflowTaskConfigurationReadModel[] | null;
};
export type WorkflowAssignableActivityReadModel = {
  definitionId: string;
  name: string;
  workflowType: string;
  isContractWorkflow: boolean;
  activities: AssignableActivityReadModel[];
};
export type WorkflowAssignableActivityConfigurationOptions = {
  type: string;
  options: string[];
};
export type GetAssignableWorkflowActivitiesQueryResponse = {
  workflows: WorkflowAssignableActivityReadModel[];
  configurationOptions: WorkflowAssignableActivityConfigurationOptions[];
};
export type CreateGroupResponse = {
  groupId: string;
};
export type RoleAssignmentPayload = {
  roleId: string;
  projectId?: string | null;
};
export type GroupPayload = {
  name: string;
  comment?: string | null;
  assignedUsers: string[];
  roleAssignments: RoleAssignmentPayload[];
};
export type TaxonomyType =
  | 'Undefined'
  | 'Project'
  | 'Area'
  | 'Quarter'
  | 'BuildingComplex'
  | 'Building'
  | 'Wing'
  | 'Floor'
  | 'Unit'
  | 'Sector'
  | 'Room'
  | 'Zone'
  | 'Parking'
  | 'Bed'
  | 'Other';
export type TaxonomyUsageType =
  | 'Undefined'
  | 'Production'
  | 'Traffic'
  | 'Technical'
  | 'Residential'
  | 'Office'
  | 'Retail'
  | 'Storage'
  | 'Hotel'
  | 'Mixed';
export type ParkingLotType = 'Undefined' | 'Garage' | 'Outdoor' | 'Duplex' | 'Carport' | 'Other';
export type ParkingLotFeatures = 'Wallbox11kW' | 'Wallbox22kW' | 'Xxl' | 'Disabled';
export type CreateParkingLotPayload = {
  description?: string | null;
  number?: string | null;
  type: ParkingLotType;
  features: ParkingLotFeatures[];
};
export type UserDefinedFieldPayload = {
  userDefinedFieldDefinitionId: string;
  text?: string | null;
  number?: number | null;
  listSelectedItemId?: string | null;
  markedVisible: boolean;
};
export type TaxonomyItemPayload = {
  parentId?: string | null;
  childId?: string | null;
  name: string;
  customerName?: string | null;
  bgf?: number | null;
  ngf?: number | null;
  floorAreaCount?: number | null;
  constructionFloor?: number | null;
  rentalSpace?: number | null;
  salesSpace?: number | null;
  commonSpace?: number | null;
  grossVolume?: number | null;
  other?: number | null;
  amount?: number | null;
  type: TaxonomyType;
  usageType?: TaxonomyUsageType | null;
  parkingLot?: CreateParkingLotPayload | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type State = 'Empty' | 'JustValue' | 'JustCalculated' | 'Equality' | 'Remainder' | 'Overflow';
export type AreaSizeReadModel = {
  bgf: QuantityReadModel;
  ngf: QuantityReadModel;
  floorAreaCount?: number | null;
  constructionFloor: QuantityReadModel;
  rentalSpace: QuantityReadModel;
  salesSpace: QuantityReadModel;
  commonSpace: QuantityReadModel;
  grossVolume: QuantityReadModel;
  other: QuantityReadModel;
  amount: QuantityReadModel;
  calculatedGrossFloor: QuantityReadModel;
  calculatedNetFloor: QuantityReadModel;
  calculatedFloorAreaCountAvg?: number | null;
  calculatedConstructionFloor: QuantityReadModel;
  calculatedRentalSpace: QuantityReadModel;
  calculatedSalesSpace: QuantityReadModel;
  calculatedCommonSpace: QuantityReadModel;
  calculatedGrossVolume: QuantityReadModel;
  calculatedParkingSpace: QuantityReadModel;
  calculatedOther: QuantityReadModel;
  grossFloorRestValue: QuantityReadModel;
  netFloorRestValue: QuantityReadModel;
  rentalSpaceRestValue: QuantityReadModel;
  effectiveGrossFloorValue: QuantityReadModel;
  effectiveNetFloorValue: QuantityReadModel;
  effectiveRentalSpaceValue: QuantityReadModel;
  effectiveCommonSpaceValue: QuantityReadModel;
  parkingGrossFloor: QuantityReadModel;
  parkingNetFloor: QuantityReadModel;
  parkingRentalSpace: QuantityReadModel;
  grossFloorState: State;
  netFloorState: State;
  rentalSpaceState: State;
};
export type TaxonomyShortReadModel = {
  calculationModelId: string;
  calculationModelName: string;
  itemId: string;
  itemName: string;
  itemCustomerName?: string | null;
  itemType: TaxonomyType;
  itemUsageType?: TaxonomyUsageType | null;
  sizes: AreaSizeReadModel;
  orderWithinParent: number;
};
export type DealType = 'Rent' | 'Sell';
export type DealStatus = 'None' | 'Draft' | 'Interested' | 'Reserved' | 'Closed';
export type DealContactReadModel = {
  id: string;
  role?: string | null;
  company?: string | null;
  name: string;
  email?: string | null;
  mobile?: string | null;
};
export type LightDealCostItemReadModel = {
  id: string;
  taxonomyItemId: string;
  partCommonSpace: QuantityReadModel;
  partRentalSpace: QuantityReadModel;
  partParkingAmount: number;
};
export type DealContractItemReadModel = {
  id: string;
  name: string;
  category: ContractItemCategory;
  calculation: CalculationTarget;
  factor: QuantityReadModel;
  value: QuantityReadModel;
  vat: number;
  hasSpaceConflict: boolean;
};
export type SecurityType = 'None' | 'Surety' | 'RentalDeposit' | 'DepositInsurance';
export type PaymentMethod = 'None' | 'OneTime' | 'PaymentPlan';
export type PaymentFrequency = 'Monthly' | 'Quarterly' | 'Yearly';
export type AdjustmentType = 'None' | 'ConsumerPriceIndex' | 'Fix';
export type DealRentConditionReadModel = {
  securityAmount: number;
  securityType: SecurityType;
  paymentMethod: PaymentMethod;
  paymentAppointment?: string | null;
  rentStart?: string | null;
  rentEnd?: string | null;
  contractTerminationNotice: number;
  contractEarliestTerminationDate?: string | null;
  contractPossibleExtensions: number;
  contractExtensionDuration: number;
  rentFreePeriodStart?: string | null;
  rentFreePeriodEnd?: string | null;
  billingPeriod: PaymentFrequency;
  rentDueDays: number;
  adjustmentType: AdjustmentType;
  adjustmentTypeActiveValue: number;
  adjustmentInterval: PaymentFrequency;
  adjustmentDeadline?: string | null;
};
export type LightDealReadModel = {
  id: string;
  projectId: string;
  name: string;
  code: string;
  type: DealType;
  status: DealStatus;
  start?: string | null;
  end?: string | null;
  updatedAt: string;
  contacts: DealContactReadModel[];
  costItems: LightDealCostItemReadModel[];
  contractItems: DealContractItemReadModel[];
  dealCondition: DealRentConditionReadModel;
  totalRentedSpace: QuantityReadModel;
  totalDealValue: QuantityReadModel;
};
export type TaxonomyItemParkingLotReadModel = {
  parkingLotId: string;
  description?: string | null;
  number?: string | null;
  type: ParkingLotType;
  features: ParkingLotFeatures[];
};
export type TaxonomyItemFileTypeEnum =
  | 'Thumbnail'
  | 'Image'
  | 'Sketch'
  | 'FloorPlan'
  | 'SitePlan'
  | 'Contract'
  | 'Defect'
  | 'Document';
export type TaxonomyItemFileReadModel = {
  id: string;
  name: string;
  size: number;
  fileType: TaxonomyItemFileTypeEnum;
  created: string;
};
export type UserDefinedFieldType = 'Text' | 'Number' | 'List';
export type UserDefinedFieldElementType =
  | 'Cost'
  | 'Project'
  | 'Taxonomy'
  | 'Contract'
  | 'ContractTitle'
  | 'Invoice'
  | 'Risk'
  | 'Earning';
export type UserDefinedFieldVisibilityType =
  | 'AlwaysVisible'
  | 'VisibleOnComponentAfterManualSelection'
  | 'VisibleOnAllComponentsAfterManualSelection';
export type UserDefinedFieldDefinitionListItemReadModel = {
  listItemId: string;
  label: string;
};
export type UserDefinedFieldCalculateElementType = 'Group' | 'Element';
export type UserDefinedFieldAssignedProjectsReadModel = {
  id: string;
  projectName: string;
};
export type ElementUserDefinedFieldDefinitionReadModel = {
  id: string;
  name: string;
  description?: string | null;
  fieldType: UserDefinedFieldType;
  elementType: UserDefinedFieldElementType;
  visibilityType: UserDefinedFieldVisibilityType;
  isVisible: boolean;
  isMarkedVisible: boolean;
  isUsed: boolean;
  listItems?: UserDefinedFieldDefinitionListItemReadModel[] | null;
  calculateElementType?: UserDefinedFieldCalculateElementType | null;
  userDefinedField?: ElementUserDefinedFieldReadModel | null;
  isRequired: boolean;
  defaultText?: string | null;
  defaultNumber?: number | null;
  defaultListItem?: UserDefinedFieldDefinitionListItemReadModel | null;
  assignedProjects?: UserDefinedFieldAssignedProjectsReadModel[] | null;
};
export type ElementUserDefinedFieldReadModel = {
  definition: ElementUserDefinedFieldDefinitionReadModel;
  id: string;
  text?: string | null;
  number?: number | null;
  listSelectedItem?: UserDefinedFieldDefinitionListItemReadModel | null;
  isMarkedVisible: boolean;
};
export type TaxonomyReadModel = {
  calculationModelId: string;
  calculationModelName: string;
  calculationModelMaxGrossFloor: QuantityReadModel;
  grossFloorEfficiency: QuantityReadModel;
  netFloorEfficiency: QuantityReadModel;
  rentalSpaceEfficiency: QuantityReadModel;
  types: TaxonomyType[];
  itemId: string;
  itemName: string;
  itemCustomerName?: string | null;
  itemType: TaxonomyType;
  itemUsageType?: TaxonomyUsageType | null;
  itemLogicalId: string;
  sizes: AreaSizeReadModel;
  children: TaxonomyReadModel[];
  parent?: TaxonomyShortReadModel | null;
  dealCosts: LightDealReadModel[];
  parkingLot?: TaxonomyItemParkingLotReadModel | null;
  files?: TaxonomyItemFileReadModel[] | null;
  description?: string | null;
  cost?: number | null;
  orderWithinParent: number;
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
};
export type PositionType = 'Start' | 'End';
export type TimelineTypes =
  | 'FixedDates'
  | 'DeliveryPhaseReference'
  | 'TimelineReference'
  | 'CalculationModelReference'
  | 'MileStoneReference'
  | 'ProjectEnd';
export type TimelineDurationUnit = 'Days' | 'Months' | 'Weeks' | 'Years';
export type DistributionType =
  | 'None'
  | 'AllAtTheStart'
  | 'AllAtTheEnd'
  | 'Constant'
  | 'Progressive'
  | 'Degressive'
  | 'ReverseCurve'
  | 'Manual'
  | 'Effective'
  | 'BellCurve';
export type DistributionFrequency = 'None' | 'Day' | 'Week' | 'Month' | 'QuarterYear' | 'HalfYear' | 'Year';
export type ManualDistributionValuePayload = {
  id?: string | null;
  calculationDeliveryPhaseId?: string | null;
  fixedDate?: string | null;
  description: string;
  percentage: number;
  fixedValue: number;
};
export type ManualDistributionPatternPayload = {
  distributionPatterId?: string | null;
  name: string;
  description: string;
  manualDistributionPayload: ManualDistributionValuePayload[];
};
export type TimelineDistributionPayload = {
  id?: string | null;
  distributionType: DistributionType;
  distributionFrequency: DistributionFrequency;
  manualDistribution?: ManualDistributionPatternPayload | null;
};
export type ElementTimelinePayload = {
  id?: string | null;
  startCalculationModelDeliveryPhaseId?: string | null;
  startCalculationModelMileStoneId?: string | null;
  startOffset?: number | null;
  startOffsetPosition?: PositionType | null;
  startFixedStartDate?: string | null;
  startElementTimelineId?: string | null;
  startType: TimelineTypes;
  duration?: number | null;
  endCalculationModelDeliveryPhaseId?: string | null;
  endCalculationModelMileStoneId?: string | null;
  endOffset?: number | null;
  endOffsetPosition?: PositionType | null;
  endElementTimelineId?: string | null;
  endType: TimelineTypes;
  endFixedDate?: string | null;
  startTimelineDurationUnit: TimelineDurationUnit;
  endTimelineDurationUnit: TimelineDurationUnit;
  durationUnit: TimelineDurationUnit;
  timelineDistribution?: TimelineDistributionPayload | null;
};
export type CalculationModelDeliveryPhasePayload = {
  name: string;
  order: number;
  code: string;
  deliveryPhaseTimeLinePayload: ElementTimelinePayload;
};
export type TimelineValueReadModel = {
  periodNumber: number;
  periodStart: string;
  periodEnd: string;
  effectiveStart: string;
  effectiveEnd: string;
  percentage: number;
  value: number;
  isActual: boolean;
};
export type DistributionValueReadModel = {
  id?: string | null;
  date?: string | null;
  description: string;
  percentage: number;
  value: number;
  rest: boolean;
};
export type DistributionPatternReadModel = {
  id?: string | null;
  name: string;
  description: string;
  distributionValues: DistributionValueReadModel[];
};
export type DistributionReadModel = {
  id: string;
  type: DistributionType;
  frequency: DistributionFrequency;
  distributionValues: TimelineValueReadModel[];
  distributionPattern?: DistributionPatternReadModel | null;
};
export type ElementTimelineReadModel = {
  id?: string | null;
  calculationModelDeliveryPhaseId?: string | null;
  startCalculationModelDeliveryPhaseId?: string | null;
  startCalculationModelMileStoneId?: string | null;
  startOffset?: number | null;
  startOffsetUnit?: TimelineDurationUnit | null;
  startOffsetPosition?: PositionType | null;
  startFixedStartDate?: string | null;
  startElementTimelineId?: string | null;
  startType: TimelineTypes;
  effectiveStartDate?: string | null;
  duration?: number | null;
  durationUnit?: TimelineDurationUnit | null;
  endDate?: string | null;
  endCalculationModelDeliveryPhaseId?: string | null;
  endCalculationModelMileStoneId?: string | null;
  endOffset?: number | null;
  endOffsetUnit?: TimelineDurationUnit | null;
  endOffsetPosition?: PositionType | null;
  endElementTimelineId?: string | null;
  endType: TimelineTypes;
  effectiveEndDate?: string | null;
  distribution?: DistributionReadModel | null;
};
export type DeliveryPhaseOverviewReadModel = {
  id: string;
  name: string;
  order: number;
  code: string;
  timeLine: ElementTimelineReadModel;
};
export type GetCalculationModelDeliveryPhasesQueryResponse = {
  calculationModelStartDate: string;
  calculationModelEndDate: string;
  deliveryPhases: DeliveryPhaseOverviewReadModel[];
};
export type CalculationModelMilestonePayload = {
  name: string;
  code: string;
  date: string;
  order: number;
  isAchieved: boolean;
};
export type CalculationModelMilestoneReadModel = {
  id: string;
  code: string;
  name: string;
  date: string;
  order: number;
  isAchieved: boolean;
};
export type GetCalculationModelMilestonesQueryResponse = {
  milestones: CalculationModelMilestoneReadModel[];
};
export type CreateCalculationModelUserSnapshotPayload = {
  comment: string;
};
export type TenantCatalogType = 'Costs' | 'Earnings' | 'Risks' | 'Finance';
export type CreateTenantCatalogElementPayload = {
  catalogId: string;
  code: string;
  name: string;
  parentId?: string | null;
  type: TenantCatalogType;
  earningsElementType: EarningsType;
};
export type DeleteTenantCatalogElementPayload = {
  catalogId: string;
  type: TenantCatalogType;
  elementId: string;
};
export type UpdateTenantCatalogElementPayload = {
  catalogId: string;
  elementId: string;
  type: TenantCatalogType;
  code: string;
  name: string;
  earningsElementType: EarningsType;
};
export type CreateTenantCatalogPayload = {
  catalogName: string;
  type: TenantCatalogType;
};
export type DeleteTenantCatalogPayload = {
  catalogId: string;
  type: TenantCatalogType;
};
export type UpdateTenantCatalogPayload = {
  catalogId: string;
  catalogName: string;
  type: TenantCatalogType;
};
export type CreateCatalogTemplateResponse = {
  catalogTemplateId: string;
};
export type CatalogTemplatePayload = {
  name: string;
  comment?: string | null;
  costCatalogId: string;
  riskCatalogId?: string | null;
  earningsCatalogId?: string | null;
  financingCatalogId?: string | null;
};
export type CreateClusterItemRequirementResponse = {
  requirementId?: string | null;
};
export type CreateClusterItemRequirementPayload = {
  code: string;
  name: string;
};
export type CreateCommitmentResponse = {
  commitmentId: string;
};
export type CreateBudgetAssignmentPayload = {
  costElementId: string;
  budgetNet: number;
};
export type CreateCommitmentPayload = {
  code: string;
  name: string;
  description?: string | null;
  addedContracts: string[];
  budgetAssignments: CreateBudgetAssignmentPayload[];
};
export type ContractState = 'Pending' | 'Settled' | 'Paid';
export type TrustState = 'Invited' | 'Accepted' | 'Expired';
export type ShortContractReadModel = {
  id: string;
  commitmentId?: string | null;
  code: string;
  name: string;
  description?: string | null;
  contractDate?: string | null;
  state: ContractState;
  contractorId?: string | null;
  contractorCompanyName?: string | null;
  contractorCode?: string | null;
  contractorName?: string | null;
  contractorPhone?: string | null;
  contractorEmail?: string | null;
  isTrustedContractor: boolean;
  isContractorTrustActive: boolean;
  contractorTrustState?: TrustState | null;
  clientId?: string | null;
  clientCompanyName?: string | null;
  clientCode?: string | null;
  clientName?: string | null;
  clientPhone?: string | null;
  clientEmail?: string | null;
  isTrustedClient: boolean;
  isClientTrustActive: boolean;
  clientTrustState?: TrustState | null;
  vat?: number | null;
  discount?: number | null;
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
  budgetNet: number;
  budgetGross: number;
  offerNet: number;
  offerGross: number;
  approvedOfferNet: number;
  approvedOfferGross: number;
  contractValueNet: number;
  contractValueGross: number;
  adjustmentValueNet: number;
  adjustmentValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  isPxContract: boolean;
  hasFinalInvoice: boolean;
  workflow?: string | null;
};
export type ShortCommitmentReadModel = {
  id: string;
  code: string;
  name: string;
  description?: string | null;
  budgetNet: number;
  budgetGross: number;
  contractValueNet: number;
  contractValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  contracts: ShortContractReadModel[];
};
export type CreateCompanyBranchResponse = {
  companyBranchId: string;
};
export type CompanyAddressPayload = {
  supplement?: string | null;
  street?: string | null;
  number?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
};
export type CreateCompanyBranchPayload = {
  isMainBranch: boolean;
  code: string;
  name: string;
  address?: CompanyAddressPayload | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  link?: string | null;
  creditorId?: string | null;
  debitorId?: string | null;
  spvId?: string | null;
};
export type CreateCompanyResponse = {
  companyId: string;
};
export type CreatePrivatePersonResponse = {
  personId: string;
};
export type PersonGender = 'M' | 'F' | 'D';
export type CompanyPersonPayload = {
  gender: PersonGender;
  title?: string | null;
  firstName: string;
  lastName: string;
  function?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  web?: string | null;
  branchId?: string | null;
};
export type CreateCompanyBankAccountPayload = {
  bankName?: string | null;
  swift?: string | null;
  accountOwner?: string | null;
  iban: string;
};
export type PrivatePersonPayload = {
  person: CompanyPersonPayload;
  address?: CompanyAddressPayload | null;
  account?: CreateCompanyBankAccountPayload | null;
  creditorId?: string | null;
  debitorId?: string | null;
  spvId?: string | null;
};
export type CreateCompanyPersonResponse = {
  personId: string;
};
export type CreateContractResponse = {
  contractId: string;
};
export type CalculationRuleValueOverridePayload = {
  calculationRuleId: string;
  percentage?: number | null;
  value?: number | null;
  vat?: number | null;
};
export type GetContractsQueryResponse = {
  commitments?: ShortCommitmentReadModel[] | null;
  contracts?: ShortContractReadModel[] | null;
  availableWorkflows: string[];
};
export type DocumentReadModel = {
  id: string;
  name: string;
  size: number;
  created: string;
};
export type CreateContractTitleResponse = {
  contractTitleId: string;
};
export type CreateContractTitlePayload = {
  code: string;
  name: string;
  description?: string | null;
  type: ContractTitleType;
  state: ContractTitleState;
  vat: number;
  discount: number;
  offer: number;
  approvedOffer: number;
  adjustmentValue: number;
  budgetAssignments: CreateBudgetAssignmentPayload[];
  userDefinedFields?: UserDefinedFieldPayload[] | null;
};
export type CellType = 'Static' | 'Formula';
export type StaticValueModel = {
  value: number;
  unit: string;
};
export type FactorPayload = {
  type: CellType;
  staticFactor?: StaticValueModel | null;
  formula?: FormulaPayload | null;
};
export type VatDistribution = {
  date: string;
  amount: number;
};
export type VatElementPayload = {
  vatCostCatalogId: string;
  vatDistributions: VatDistribution[];
  costGroupIds: string[];
  userDefinedFieldId?: string | null;
  userDefinedFieldLabelIds?: string[] | null;
  vatReturnPercentage: number;
  delayMonths: number;
};
export type CostElementPayload = {
  catalogElementId?: string | null;
  amount: FactorPayload;
  unitPrice: FactorPayload;
  deliveryPhasePayload?: ElementTimelinePayload | null;
  description: string;
  isGroup: boolean;
  statusId?: string | null;
  vatId?: string | null;
  constructionPhaseId?: string | null;
  tagIds?: string[] | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
  vatPayload?: VatElementPayload | null;
};
export type TagPayload = {
  name: string;
};
export type CreateDefaultValuesResponse = {
  defaultValuesId: string;
};
export type DefaultValuesPayload = {
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
};
export type DefaultValuesReadModel = {
  id: string;
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
};
export type CreateDeliveryPhaseResponse = {
  id: string;
};
export type DeliveryPhasePayload = {
  hoaiDeliveryPhaseNumber: number;
  name: string;
  durationsDays: number;
  order?: number | null;
  code: string;
};
export type CreateDeliveryPhasesCatalogResponse = {
  id: string;
};
export type DeliveryPhasesCatalogPayload = {
  name: string;
};
export type DeliveryPhaseReadModel = {
  name: string;
  code: string;
  order: number;
  defaultDurationsMonths: number;
  hoaiDeliveryPhaseNumber: number;
};
export type DeliveryPhasesCatalogReadModel = {
  id: string;
  name: string;
  tenantId: string;
  deliveryPhases: DeliveryPhaseReadModel[];
};
export type GetDeliveryPhasesCatalogsQueryResponse = {
  catalogs: DeliveryPhasesCatalogReadModel[];
};
export type RecurringPaymentData = {
  contractDate: string;
  startDate: string;
  endDate?: string | null;
  paymentFrequency: PaymentFrequency;
};
export type ModifyEarningsElementPayload = {
  code: string;
  title?: string | null;
  description: string;
  amount: FactorPayload;
  unitPrice: FactorPayload;
  type: EarningsType;
  recurringPaymentData?: RecurringPaymentData | null;
  oneTimePaymentData?: ElementTimelinePayload | null;
  earningsCatalogElementId?: string | null;
  isGroup: boolean;
  calculationDate?: string | null;
  relevantForValuation: boolean;
  multiple?: number | null;
  rentIndex?: number | null;
  rentIndexDate?: string | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type FinancingType = 'OwnCapital' | 'Credit' | 'Grant' | 'Fund';
export type PaymentPlanType =
  | 'AllInTheBeginning'
  | 'MonthlyLinear'
  | 'QuarterlyLinear'
  | 'Variable'
  | 'EndOfTheProject';
export type VariablePayoutPlanElementPayload = {
  id?: string | null;
  order: number;
  description: string;
  paymentOn: string;
  amount?: number | null;
  percentage?: number | null;
};
export type VariablePaybackPlanElementPayload = {
  id?: string | null;
  order: number;
  description: string;
  paymentOn: string;
  amount?: number | null;
  rest: boolean;
};
export type InterestRateType = 'Fixed' | 'Euribor' | 'Variable';
export type InterestRateMethod = 'Days30360' | 'Act360' | 'Act365';
export type InterestPeriod = 'Monthly' | 'Quarterly' | 'Biannual' | 'Yearly' | 'End';
export type EuriborPeriod = 'OneMonth' | 'ThreeMonths' | 'SixMonths' | 'TwelveMonths';
export type InterestRatePayload = {
  interestRate?: number | null;
  interestRateType: InterestRateType;
  interestMethod: InterestRateMethod;
  interestPeriod: InterestPeriod;
  firstInterestDate?: string | null;
  interestCostCatalogId?: string | null;
  euriborPeriod?: EuriborPeriod | null;
  liquidityPremium?: number | null;
  bankMarge?: number | null;
  firstEuriborDate?: string | null;
  payInterestOnCalculation: boolean;
  commitmentInterestRate?: number | null;
  commitmentStartDate?: string | null;
};
export type OwnCapitalType =
  | 'OwnCapital'
  | 'Cash'
  | 'Fund'
  | 'JointVentureParticipation'
  | 'Bond'
  | 'ShareholderLoan'
  | 'Other';
export type GrantType = 'Credit' | 'FinancialSupport' | 'KfWGrants' | 'Other';
export type FinancingFeePayload = {
  id?: string | null;
  date: string;
  description: string;
  amount: number;
  costCatalogElementId?: string | null;
};
export type RepaymentMethod = 'Annuity' | 'Amortization' | 'AnnuityByPercentage';
export type LoanTypes = 'MoneyMarketCredit' | 'CurrentAccountCredit' | 'Avalkredit' | 'CashAccount';
export type FinancingElementPayload = {
  financingType: FinancingType;
  code?: string | null;
  name?: string | null;
  description: string;
  provider?: string | null;
  nominalAmount: number;
  grantFee?: number | null;
  fixedInterestRate?: number | null;
  payoutPattern: PaymentPlanType;
  variablePayoutPlan?: VariablePayoutPlanElementPayload[] | null;
  payoutTimeline?: ElementTimelinePayload | null;
  paybackPattern: PaymentPlanType;
  variablePaybackPlan?: VariablePaybackPlanElementPayload[] | null;
  paybackTimeline?: ElementTimelinePayload | null;
  interestRatePayload?: InterestRatePayload | null;
  ownCapitalType?: OwnCapitalType | null;
  grantType?: GrantType | null;
  feesPayload: FinancingFeePayload[];
  partOfIrr: boolean;
  financingCatalogElementId?: string | null;
  purposeFinancingGroupIds: string[];
  financedCostCatalogElementIds: string[];
  financeAllCosts: boolean;
  grantAmount: number;
  annuityPercentage?: number | null;
  grantDate?: ElementTimelinePayload | null;
  residualDebt?: number | null;
  repaymentMethod?: RepaymentMethod | null;
  loanType?: LoanTypes | null;
};
export type CreateInvoiceByContractResponse = {
  invoiceId: string;
};
export type InvoiceType = 'Single' | 'Partial' | 'PartialFinal' | 'Final' | 'AdvancePayment' | 'Warranty';
export type CreateInvoiceByContractDto = {
  invoiceDocumentId?: string | null;
  calculationSchemeId?: string | null;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  vat?: number | null;
  claim: number;
  invoiceDate: string;
  dateOfReceipt: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  comment?: string | null;
  userDefinedFields?: UserDefinedFieldPayload[] | null;
};
export type CreateCalculationRuleResponse = {
  calculationRuleId: string;
};
export type CalculationRuleType =
  | 'Deduction'
  | 'Retention'
  | 'Subtotal'
  | 'Discount'
  | 'Vat'
  | 'Repayment'
  | 'PartialPayment'
  | 'CashDiscount';
export type ReferenceType =
  | 'None'
  | 'InvoiceValue'
  | 'InvoiceValueNet'
  | 'InvoiceValueGross'
  | 'PartialPayment'
  | 'PartialPaymentNet'
  | 'PartialPaymentGross'
  | 'MainContractValue'
  | 'MainContractValueNet'
  | 'MainContractValueGross'
  | 'SupplementValue'
  | 'SupplementValueNet'
  | 'SupplementValueGross'
  | 'ContractValue'
  | 'ContractValueNet'
  | 'ContractValueGross'
  | 'CalculationRule'
  | 'CalculationRuleGroup';
export type CreateCalculationRulePayload = {
  name: string;
  type: CalculationRuleType;
  vat?: number | null;
  percentage?: number | null;
  value?: number | null;
  referenceType?: ReferenceType | null;
  calculationRuleGroupReferenceId?: string | null;
  calculationRuleReferenceId?: string | null;
  partialInvoiceReferenceType?: ReferenceType | null;
  partialInvoiceCalculationRuleGroupReferenceId?: string | null;
  partialInvoiceCalculationRuleReferenceId?: string | null;
  partialFinalInvoiceReferenceType?: ReferenceType | null;
  partialFinalInvoiceCalculationRuleGroupReferenceId?: string | null;
  partialFinalInvoiceCalculationRuleReferenceId?: string | null;
  finalInvoiceReferenceType?: ReferenceType | null;
  finalInvoiceCalculationRuleGroupReferenceId?: string | null;
  finalInvoiceCalculationRuleReferenceId?: string | null;
};
export type CreateCalculationRuleGroupResponse = {
  calculationRuleGroupId: string;
};
export type CalculationRuleGroupType = 'Custom' | 'Payment';
export type CreateCalculationRuleGroupPayload = {
  name: string;
  type: CalculationRuleGroupType;
};
export type CreateInvoiceResponse = {
  invoiceId: string;
};
export type CreateInvoiceDto = {
  invoiceDocumentId?: string | null;
  calculationSchemeId?: string | null;
  invoicingPartyId?: string | null;
  invoiceRecipientId?: string | null;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  vat?: number | null;
  claim: number;
  invoiceDate: string;
  dateOfReceipt: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  costElementId?: string | null;
  comment?: string | null;
  userDefinedFields?: UserDefinedFieldPayload[] | null;
};
export type StartWorkflowReasonStatus =
  | 'None'
  | 'NoWorkflowAvailable'
  | 'InvoiceMustBePendingState'
  | 'InProgress'
  | 'NoDefaultWorkflow'
  | 'NoContractInvoiceWorkflow'
  | 'NoDefaultAvailableContractWorkflowMustBeSelected'
  | 'SomeDefaultActivitiesNotAssigned'
  | 'SomeContractActivitiesNotAssigned'
  | 'SomeActivityHasNoUserOrGroup';
export type StartWorkflowStatusReadModel = {
  canStartWorkflow: boolean;
  canRestartWorkflow: boolean;
  workflowName?: string | null;
  reasonStatus: StartWorkflowReasonStatus;
};
export type ShortInvoiceReadModel = {
  id: string;
  contractId?: string | null;
  contractName?: string | null;
  contractCode?: string | null;
  invoicingPartyId?: string | null;
  invoicingPartyCompanyName?: string | null;
  invoicingPartyCode?: string | null;
  invoicingPartyName?: string | null;
  invoicingPartyPhone?: string | null;
  invoicingPartyEmail?: string | null;
  invoiceRecipientId?: string | null;
  invoiceRecipientCompanyName?: string | null;
  invoiceRecipientCode?: string | null;
  invoiceRecipientName?: string | null;
  invoiceRecipientPhone?: string | null;
  invoiceRecipientEmail?: string | null;
  sequentialNumber: number;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  state: InvoiceState;
  distributionType: InvoiceValueDistributionType;
  vat?: number | null;
  claim: number;
  dateOfReceipt: string;
  invoiceDate: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  dateOfAudit?: string | null;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  valueNet: number;
  valueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  discountValueNet: number;
  discountValueGross: number;
  retentionNet: number;
  retentionGross: number;
  advancePaymentNet: number;
  advancePaymentGross: number;
  repaymentNet: number;
  repaymentGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  cashDiscountNet: number;
  cashDiscountGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
  isPxInvoice: boolean;
  comment?: string | null;
  hasWorkflow: boolean;
  startWorkflowStatus: StartWorkflowStatusReadModel;
};
export type GetInvoicesQueryResponse = {
  invoices?: ShortInvoiceReadModel[] | null;
};
export type CreateInvoiceTitleResponse = {
  invoiceTitleId: string;
};
export type InvoiceTitlePayload = {
  code: string;
  checked: boolean;
  vat: number;
  invoiceValue: number;
};
export type UpdateKlippaConfigurationRequest = {
  name: string;
  checkFlowkeyPath: string;
  postDocumentPath: string;
  promptModelSlug: string;
  isActive: boolean;
};
export type KlippaConfigurationEntity = {
  id: string;
  name: string;
  checkFlowkeyPath: string;
  postDocumentPath: string;
  promptModelSlug: string;
  isActive: boolean;
};
export type KlippaConfigurationModel = {
  entity: KlippaConfigurationEntity;
};
export type GetKlippaApiConfigurationsResponse = {
  configuration: KlippaConfigurationModel[];
};
export type AdminCreateLicenseResponse = {
  licenseId: string;
};
export type CreateLicensePayload = {
  name: string;
  description: string;
  durationInMonths: number;
  pricePerMonth: number;
  features: string[];
};
export type AdminCreateLicenseGrantResponse = {
  licenseGrantId: string;
};
export type CreateLicenseGrantPayload = {
  tenantId: string;
  licenseId: string;
  seats?: number | null;
  seatPricePerMonth: number;
  activeTo: string;
  assignees: string[];
};
export type LocalLawElementPayload = {
  catalogElementId?: string | null;
  description: string;
  consumptionPayloads: ConsumptionPayload[];
};
export type PlotType = 'Plot' | 'Infrastructure' | 'SpecialItem';
export type PlotUsageType =
  | 'Undefined'
  | 'Production'
  | 'Traffic'
  | 'Technical'
  | 'Residential'
  | 'Office'
  | 'Retail'
  | 'Storage'
  | 'Hotel'
  | 'Mixed';
export type PlotPayload = {
  name: string;
  size: number;
  type: PlotType;
  constructionSize?: number | null;
  parentId?: string | null;
  description?: string | null;
  grossVolume?: number | null;
  cost?: number | null;
  street?: string | null;
  parcelNumber?: string | null;
  usageType: PlotUsageType;
};
export type PlotItemFileTypeEnum =
  | 'Thumbnail'
  | 'Image'
  | 'Sketch'
  | 'FloorPlan'
  | 'SitePlan'
  | 'Contract'
  | 'Defect'
  | 'Document';
export type PlotItemFileReadModel = {
  id: string;
  name: string;
  size: number;
  fileType: PlotItemFileTypeEnum;
  created: string;
};
export type PlotReadModel = {
  calculationModelId: string;
  calculationModelName: string;
  calculationModelGfz: number;
  calculationModelGrz: number;
  maxBgf: QuantityReadModel;
  plotId: string;
  plotName: string;
  plotAddress: string;
  totalPlotSize: QuantityReadModel;
  constructionSize: QuantityReadModel;
  type: PlotType;
  plots: PlotReadModel[];
  isProjectPlot: boolean;
  description?: string | null;
  grossVolume: QuantityReadModel;
  cost: QuantityReadModel;
  street?: string | null;
  parcelNumber?: string | null;
  usageType: PlotUsageType;
  files?: PlotItemFileReadModel[] | null;
};
export type EuriborPayload = {
  oneMonthEuribor: number;
  threeMonthsEuribor: number;
  sixMonthsEuribor: number;
  twelveMonthsEuribor: number;
  userConfirmed: boolean;
};
export type CreateEuriborPayload = {
  date: string;
  euriborPayload: EuriborPayload;
};
export type CreateProjectResponse = {
  projectId: string;
};
export type UnitSystem = 'Metric' | 'Imperial' | 'Universal';
export type ElementType = 'None' | 'CostElement' | 'RiskElement' | 'Earnings' | 'Taxonomy';
export type ElementReadModel = {
  id: string;
  name: string;
  elementType: ElementType;
};
export type VatReadModel = {
  id: string;
  vat: number;
  elementType: ElementType;
};
export type TagReadModel = {
  id: string;
  name: string;
};
export type ProjectSettingReadModel = {
  statuses?: ElementReadModel[] | null;
  constructionPhases?: ElementReadModel[] | null;
  vats?: VatReadModel[] | null;
  tags?: TagReadModel[] | null;
};
export type ShortProjectReadModel = {
  groupId?: string | null;
  tenantId: string;
  tenantName: string;
  coverPictureId?: string | null;
  id: string;
  projectId: string;
  dwhProjectId?: string | null;
  name: string;
  street?: string | null;
  number?: string | null;
  postalCode?: string | null;
  city?: string | null;
  countryCode?: string | null;
  externalCode?: string | null;
  internalCode?: string | null;
  vat?: number | null;
  bgf?: number | null;
  plotSize?: number | null;
  unitSystem: UnitSystem;
  startWorkflowAutomatically: boolean;
  projectSetting?: ProjectSettingReadModel | null;
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
};
export type ShortProjectGroupReadModel = {
  id: string;
  name: string;
  description?: string | null;
  externalId?: string | null;
  coverPictureId?: string | null;
  totalBgf?: number | null;
  totalPlotSize?: number | null;
  cities: string[];
  projects: ShortProjectReadModel[];
};
export type TrustedProjectReadModel = {
  id: string;
  projectId: string;
  name: string;
  tenantId: string;
  tenantName: string;
  hasAvatarImage: boolean;
  coverPictureId?: string | null;
  city?: string | null;
};
export type GetProjectsQueryResponse = {
  projects: ShortProjectReadModel[];
  projectGroups: ShortProjectGroupReadModel[];
  trustedProjects: TrustedProjectReadModel[];
};
export type CreateInvoiceCalculationSchemeResponse = {
  calculationSchemeId: string;
};
export type InvoiceCalculationSchemeType = 'Cumulated' | 'Single' | 'AdvancePayment';
export type InvoiceCalculationSchemePayload = {
  isDefault: boolean;
  name: string;
  description?: string | null;
  type: InvoiceCalculationSchemeType;
};
export type CalculationRuleShortReadModel = {
  calculationRuleId: string;
  orderNumber: number;
  name: string;
  type: CalculationRuleType;
  vat?: number | null;
  percentage?: number | null;
  value?: number | null;
  referenceType: ReferenceType;
  calculationRuleReferenceId?: string | null;
  calculationRuleGroupReferenceId?: string | null;
  partialInvoiceReferenceType: ReferenceType;
  partialInvoiceCalculationRuleReferenceId?: string | null;
  partialInvoiceCalculationRuleGroupReferenceId?: string | null;
  partialFinalInvoiceReferenceType: ReferenceType;
  partialFinalInvoiceCalculationRuleReferenceId?: string | null;
  partialFinalInvoiceCalculationRuleGroupReferenceId?: string | null;
  finalInvoiceReferenceType: ReferenceType;
  finalInvoiceCalculationRuleReferenceId?: string | null;
  finalInvoiceCalculationRuleGroupReferenceId?: string | null;
};
export type CalculationRuleGroupShortReadModel = {
  calculationRuleGroupId: string;
  orderNumber: number;
  name: string;
  type: CalculationRuleGroupType;
  calculationRules: CalculationRuleShortReadModel[];
};
export type CalculationRuleReadModel = {
  calculationRuleId: string;
  orderNumber: number;
  name: string;
  type: CalculationRuleType;
  vat?: number | null;
  percentage?: number | null;
  value?: number | null;
  referenceType: ReferenceType;
  calculationRuleReference?: CalculationRuleShortReadModel | null;
  calculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
  partialInvoiceReferenceType: ReferenceType;
  partialInvoiceCalculationRuleReference?: CalculationRuleShortReadModel | null;
  partialInvoiceCalculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
  partialFinalInvoiceReferenceType: ReferenceType;
  partialFinalInvoiceCalculationRuleReference?: CalculationRuleShortReadModel | null;
  partialFinalInvoiceCalculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
  finalInvoiceReferenceType: ReferenceType;
  finalInvoiceCalculationRuleReference?: CalculationRuleShortReadModel | null;
  finalInvoiceCalculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
};
export type CalculationRuleGroupReadModel = {
  calculationRuleGroupId: string;
  orderNumber: number;
  name: string;
  type: CalculationRuleGroupType;
  calculationRules: CalculationRuleReadModel[];
};
export type InvoiceCalculationSchemeReadModel = {
  invoiceCalculationSchemeId: string;
  isDefault: boolean;
  name: string;
  description?: string | null;
  type: InvoiceCalculationSchemeType;
  calculationRuleGroups: CalculationRuleGroupReadModel[];
};
export type ElementPayload = {
  name: string;
  elementType: ElementType;
};
export type CreateProjectSettingTagCommandResponse = {
  tagId: string;
};
export type VatPayload = {
  vat: number;
  elementType: ElementType;
};
export type DealPayload = {
  name: string;
  code: string;
  status: DealStatus;
  start?: string | null;
  end?: string | null;
};
export type DealCostItemReadModel = {
  id: string;
  taxonomyItemId: string;
  partCommonSpace: QuantityReadModel;
  partRentalSpace: QuantityReadModel;
  partParkingAmount: number;
  taxonomyItem: TaxonomyReadModel;
  conflictingSpaceUsage: DealCostItemReadModel[];
  conflictingReserved: DealCostItemReadModel[];
  conflictingRented: DealCostItemReadModel[];
  conflictingSold: DealCostItemReadModel[];
};
export type DealDocumentReadModel = {
  id: string;
  name: string;
  size: number;
  created: string;
};
export type DealReadModel = {
  id: string;
  projectId: string;
  name: string;
  code: string;
  type: DealType;
  status: DealStatus;
  start?: string | null;
  end?: string | null;
  updatedAt: string;
  contacts: DealContactReadModel[];
  costItems: DealCostItemReadModel[];
  contractItems: DealContractItemReadModel[];
  dealCondition: DealRentConditionReadModel;
  documents: DealDocumentReadModel[];
};
export type GetDealsResult = {
  deals: DealReadModel[];
};
export type ModifyRiskElementPayload = {
  riskCatalogElementId?: string | null;
  description?: string | null;
  probability: number;
  amount: FactorPayload;
  unitPrice: FactorPayload;
  elementTimeline?: ElementTimelinePayload | null;
  isGroup: boolean;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type CreateTenantResponse = {
  tenantId: string;
};
export type CreateTenantPayload = {
  name: string;
  subdomain: string;
  initializeAdditionalCatalogs?: string | null;
};
export type UserDefinedFieldDefinitionListItemPayload = {
  listItemId?: string | null;
  label: string;
};
export type UserDefinedFieldDefinitionPayload = {
  name: string;
  description?: string | null;
  fieldType: UserDefinedFieldType;
  elementType: UserDefinedFieldElementType;
  visibilityType: UserDefinedFieldVisibilityType;
  listItems?: UserDefinedFieldDefinitionListItemPayload[] | null;
  calculateElementType?: UserDefinedFieldCalculateElementType | null;
  isRequired: boolean;
  defaultText?: string | null;
  defaultNumber?: number | null;
  defaultListItem?: UserDefinedFieldDefinitionListItemPayload | null;
  assignedProjectIds?: string[] | null;
};
export type UserDefinedFieldDefinitionReadModel = {
  id: string;
  name: string;
  description?: string | null;
  fieldType: UserDefinedFieldType;
  elementType: UserDefinedFieldElementType;
  visibilityType: UserDefinedFieldVisibilityType;
  isVisible: boolean;
  isMarkedVisible: boolean;
  isUsed: boolean;
  listItems?: UserDefinedFieldDefinitionListItemReadModel[] | null;
  calculateElementType?: UserDefinedFieldCalculateElementType | null;
  isRequired: boolean;
  defaultText?: string | null;
  defaultNumber?: number | null;
  defaultListItem?: UserDefinedFieldDefinitionListItemReadModel | null;
  assignedProjects?: UserDefinedFieldAssignedProjectsReadModel[] | null;
};
export type GetUserDefinedFieldsDefinitionQueryResponse = {
  userDefinedFieldsDefinition: UserDefinedFieldDefinitionReadModel[];
};
export type CreateUserPayload = {
  tenantId: string;
  isOwner: boolean;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};
export type CreateVersionCommandResponse = {
  calculationModelId: string;
};
export type CreateVersionPayload = {
  name: string;
  description?: string | null;
};
export type CalcModelImport = {
  calcModelId: string;
  calculationModelName: string;
  imported: string;
};
export type ImportReadModel = {
  id: string;
  projectState: string;
  staged: string;
  includesInvoices: boolean;
  executedImports: CalcModelImport[];
};
export type ImportProbisDataPayload = {
  importId: string;
  importInvoiceData: boolean;
};
export type DeactivateLicenseGrantPayload = {
  tenantId: string;
  licenseGrantId: string;
};
export type AiProcessingState = 'Processing' | 'Failed' | 'Succeeded' | 'Unprocessed' | 'Canceled';
export type UploadContractReadModel = {
  id: string;
  logicalId: string;
  code: string;
  name: string;
};
export type AiEvalDocumentReadModel = {
  id: string;
  filename: string;
  size: number;
  state: AiProcessingState;
  jobId?: string | null;
  uploadedByContract?: UploadContractReadModel | null;
  created: string;
  updated: string;
  processed?: string | null;
};
export type AiEvalAddress = {
  street: string;
  number: string;
  postalCode: string;
  city: string;
  country: string;
};
export type AiEvalBankDetails = {
  iban: string;
  bic: string;
};
export type AiEvalInvoiceDetails = {
  number: string;
  projectNumber: string;
  purchaseOrder: string;
  deliveryPhase: string;
  invoiceDate?: string | null;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  amount: number;
  vat: number;
  gross: number;
  net: number;
  type: InvoiceType;
};
export type AiEvalValuesReadModel = {
  contractorName: string;
  contractorPhone: string;
  contractorFax: string;
  contractorEmail: string;
  contractorTaxNumber: string;
  contractorAddress: AiEvalAddress;
  contractorBankDetails: AiEvalBankDetails;
  contractCode: string;
  customerId?: string | null;
  contractId?: string | null;
  customerName: string;
  customerNumber: string;
  customerAddress: AiEvalAddress;
  invoiceDetails: AiEvalInvoiceDetails;
};
export type AiEvalResultReadModel = {
  document: AiEvalDocumentReadModel;
  result?: AiEvalValuesReadModel | null;
  rawResultJson: any;
};
export type UpdateGroupPayload = {
  name: string;
  comment?: string | null;
  addedUsers: string[];
  deletedUsers: string[];
  addedRoleAssignments: RoleAssignmentPayload[];
  deletedRoleAssignments: string[];
};
export type UpdateCatalogElementPermissionPayload = {
  catalogElementPermissionId: string;
  canReadPermission: boolean;
  canWritePermission: boolean;
};
export type UpdateRoleCatalogTemplatePayload = {
  catalogTemplateId: string;
  added: CreateCatalogElementPermissionPayload[];
  updated: UpdateCatalogElementPermissionPayload[];
  deleted: string[];
};
export type UpdateRolePayload = {
  name: string;
  comment?: string | null;
  scope: PermissionScope;
  addedPermissions: ProbisPermission[];
  deletedPermissions: ProbisPermission[];
  addedTemplates: CreateRoleCatalogTemplatePayload[];
  updatedTemplates: UpdateRoleCatalogTemplatePayload[];
  deletedTemplates: string[];
};
export type MonthlyDistributionElement = {
  date: string;
  value: number;
};
export type TimelineErrors =
  | 'DistributedMore'
  | 'DistributedLess'
  | 'RestElementExists'
  | 'ChildHasError'
  | 'MonthlyDistributionDiffers'
  | 'MissingTimeline';
export type ElementDeliveryPeriod = {
  startDate: string;
  startReferenceDisplayName?: string | null;
  startReferenceCode?: string | null;
  startOffset?: number | null;
  startOffsetUnit?: TimelineDurationUnit | null;
  endDate: string;
  endReferenceDisplayName?: string | null;
  endReferenceCode?: string | null;
  endOffset?: number | null;
  endOffsetUnit?: TimelineDurationUnit | null;
  duration?: number | null;
  durationUnit?: TimelineDurationUnit | null;
  totalAmount: number;
  hasRestDistribution: boolean;
  preventErrors: boolean;
  justCalculated: boolean;
  monthlyDistribution: MonthlyDistributionElement[];
  children: ElementDeliveryPeriod[];
  errors: TimelineErrors[];
};
export type DatedPaymentDto = {
  date: string;
  payment: number;
  invoiceReference: string;
  isDwhData: boolean;
};
export type DatedPaymentGroupDto = {
  date: string;
  paymentSum: number;
  payments: DatedPaymentDto[];
  isDwhData: boolean;
};
export type FinanceTimeLineElement = {
  date: string;
  plan?: MonthlyDistributionElement | null;
  datedPayments?: DatedPaymentGroupDto | null;
};
export type FinanceTimeline = {
  start: string;
  end: string;
  plannedToDate: number;
  paymentToDate: number;
  totalPlanned: number;
  totalPayment: number;
  elements: FinanceTimeLineElement[];
};
export type CostElementBudgetAssignmentReadModel = {
  id: string;
  commitmentId?: string | null;
  contractId?: string | null;
  contractTitleId?: string | null;
  invoiceId?: string | null;
  budgetValue: number;
  invoiceValue: number;
  paymentValue: number;
  forecastValue: number;
};
export type ContractBudgetErrors = 'BudgetWasReduced' | 'ChildHasError';
export type CostElementDto = {
  costCatalogElementId?: string | null;
  elementId: string;
  logicalId: string;
  costCatalogCode?: string | null;
  costCatalogDescription?: string | null;
  title?: string | null;
  description?: string | null;
  amount?: number | null;
  unitPrice?: number | null;
  totalValue?: number | null;
  unit?: string | null;
  timelineElement?: ElementDeliveryPeriod | null;
  financeTimeline?: FinanceTimeline | null;
  totalContract?: number | null;
  totalSupplement?: number | null;
  totalForecast?: number | null;
  totalAdjustment?: number | null;
  totalInvoice?: number | null;
  totalPayment?: number | null;
  obligo?: number | null;
  restBudget?: number | null;
  availableBudget?: number | null;
  readOnly: boolean;
  budgetAssignments: CostElementBudgetAssignmentReadModel[];
  contractBudgetErrors: ContractBudgetErrors[];
  hasFormula: boolean;
  isLocalLawElement: boolean;
  vat?: number | null;
  timelineErrors: TimelineErrors[];
};
export type CommitmentDto = {
  id: string;
  logicalId: string;
  costElementId: string;
  code: string;
  name: string;
  budget: number;
  contract: number;
  supplement: number;
  adjustment: number;
  forecast: number;
  invoice: number;
  payment: number;
  obligo: number;
  datedPayments: DatedPaymentGroupDto[];
};
export type CalculationCommitmentDto = {
  modelCommitment?: CommitmentDto | null;
  referenceCommitment?: CommitmentDto | null;
};
export type ContractDto = {
  id: string;
  logicalId: string;
  costElementId: string;
  code: string;
  name: string;
  budget: number;
  contract: number;
  supplement: number;
  forecast: number;
  adjustment: number;
  invoice: number;
  payment: number;
  obligo: number;
  datedPayments: DatedPaymentGroupDto[];
};
export type CalculationContractDto = {
  modelContract?: ContractDto | null;
  referenceContract?: ContractDto | null;
};
export type InvoicingPartyDto = {
  id: string;
  costElementId: string;
  code: string;
  name: string;
  budget: number;
  forecast: number;
  invoice: number;
  payment: number;
  obligo: number;
  datedPayments: DatedPaymentGroupDto[];
};
export type CalculationInvoicingPartyDto = {
  modelInvoicingParty?: InvoicingPartyDto | null;
  referenceInvoicingParty?: InvoicingPartyDto | null;
};
export type CalculationCostElement = {
  modelCostElement?: CostElementDto | null;
  referenceCostElement?: CostElementDto | null;
  commitments: CalculationCommitmentDto[];
  contracts: CalculationContractDto[];
  invoicingParties: CalculationInvoicingPartyDto[];
};
export type CalculationState = 'Empty' | 'JustTotal' | 'JustCalculated' | 'Equality' | 'Remainder' | 'Overflow';
export type CostElementGroupValues = {
  total?: number | null;
  calculatedValue?: number | null;
  effectiveValue?: number | null;
  amount?: number | null;
  unitPrice?: number | null;
  unit?: string | null;
  totalContract?: number | null;
  totalSupplement?: number | null;
  totalForecast?: number | null;
  calculatedForecast?: number | null;
  effectiveForecast?: number | null;
  totalAdjustment?: number | null;
  totalInvoice?: number | null;
  totalDatedPayments?: number | null;
  obligo?: number | null;
  restBudget?: number | null;
  availableBudget?: number | null;
  vat?: number | null;
  difference?: number | null;
  overflow?: number | null;
  remainder?: number | null;
  calculationState: CalculationState;
};
export type CostCatalogElementDto = {
  id: string;
  costElementId?: string | null;
  orderNumber: number;
  code?: string | null;
  description?: string | null;
  hasParent: boolean;
  timelineElement?: ElementDeliveryPeriod | null;
  financeTimeline?: FinanceTimeline | null;
  children: CostCatalogElementDto[];
  costElements: CalculationCostElement[];
  commitments: CalculationCommitmentDto[];
  contracts: CalculationContractDto[];
  invoicingParties: CalculationInvoicingPartyDto[];
  datedPayments: DatedPaymentGroupDto[];
  budgetAssignments: CostElementBudgetAssignmentReadModel[];
  modelValues: CostElementGroupValues;
  refValues?: CostElementGroupValues | null;
  hasFormula: boolean;
  isUnassignedContainer: boolean;
  timelineErrors: TimelineErrors[];
  contractBudgetErrors: ContractBudgetErrors[];
};
export type CalculationModelDeliveryPhaseReadModel = {
  id: string;
  order: number;
  code: string;
  name: string;
  timeLine: ElementTimelineReadModel;
};
export type ElementReferenceType = 'CostElement' | 'SaleElement' | 'FinanceElementPayout' | 'FinanceElementPayback';
export type CalculationModelElementTimelineReadModel = {
  id: string;
  type: ElementReferenceType;
  description: string;
  effectiveStart: string;
  effectiveEnd: string;
};
export type CalculationModelPayload = {
  id: string;
  projectId: string;
  costCatalogId: string;
  riskCatalogId?: string | null;
  earningsCatalogId?: string | null;
  financingCatalogId?: string | null;
  name: string;
  startDate: string;
  endDate?: string | null;
  statusDate?: string | null;
  flatCatalogElements: CostCatalogElementDto[];
  calculationModelDeliveryPhases: CalculationModelDeliveryPhaseReadModel[];
  plots: PlotReadModel[];
  totalCalculationModelCosts?: number | null;
  totalCalculationModelEarnings?: number | null;
  totalCalculationModelRisks?: number | null;
  totalCalculationModelCostForecasts?: number | null;
  totalCalculationModelRiskForecasts?: number | null;
  totalCalculationModelEarningForecasts?: number | null;
  timeframeElements: CalculationModelElementTimelineReadModel[];
  taxonomyItems: TaxonomyReadModel[];
  milestones: CalculationModelMilestoneReadModel[];
};
export type CalculationModelType =
  | 'Version'
  | 'ArchivedVariant'
  | 'UserSnapshot'
  | 'SystemSnapshot'
  | 'Variant'
  | 'ArchivedVersion';
export type CurrencyValueReadModel = {
  value?: number | null;
  unit: string;
};
export type ChangeReadModel = {
  changedAt: string;
  changedBy: string;
};
export type ReciprocalCurrencyValueReadModel = {
  value?: number | null;
  unit: string;
  reciprocalUnit: string;
};
export type TaxonomyPercentagesReadModel = {
  type: TaxonomyUsageType;
  percentage: QuantityReadModel;
};
export type TaxonomyOverviewReadModel = {
  usagePercentages?: TaxonomyPercentagesReadModel[] | null;
};
export type CashFlowReferenceDate = 'ReceivedDate' | 'InvoiceDate' | 'ApprovalDate' | 'PaymentDate';
export type CashFlowReferenceValue = 'Invoice' | 'Payment' | 'AbsolutePayment';
export type CashFlowSettingsReadModel = {
  statusMonth: string;
  referenceDate: CashFlowReferenceDate;
  referenceValue: CashFlowReferenceValue;
  overridePlan: boolean;
};
export type CalculationModelMetadata = {
  id: string;
  name: string;
  description?: string | null;
  type?: CalculationModelType | null;
  costCatalogId: string;
  earningsCatalogId?: string | null;
  riskCatalogId?: string | null;
  financingCatalogId?: string | null;
  totalCost: CurrencyValueReadModel;
  totalEarnings: CurrencyValueReadModel;
  totalRisks: CurrencyValueReadModel;
  totalCostAndRisk: CurrencyValueReadModel;
  totalFinancing: CurrencyValueReadModel;
  totalGrants: CurrencyValueReadModel;
  totalProfit: CurrencyValueReadModel;
  totalFinancingCost: CurrencyValueReadModel;
  totalCostForecasts: CurrencyValueReadModel;
  totalRiskForecasts: CurrencyValueReadModel;
  totalEarningForecasts: CurrencyValueReadModel;
  deviation: number;
  deviationPercent: QuantityReadModel;
  grossFloorValue: QuantityReadModel;
  netFloorValue: QuantityReadModel;
  rentalSpaceValue: QuantityReadModel;
  grossFloorEfficiency: QuantityReadModel;
  netFloorEfficiency: QuantityReadModel;
  rentalSpaceEfficiency: QuantityReadModel;
  grossFloorRemainder: QuantityReadModel;
  netFloorRemainder: QuantityReadModel;
  rentalSpaceRemainder: QuantityReadModel;
  plot: QuantityReadModel;
  snapshotComment?: string | null;
  lastEdit: ChangeReadModel;
  created: ChangeReadModel;
  snapshotCreatedBy: string;
  parentId?: string | null;
  readOnly: boolean;
  calculationModelStartDate: string;
  calculationModelEndDate: string;
  deliveryPhasesCount: number;
  gfz: number;
  grz: number;
  totalRentedArea: QuantityReadModel;
  totalMonthlyRentalRevenue: CurrencyValueReadModel;
  multiplier?: number | null;
  averageRentUnitPrice: ReciprocalCurrencyValueReadModel;
  averageRentPerPiece?: number | null;
  averageSaleUnitPrice: ReciprocalCurrencyValueReadModel;
  averageSalePerPiece?: number | null;
  projectId: string;
  coverPictureId?: string | null;
  version?: string | null;
  favorite: boolean;
  roi: QuantityReadModel;
  calculatedIrr: QuantityReadModel;
  plannedIrr: QuantityReadModel;
  ownCapitalRatio: QuantityReadModel;
  roe: QuantityReadModel;
  taxonomyOverview: TaxonomyOverviewReadModel;
  cashFlowSettings?: CashFlowSettingsReadModel | null;
};
export type CalculationModelReadModel = {
  payload: CalculationModelPayload;
  modelMetadata: CalculationModelMetadata;
  referenceMetadata?: CalculationModelMetadata | null;
};
export type GetCalculationModelQueryResponse = {
  calculationModel: CalculationModelReadModel;
};
export type UpdateCatalogTemplatePayload = {
  name: string;
  comment?: string | null;
  riskCatalogId?: string | null;
  earningsCatalogId?: string | null;
  financingCatalogId?: string | null;
};
export type RequirementState = 'Created' | 'Submitted' | 'Rejected' | 'Released';
export type RequirementType = 'Requirement' | 'Concept' | 'GuidingDocument' | 'Project';
export type ClusterItemShortReadModel = {
  id: string;
  name: string;
};
export type RequirementCategory =
  | 'AccidentPrevention'
  | 'Capacity'
  | 'Internal'
  | 'CapacityExpansion'
  | 'DefectCorrectionInternal'
  | 'Regulatory'
  | 'Renovation'
  | 'CapacityExpansionOther'
  | 'SalesImpactFees'
  | 'SalesImpactExternal';
export type RequirementCustomFieldType = 'Text' | 'Number' | 'Date' | 'Select' | 'MultiSelect';
export type RequirementCustomFieldReadModel = {
  id: string;
  name: string;
  type: RequirementCustomFieldType;
  category: string;
  description?: string | null;
  text?: string | null;
  number?: number | null;
  date?: string | null;
  options?: string[] | null;
};
export type RequirementDocumentReadModel = {
  id: string;
  fileName: string;
  fileSize: number;
  created: string;
};
export type RequirementReadModel = {
  id: string;
  name: string;
  code: string;
  state: RequirementState;
  type: RequirementType;
  clusterItem: ClusterItemShortReadModel;
  createdById: string;
  createdByName: string;
  deadline?: string | null;
  comment?: string | null;
  category?: RequirementCategory | null;
  description?: string | null;
  reportedById: string;
  reportedByFullName?: string | null;
  reportedByDeputyId?: string | null;
  reportedByDeputyName?: string | null;
  customFields: RequirementCustomFieldReadModel[];
  documents: RequirementDocumentReadModel[];
};
export type GetClusterItemRequirementQueryResponse = {
  requirement?: RequirementReadModel | null;
};
export type UpdateRequirementCustomFieldPayload = {
  name: string;
  type: RequirementCustomFieldType;
  category: string;
  description?: string | null;
  text?: string | null;
  number?: number | null;
  date?: string | null;
  options?: string[] | null;
  required?: boolean | null;
};
export type UpdateClusterItemRequirementPayload = {
  name: string;
  clusterItemId: string;
  category?: RequirementCategory | null;
  description?: string | null;
  reportedByDeputyId?: string | null;
  customFields?: UpdateRequirementCustomFieldPayload[] | null;
};
export type CompanyAddressReadModel = {
  supplement?: string | null;
  street?: string | null;
  number?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
};
export type CompanyType = 'Company' | 'PrivatePerson';
export type CompanyBankAccountReadModel = {
  id: string;
  bankName?: string | null;
  swift?: string | null;
  accountOwner?: string | null;
  iban: string;
};
export type ProjectAssignmentInfo = {
  id: string;
  projectId: string;
  name: string;
  city?: string | null;
  projectCoverId?: string | null;
};
export type TenantTrustInfo = {
  trustId: string;
  personName: string;
  personEmail: string;
  trustIsActive: boolean;
  trustState: TrustState;
};
export type CompanyAssignmentReadModel = {
  project: ProjectAssignmentInfo;
  usedInProject: boolean;
  tenantTrust?: TenantTrustInfo | null;
};
export type PersonBranchAssignmentReadModel = {
  id: string;
  branchName: string;
  branchId: string;
  personId: string;
  personName: string;
  personEmail?: string | null;
};
export type CompanyBranchReadModel = {
  id: string;
  companyId: string;
  logoImageId?: string | null;
  isMainBranch: boolean;
  code: string;
  name: string;
  companyName: string;
  address?: CompanyAddressReadModel | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  link?: string | null;
  creditorId?: string | null;
  debitorId?: string | null;
  spvId?: string | null;
  companyType: CompanyType;
  bankAccounts: CompanyBankAccountReadModel[];
  projectAssignments: CompanyAssignmentReadModel[];
  persons: PersonBranchAssignmentReadModel[];
};
export type InvoiceTitleReadModel = {
  id: string;
  invoiceId: string;
  contractTitleId?: string | null;
  code: string;
  contractTitleName?: string | null;
  checked: boolean;
  vat: number;
  valueNet: number;
  valueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  discountValueNet: number;
  discountValueGross: number;
  retentionNet: number;
  retentionGross: number;
  advancePaymentNet: number;
  advancePaymentGross: number;
  repaymentNet: number;
  repaymentGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  cashDiscountNet: number;
  cashDiscountGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
};
export type BudgetAssignmentReadModel = {
  id: string;
  costElementId: string;
  code: string;
  description: string;
  costElementTotalValue: number;
  costElementVat: number;
  budgetNet: number;
  budgetGross: number;
};
export type ContractTitleReadModel = {
  id: string;
  contractId: string;
  code: string;
  name: string;
  description?: string | null;
  type: ContractTitleType;
  state: ContractTitleState;
  vat: number;
  discount: number;
  budgetValueNet: number;
  budgetValueGross: number;
  offerNet: number;
  offerGross: number;
  approvedOfferNet: number;
  approvedOfferGross: number;
  contractValueNet: number;
  contractValueGross: number;
  adjustmentValueNet: number;
  adjustmentValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  canBeBudgeted: boolean;
  invoiceTitles: InvoiceTitleReadModel[];
  budgetAssignments: BudgetAssignmentReadModel[];
};
export type InvoiceCalculationRuleReadModel = {
  calculationRuleId: string;
  orderNumber: number;
  name: string;
  type: CalculationRuleType;
  vat?: number | null;
  percentage?: number | null;
  value?: number | null;
  referenceValue?: number | null;
  calculationResultNet: number;
  calculationResultGross: number;
  referenceType: ReferenceType;
  calculationRuleReference?: CalculationRuleShortReadModel | null;
  calculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
  partialInvoiceReferenceType: ReferenceType;
  partialInvoiceCalculationRuleReference?: CalculationRuleShortReadModel | null;
  partialInvoiceCalculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
  partialFinalInvoiceReferenceType: ReferenceType;
  partialFinalInvoiceCalculationRuleReference?: CalculationRuleShortReadModel | null;
  partialFinalInvoiceCalculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
  finalInvoiceReferenceType: ReferenceType;
  finalInvoiceCalculationRuleReference?: CalculationRuleShortReadModel | null;
  finalInvoiceCalculationRuleGroupReference?: CalculationRuleGroupShortReadModel | null;
};
export type InvoiceCalculationRuleGroupReadModel = {
  calculationRuleGroupId: string;
  orderNumber: number;
  name: string;
  type: CalculationRuleGroupType;
  calculationResultNet: number;
  calculationResultGross: number;
  calculationRules: InvoiceCalculationRuleReadModel[];
};
export type InvoiceDocumentReadModel = {
  id: string;
  fileName: string;
  fileSize: number;
  isInvoiceSource: boolean;
  created: string;
};
export type WorkflowErrorReadModel = {
  entityId: string;
  activityName?: string | null;
  message?: string | null;
};
export type InvoiceReadModel = {
  id: string;
  contractId?: string | null;
  contractName?: string | null;
  contractCode?: string | null;
  calculationSchemeId?: string | null;
  calculationSchemeName?: string | null;
  invoicingParty?: CompanyBranchReadModel | null;
  invoiceRecipient?: CompanyBranchReadModel | null;
  sequentialNumber: number;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  state: InvoiceState;
  distributionType: InvoiceValueDistributionType;
  vat?: number | null;
  claim: number;
  dateOfReceipt: string;
  invoiceDate: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  dateOfAudit?: string | null;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  valueNet: number;
  valueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  discountValueNet: number;
  discountValueGross: number;
  retentionNet: number;
  retentionGross: number;
  advancePaymentNet: number;
  advancePaymentGross: number;
  repaymentNet: number;
  repaymentGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  cashDiscountNet: number;
  cashDiscountGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
  canBeDistributedByContractTitleForecast: boolean;
  canBeDistributedByPreviousInvoiceTitleValue: boolean;
  canBeBudgeted: boolean;
  isPxInvoice: boolean;
  invoiceTitles: InvoiceTitleReadModel[];
  invoiceCalculationRuleGroups: InvoiceCalculationRuleGroupReadModel[];
  budgetAssignment?: BudgetAssignmentReadModel | null;
  documents: InvoiceDocumentReadModel[];
  hasWorkflow: boolean;
  comment?: string | null;
  errors?: WorkflowErrorReadModel[] | null;
  startWorkflowStatus: StartWorkflowStatusReadModel;
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
};
export type ContractDocumentReadModel = {
  id: string;
  name: string;
  size: number;
  created: string;
};
export type InvoiceCalculationSchemeShortReadModel = {
  invoiceCalculationSchemeId: string;
  isDefault: boolean;
  name: string;
  description?: string | null;
  type: InvoiceCalculationSchemeType;
  calculationRuleGroups: CalculationRuleGroupShortReadModel[];
};
export type CalculationRuleValueOverrideReadModel = {
  calculationRule: CalculationRuleShortReadModel;
  factor?: number | null;
  value?: number | null;
  vat?: number | null;
};
export type ContractInvoiceCalculationSchemeReadModel = {
  invoiceCalculationScheme: InvoiceCalculationSchemeShortReadModel;
  valueOverrides: CalculationRuleValueOverrideReadModel[];
};
export type ContractReadModel = {
  id: string;
  commitmentId?: string | null;
  commitmentName?: string | null;
  code: string;
  name: string;
  description?: string | null;
  contractDate?: string | null;
  state: ContractState;
  contractor?: CompanyBranchReadModel | null;
  client?: CompanyBranchReadModel | null;
  vat?: number | null;
  discount?: number | null;
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
  budgetNet: number;
  budgetGross: number;
  offerNet: number;
  offerGross: number;
  approvedOfferNet: number;
  approvedOfferGross: number;
  contractValueNet: number;
  contractValueGross: number;
  adjustmentValueNet: number;
  adjustmentValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  canBeBudgeted: boolean;
  isPxContract: boolean;
  elementTimeline?: ElementTimelineReadModel | null;
  titles: ContractTitleReadModel[];
  invoices: InvoiceReadModel[];
  documents: ContractDocumentReadModel[];
  budgetAssignments: BudgetAssignmentReadModel[];
  invoiceCalculationSchemes: ContractInvoiceCalculationSchemeReadModel[];
  workflow?: string | null;
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
};
export type CommitmentReadModel = {
  id: string;
  code: string;
  name: string;
  description?: string | null;
  budgetNet: number;
  budgetGross: number;
  contractValueNet: number;
  contractValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  canBeBudgeted: boolean;
  contracts: ContractReadModel[];
  budgetAssignments: BudgetAssignmentReadModel[];
};
export type UpdateBudgetAssignmentPayload = {
  budgetAssignmentId: string;
  budgetNet: number;
};
export type UpdateCommitmentPayload = {
  code: string;
  name: string;
  description?: string | null;
  addedContracts: string[];
  deletedContracts: string[];
  addedBudgetAssignments: CreateBudgetAssignmentPayload[];
  updatedBudgetAssignments: UpdateBudgetAssignmentPayload[];
  deletedBudgetAssignments: string[];
};
export type UpdateCompanyBankAccountPayload = {
  id: string;
  bankName?: string | null;
  swift?: string | null;
  accountOwner?: string | null;
  iban: string;
};
export type UpdateCompanyBranchPayload = {
  isMainBranch: boolean;
  code: string;
  name: string;
  address?: CompanyAddressPayload | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  link?: string | null;
  creditorId?: string | null;
  debitorId?: string | null;
  spvId?: string | null;
  addedProjectAssignments: string[];
  deletedProjectAssignments: string[];
  addedBankAccounts: CreateCompanyBankAccountPayload[];
  updatedBankAccounts: UpdateCompanyBankAccountPayload[];
  deletedBankAccounts: string[];
};
export type CompanyPersonReadModel = {
  id: string;
  companyId: string;
  gender: PersonGender;
  title?: string | null;
  firstName: string;
  lastName: string;
  function?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
  web?: string | null;
  branches: PersonBranchAssignmentReadModel[];
  fullname: string;
};
export type CompanyReadModel = {
  id: string;
  type: CompanyType;
  name: string;
  taxId?: string | null;
  companyRegistrationNumber?: string | null;
  link?: string | null;
  logoFileId?: string | null;
  branches: CompanyBranchReadModel[];
  persons: CompanyPersonReadModel[];
};
export type ContractInvoiceCalculationSchemePayload = {
  invoiceCalculationSchemeId: string;
  valueOverrides: CalculationRuleValueOverridePayload[];
};
export type UpdateContractPayload = {
  code: string;
  name: string;
  description?: string | null;
  contractDate?: string | null;
  vat?: number | null;
  discount?: number | null;
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
  commitmentId?: string | null;
  contractorId?: string | null;
  clientId?: string | null;
  elementTimeline?: ElementTimelinePayload | null;
  cumulatedInvoiceCalculationScheme?: ContractInvoiceCalculationSchemePayload | null;
  singleInvoiceCalculationScheme?: ContractInvoiceCalculationSchemePayload | null;
  advancePaymentInvoiceCalculationScheme?: ContractInvoiceCalculationSchemePayload | null;
  addedBudgetAssignments: CreateBudgetAssignmentPayload[];
  updatedBudgetAssignments: UpdateBudgetAssignmentPayload[];
  deletedBudgetAssignments: string[];
  workflow?: string | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type UpdateContractTitlePayload = {
  code: string;
  name: string;
  description?: string | null;
  type: ContractTitleType;
  state: ContractTitleState;
  vat: number;
  discount: number;
  offer: number;
  approvedOffer: number;
  adjustmentValue: number;
  addedBudgetAssignments: CreateBudgetAssignmentPayload[];
  updatedBudgetAssignments: UpdateBudgetAssignmentPayload[];
  deletedBudgetAssignments: string[];
  userDefinedFields?: UserDefinedFieldPayload[] | null;
};
export type CostElementCatalogElementReadModel = {
  id: string;
  code: string;
  description: string;
  longDescription?: string | null;
};
export type StaticValueResponseModel = {
  value?: number | null;
  unit?: string | null;
};
export type FormulaCostCatalogElementModel = {
  id: string;
  code: string;
  description: string;
  effectiveValue: number;
};
export type FormulaRiskCatalogElementModel = {
  id: string;
  code: string;
  description: string;
  effectiveValue: number;
};
export type FormulaTaxonomyItemModel = {
  id: string;
  name: string;
  bgf?: number | null;
  ngf?: number | null;
  rentalSpace?: number | null;
  grz?: number | null;
  amount?: number | null;
};
export type FormulaPlotModel = {
  id: string;
  name: string;
  size: number;
  grz?: number | null;
  gfz?: number | null;
};
export type FormulaCalculationModelDeliveryPhaseModel = {
  id: string;
};
export type FormulaEarningsCatalogElementModel = {
  id: string;
  code: string;
  description: string;
  effectiveValue: number;
};
export type FormulaCalculationExpressionParameterModel = {
  position: number;
  costCatalogElement?: FormulaCostCatalogElementModel | null;
  riskCatalogElement?: FormulaRiskCatalogElementModel | null;
  taxonomyItem?: FormulaTaxonomyItemModel | null;
  plot?: FormulaPlotModel | null;
  calculationModelDeliveryPhase?: FormulaCalculationModelDeliveryPhaseModel | null;
  earningsCatalogElement?: FormulaEarningsCatalogElementModel | null;
};
export type FormulaStrategyValueResponseModel = {
  formulaId: string;
  expression: string;
  expressionParameters?: FormulaCalculationExpressionParameterModel[] | null;
  value: number;
};
export type FactorValueModel = {
  type: CellType;
  staticValue?: StaticValueResponseModel | null;
  formulaValue?: FormulaStrategyValueResponseModel | null;
};
export type ElementSettingReadModel = {
  hasVat: boolean;
  hasStatus: boolean;
  hasConstructionPhase: boolean;
  hasTags: boolean;
};
export type EmissionPropertyType =
  | 'Parking'
  | 'RetailStore'
  | 'Supermarket'
  | 'GroceryStore'
  | 'OtherLodging'
  | 'OtherResidential'
  | 'Office'
  | 'Hotel'
  | 'AdultEducation'
  | 'AmbulatorySurgicalCenter'
  | 'AutomobileDealership'
  | 'BankBranch'
  | 'BowlingAlley'
  | 'College'
  | 'University'
  | 'ConvenienceStoreWithoutGasStation'
  | 'Courthouse'
  | 'DataCenter'
  | 'DistributionCenter'
  | 'EnclosedMall'
  | 'FinancialOffice'
  | 'FitnessCenter'
  | 'HealthClub'
  | 'Gym'
  | 'FoodSales'
  | 'FoodService'
  | 'HospitalGeneralMedicalAndSurgical'
  | 'K12School'
  | 'Laboratory'
  | 'Library'
  | 'LifestyleCenter'
  | 'MailingCenter'
  | 'PostOffice'
  | 'Manufacturing'
  | 'IndustrialPlant'
  | 'MedicalOffice'
  | 'MovieTheater'
  | 'MultifamilyHousing'
  | 'Museum'
  | 'NonRefrigeratedWarehouse'
  | 'OtherEducation'
  | 'OtherEntertainment'
  | 'OtherPublicAssembly'
  | 'OtherMall'
  | 'OtherPublicServices'
  | 'OtherRecreation'
  | 'OtherRestaurant'
  | 'OtherBar'
  | 'OtherServices'
  | 'OtherSpecialtyHospital'
  | 'OtherTechnology'
  | 'OtherScience'
  | 'OutpatientRehabilitation'
  | 'PhysicalTherapy'
  | 'PerformingArts'
  | 'PersonalServices'
  | 'PersonalServicesHealth'
  | 'PersonalServicesBeauty'
  | 'PersonalServicesDryCleaning'
  | 'PreSchool'
  | 'Daycare'
  | 'RefrigeratedWarehouse'
  | 'RepairServices'
  | 'RepairServicesVehicle'
  | 'RepairServicesShoe'
  | 'RepairServicesLocksmith'
  | 'ResidenceHall'
  | 'Dormitory'
  | 'ResidentialCareFacility'
  | 'Restaurant'
  | 'SelfStorageFacility'
  | 'SeniorCareCommunity'
  | 'Social'
  | 'MeetingHall'
  | 'StripMall'
  | 'TransportationTerminal'
  | 'Station'
  | 'UrgentCare'
  | 'Clinic'
  | 'OtherOutpatient'
  | 'VocationalSchool'
  | 'WholesaleClub'
  | 'SuperCenter'
  | 'WorshipFacility';
export type PropertyTypeReadModel = {
  emissionFactorId: string;
  propertyType: EmissionPropertyType;
};
export type ConsumptionUsageReadModel = {
  usageId: string;
  usage: QuantityReadModel;
  year: number;
};
export type ConsumptionTaxonomyReadModel = {
  taxonomyItemId: string;
  bgf: QuantityReadModel;
  ngf: QuantityReadModel;
  rentalSpace: QuantityReadModel;
};
export type ConsumptionElementReadModel = {
  consumptionElementId: string;
  utilityType: UtilityType;
  propertyType: PropertyTypeReadModel;
  consumptionUsages: ConsumptionUsageReadModel[];
  taxonomies: ConsumptionTaxonomyReadModel[];
  consumption: QuantityReadModel;
  limit: QuantityReadModel;
};
export type LocalLawElementReadModel = {
  consumptions: ConsumptionElementReadModel[];
  totalConsumption: QuantityReadModel;
  totalConsumptionLimit: QuantityReadModel;
};
export type VatElementReadModel = {
  vatReturnPercentage: number;
  delayMonths: number;
  costGroupIds?: string[] | null;
  userDefinedFieldId?: string | null;
  userDefinedFieldLabelIds?: string[] | null;
};
export type YetAnotherCostElementReadmodel = {
  id: string;
  tenantId: string;
  calculationModelId: string;
  description?: string | null;
  totalValueCostCatalogId?: string | null;
  deliveryPhase?: ElementTimelineReadModel | null;
  calculatedTimeline?: ElementTimelineReadModel | null;
  catalogElementElement?: CostElementCatalogElementReadModel | null;
  calculatedValue: number;
  amount: FactorValueModel;
  unitPrice: FactorValueModel;
  isGroup: boolean;
  hasBudget: boolean;
  readOnly: boolean;
  financingFeeElementId?: string | null;
  financingElementId?: string | null;
  extensionElementSetting: ElementSettingReadModel;
  statusElement?: ElementReadModel | null;
  constructionPhaseElement?: ElementReadModel | null;
  vatElement?: VatReadModel | null;
  tagElements?: TagReadModel[] | null;
  documents: DocumentReadModel[];
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
  budgetAssignments: CostElementBudgetAssignmentReadModel[];
  localLawElement?: LocalLawElementReadModel | null;
  vatCostElement?: VatElementReadModel | null;
  isLocalLawElement: boolean;
  isVatElement: boolean;
};
export type GetCostElementResult = {
  readModel: YetAnotherCostElementReadmodel;
};
export type UpdateCostElementResponse = {
  elementId: string;
};
export type RecurringEarningsReadModel = {
  paymentFrequency: PaymentFrequency;
  paymentStartsAt?: string | null;
  paymentEndsAt?: string | null;
};
export type EarningsElementValuation = {
  calculationDate: string;
  valuation: number;
  indexedRent: number;
  monthlyRent: number;
  yearlyRent: number;
  totalRentalIncome: number;
};
export type EarningsElementReadModel = {
  id: string;
  earningsCatalogElementId?: string | null;
  tenantId: string;
  isGroup: boolean;
  code?: string | null;
  title?: string | null;
  description?: string | null;
  amount: FactorValueModel;
  unitPrice: FactorValueModel;
  totalValue?: number | null;
  unit?: string | null;
  earningsType: EarningsType;
  recurringEarningData?: RecurringEarningsReadModel | null;
  oneTimePaymentData?: ElementTimelineReadModel | null;
  recurringTimeline?: ElementTimelineReadModel | null;
  timeline?: ElementDeliveryPeriod | null;
  totalRentedSpace?: number | null;
  rentIndex?: number | null;
  rentIndexDate?: string | null;
  multiple?: number | null;
  calculatedMultiple?: number | null;
  isRelevantForValuation: boolean;
  calculationDate: string;
  elementValuation?: EarningsElementValuation | null;
  documents: DocumentReadModel[];
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
};
export type GetEarningsElementResult = {
  readModel: EarningsElementReadModel;
};
export type FinancingPaymentPlanElementReadModel = {
  id?: string | null;
  order: number;
  description: string;
  amount?: number | null;
  percentage?: number | null;
  paymentOn: string;
};
export type FinancingPaybackPlanElementReadModel = {
  id?: string | null;
  order: number;
  description: string;
  amount?: number | null;
  paymentOn: string;
  rest: boolean;
};
export type FinancingState = 'Unknown' | 'Underfinanced' | 'FullyFinanced' | 'Overfinanced';
export type FinancingPurposeResult = {
  totalCosts?: number | null;
  totalFinancing: number;
  costCatalogItemIds: string[];
  vatReturnPercentage: number;
  vatCostCatalogElementId?: string | null;
  vatDistributions: VatDistribution[];
  difference?: number | null;
  financingState: FinancingState;
};
export type FinancingTransactionReadModel = {
  paymentOn: string;
  type: string;
  description: string;
  amount: number;
  beginningPrincipleBalance: number;
  beginningInterestBalance: number;
  endingPrincipleBalance: number;
  endingInterestBalance: number;
  interestRate?: number | null;
  euriborRate?: number | null;
  bankMarge?: number | null;
  liquidityPremium?: number | null;
  beginningTotalBalance: number;
  endingBalance: number;
  amountTowardsPrincipe: number;
  amountTowardsInterest: number;
};
export type FinancingElementFeeReadModel = {
  id?: string | null;
  date: string;
  description: string;
  amount: number;
  costCatalogElementId?: string | null;
};
export type FinancingElementReadModel = {
  id: string;
  financingCatalogElementId?: string | null;
  calculationModelId: string;
  tenantId: string;
  description?: string | null;
  code?: string | null;
  title?: string | null;
  financingType: FinancingType;
  grantType?: GrantType | null;
  provider?: string | null;
  nominalAmount: number;
  payoutTimeline?: ElementTimelineReadModel | null;
  payoutPattern: PaymentPlanType;
  paybackTimeline?: ElementTimelineReadModel | null;
  paybackPattern: PaymentPlanType;
  residualDebt?: number | null;
  customPayoutPlan: FinancingPaymentPlanElementReadModel[];
  customPaybackPlan: FinancingPaybackPlanElementReadModel[];
  interestPayload?: InterestRatePayload | null;
  ownCapitalType?: OwnCapitalType | null;
  loanType?: LoanTypes | null;
  repaymentMethod?: RepaymentMethod | null;
  interestPeriod?: InterestPeriod | null;
  totalFinancingCosts: number;
  partOfIrr: boolean;
  calculatedIrr?: number | null;
  purposeFinancingElementIds: string[];
  financedCostCatalogElementIds: string[];
  financeAllCosts: boolean;
  financingPurposeId?: string | null;
  isLocked: boolean;
  grantAmount: number;
  initialAnnuityRepaymentPercentage?: number | null;
  grantDate?: ElementTimelineReadModel | null;
  financingPurposeResult: FinancingPurposeResult;
  transactions: FinancingTransactionReadModel[];
  fees: FinancingElementFeeReadModel[];
  documents: DocumentReadModel[];
};
export type GetFinancingElementQueryResult = {
  readModel: FinancingElementReadModel;
};
export type UpdateInvoiceByContractPayload = {
  calculationSchemeId?: string | null;
  sequentialNumber: number;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  distributionType: InvoiceValueDistributionType;
  vat?: number | null;
  claim: number;
  invoiceValue?: number | null;
  invoiceDate: string;
  dateOfReceipt: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  dateOfAudit?: string | null;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  addedDocuments: string[];
  deletedDocuments: string[];
  comment?: string | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type UpdateCalculationRulePayload = {
  calculationRuleGroupId: string;
  name: string;
  type: CalculationRuleType;
  vat?: number | null;
  percentage?: number | null;
  value?: number | null;
  referenceType?: ReferenceType | null;
  calculationRuleGroupReferenceId?: string | null;
  calculationRuleReferenceId?: string | null;
  partialInvoiceReferenceType?: ReferenceType | null;
  partialInvoiceCalculationRuleGroupReferenceId?: string | null;
  partialInvoiceCalculationRuleReferenceId?: string | null;
  partialFinalInvoiceReferenceType?: ReferenceType | null;
  partialFinalInvoiceCalculationRuleGroupReferenceId?: string | null;
  partialFinalInvoiceCalculationRuleReferenceId?: string | null;
  finalInvoiceReferenceType?: ReferenceType | null;
  finalInvoiceCalculationRuleGroupReferenceId?: string | null;
  finalInvoiceCalculationRuleReferenceId?: string | null;
};
export type UpdateCalculationRuleGroupPayload = {
  name: string;
};
export type GetInvoiceQueryResponse = {
  invoice?: InvoiceReadModel | null;
};
export type UpdateInvoicePayload = {
  calculationSchemeId?: string | null;
  sequentialNumber: number;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  distributionType: InvoiceValueDistributionType;
  vat?: number | null;
  claim: number;
  invoiceValue?: number | null;
  invoiceDate: string;
  dateOfReceipt: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  dateOfAudit?: string | null;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  addedDocuments: string[];
  deletedDocuments: string[];
  comment?: string | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
  invoicingPartyId?: string | null;
  invoiceRecipientId?: string | null;
  costElementId?: string | null;
};
export type DeleteLicensePayload = {
  licenseId: string;
};
export type LicenseFeatureReadModel = {
  id: string;
  feature: LicenseFeature;
  featureType: LicenseFeatureType;
};
export type LicenseReadModel = {
  id: string;
  name: string;
  description: string;
  durationInMonths: number;
  pricePerMonth: number;
  features: LicenseFeatureReadModel[];
};
export type DeleteLicenseGrantPayload = {
  tenantId: string;
  licenseGrantId: string;
};
export type UpdatePlotPayload = {
  name: string;
  size: number;
  type: PlotType;
  constructionSize?: number | null;
  description?: string | null;
  grossVolume?: number | null;
  cost?: number | null;
  street?: string | null;
  parcelNumber?: string | null;
  usageType: PlotUsageType;
};
export type Currency = 'Usd' | 'Eur' | 'Gbp';
export type CurrencyReadModel = {
  currency: Currency;
  unit: string;
};
export type ProjectPrimaryCatalogReadModel = {
  id: string;
  name: string;
};
export type PrimaryRiskCatalogReadModel = {
  id: string;
  name: string;
};
export type PropertyScore = {
  catering?: number | null;
  family?: number | null;
  health?: number | null;
  leisure?: number | null;
  location?: number | null;
  noise?: number | null;
  nuisance?: number | null;
  shopping?: number | null;
  view?: number | null;
};
export type ValuationPoint = {
  date: string;
  value: number;
  lower: number;
  upper: number;
};
export type PriceHubbleProjectValuation = {
  date: string;
  score: PropertyScore;
  avgRentUnitPrice: QuantityReadModel;
  avgSaleUnitPrice: QuantityReadModel;
  rentTimeline: ValuationPoint[];
  lastYearRentIncrease: QuantityReadModel;
  sellTimeline: ValuationPoint[];
  lastYearSaleIncrease: QuantityReadModel;
};
export type PoiType =
  | 'Undefined'
  | 'Airport'
  | 'Train'
  | 'Metro'
  | 'Subway'
  | 'Tram'
  | 'Bus'
  | 'EvCharging'
  | 'Shopping';
export type DoubleQuantityReadModel = {
  value: number;
  unit: string;
};
export type ProjectPoi = {
  name: string;
  latitude: number;
  longitude: number;
  type: PoiType;
  metaData: {
    [key: string]: string;
  };
  distance: DoubleQuantityReadModel;
};
export type ProjectPayload = {
  id: string;
  groupId?: string | null;
  projectId: string;
  dwhProjectId?: string | null;
  name: string;
  street?: string | null;
  number?: string | null;
  postalCode?: string | null;
  city?: string | null;
  countryCode?: string | null;
  externalCode?: string | null;
  internalCode?: string | null;
  vat?: number | null;
  currency: CurrencyReadModel;
  primaryCatalog: ProjectPrimaryCatalogReadModel;
  primaryRiskCatalog?: PrimaryRiskCatalogReadModel | null;
  tenantId: string;
  tenantName: string;
  coverPictureId?: string | null;
  projectSetting?: ProjectSettingReadModel | null;
  priceHubbleValuation?: PriceHubbleProjectValuation | null;
  latitude?: number | null;
  longitude?: number | null;
  pois: ProjectPoi[];
  unitSystem: UnitSystem;
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
  startWorkflowAutomatically: boolean;
};
export type ProjectReadModel = {
  payload: ProjectPayload;
  calculationModels: CalculationModelMetadata[];
};
export type GetProjectQueryResponse = {
  project: ProjectReadModel;
};
export type DeleteProjectInvitePayload = {
  projectId: string;
  trustId: string;
};
export type CostItemPayload = {
  partCommonSpace: number;
  partRentSpace: number;
  partParkingAmount: number;
};
export type RiskAmountTarget =
  | 'FixedValue'
  | 'CatalogCostElement'
  | 'TotalCost'
  | 'EarningsElement'
  | 'TotalEarning'
  | 'EarningsGroups';
export type RiskElementReadModel = {
  id: string;
  calculationModelId: string;
  riskCatalogElementId?: string | null;
  code?: string | null;
  title?: string | null;
  description?: string | null;
  riskTarget: RiskAmountTarget;
  probability: number;
  fixedAmount?: number | null;
  fixedUnitPrice?: number | null;
  amountPercentage?: number | null;
  referencedItemTitle?: string | null;
  referencedItemCode?: string | null;
  riskAmount: number;
  earningsElementId?: string | null;
  totalPriceCostCatalogItemId?: string | null;
  elementTimeline?: ElementTimelineReadModel | null;
  calculatedTimeline?: ElementTimelineReadModel | null;
  timeline?: ElementDeliveryPeriod | null;
  earningsType?: EarningsType | null;
  amount: FactorValueModel;
  unitPrice: FactorValueModel;
  total: number;
  isGroup: boolean;
  documents: DocumentReadModel[];
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
};
export type GetRiskElementResult = {
  readModel: RiskElementReadModel;
};
export type UpdateParkingLotPayload = {
  description?: string | null;
  number?: string | null;
  type: ParkingLotType;
  features: ParkingLotFeatures[];
};
export type UpdateTaxonomyItemPayload = {
  name: string;
  customerName?: string | null;
  bgf?: number | null;
  ngf?: number | null;
  floorAreaCount?: number | null;
  constructionFloor?: number | null;
  rentalSpace?: number | null;
  salesSpace?: number | null;
  commonSpace?: number | null;
  grossVolume?: number | null;
  other?: number | null;
  amount?: number | null;
  type: TaxonomyType;
  usageType?: TaxonomyUsageType | null;
  parkingLot?: UpdateParkingLotPayload | null;
  description?: string | null;
  cost?: number | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type DetailValues = {
  budgetValue: number;
  mainContractValueNet: number;
  mainContractValueGross: number;
  supplementValueNet: number;
  supplementValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
};
export type DetailCommitment = {
  uid: string;
  id: string;
  code: string;
  name: string;
  values: DetailValues;
};
export type DetailContractTitle = {
  uid: string;
  id: string;
  code: string;
  name: string;
  values: DetailValues;
};
export type DetailContract = {
  uid: string;
  id: string;
  code: string;
  name: string;
  titles: DetailContractTitle[];
  values: DetailValues;
};
export type DetailCatalogElement = {
  uid: string;
  id: string;
  code: string;
  name: string;
  childElements: DetailCatalogElement[];
  commitments: DetailCommitment[];
  contracts: DetailContract[];
  availableBudget: number;
  childrenBudget: number;
  values?: DetailValues | null;
};
export type DetailReportSummary = {
  availableBudget: number;
  values?: DetailValues | null;
};
export type DetailReportReadModel = {
  tenantId: string;
  projectId: string;
  calculationModelId: string;
  catalogElements: DetailCatalogElement[];
  total: DetailReportSummary;
};
export type DetailReportResponse = {
  projectImage?: FileDto | null;
  tenantLogo?: FileDto | null;
  data: DetailReportReadModel;
};
export type ChangeSubdomainCommand = {
  tenantId: string;
  subdomain: string;
};
export type ErrorCodeModel = {
  name: string;
  children: ErrorCodeModel[];
  items: {
    [key: string]: string;
  };
};
export type TemplateContentType = 'Catalog' | 'Company';
export type ForecastInvestmentRowReadModel = {
  code: string;
  title?: string | null;
  description?: string | null;
  unit?: string | null;
  amount?: number | null;
  unitPrice?: number | null;
  unitPriceDate?: string | null;
  indexUnitPrice?: number | null;
  indexUnitPriceDate?: string | null;
  index?: number | null;
  multiple?: number | null;
  percentage?: number | null;
  total?: number | null;
  difference?: number | null;
  gikPercent?: number | null;
  isRootGroup: boolean;
  children?: ForecastInvestmentRowReadModel[] | null;
  rowIdentifier: string;
};
export type ForecastInvestmentReadModel = {
  gekRows: ForecastInvestmentRowReadModel[];
  gikRows: ForecastInvestmentRowReadModel[];
  operationRows: ForecastInvestmentRowReadModel[];
  gek: number;
  gik: number;
  gikWithOperation: number;
};
export type ForecastMarketReadModel = {
  rentalIncomeRows: ForecastInvestmentRowReadModel[];
  rentalValueRows: ForecastInvestmentRowReadModel[];
  propertyValueRows: ForecastInvestmentRowReadModel[];
  total: number;
};
export type ForecastReportReadModel = {
  projectName: string;
  calculationModel: CalculationModelReadModel;
  investment: ForecastInvestmentReadModel;
  rentAndMarketValue: ForecastMarketReadModel;
};
export type ForecastReportResponse = {
  report: ForecastReportReadModel;
};
export type EmailValue = {
  email: string;
};
export type ForgotPasswordCommand = {
  email: EmailValue;
};
export type GenerateNextProjectObjectCodeResponse = {
  code: string;
};
export type ProjectObjectType = 'Commitment' | 'Contract' | 'Title' | 'Invoice';
export type GenerateProjectObjectCodePayload = {
  ownerObjectId?: string | null;
  contractTitleType?: ContractTitleType | null;
  projectObjectType: ProjectObjectType;
};
export type GenerateNextTenantObjectCodeResponse = {
  code: string;
};
export type TenantObjectType = 'Company';
export type GenerateTenantObjectCodePayload = {
  ownerObjectId?: string | null;
  tenantObjectType: TenantObjectType;
};
export type CreateAiEvalDocumentResponse = {
  fileName: string;
  aiServiceError?: string | null;
};
export type TaskEntityType = 'Invoice' | 'Contract';
export type TaskEntityReadModel = {
  id: string;
  entityType: TaskEntityType;
  name?: string | null;
  description?: string | null;
};
export type WorkflowTaskReadModel = {
  workflowTaskId: string;
  workflowName?: string | null;
  taskName: string;
  comment?: string | null;
  createdAt?: string | null;
  authorizedUsers?: WorkflowAuthorizedUsersReadModel[] | null;
  authorizedGroups?: WorkflowAuthorizedGroupReadModel[] | null;
  taskEntityReadModel?: TaskEntityReadModel | null;
  status: WorkflowTaskStatus;
  completedAt?: string | null;
  completedBy?: WorkflowAuthorizedUsersReadModel | null;
};
export type GetWorkflowTasksQueryResponse = {
  workflowTasks: WorkflowTaskReadModel[];
};
export type GetAssemblyVersionQueryResponse = {
  assemblyVersion: string;
};
export type AuditType = 'None' | 'Create' | 'Update' | 'Delete';
export type AuditLogReadModel = {
  id: string;
  userId: string;
  tenantUserId?: string | null;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  hasAvatar: boolean;
  dateTime: string;
  type: AuditType;
  oldValues: {
    [key: string]: string | null;
  };
  newValues: {
    [key: string]: string | null;
  };
};
export type AuditLogTarget = 'None' | 'ContractTitle' | 'Contract' | 'InvoiceTitle' | 'Invoice' | 'CostElement';
export type AuthPermissionDefinitionReadModel = {
  name: ProbisPermission;
  requiredPermissions?: ProbisPermission[] | null;
  scope: PermissionScope;
};
export type AutomaticDistributionPayload = {
  costElementId?: string | null;
  distributionType: DistributionType;
  start: string;
  end: string;
  value: number;
  distributionFrequency: DistributionFrequency;
};
export type CalculationModelCostsReadModel = {
  id: string;
  catalogElements: CostCatalogElementDto[];
  costElementsTotals?: FinanceTimeline | null;
};
export type CalculationModelCostsQueryResponse = {
  payload: CalculationModelCostsReadModel;
};
export type CalculationModelEarningsGroupElement = {
  groupId?: string | null;
  earningsElementId?: string | null;
  earningsType: EarningsType;
  order: number;
  code: string;
  title: string;
  description?: string | null;
  amount?: number | null;
  unit?: string | null;
  unitPrice?: number | null;
  rentIndex?: number | null;
  timelineElement?: ElementDeliveryPeriod | null;
  financeTimeline?: FinanceTimeline | null;
  total?: number | null;
  calculatedValue?: number | null;
  effectiveValue?: number | null;
  totalForecast?: number | null;
  calculatedForecast?: number | null;
  effectiveForecast?: number | null;
  multiple?: number | null;
  calculatedMultiple?: number | null;
  relevantForValuation?: boolean | null;
  elementValuation?: EarningsElementValuation | null;
  children: EarningsElementShortReadDto[];
  hasFormula: boolean;
  isCatalog: boolean;
  difference?: number | null;
  multipleDifference?: number | null;
  effectiveMultiple?: number | null;
  overflow?: number | null;
  remainder?: number | null;
  calculationState: CalculationState;
  multipleCalculationState?: CalculationState | null;
};
export type CalculationModelEarningsElement = {
  id: string;
  earningsCatalogElementId?: string | null;
  code?: string | null;
  title?: string | null;
  description?: string | null;
  amount?: number | null;
  unit?: string | null;
  unitPrice?: number | null;
  calculatedUnitPrice?: number | null;
  rentIndex?: number | null;
  totalValue?: number | null;
  totalForecast?: number | null;
  multiple?: number | null;
  timelineElement?: ElementDeliveryPeriod | null;
  financeTimeline?: FinanceTimeline | null;
  relvantForValuation?: boolean | null;
  elementValuation?: EarningsElementValuation | null;
  children: CalculationModelEarningsElement[];
  hasFormula: boolean;
  timelineErrors: TimelineErrors[];
};
export type EarningsElementShortReadDto = {
  group?: CalculationModelEarningsGroupElement | null;
  earningsElement?: CalculationModelEarningsElement | null;
};
export type CalculationModelEarningsReadModel = {
  id: string;
  earnings: EarningsElementShortReadDto[];
  earningElementsTotals?: FinanceTimeline | null;
};
export type CalculationModelEarningsQueryResponse = {
  payload: CalculationModelEarningsReadModel;
};
export type CalculationModelRiskGroupReadModel = {
  groupId?: string | null;
  riskElementId?: string | null;
  code?: string | null;
  title?: string | null;
  description?: string | null;
  riskTarget?: RiskAmountTarget | null;
  probability?: number | null;
  fixedAmount?: number | null;
  fixedUnitPrice?: number | null;
  amountPercentage?: number | null;
  amountEffective?: number | null;
  amountAtRisk?: number | null;
  unitPriceEffective?: number | null;
  referencedItemTitle?: string | null;
  referencedItemCode?: string | null;
  earningsType?: EarningsType | null;
  timelineElement?: ElementDeliveryPeriod | null;
  financeTimeline?: FinanceTimeline | null;
  amount?: FactorValueModel | null;
  unitPrice?: FactorValueModel | null;
  total?: number | null;
  calculatedValue?: number | null;
  effectiveValue?: number | null;
  totalForecast?: number | null;
  calculatedForecast?: number | null;
  effectiveForecast?: number | null;
  children: RiskElementShortReadDto[];
  hasFormula: boolean;
  difference?: number | null;
  overflow?: number | null;
  remainder?: number | null;
  calculationState: CalculationState;
};
export type CalculationModelRiskElementReadModel = {
  groupId?: string | null;
  riskElementId?: string | null;
  code?: string | null;
  title?: string | null;
  description?: string | null;
  riskTarget?: RiskAmountTarget | null;
  probability?: number | null;
  fixedAmount?: number | null;
  fixedUnitPrice?: number | null;
  amountPercentage?: number | null;
  amountEffective?: number | null;
  amountAtRisk?: number | null;
  unitPriceEffective?: number | null;
  referencedItemTitle?: string | null;
  referencedItemCode?: string | null;
  earningsType?: EarningsType | null;
  timelineElement?: ElementDeliveryPeriod | null;
  financeTimeline?: FinanceTimeline | null;
  amount?: FactorValueModel | null;
  unitPrice?: FactorValueModel | null;
  total: number;
  totalForecast?: number | null;
  hasFormula: boolean;
  timelineErrors: TimelineErrors[];
};
export type RiskElementShortReadDto = {
  group?: CalculationModelRiskGroupReadModel | null;
  riskElement?: CalculationModelRiskElementReadModel | null;
};
export type CalculationModelRisksReadModel = {
  id: string;
  riskElements: RiskElementShortReadDto[];
  riskElementsTotals?: FinanceTimeline | null;
};
export type CalculationModelRisksQueryResponse = {
  payload: CalculationModelRisksReadModel;
};
export type FinancingError =
  | 'UnknownFinancingState'
  | 'Underfinanced'
  | 'Overfinanced'
  | 'SomeCostsMissingDistribution'
  | 'SomeMonthsUnderfinanced';
export type FinancingElementShortReadModel = {
  id: string;
  financingCatalogElementId?: string | null;
  code?: string | null;
  title?: string | null;
  description?: string | null;
  type: FinancingType;
  interestRate?: number | null;
  totalValue: number;
  timelineElement?: ElementDeliveryPeriod | null;
  financeTimeline?: FinanceTimeline | null;
  totalCost: number;
  totalGrantAmount: number;
  isLocked: boolean;
  errors: FinancingError[];
};
export type FinancingGroupReadModel = {
  groupId?: string | null;
  catalogId?: string | null;
  financingType?: FinancingType | null;
  code: string;
  title: string;
  totalValue: number;
  financingElements: FinancingElementShortReadModel[];
  groups: FinancingGroupReadModel[];
  totalCost: number;
  totalGrantAmount: number;
  financeTimeline?: FinanceTimeline | null;
  timelineElement?: ElementDeliveryPeriod | null;
};
export type CalculationModelFinancingReadModel = {
  id: string;
  financingGroups: FinancingGroupReadModel[];
  totalEquity: number;
};
export type CalculationModelFinancingQueryResponse = {
  payload: CalculationModelFinancingReadModel;
};
export type UserDefinedFieldExportReadModel = {
  name: string;
  value?: string | null;
};
export type ProjectExportReadModel = {
  name: string;
  groupName?: string | null;
  projectId: string;
  street?: string | null;
  number?: string | null;
  postalCode?: string | null;
  city?: string | null;
  countryCode?: string | null;
  externalCode?: string | null;
  internalCode?: string | null;
  vat?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  userDefinedFields?: UserDefinedFieldExportReadModel[] | null;
};
export type CostElementExportReadModel = {
  code: string;
  description: string;
  amount?: number | null;
  amountUnit?: string | null;
  unitPrice?: number | null;
  effectiveValue?: number | null;
  calculated?: number | null;
  overflow?: number | null;
  rest?: number | null;
  vat?: number | null;
  userDefinedFields?: UserDefinedFieldExportReadModel[] | null;
};
export type RiskElementExportReadModel = {
  code?: string | null;
  description?: string | null;
  fixedAmount?: number | null;
  amountPercentage?: number | null;
  probability?: number | null;
  amountEffective?: number | null;
  unitPriceEffective?: number | null;
  total: number;
};
export type EarningsExportElement = {
  code: string;
  description?: string | null;
  amount?: number | null;
  unit?: string | null;
  unitPrice?: number | null;
  multiple?: number | null;
  totalValue: number;
  isTopLevel: boolean;
};
export type EarningsGroupExportReadModel = {
  code: string;
  title: string;
  totalValue: number;
  earnings: EarningsExportElement[];
};
export type TaxonomyItemExportReadModel = {
  itemName: string;
  itemType: TaxonomyType;
  grossFloor?: QuantityReadModel | null;
  netFloor?: QuantityReadModel | null;
  rentalSpace?: QuantityReadModel | null;
  amount?: QuantityReadModel | null;
  userDefinedFields?: UserDefinedFieldExportReadModel[] | null;
};
export type FinancingElementExportReadModel = {
  code?: string | null;
  title?: string | null;
  totalGrantAmount?: number | null;
  totalCost?: number | null;
  totalValue: number;
};
export type FinancingGroupExportReadModel = {
  code: string;
  title: string;
  totalValue: number;
  financingElements: FinancingElementExportReadModel[];
  groups: FinancingGroupExportReadModel[];
  totalCost: number;
  totalGrantAmount: number;
};
export type ContractTitleExportReadModel = {
  contractCode: string;
  code: string;
  name: string;
  budgetNet: number;
  budgetGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  userDefinedFields?: UserDefinedFieldExportReadModel[] | null;
};
export type ContractExportReadModel = {
  code: string;
  name: string;
  contractorName?: string | null;
  clientName?: string | null;
  budgetNet: number;
  budgetGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  titles: ContractTitleExportReadModel[];
  userDefinedFields?: UserDefinedFieldExportReadModel[] | null;
};
export type CommitmentExportReadModel = {
  code: string;
  name: string;
  budgetNet: number;
  budgetGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  contracts: ContractExportReadModel[];
};
export type InvoiceTitleExportReadModel = {
  code?: string | null;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
};
export type InvoiceExportReadModel = {
  code: string;
  sequentialNumber: number;
  state?: string | null;
  invoiceDate: string;
  dueDate?: string | null;
  contractCode?: string | null;
  contractName?: string | null;
  claim: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  invoiceTitles: InvoiceTitleExportReadModel[];
  userDefinedFields?: UserDefinedFieldExportReadModel[] | null;
};
export type CalculationModelExportReadModel = {
  project: ProjectExportReadModel;
  costElements: CostElementExportReadModel[];
  riskElementReadModels: RiskElementExportReadModel[];
  earningsGroups: EarningsGroupExportReadModel[];
  taxonomyItems: TaxonomyItemExportReadModel[];
  financingGroups: FinancingGroupExportReadModel[];
  commitments: CommitmentExportReadModel[];
  contractsWithoutCommitment: ContractExportReadModel[];
  contractTitles: ContractTitleExportReadModel[];
  invoices: InvoiceExportReadModel[];
  calculationModelName: string;
};
export type GetCalculationModelExportQueryResponse = {
  calculationModelExport: CalculationModelExportReadModel;
};
export type CalculationModelHistory = {
  parentVersion?: CalculationModelMetadata | null;
  savePoints: CalculationModelMetadata[];
  currentVersion: CalculationModelMetadata;
};
export type GenerateElementCodeResponse = {
  code: string;
};
export type ClusterType = 'Undefined' | 'Airport' | 'SpecialBuilding' | 'Building' | 'BuildingComplex';
export type RequirementShortReadModel = {
  id: string;
  name: string;
  code: string;
  type: RequirementType;
  state: RequirementState;
  createdAt: string;
  reportedById: string;
  reportedByFullName: string;
  reportedByEmail: string;
  reportedByHasAvatar: boolean;
  reportedByDeputyId?: string | null;
  deadline?: string | null;
};
export type ClusterItemReadModel = {
  id: string;
  name: string;
  type: ClusterType;
  requirements: RequirementShortReadModel[];
};
export type GetClusterItemsQueryResponse = {
  clusterItems?: ClusterItemReadModel[] | null;
};
export type ClusterItemTreeReadModel = {
  id: string;
  name: string;
  type: ClusterType;
  children: ClusterItemTreeReadModel[];
  description?: string | null;
  requirements: RequirementShortReadModel[];
};
export type GetClusterItemsTreeQueryResponse = {
  clusterItemsTree?: ClusterItemTreeReadModel | null;
};
export type GetCostCatalogQueryResponse = {
  costCatalog: CostCatalogReadModel;
};
export type GetCostCatalogsQueryResponse = {
  catalogs: CostCatalogReadModel[];
};
export type MonthlyCostDistributionElement = {
  date: string;
  amount: number;
  vat: number;
};
export type GetCostElementsMonthlyDistributionQueryResult = {
  monthlyDistributions: MonthlyCostDistributionElement[];
};
export type CrossTenantContractReadModel = {
  id: string;
  code: string;
  name: string;
};
export type GetCrossTenantContractsQueryResponse = {
  project: TrustedProjectReadModel;
  contracts: CrossTenantContractReadModel[];
};
export type GetEarningsCatalogQueryResponse = {
  earningsCatalog: EarningsCatalogReadModel;
};
export type GetEarningsCatalogsQueryResponse = {
  earningsCatalogs: EarningsCatalogReadModel[];
};
export type EmissionYearlyOverview = {
  emissionPerYear: QuantityReadModel;
  emissionThreshold: QuantityReadModel;
  emissionExcess: QuantityReadModel;
  penaltyCost: QuantityReadModel;
};
export type EmissionYearlyDetail = {
  year: number;
  consumption: QuantityReadModel;
  emissionThreshold: QuantityReadModel;
  penaltyCost: QuantityReadModel;
};
export type EmissionPeriod = {
  startYear: number;
  endYear: number;
  emissionPerYearOverview: EmissionYearlyOverview;
  emissionYearlyDetail?: EmissionYearlyDetail | null;
};
export type CalculationModelEmissionYearlyDistributionReadModel = {
  emissionPeriods: EmissionPeriod[];
};
export type GetEmissionYearlyDistributionsQueryResponse = {
  emissionYearlyDistributions: CalculationModelEmissionYearlyDistributionReadModel;
};
export type EuriborReadModel = {
  id: string;
  date: string;
  oneMonthEuribor: number;
  threeMonthsEuribor: number;
  sixMonthsEuribor: number;
  twelveMonthsEuribor: number;
  systemConfirmed: boolean;
  userConfirmed: boolean;
  tenantId?: string | null;
};
export type EuriborExtendedReadModel = {
  date: string;
  euriborReadModel?: EuriborReadModel | null;
};
export type GetEuriborRatesResult = {
  lastConfirmedEuribor?: EuriborReadModel | null;
  euriborRates: EuriborExtendedReadModel[];
};
export type GetFinancingCatalogQueryResponse = {
  financingCatalog: FinancingCatalogReadModel;
};
export type GetFinancingCatalogsQueryResponse = {
  financingCatalogs: FinancingCatalogReadModel[];
};
export type MonthlySuggestedPayoutElement = {
  date: string;
  suggestedAmountPayout: number;
  totalAmount: number;
  suggestedVatAmountPayout: number;
  totalVat: number;
  totalSuggestedPayout: number;
};
export type GetSuggestedFinancingElementDistributionQueryResult = {
  monthlyDistributionSuggestions: MonthlySuggestedPayoutElement[];
};
export type FinancePayoutTypeEnum = 'ProRataPayout' | 'PayoutFirst' | 'PayoutLast';
export type GetFinancingSuggestedDistributionDto = {
  costCatalogElementIds: string[];
  percentage: number;
  nominalAmount: number;
  includeVat: boolean;
  payoutType: FinancePayoutTypeEnum;
};
export type FinancingElementMetadataElement = {
  id: string;
  code: string;
  description: string;
  nominalAmount: number;
  financedCostCatalogIds: string[];
};
export type FinancingElementsMetadataReadModel = {
  elements: FinancingElementMetadataElement[];
};
export type GetFinancingElementsMetadataQueryResult = {
  readModel: FinancingElementsMetadataReadModel;
};
export type FinancingShare = {
  id: string;
  name: string;
  amount: number;
};
export type CostsFinancingOverview = {
  date: string;
  totalCosts: number;
  balance: number;
  financingShares: FinancingShare[];
  totalFinancing: number;
};
export type FinancingShareMonthByMonth = {
  id: string;
  name: string;
  amounts: number[];
};
export type OverviewResult = {
  dates: string[];
  totalCosts: number[];
  totalFinancing: number[];
  balances: number[];
  financingSegments: FinancingShareMonthByMonth[];
};
export type GetFinancingCostsOverviewQueryResult = {
  costsFinancingOverviewItems: CostsFinancingOverview[];
  overviewResult: OverviewResult;
};
export type UserInvitationReadModel = {
  email: string;
  tenantName: string;
};
export type TenantTrustInviteReadModel = {
  inviteId: string;
  inviterName: string;
  inviteeMail: string;
  inviteeCompany: string;
  inviteePerson: string;
  inviteToken: string;
  inviteExpiration: string;
};
export type GetTenantTrustInviteQueryResponse = {
  trustInvite: TenantTrustInviteReadModel;
};
export type SampleCalculationRuleReadModel = {
  id: string;
  calculationResultNet: number;
  calculationResultGross: number;
  vat?: number | null;
  percentage?: number | null;
  value?: number | null;
  referenceValue?: number | null;
};
export type SampleCalculationRuleGroupReadModel = {
  id: string;
  calculationResultNet: number;
  calculationResultGross: number;
  calculationRuleResults: SampleCalculationRuleReadModel[];
};
export type SampleInvoiceReadModel = {
  type: InvoiceType;
  vat?: number | null;
  invoiceValueNet: number;
  invoiceValueGross: number;
  partialPaymentNet?: number | null;
  partialPaymentGross?: number | null;
  calculationRuleGroupResult: SampleCalculationRuleGroupReadModel[];
};
export type InvoiceCalculationSampleReadModel = {
  contractValueNet: number;
  contractValueGross: number;
  mainContractValueNet: number;
  mainContractValueGross: number;
  supplementValueNet: number;
  supplementValueGross: number;
  contractVat?: number | null;
  invoices: SampleInvoiceReadModel[];
};
export type GetInvoiceCalculationSampleResponse = {
  calculationSample: InvoiceCalculationSampleReadModel;
};
export type CommitmentReportReadModel = {
  id: string;
  code: string;
  name: string;
  description?: string | null;
  budgetNet: number;
  budgetGross: number;
  contractValueNet: number;
  contractValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  budgetAssignments: BudgetAssignmentReadModel[];
};
export type ShortInvoiceReportReadModel = {
  id: string;
  invoicingParty?: CompanyBranchReadModel | null;
  invoiceRecipient?: CompanyBranchReadModel | null;
  sequentialNumber: number;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  state: InvoiceState;
  distributionType: InvoiceValueDistributionType;
  vat?: number | null;
  claim: number;
  dateOfReceipt: string;
  invoiceDate: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  dateOfAudit?: string | null;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  valueNet: number;
  valueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  discountValueNet: number;
  discountValueGross: number;
  retentionNet: number;
  retentionGross: number;
  advancePaymentNet: number;
  advancePaymentGross: number;
  repaymentNet: number;
  repaymentGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  cashDiscountNet: number;
  cashDiscountGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
  isPxInvoice: boolean;
  invoiceTitles: InvoiceTitleReadModel[];
  invoiceCalculationRuleGroups: InvoiceCalculationRuleGroupReadModel[];
  comment?: string | null;
};
export type InvoiceContractReportReadModel = {
  id: string;
  commitment?: CommitmentReportReadModel | null;
  code: string;
  name: string;
  description?: string | null;
  state: ContractState;
  contractDate?: string | null;
  contractor?: CompanyBranchReadModel | null;
  client?: CompanyBranchReadModel | null;
  vat?: number | null;
  discount?: number | null;
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
  budgetNet: number;
  budgetGross: number;
  offerNet: number;
  offerGross: number;
  approvedOfferNet: number;
  approvedOfferGross: number;
  contractValueNet: number;
  contractValueGross: number;
  mainContractValueNet: number;
  mainContractValueGross: number;
  supplementValueNet: number;
  supplementValueGross: number;
  adjustmentValueNet: number;
  adjustmentValueGross: number;
  forecastValueNet: number;
  forecastValueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  isPxContract: boolean;
  elementTimeline?: ElementTimelineReadModel | null;
  titles: ContractTitleReadModel[];
  invoices: ShortInvoiceReportReadModel[];
  budgetAssignments: BudgetAssignmentReadModel[];
  userDefinedFields?: ElementUserDefinedFieldReadModel[] | null;
};
export type InvoiceReportReadModel = {
  id: string;
  contract?: InvoiceContractReportReadModel | null;
  invoicingParty?: CompanyBranchReadModel | null;
  invoiceRecipient?: CompanyBranchReadModel | null;
  sequentialNumber: number;
  code: string;
  externalCode?: string | null;
  type: InvoiceType;
  state: InvoiceState;
  distributionType: InvoiceValueDistributionType;
  vat?: number | null;
  claim: number;
  dateOfReceipt: string;
  invoiceDate: string;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  dateOfAudit?: string | null;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  valueNet: number;
  valueGross: number;
  invoiceValueNet: number;
  invoiceValueGross: number;
  discountValueNet: number;
  discountValueGross: number;
  retentionNet: number;
  retentionGross: number;
  advancePaymentNet: number;
  advancePaymentGross: number;
  repaymentNet: number;
  repaymentGross: number;
  paymentValueNet: number;
  paymentValueGross: number;
  cashDiscountNet: number;
  cashDiscountGross: number;
  totalPaymentValueNet: number;
  totalPaymentValueGross: number;
  isPxInvoice: boolean;
  invoiceTitles: InvoiceTitleReadModel[];
  invoiceCalculationRuleGroups: InvoiceCalculationRuleGroupReadModel[];
  budgetAssignment?: BudgetAssignmentReadModel | null;
  comment?: string | null;
  calculationSchemeId?: string | null;
};
export type GetInvoiceReportQueryResponse = {
  invoice: InvoiceReportReadModel;
};
export type InvoiceTaskReadModel = {
  workflowTaskId: string;
  taskName: string;
  isLastTask: boolean;
  nextActivity?: string | null;
  status: WorkflowTaskStatus;
  authorizedUsers: WorkflowAuthorizedUsersReadModel[];
};
export type TasksHistoryReadModel = {
  taskName: string;
  status: WorkflowTaskStatus;
  completedBy?: WorkflowAuthorizedUsersReadModel | null;
  completedAt?: string | null;
  comment?: string | null;
};
export type FutureTaskReadModel = {
  taskName: string;
  status: WorkflowTaskStatus;
  authorizedUsers?: WorkflowAuthorizedUsersReadModel[] | null;
  authorizedGroups?: WorkflowAuthorizedGroupReadModel[] | null;
  order: number;
};
export type WorkflowDetailReadModel = {
  definitionId: string;
  workflowName?: string | null;
  currentTask?: InvoiceTaskReadModel | null;
  tasksHistory?: TasksHistoryReadModel[] | null;
  futureTasks?: FutureTaskReadModel[] | null;
  errors?: WorkflowErrorReadModel[] | null;
};
export type PortfolioReadModel = {
  id: string;
  tenantId: string;
  coverPictureId?: string | null;
  name: string;
};
export type GetPortfolioQueryResponse = {
  portfolio: PortfolioReadModel;
};
export type GetPortfolioNextRequirementCodeQueryResponse = {
  code: string;
};
export type PortfolioShortReadModel = {
  id: string;
  coverPictureId?: string | null;
  name: string;
};
export type GetPortfoliosQueryResponse = {
  portfolios: PortfolioShortReadModel[];
};
export type ProjectGroupReadModel = {
  id: string;
  name: string;
  description?: string | null;
  externalId?: string | null;
  coverPictureId?: string | null;
  totalBgf?: number | null;
  totalPlotSize?: number | null;
  cities: string[];
  projects: ProjectReadModel[];
};
export type GetProjectGroupQueryResponse = {
  projectGroup: ProjectGroupReadModel;
};
export type GetUserDefinedFieldsDefinitionByElementQueryResponse = {
  userDefinedFieldsDefinition: ElementUserDefinedFieldDefinitionReadModel[];
};
export type RecurringPaymentDistributionPayload = {
  start: string;
  end: string;
  unitPrice: number;
  amount: number;
  rentIndex?: number | null;
  rentIndexDate?: string | null;
  paymentFrequency: PaymentFrequency;
};
export type RegionEmissionFactorReadModel = {
  id: string;
  propertyType: EmissionPropertyType;
};
export type RegionEmissionFactorsReadModel = {
  regionEmissionFactors: RegionEmissionFactorReadModel[];
};
export type GetRiskCatalogQueryResponse = {
  riskCatalog: RiskCatalogReadModel;
};
export type GetRiskCatalogsQueryResponse = {
  riskCatalogs: RiskCatalogReadModel[];
};
export type SelectCommitmentReadModel = {
  id: string;
  code: string;
  name: string;
};
export type SelectContractInvoiceCalculationSchemeReadModel = {
  id: string;
  name: string;
  type: InvoiceCalculationSchemeType;
};
export type SelectContractReadModel = {
  id: string;
  code: string;
  name: string;
  state: ContractState;
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
  isPxContract: boolean;
  hasFinalInvoice: boolean;
  clientName?: string | null;
  clientCompanyName?: string | null;
  contractorName?: string | null;
  contractorCompanyName?: string | null;
  invoiceCalculationSchemes: SelectContractInvoiceCalculationSchemeReadModel[];
};
export type TaxonomyAvailableForDealReadModel = {
  taxonomyItem: TaxonomyReadModel;
  childItems: TaxonomyAvailableForDealReadModel[];
  isActivelyUsedInDeal: boolean;
  isSelectable: boolean;
};
export type GetDealSelectableTaxonomyItemsResult = {
  taxonomyAvailableForDeal: TaxonomyAvailableForDealReadModel;
};
export type TaxonomyCostItemsReadModel = {
  taxonomyItem: TaxonomyReadModel;
  childItems: TaxonomyCostItemsReadModel[];
  costItems: DealCostItemReadModel[];
  attachedContracts: DealReadModel[];
  conflictsWithChildren: boolean;
};
export type AuthProjectReadModel = {
  id: string;
  projectId: string;
  name: string;
  externalCode?: string | null;
  internalCode?: string | null;
  isDin276: boolean;
  primaryRiskCatalogId?: string | null;
  primaryEarningsCatalogId?: string | null;
  primaryFinancingCatalogId?: string | null;
};
export type GetTenantAuthProjectsQueryResponse = {
  readModels: AuthProjectReadModel[];
};
export type GetTenantAuthSubjectsQueryResponse = {
  readModels: AuthSubjectReadModel[];
};
export type TenantCatalogReadModel = {
  id: string;
  type: TenantCatalogType;
  name: string;
  projectCount: number;
  isTenantCatalog: boolean;
};
export type GetTenantCatalogsQueryResponse = {
  tenantCatalogs: TenantCatalogReadModel[];
};
export type TenantUserReadModel = {
  userId: string;
  tenantUserId: string;
  fullName: string;
  email: string;
};
export type TenantReadModel = {
  tenantId: string;
  tenantName: string;
  tenantSubdomain: string;
  users: TenantUserReadModel[];
};
export type GetTenantsQueryResponse = {
  tenants: TenantReadModel[];
};
export type TenantTrustReadModel = {
  inviteId: string;
  invitingTenantId: string;
  invitingTenantName: string;
  invitingUserName: string;
  inviteeMail: string;
  inviteeName: string;
  status: TrustState;
  expiration: string;
  isActive: boolean;
  invitedTenantId?: string | null;
  invitedTenantName?: string | null;
  acceptedByTenantName?: string | null;
  acceptedByTenantUser?: string | null;
};
export type GetTenantTrustsQueryResponse = {
  trustedTenants: TenantTrustReadModel[];
  trustingTenants: TenantTrustReadModel[];
};
export type UserTenantReadModel = {
  userId: string;
  tenantId: string;
  tenantUserId: string;
  userIsOwner: boolean;
  tenantName: string;
  tenantDescription: string;
  tenantSubdomain: string;
  tenantHasAvatar: boolean;
  projectCount: number;
  groupCount: number;
};
export type EarningsElementValuationResult = {
  valuation: EarningsElementValuation;
};
export type GetEarningsElementValuationPayload = {
  relevantForValuation: boolean;
  calculationDate: string;
  amount: number;
  rent: number;
  multiple: number;
  index?: number | null;
  rentIndexDate?: string | null;
  rentStart?: string | null;
  rentEnd?: string | null;
};
export type GetSuggestedVatReturnDistributionQueryResult = {
  totalVat: number;
  totalVatReturn: number;
  vatReturnSuggestion: VatDistribution[];
};
export type GetVatReturnDistributionDto = {
  costCatalogElementIds: string[];
  monthsDelay: number;
  returnPercentage: number;
  userDefinedFieldId?: string | null;
  userDefinedFieldLabelIds?: string[] | null;
};
export type WorkflowReadModel = {
  definitionId: string;
  name: string;
  isContractWorkflow: boolean;
};
export type GetWorkflowsQueryResponse = {
  workflows: WorkflowReadModel[];
};
export type CatalogDataRecord = {
  code: string;
  parentCode: string;
  name: string;
};
export type CatalogElementData = {
  data: CatalogDataRecord;
  children: CatalogElementData[];
  errors: string[];
};
export type ParseCatalogFileResponse = {
  catalogElements: CatalogElementData[];
};
export type ImportCatalogDataPayload = {
  catalogName: string;
  type: TenantCatalogType;
  elements: CatalogElementData[];
};
export type ContractDataRecord = {
  code: string;
  name: string;
  description?: string | null;
  commitmentCode?: string | null;
  clientCode?: string | null;
  contractorCode?: string | null;
  contractDate?: string | null;
  contractVat?: number | null;
  contractDiscount?: number | null;
  dueDateDeadline?: number | null;
  cashDiscountDeadline?: number | null;
  contractUserDefinedFields: {
    [key: string]: any | null;
  };
  titleCode?: string | null;
  titleName?: string | null;
  titleDescription?: string | null;
  titleType?: ContractTitleType | null;
  titleState?: ContractTitleState | null;
  titleVat?: number | null;
  titleDiscount?: number | null;
  titleOffer?: number | null;
  titleApprovedOffer?: number | null;
  titleAdjustment?: number | null;
  titleUserDefinedFields: {
    [key: string]: any | null;
  };
};
export type ContractElementData = {
  data: ContractDataRecord;
  errors: string[];
};
export type ImportContractDataResponse = {
  errorElements: ContractElementData[];
};
export type CostElementDataRecord = {
  name: string;
  catalogElement?: string | null;
  unit: string;
  amount: number;
  unitPrice: number;
  userDefinedFields: {
    [key: string]: any;
  };
};
export type CostElementData = {
  data: CostElementDataRecord;
  errors: string[];
};
export type ImportCostElementDataResponse = {
  errorElements: CostElementData[];
};
export type InvoiceDataRecord = {
  contractCode?: string | null;
  orderNumber?: number | null;
  invoiceCode: string;
  type: InvoiceType;
  state: InvoiceState;
  invoiceDate: string;
  dateOfReceipt: string;
  externalCode?: string | null;
  invoicingPartyCode?: string | null;
  invoiceRecipientCode?: string | null;
  claim?: number | null;
  vat?: number | null;
  dueDate?: string | null;
  cashDiscountDate?: string | null;
  dateOfAudit?: string | null;
  dateOfApproval?: string | null;
  paymentDate?: string | null;
  distributionType: InvoiceValueDistributionType;
  invoiceValue?: number | null;
  userDefinedFields: {
    [key: string]: any | null;
  };
};
export type InvoiceElementData = {
  data: InvoiceDataRecord;
  errors: string[];
};
export type ImportInvoiceDataResponse = {
  errorElements: InvoiceElementData[];
};
export type CompanyDataRecord = {
  name: string;
  taxId?: string | null;
  companyRegistrationNumber?: string | null;
  link?: string | null;
  branchCode?: string | null;
  branchName?: string | null;
  branchIsMain?: boolean | null;
  branchEmail?: string | null;
  branchPhone?: string | null;
  branchMobile?: string | null;
  branchLink?: string | null;
  branchCreditorId?: string | null;
  branchDebitorId?: string | null;
  branchSpvId?: string | null;
  branchAddressSupplement?: string | null;
  branchAddressStreet?: string | null;
  branchAddressNumber?: string | null;
  branchAddressPostalCode?: string | null;
  branchAddressCity?: string | null;
  branchAddressCountry?: string | null;
  branchAccountOwner?: string | null;
  branchAccountIban?: string | null;
  branchAccountBankName?: string | null;
  branchAccountSwift?: string | null;
};
export type CompanyElementData = {
  data: CompanyDataRecord;
  errors: string[];
};
export type ParseCompanyFileResponse = {
  companies: CompanyElementData[];
};
export type ImportCompanyDataResponse = {
  errorElements: CompanyElementData[];
};
export type InitializeBuildingTaxonomyPayload = {
  name: string;
  customerName?: string | null;
  bgf?: number | null;
  ngf?: number | null;
  rentalSpace?: number | null;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type SetInviteEnabledPayload = {
  inviteId: string;
  enabled: boolean;
};
export type SignupForInviteCommand = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tenantName: string;
  inviteToken: string;
};
export type InviteUserRequest = {
  email: string;
};
export type LoginUserResponse = {
  sessionToken: string;
};
export type LoginUserRequest = {
  email: string;
  password: string;
};
export type MoveTenantCatalogElementPayload = {
  catalogId: string;
  type: TenantCatalogType;
  elementId: string;
  newIndexInParent: number;
};
export type MoveDirection = 'Up' | 'Down';
export type MovePayload = {
  direction: MoveDirection;
};
export type ParseContractFileResponse = {
  contracts: ContractElementData[];
};
export type ParseCostElementFileResponse = {
  costElements: CostElementData[];
};
export type ParseInvoiceFileResponse = {
  invoices: InvoiceElementData[];
};
export type AddGroupProjectsPayload = {
  groupId: string;
  projectIds: string[];
};
export type RemoveGroupProjectsPayload = {
  groupId: string;
  projectIds: string[];
};
export type CreateProjectGroupResponse = {
  id: string;
};
export type UpdateProjectGroupPayload = {
  id: string;
  name: string;
  description?: string | null;
  externalId?: string | null;
};
export type QuickCreateCompanyResponse = {
  companyId: string;
};
export type CompanyPayload = {
  name: string;
  taxId?: string | null;
  companyRegistrationNumber?: string | null;
  link?: string | null;
};
export type QuickCreateCompanyPayload = {
  company: CompanyPayload;
  branch: CreateCompanyBranchPayload;
  account?: CreateCompanyBankAccountPayload | null;
  person?: CompanyPersonPayload | null;
};
export type RemoveCompanyFromPersonPayload = {
  branchId: string;
};
export type ResetPasswordCommand = {
  password: string;
  resetToken: string;
};
export type SearchIn = 'Project' | 'Cost' | 'Taxonomy' | 'Contract' | 'ContractTitle' | 'Invoice' | 'Risk' | 'Earning';
export type SearchReadModel = {
  searchIn: SearchIn;
  ids: string[];
};
export type SearchResponse = {
  readModels: SearchReadModel[];
};
export type SearchFilterType = 'UserDefinedField';
export type SearchFilter = {
  type: SearchFilterType;
  id: string;
};
export type SearchPayload = {
  searchIn: SearchIn[];
  values: string[];
  searchFilters: SearchFilter[];
  projectId?: string | null;
  calculationModelId?: string | null;
};
export type StartInvoiceWorkflowPayload = {
  projectId: string;
  calculationModelId: string;
  invoiceId: string;
};
export type TenantAddressPayload = {
  name: string;
  street: string;
  number?: string | null;
  suffix?: string | null;
  postalCode: string;
  city: string;
  state?: string | null;
  country: string;
  email: string;
};
export type InviteTenantResponse = {
  id: string;
  inviteUrl?: string | null;
};
export type InviteTenantPayload = {
  companyId: string;
  companyBranchId: string;
  companyPersonId: string;
  projectId: string;
};
export type TenantNamePayload = {
  name: string;
  name2?: string | null;
};
export type TenantOwnerPayload = {
  tenantUserId: string;
};
export type UpdateWorkflowAssignableActivityConfigurationPayload = {
  id: string;
  value: string;
};
export type UpdateWorkflowAssignableActivityPayload = {
  workflowDefinitionId: string;
  authorizedUsers: string[];
  authorizedGroups: string[];
  configurations: UpdateWorkflowAssignableActivityConfigurationPayload[];
};
export type MembershipPayload = {
  membership: TenantUserState;
};
export type UpdateSubjectPayload = {
  addedGroups: string[];
  deletedGroups: string[];
  addedRoleAssignments: RoleAssignmentPayload[];
  deletedRoleAssignments: string[];
};
export type UpdateAutomaticWorkflowFlagPayload = {
  startWorkflowAutomatically: boolean;
};
export type UpdateCalculationModelCashFlowSettingsPayload = {
  date?: string | null;
  dateRef: CashFlowReferenceDate;
  valueRef: CashFlowReferenceValue;
  overridePlan: boolean;
};
export type UpdateCalculationModelKpisPayload = {
  gfz: number;
  grz: number;
};
export type UpdateCalculationModelMetadataPayload = {
  title: string;
  description?: string | null;
  plannedIrr?: number | null;
  startDate: string;
  endDate: string;
};
export type UpdateClusterItemRequirementStatusPayload = {
  name: string;
  clusterItemId: string;
  state: RequirementState;
  comment?: string | null;
  deadline?: string | null;
  reportedById?: string | null;
  reportedByDeputyId?: string | null;
  editorId?: string | null;
  editorDeputyIds?: string[] | null;
};
export type UpdateCostExtensionElementSettingResponse = {
  elementId: string;
};
export type ElementSettingPayload = {
  hasStatus: boolean;
  hasConstructionPhase: boolean;
  hasTags: boolean;
  hasVat: boolean;
};
export type InvoiceTitleByContractTitlePayload = {
  vat: number;
  checked: boolean;
  invoiceValue: number;
};
export type UpdateLicensePayload = {
  licenseId: string;
  name: string;
  description: string;
  durationInMonths: number;
  pricePerMonth: number;
  features: string[];
};
export type LicenseGrantAssigneesPayload = {
  assignees: string[];
};
export type UpdateLicenseGrantPayload = {
  tenantId: string;
  licenseGrantId: string;
  seats?: number | null;
  seatPricePerMonth: number;
  activeTo: string;
  assignees: string[];
};
export type ProbisDataPayload = {
  projectState: string;
  compressedPayload: string;
};
export type UpdateProjectMetadataPayload = {
  projectId: string;
  dwhProjectId?: string | null;
  name: string;
  street?: string | null;
  houseNumber?: string | null;
  postalCode?: string | null;
  city?: string | null;
  countryCode?: string | null;
  vat?: number | null;
  currency: Currency;
  userDefinedFieldsPayload?: UserDefinedFieldPayload[] | null;
};
export type RentConditionPayload = {
  securityAmount: number;
  securityType: SecurityType;
  paymentMethod: PaymentMethod;
  paymentAppointment?: string | null;
  rentStart?: string | null;
  rentEnd?: string | null;
  contractTerminationNotice: number;
  contractEarliestTerminationDate?: string | null;
  contractPossibleExtensions: number;
  contractExtensionDuration: number;
  rentFreePeriodStart?: string | null;
  rentFreePeriodEnd?: string | null;
  billingPeriod: PaymentFrequency;
  rentDueDays: number;
  adjustmentType: AdjustmentType;
  adjustmentTypeActiveValue: number;
  adjustmentInterval: PaymentFrequency;
  adjustmentDeadline?: string | null;
};
export type UpdateVatCostElementCommandResponse = {
  updatedTimeline: ElementTimelineReadModel;
  updatedUnitPrice: number;
};
export type UpdateVatElementPayload = {
  vatReturnPercentage: number;
  delayMonths: number;
  costGroupIds?: string[] | null;
  userDefinedFieldId?: string | null;
  userDefinedFieldLabelIds?: string[] | null;
};
export type ValidateIbanPayload = {
  iban: string;
};
export const {
  useApiPostAcceptUserInvitationMutation,
  useApiPostAcceptTenantInviteMutation,
  useApiPostActiveApiAccessMutation,
  useApiAdminPostActivateLicenseGrantMutation,
  useApiPostApiAccessMutation,
  useApiGetExternalApisQuery,
  useApiRemoveApiAccessMutation,
  useApiUpdateApiAccessMutation,
  useApiPostCreateRentDealContactMutation,
  useApiPostCreateRentDealContractItemMutation,
  useApiPostCreateRentDealCostItemMutation,
  useApiPostCreateRentDealDocumentMutation,
  useApiPostCreateSellDealContactMutation,
  useApiPostCreateSellDealContractItemMutation,
  useApiPostCreateSellDealCostItemMutation,
  useApiPostCreateSellDealDocumentMutation,
  useApiPostApplyCostElementForecastAsBudgetMutation,
  useApiPostApproveInvoiceMutation,
  useApiPostCreateCalculationModelArchiveMutation,
  useApiPostAuditInvoiceMutation,
  useApiGetAuthRolesQuery,
  useApiPostCreateAuthRoleMutation,
  useApiGetBaseReportQuery,
  useApiGetBugetReportQuery,
  useApiGetBudgetExcelReportQuery,
  useApiPostCalculateAllConsumptionsCostMutation,
  useApiPostCalculateConsumptionCostMutation,
  useApiPostCalculateContractTitleMutation,
  useApiPostCalculateFormulaMutation,
  useApiPostCalculateInvoiceValueDistributionMutation,
  useApiCancelAiEvalProcessMutation,
  useApiGetCashFlowReportQuery,
  useApiPostUpdateCalculationModelVersionMutation,
  useApiPostUpdateUserEmailMutation,
  useApiPostUpdateUserLanguageMutation,
  useApiPostUpdateUserNameMutation,
  useApiPostUpdatePasswordMutation,
  useApiPostCheckSessionMutation,
  useApiGetDevelopmentCommandListQuery,
  useApiGetComparisonReportQuery,
  useApiGetComparisonExcelReportQuery,
  useApiPostReviewInvoiceWorkflowTaskMutation,
  useApiGetImportContractFileTemplateQuery,
  useApiGetContractReportQuery,
  useApiAdminPostConvertContractsMutation,
  useApiPostCopyAuthRoleMutation,
  useApiPostCreateCalculationModelCopyMutation,
  useApiPostCopyContractMutation,
  useApiPostCreateProjectCopyMutation,
  useApiPostCopyProjectInvoiceCalculationSchemeMutation,
  useApiAdminPostCopyProjectToTenantMutation,
  useApiPostCopyTenantInvoiceCalculationSchemeMutation,
  useApiPostCopyTenantInvoiceCalculationSchemeToProjectMutation,
  useApiAdminPostCopyWorkflowMutation,
  useApiGetImportCostElementFileTemplateQuery,
  useApiPostAssignableWorkflowActivitiesMutation,
  useApiGetAssignableWorkflowActivitiesQuery,
  useApiPostCreateAuthGroupMutation,
  useApiGetAuthGroupsQuery,
  useApiPostCreateTaxonomyMutation,
  useApiGetTaxonomyQuery,
  useApiPostCreateCalculationModelDeliveryPhasesMutation,
  useApiGetCalculationModelDeliveryPhasesQuery,
  useApiPostCreateCalculationModelMilestonesMutation,
  useApiGetCalculationModelMilestonesQuery,
  useApiPostCreateCalculationModelSnapshotMutation,
  useApiCreateCatalogElementMutation,
  useApiDeleteCatalogElementMutation,
  useApiUpdateCatalogElementMutation,
  useApiCreateCatalogMutation,
  useApiDeleteCatalogMutation,
  useApiUpdateCatalogMutation,
  useApiPostCreateCatalogTemplateMutation,
  useApiGetCatalogTemplatesQuery,
  useApiPostCreateClusterItemRequirementDocumentsMutation,
  useApiPostCreateClusterItemRequirementMutation,
  useApiPostCreateCommitmentMutation,
  useApiGetCommitmentsQuery,
  useApiPostCreateCompanyBranchMutation,
  useApiPostCreateCompanyMutation,
  useApiPostCreatePrivatePersonMutation,
  useApiPostCreateCompanyPersonMutation,
  useApiPostCreateContractMutation,
  useApiGetContractsQuery,
  useApiPostCreateContractTitleDocumentMutation,
  useApiGetContractTitleDocumentsQuery,
  useApiPostCreateContractTitleMutation,
  useApiPostCreateCostElementDocumentMutation,
  useApiPostCreateCostElementMutation,
  useApiPostCreateCostElementTagMutation,
  useApiPostCreateDefaultValuesMutation,
  useApiGetDefaultValuesQuery,
  useApiPostCreateDeliveryPhaseCatalogMutation,
  useApiPostCreateDeliveryPhaseMutation,
  useApiGetCatalogsDeliveryPhasesQuery,
  useApiPostCreateEarningsElementDocumentMutation,
  useApiPostCreateEarningsElementMutation,
  useApiPostCreateFinancingElementDocumentMutation,
  useApiPostCreateFinancingElementMutation,
  useApiPostCreateInvoiceByContractMutation,
  useApiPostCreateInvoiceByContractWithSourceDocumentMutation,
  useApiPostCreateInvoiceCalculationRuleMutation,
  useApiPostCreateInvoiceCalculationRuleGroupMutation,
  useApiPostCreateInvoiceDocumentsMutation,
  useApiPostCreateInvoiceMutation,
  useApiGetInvoicesQuery,
  useApiPostCreateInvoiceSourceDocumentMutation,
  useApiPostCreateInvoiceTitleMutation,
  useApiPostCreateInvoiceWithSourceDocumentMutation,
  useApiAdminCreateKlippaConfigurationsMutation,
  useApiAdminGetKlippaConfigurationsQuery,
  useApiAdminPostCreateLicenseMutation,
  useApiAdminPostCreateLicenseGrantMutation,
  useApiPostCreateLocalLawCostElementMutation,
  useApiPostCreatePlotMutation,
  useApiGetPlotsByCalculationModelQuery,
  useApiPostCreateProjectCalculationRuleMutation,
  useApiPostCreateProjectCalculationRuleGroupMutation,
  useApiPostCreateEuriborRateProjectionMutation,
  useApiPostCreateProjectMutation,
  useApiGetProjectsQuery,
  useApiPostCreateProjectInvoiceCalculationSchemeMutation,
  useApiGetProjectInvoiceCalculationSchemesQuery,
  useApiPostCreateProjectConstructionPhaseMutation,
  useApiPostCreateProjectStatusMutation,
  useApiPostCreateProjectTagMutation,
  useApiPostCreateProjectVatMutation,
  useApiPostProjectValuationMutation,
  useApiPostCreateRentDealMutation,
  useApiGetRentDealsQuery,
  useApiPostCreateRiskElementDocumentMutation,
  useApiPostCreateRiskElementMutation,
  useApiPostCreateSellDealMutation,
  useApiGetSellDealsQuery,
  useApiPostCreateTenantCalculationRuleMutation,
  useApiPostCreateTenantCalculationRuleGroupMutation,
  useApiAdminPostCreateTenantMutation,
  useApiPostCreateTenantInvoiceCalculationSchemeMutation,
  useApiGetTenantInvoiceCalculationSchemesQuery,
  useApiPostCreateTenantUserDefinedFieldDefinitionMutation,
  useApiGetUserDefinedFieldsDefinitionQuery,
  useApiAdminPostCreateUserMutation,
  useApiPostCreateVatCostElementMutation,
  useApiPostCreateProjectVersionMutation,
  useApiGetCrossTenantAvatarQuery,
  useApiPostCrossTenantUploadAiEvalDocumentMutation,
  useApiGetExchangeDataImportsQuery,
  useApiGetExchangeDataHasNewImportsQuery,
  useApiPostExchangeDataProcessImportMutation,
  useApiAdminPostDeactivateLicenseGrantMutation,
  useApiDeleteAiEvalDocumentMutation,
  useApiGetAiEvalResultQuery,
  useApiDeleteAuthGroupMutation,
  useApiPostUpdateAuthGroupMutation,
  useApiDeleteAuthRoleMutation,
  useApiPostUpdateAuthRoleMutation,
  useApiDeleteCalculationModelDeliveryPhasesMutation,
  useApiPostUpdateCalculationModelDeliveryPhasesMutation,
  useApiDeleteCalculationModelMutation,
  useApiGetCalculationModelQuery,
  useApiDeleteCalculationModelMilestonesMutation,
  useApiPostUpdateCalculationModelMilestonesMutation,
  useApiDeleteCatalogTemplateMutation,
  useApiGetCatalogTemplateQuery,
  useApiPostUpdateCatalogTemplateMutation,
  useApiDeleteClusterItemRequirementDocumentMutation,
  useApiGetClusterItemRequirementDocumentQuery,
  useApiDeleteClusterItemRequirementMutation,
  useApiGetClusterItemRequirementQuery,
  useApiPostUpdateClusterItemRequirementMutation,
  useApiDeleteCommitmentMutation,
  useApiGetCommitmentQuery,
  useApiPostUpdateCommitmentMutation,
  useApiDeleteCompanyBranchMutation,
  useApiPostUpdateCompanyBranchMutation,
  useApiDeleteCompanyMutation,
  useApiGetTenantCompanyQuery,
  useApiDeleteCompanyPersonMutation,
  useApiPostUpdateCompanyPersonMutation,
  useApiDeleteContractDocumentMutation,
  useApiGetContractDocumentQuery,
  useApiDeleteContractMutation,
  useApiGetContractQuery,
  useApiPostUpdateContractMutation,
  useApiDeleteContractTitleDocumentMutation,
  useApiGetContractTitleDocumentQuery,
  useApiDeleteContractTitleMutation,
  useApiPostUpdateContractTitleMutation,
  useApiDeleteCostElementDocumentMutation,
  useApiGetCostElementDocumentQuery,
  useApiDeleteCostElementMutation,
  useApiGetCostElementQuery,
  useApiPostUpdateCostElementMutation,
  useApiDeleteCostElementTagMutation,
  useApiPostUpdateCostElementTagMutation,
  useApiDeleteDefaultValuesMutation,
  useApiPostUpdateDefaultValuesMutation,
  useApiDeleteDeliveryPhaseCatalogMutation,
  useApiGetDeliveryPhaseCatalogQuery,
  useApiDeleteEarningsElementDocumentMutation,
  useApiGetEarningsElementDocumentQuery,
  useApiDeleteEarningsElementMutation,
  useApiGetEarningsElementQuery,
  useApiPostUpdateEarningsElementMutation,
  useApiDeleteEuriborRateProjectionMutation,
  useApiPostUpdateEuriborRateProjectionMutation,
  useApiDeleteFinancingElementDocumentMutation,
  useApiGetFinancingElementDocumentQuery,
  useApiDeleteFinancingElementMutation,
  useApiGetFinancingElementQuery,
  useApiPostUpdateFinancingElementMutation,
  useApiDeleteTenantInviteMutation,
  useApiDeleteInvoiceByContractMutation,
  useApiPostUpdateInvoiceByContractMutation,
  useApiDeleteInvoiceCalculationRuleMutation,
  useApiPostUpdateInvoiceCalculationRuleMutation,
  useApiDeleteInvoiceCalculationRuleGroupMutation,
  useApiPostUpdateInvoiceCalculationRuleGroupMutation,
  useApiDeleteInvoiceDocumentMutation,
  useApiGetInvoiceDocumentQuery,
  useApiDeleteInvoiceMutation,
  useApiGetInvoiceQuery,
  useApiPostUpdateInvoiceMutation,
  useApiDeleteInvoiceTitleMutation,
  useApiPostUpdateInvoiceTitleMutation,
  useApiAdminDeleteLicenseMutation,
  useApiAdminGetLicensesQuery,
  useApiAdminDeleteLicenseGrantMutation,
  useApiDeletePlotMutation,
  useApiGetPlotQuery,
  useApiPostUpdatePlotMutation,
  useApiDeletePlotItemFileMutation,
  useApiGetPlotItemFileQuery,
  useApiDeleteProjectCalculationRuleMutation,
  useApiPostUpdateProjectCalculationRuleMutation,
  useApiDeleteProjectCalculationRuleGroupMutation,
  useApiPostUpdateProjectCalculationRuleGroupMutation,
  useApiDeleteProjectMutation,
  useApiGetProjectQuery,
  useApiDeleteTenantProjectInviteMutation,
  useApiDeleteProjectInvoiceCalculationSchemeMutation,
  useApiPostUpdateProjectInvoiceCalculationSchemeMutation,
  useApiDeleteProjectConstructionPhaseMutation,
  useApiPostUpdateProjectConstrutionPhaseMutation,
  useApiDeleteProjectStatusMutation,
  useApiPostUpdateProjectStatusMutation,
  useApiDeleteProjectTagMutation,
  useApiDeleteProjectVatMutation,
  useApiPostUpdateProjectVatMutation,
  useApiDeleteRentDealContactMutation,
  useApiPostUpdateRentDealContactMutation,
  useApiDeleteRentDealContractItemMutation,
  useApiPostUpdateRentDealContractItemMutation,
  useApiDeleteRentDealCostItemMutation,
  useApiPostUpdateRentDealCostItemMutation,
  useApiDeleteRentDealDocumentMutation,
  useApiGetRentDealDocumentQuery,
  useApiDeleteRentDealMutation,
  useApiPostUpdateRentDealMutation,
  useApiDeleteRiskElementDocumentMutation,
  useApiGetRiskElementDocumentQuery,
  useApiDeleteRiskElementMutation,
  useApiGetRiskElementQuery,
  useApiPostUpdateRiskElementMutation,
  useApiDeleteSellDealContactMutation,
  useApiPostUpdateSellDealContactMutation,
  useApiDeleteSellDealContractItemMutation,
  useApiPostUpdateSellDealContractItemMutation,
  useApiDeleteSellDealCostItemMutation,
  useApiPostUpdateSellDealCostItemMutation,
  useApiDeleteSellDealDocumentMutation,
  useApiGetSellDealDocumentQuery,
  useApiDeleteSellDealMutation,
  useApiPostUpdateSellDealMutation,
  useApiDeleteTaxonomyMutation,
  useApiPostUpdateTaxonomyMutation,
  useApiDeleteTaxonomyItemFileMutation,
  useApiGetTaxonomyItemFileQuery,
  useApiDeleteTenantCalculationRuleMutation,
  useApiPostUpdateTenantCalculationRuleMutation,
  useApiDeleteTenantCalculationRuleGroupMutation,
  useApiPostUpdateTenantCalculationRuleGroupMutation,
  useApiDeleteTenantInvoiceCalculationSchemeMutation,
  useApiPostUpdateTenantInvoiceCalculationSchemeMutation,
  useApiDeleteTenantUserDefinedFieldDefinitionMutation,
  useApiPostUpdateTenantUserDefinedFieldDefinitionMutation,
  useApiGetDetailReportQuery,
  useApiGetDetailReportAxaQuery,
  useApiPostTriggerJobMutation,
  useApiPostDuplicateTaxonomyMutation,
  useApiAdminPostEditSubdomainMutation,
  useApiGetErrorCodesQuery,
  useApiGetCalculationModelExcelQuery,
  useApiGetImportFileTemplateQuery,
  useApiGetForecastReportQuery,
  useApiGetForecastExcelReportQuery,
  useApiPostForgotPasswordMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
  useApiPostGenerateNextTenantObjectCodeMutation,
  useApiGetAiEvalDocumentsQuery,
  useApiPostUploadAiEvalDocumentMutation,
  useApiGetAllTasksQuery,
  useApiGetAssemblyVersionQuery,
  useApiGetAuditLogsByKeyQuery,
  useApiGetAuditLogsByTargetQuery,
  useApiGetAuthPermissionDefinitionsQuery,
  useApiGetAuthSubjectsQuery,
  useApiPostCalculateAutomaticTimelineDistributionMutation,
  useApiGetCalculationModelCostsQuery,
  useApiGetCalculationModelEarningsQuery,
  useApiGetCalculationModelRisksQuery,
  useApiGetCalculationModelFinancingQuery,
  useApiGetCalculationModelExportQuery,
  useApiGetCalculationModelHistoryQuery,
  useApiGetCatalogElementCodeQuery,
  useApiGetClusterItemsQuery,
  useApiGetClusterItemsTreeQuery,
  useApiGetCompanyLogoQuery,
  useApiGetCatalogQuery,
  useApiGetCatalogsQuery,
  useApiGetCostElementsDistributionMutation,
  useApiGetCrossTenantContractsQuery,
  useApiGetCrossTenantProjectCoverQuery,
  useApiGetAiEvalDocumentFileQuery,
  useApiPostReprocessAiEvalDocumentMutation,
  useApiGetEarningsCatalogQuery,
  useApiGetEarningsCatalogsQuery,
  useApiGetEffectiveTimelineDistributionMutation,
  useApiGetEmissionYearlyDistributionsQuery,
  useApiGetEuriborRatesQuery,
  useApiGetFinancingCatalogQuery,
  useApiGetFinancingCatalogsQuery,
  useApiGetFinancingElementsDistributionSuggestionMutation,
  useApiGetFinancingElementsMetadataQuery,
  useApiGetFinancingPurposeOverviewQuery,
  useApiGetUserInvitationQuery,
  useApiGetTenantInviteQuery,
  useApiGetInvoiceCalculationSampleQuery,
  useApiGetInvoiceReportQuery,
  useApiGetInvoiceWorkflowDetailQuery,
  useApiGetJobRouterPdfQuery,
  useApiGetLicenseFeaturesQuery,
  useApiAdminGetLicenseFeaturesQuery,
  useApiGetLicenseGrantsQuery,
  useApiAdminGetLicenseGrantsQuery,
  useApiGetPasswordResetTokenQuery,
  useApiGetPlainAuthGroupQuery,
  useApiGetPlainAuthRolesQuery,
  useApiGetPlainAuthSubjectsQuery,
  useApiGetPlotItemThumbnailQuery,
  useApiGetPortfolioQuery,
  useApiGetPortfolioNextRequirementCodeQuery,
  useApiGetPortfoliosQuery,
  useApiGetProjectCompaniesQuery,
  useApiGetProjectCompanyBranchesQuery,
  useApiGetProjectCoverQuery,
  useApiGetProjectGroupCoverQuery,
  useApiGetProjectGroupQuery,
  useApiDeleteProjectGroupMutation,
  useApiGetProjectReadableCostCatalogQuery,
  useApiGetProjectReadableEarningsCatalogQuery,
  useApiGetProjectReadableFinancingCatalogQuery,
  useApiGetProjectReadableRiskCatalogQuery,
  useApiProjectGetUserDefinedFieldsDefinitionByElementQuery,
  useApiProjectGetUserDefinedFieldsDefinitionQuery,
  useApiGetProjectWritableCostCatalogQuery,
  useApiGetProjectWritableEarningsCatalogQuery,
  useApiGetProjectWritableFinancingCatalogQuery,
  useApiGetProjectWritableRiskCatalogQuery,
  useApiPostCalculateRecurringPaymentDistributionMutation,
  useApiGetRegionEmissionFactorsByRegionQuery,
  useApiGetRiskCatalogQuery,
  useApiGetRiskCatalogsQuery,
  useApiGetSelectableCommitmentsQuery,
  useApiGetSelectableContractsQuery,
  useApiGetRentDealAvailableTaxonomyQuery,
  useApiGetSellDealAvailableTaxonomyQuery,
  useApiGetTaxonomyItemThumbnailQuery,
  useApiGetTaxonomyWithCostItemsQuery,
  useApiAdminGetTenantAuthProjectsQuery,
  useApiAdminGetTenantAuthSubjectsQuery,
  useApiGetTenantCatalogsQuery,
  useApiGetTenantCompaniesQuery,
  useApiAdminGetTenantsQuery,
  useApiGetTenantTrustsQuery,
  useApiGetUserDefinedFieldsDefinitionByElementQuery,
  useApiGetUserTenantsQuery,
  useApiGetUserWorkflowTasksQuery,
  useApiPostEarningsElementValuationMutation,
  useApiGetVatReturnDistributionSuggestionMutation,
  useApiGetWorkflowsQuery,
  useApiAdminGetTenantWorkflowsQuery,
  useApiPostCatalogImportFileMutation,
  useApiPostCatalogImportDataMutation,
  useApiPostImportContractFileMutation,
  useApiPostImportCostElementsFileMutation,
  useApiPostImportInvoiceFileMutation,
  useApiPostCompaniesImportFileMutation,
  useApiPostCompanyImportDataMutation,
  useApiPostInitializeTaxonomyMutation,
  useApiPostInviteSetEnabledMutation,
  useApiPostInviteSignupMutation,
  useApiPostCreateUserInvitationMutation,
  useApiGetImportInvoiceFileTemplateQuery,
  useApiPostLockFinancingElementMutation,
  useApiPostLoginMutation,
  useApiPostLogoutSessionsMutation,
  useApiPostLogoutMutation,
  useApiMoveCatalogElementMutation,
  useApiPostMoveInvoiceCalculationRuleMutation,
  useApiPostMoveInvoiceCalculationRuleGroupMutation,
  useApiPostMoveProjectCalculationRuleMutation,
  useApiPostMoveProjectCalculationRuleGroupMutation,
  useApiPostMoveTaxonomyMutation,
  useApiPostMoveTenantCalculationRuleMutation,
  useApiPostMoveTenantCalculationRuleGroupMutation,
  useApiPostParseContractFileMutation,
  useApiPostParseCostElementFileMutation,
  useApiPostParseInvoiceFileMutation,
  useApiPostPayContractMutation,
  useApiPostPayInvoiceMutation,
  useApiPostProjectGroupCoverMutation,
  useApiDeleteProjectGroupCoverMutation,
  useApiPostGroupProjectsMutation,
  useApiDeleteProjectsFromGroupMutation,
  useApiPostCreateProjectGroupMutation,
  useApiPatchProjectGroupMutation,
  useApiPostQuickCreateCompanyMutation,
  useApiRemoveBranchFormCompanyPersonMutation,
  useApiPostReopenContractMutation,
  useApiPostReopenInvoiceMutation,
  useApiPostReopenRentDealMutation,
  useApiPostReopenSellDealMutation,
  useApiPostResetPasswordMutation,
  useApiPostSearchMutation,
  useApiGetSentrySentryQuery,
  useApiGetSentryExceptionQuery,
  useApiPostSettleContractMutation,
  useApiStartInvoiceWorkflowMutation,
  useApiPostTakeoverInvoiceByContractMutation,
  useApiPostUpdateTenantBillingAddressMutation,
  useApiPostCreateTenantAvatarMutation,
  useApiDeleteTenantAvatarMutation,
  useApiGetTenantAvatarQuery,
  useApiPostInviteTenantMutation,
  useApiPostUpdateTenantNamesMutation,
  useApiPostUpdateTenantOwnerMutation,
  useApiPostReinviteTenantMutation,
  useApiPostTestApiAccessMutation,
  useApiPostUnlockFinancingElementMutation,
  useApiPostUpdateAssignableWorkflowActivitiesMutation,
  useApiPostUpdateAuthSubjectMembershipMutation,
  useApiPostUpdateAuthSubjectMutation,
  useApiPostUpdateAutomaticWorkflowFlagMutation,
  useApiPostUpdateCalculationModelCashFlowSettingsMutation,
  useApiPostUpdateCaluclationModelFavoriteMutation,
  useApiPostUpdateCalculationModelKpisMutation,
  useApiPostUpdateCalculationModelMetadataMutation,
  useApiPostUpdateClusterItemRequirementStatusMutation,
  useApiPostUpdateCompanyMutation,
  useApiPostUpdatePrivatePersonMutation,
  useApiPostContractDocumentsMutation,
  useApiPostUpdateCostElementExtensionSettingsMutation,
  useApiPostUpdateInvoiceTitleByContractTitleMutation,
  useApiAdminUpdateKlippaConfigurationsMutation,
  useApiAdminPostUpdateLicenseMutation,
  useApiPostUpdateLicenseGrantMutation,
  useApiAdminPostUpdateLicenseGrantMutation,
  useApiPostUpdateLocalLawCostElementMutation,
  usePostApiDataUpdateProjectByIdMutation,
  useApiPostUpdateProjectMetadataMutation,
  useApiPostUpdateRentDealConditionMutation,
  useApiPostUpdateVatElementMutation,
  useApiPostUploadPlotFileMutation,
  useApiPostUploadProjectCoverMutation,
  useApiPostUploadTaxonomyFileMutation,
  useApiPostCreateUserAvatarMutation,
  useApiDeleteUserAvatarMutation,
  useApiGetUserAvatarQuery,
  useApiPostValidateIbanMutation,
} = injectedRtkApi;
