import { DocumentViewerFileDataInlineEdit, NumberInput, PercentageIcon } from '@client/shared/toolkit';
import { getIcsDeductionName, ProjectTaxPickerInput } from '@client/project/shared';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalculationRuleReadModel, CalculationRuleShortReadModel,
  CalculationRuleValueOverridePayload,
  CalculationRuleValueOverrideReadModel
} from '@client/shared/api';

interface ContractIcsRulesProps {
  rules: CalculationRuleReadModel[]
  overrideValues: CalculationRuleValueOverridePayload[]
  updateOverrideValues: (overrideValues: CalculationRuleValueOverridePayload[]) => void
  originalOverrideValues: CalculationRuleValueOverrideReadModel[]
}

export const ContractIcsRules = (props: ContractIcsRulesProps) => {
  const {
    rules,
    overrideValues,
    updateOverrideValues,
    originalOverrideValues
  } = props

  return (
    <div className="w-full flex flex-col gap-2">
      {rules.map((rule, i) => {
        return (
          <ContractIcsRuleEditForm
            rule={rule}
            key={`contract-ics-rule-${rule.calculationRuleId}`}
            overrideValues={overrideValues}
            updateOverrideValues={updateOverrideValues}
            originalOverrideValues={originalOverrideValues}
          />
        );
      })}
    </div>
  )
}

interface ContractIcsRuleEditFormProps {
  rule: CalculationRuleShortReadModel
  originalOverrideValues: CalculationRuleValueOverrideReadModel[]
  overrideValues: CalculationRuleValueOverridePayload[]
  updateOverrideValues: (overrideValues: CalculationRuleValueOverridePayload[]) => void
}

export const ContractIcsRuleEditForm = (props: ContractIcsRuleEditFormProps) => {
  const {
    rule,
    updateOverrideValues,
    originalOverrideValues,
    overrideValues
  } = props
  const {t} = useTranslation();

  const [allowChangeMode, setAllowChangeMode] = useState(true);

  const [overridePercentage, setOverridePercentage] = useState<undefined | null | number>(undefined);
  const [overrideValue, setOverrideValue] = useState<undefined | null | number>(undefined);
  const [overrideVat, setOverrideVat] = useState<undefined | null | number>(undefined);


  useEffect(() => {
    const indexOf = overrideValues.findIndex((ov) => ov.calculationRuleId === rule.calculationRuleId);
    if (indexOf >= 0) {
      const values = overrideValues[indexOf];
      setOverridePercentage(values.percentage);
      setOverrideValue(values.value);
      setOverrideVat(values.vat);
    }
  }, [overrideValues, rule.calculationRuleId]);

  const originalRuleValues = useMemo(() => {
    return originalOverrideValues.find((ov) => ov.calculationRule.calculationRuleId === rule.calculationRuleId);
  }, [originalOverrideValues, rule.calculationRuleId])

  const setOverrideValues = useCallback((factor: undefined | null | number, value: undefined | null | number, vat: undefined | null | number) => {
    const overrideValuesCopy = [...overrideValues];
    const indexOf = overrideValues.findIndex((ov) => ov.calculationRuleId === rule.calculationRuleId);

    if (factor !== rule.percentage || value !== rule.value || vat !== rule.vat) {
      // Override already overwritten values
      if (indexOf >= 0) {
        const oldValues = overrideValues[indexOf];
        overrideValuesCopy[indexOf] = {
          calculationRuleId: rule.calculationRuleId,
          percentage: factor !== rule.percentage ? factor : oldValues.percentage,
          value: value !== rule.value ? value : oldValues.value,
          vat: vat !== rule.vat ? vat : oldValues.vat,
        }
      } else { // add new override values
        overrideValuesCopy.push({
          calculationRuleId: rule.calculationRuleId,
          percentage: factor !== rule.percentage ? factor : undefined,
          value: value !== rule.value ? value : undefined,
          vat: vat !== rule.vat ? vat : undefined,
        })
      }

      updateOverrideValues(overrideValuesCopy)
    } else {
      // no values have been changed, so remove override value
      if (indexOf >= 0) {
        overrideValuesCopy.splice(indexOf, 1);
        updateOverrideValues(overrideValuesCopy);
      }
    }
  }, [overrideValues, rule.calculationRuleId, rule.percentage, rule.value, rule.vat, updateOverrideValues])

  const factorIsDisabled = useMemo(() => {
    if (rule.type === 'Discount') {
      return true;
    }
    return false;
  }, [rule.type]);

  const valueIsDisabled = useMemo(() => {
    if (rule.calculationRuleReferenceId) {
      return true;
    }
    return false;
  }, [rule.calculationRuleReferenceId])

  const vatIsDisabled = useMemo(() => {
    return valueIsDisabled
  }, [valueIsDisabled])

  return (
    <DocumentViewerFileDataInlineEdit
      marginX=""
      marginY="my-0"
      allowChangeMode={allowChangeMode}
      closeOnBlur={false}
      toggleContent={
        <>
          <span className="font-medium">
            {getIcsDeductionName(rule.name)}
          </span>
          <NumberInput
            className="mt-2"
            label={t('ics.deductionFactor')}
            icon={<PercentageIcon className="h-6 w-6" />}
            value={overridePercentage ?? rule.percentage ?? undefined}
            onChange={(factor) => {
              setOverrideValues(factor ?? undefined, overrideValue ?? rule.value ?? undefined, overrideVat ?? rule.vat ?? undefined);
            }}
            disabled={factorIsDisabled}
            decimalScale={4}
          />
          <NumberInput
            className="mt-0.5"
            label={t('ics.deductionValueAmount')}
            value={overrideValue ?? rule.value}
            onChange={(value) => {
              setOverrideValues(overridePercentage ?? rule.percentage ?? undefined, value ?? undefined, overrideVat ?? rule.vat ?? undefined);
            }}
            disabled={valueIsDisabled}
            decimalScale={2}
          />
          <ProjectTaxPickerInput
            isNullable
            nullLabel={t('ics.useTaxRateFromInvoice')}
            value={overrideVat ?? rule.vat ?? undefined}
            onChange={(vat) => {
              setOverrideValues(overridePercentage ?? rule.percentage ?? undefined, overrideValue ?? rule.value ?? undefined, vat ?? undefined);
            }}
            handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
            disabled={vatIsDisabled}
          />
        </>
      }
    >
      <div className="grid grid-cols-5 gap-2 text-right">
        <div className="py-2 col-span-2 text-left flex flex-col justify-between leading-tight">
          {getIcsDeductionName(rule.name)}
          {(typeof overridePercentage !== 'undefined' || typeof overrideValue !== 'undefined' || typeof overrideVat !== 'undefined') && (
            <span className="text-[11px] text-gray-400 mt-1">{t('ics.defaultValueLabel')}:</span>
          )}
        </div>
        <ContractIcsRuleEditFormValue
          defaultValue={rule.percentage}
          overrideValue={overridePercentage ?? undefined}
          highlight={(originalRuleValues && overridePercentage !== originalRuleValues.factor) || !originalRuleValues}
        />
        <ContractIcsRuleEditFormValue
          defaultValue={rule.value}
          overrideValue={overrideValue ?? undefined}
          highlight={(originalRuleValues && overrideValue !== originalRuleValues.value) || !originalRuleValues}
        />
        <ContractIcsRuleEditFormValue
          defaultValue={rule.vat}
          overrideValue={overrideVat ?? undefined}
          highlight={(originalRuleValues && overrideVat !== originalRuleValues.vat) || !originalRuleValues}
          emptyLabel={<span className="italic leading-tight text-xs">{t('ics.useTaxRateFromInvoice')}</span>}
        />
      </div>
    </DocumentViewerFileDataInlineEdit>
  )
}


interface ContractIcsRuleEditFormValueProps {
  defaultValue?: number | null
  overrideValue?: number | null
  highlight?: boolean
  emptyLabel?: ReactNode | string
}

export const ContractIcsRuleEditFormValue = (props: ContractIcsRuleEditFormValueProps) => {
  const {
    defaultValue,
    overrideValue,
    highlight = false,
    emptyLabel
  } = props
  return (
    <div className="py-2 flex flex-col justify-between leading-tight">
      {typeof overrideValue !== 'undefined' && overrideValue !== defaultValue ? (
        <>
          <span className={highlight ? 'text-secondary font-bold': ''}>{overrideValue}</span>
          <span className="text-[11px] text-gray-400 mt-1">{typeof defaultValue !== 'undefined' ? defaultValue : '-'}</span>
        </>
      ) : (
        <>{typeof defaultValue !== 'undefined' ? defaultValue : emptyLabel ? emptyLabel : '-'}</>
      )}
    </div>
  )
}
