import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import {
  BudgetAssignmentReadModel,
  CostCatalogElementDto,
  CostElementDto,
  CreateBudgetAssignmentPayload,
  UpdateBudgetAssignmentPayload,
  useApiGetCalculationModelCostsQuery,
} from '@client/shared/api';
import { SlideOverTitle, AddButton, LoadingIndicator } from '@client/shared/toolkit';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useFlattenCostElements } from '../../hooks';
import { findCostElement } from '../../utils';
import { BudgetAssignmentInput, CostElementMultiSelect, FormattedCurrency } from '..';
import cn from 'classnames';

const getAvailableBudget = (costElement: CostCatalogElementDto | CostElementDto) => {
  if (costElement && 'availableBudget' in costElement) {
    return costElement.availableBudget ?? 0;
  } else if (costElement && 'modelValues' in costElement && 'availableBudget' in costElement.modelValues) {
    return costElement.modelValues.availableBudget ?? 0;
  } else if (costElement && 'totalValue' in costElement) {
    return costElement.totalValue ?? 0;
  } else if (costElement && 'modelValues' in costElement) {
    return costElement.modelValues.total ?? costElement.modelValues.effectiveValue ?? 0;
  } else {
    return 0;
  }
};

interface BudgetAssignmentProps {
  budgetAssignments?: BudgetAssignmentReadModel[] | undefined;
  updateBudgetAssignments?: ({
    added,
    updated,
    deleted,
    totalBudget,
  }: {
    added: CreateBudgetAssignmentPayload[];
    updated: UpdateBudgetAssignmentPayload[];
    deleted: string[];
    totalBudget: number;
  }) => void;
  validBudget?: (valid: boolean) => void;
  canBeBudgeted?: boolean;
  disabled?: boolean;
  showTitle?: boolean;
  id?: string;
  setIsBudgetAssignmentPopoverOpen?: (isOpen: boolean) => void;
  cannotBeBudgetedMessage?: string | ReactNode;
  focused?: boolean;
  selectedBudget?: string;
}

interface ExtendedBudgetAssignmentPayload extends CreateBudgetAssignmentPayload {
  budgetAssignment?: BudgetAssignmentReadModel;
}

export const BudgetAssignment = ({
  budgetAssignments = [],
  updateBudgetAssignments,
  validBudget,
  canBeBudgeted = true,
  disabled = false,
  showTitle = true,
  setIsBudgetAssignmentPopoverOpen,
  cannotBeBudgetedMessage,
  focused = false,
  selectedBudget,
}: BudgetAssignmentProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: costData, isFetching } = useApiGetCalculationModelCostsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: !loadedVariantId || !loadedProjectId },
  );

  const [assignments, setAssignments] = useState<ExtendedBudgetAssignmentPayload[]>(
    budgetAssignments.map((x) => {
      return {
        costElementId: x.costElementId,
        budgetNet: x.budgetNet,
        budgetAssignment: x,
      };
    }),
  );

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popoverButtonRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end', 'left', 'auto'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const [budget, setBudget] = useState<number>(0);

  const flattenedCostElements = useFlattenCostElements(costData, true);

  const getCostElement = useCallback(
    (id: string) => {
      return findCostElement(id, flattenedCostElements);
    },
    [flattenedCostElements],
  );

  const getCostElementCode = (costElementId: string) => {
    const costElement = getCostElement(costElementId);
    if (costElement && 'code' in costElement) {
      return costElement.code ?? '';
    } else if (costElement && 'costCatalogCode' in costElement) {
      return costElement.costCatalogCode ?? '';
    } else return '';
  };

  const getCostElementDescription = (costElementId: string) => {
    const costElement = getCostElement(costElementId);
    if (costElement && 'description' in costElement) {
      return costElement.description === '' ? t('projectCalculate.unnamedElement') : costElement.description ?? '';
    } else return '';
  };

  const getParent = useCallback(
    (costElementId: string) => {
      const costElement = getCostElement(costElementId);
      if (costElement && 'costCatalogElementId' in costElement) {
        const parentId = flattenedCostElements?.find((x) =>
          x.costElements.some((y) => y.modelCostElement?.elementId === costElementId),
        )?.costElementId;

        return assignments.find((x) => x.costElementId === parentId);
      } else if (costElement && 'hasParent' in costElement && costElement.hasParent) {
        const parentId = flattenedCostElements?.find((x) => x.children.some((y) => y.costElementId === costElementId))
          ?.costElementId;

        return assignments.find((x) => x.costElementId === parentId);
      }
    },
    [assignments, flattenedCostElements, getCostElement],
  );

  const getSiblings = useCallback(
    (costElementId: string) => {
      const costElement = getCostElement(costElementId);
      const siblings = [];
      if (costElement && 'costCatalogElementId' in costElement) {
        const parent = flattenedCostElements?.find((x) =>
          x.costElements.some((y) => y.modelCostElement?.elementId === costElementId),
        );
        siblings.push(
          ...assignments.filter(
            (x) => parent?.costElements.some((y) => y.modelCostElement?.elementId === x.costElementId),
          ),
        );
        siblings.push(...assignments.filter((x) => parent?.children.some((y) => y.costElementId === x.costElementId)));
      } else if (costElement && 'hasParent' in costElement && costElement.hasParent) {
        const parent = flattenedCostElements?.find((x) => x.children.some((y) => y.costElementId === costElementId));

        siblings.push(
          ...assignments.filter(
            (x) => parent?.costElements.some((y) => y.modelCostElement?.elementId === x.costElementId),
          ),
        );
        siblings.push(...assignments.filter((x) => parent?.children.some((y) => y.costElementId === x.costElementId)));
      }
      return siblings;
    },
    [assignments, flattenedCostElements, getCostElement],
  );

  const budgetPercentage = (costElementId: string, budget: number) => {
    const costElement: CostElementDto | CostCatalogElementDto | undefined = getCostElement(costElementId);
    if (costElement) {
      const availableBudget = getAvailableBudget(costElement);
      const savedBudget = budgetAssignments.find((x) => x.costElementId === costElementId)?.budgetNet ?? 0;
      return budget / (availableBudget + savedBudget === 0 ? 1 : availableBudget + savedBudget);
    } else return 0;
  };

  useEffect(() => {
    setBudget(
      assignments.reduce((sum, budgetAssignment) => {
        return sum + budgetAssignment.budgetNet;
      }, 0),
    );
  }, [assignments]);

  useEffect(() => {
    if (validBudget) {
      validBudget(
        disabled ||
          !assignments.some((budgetAssignment) => {
            const elementBudgetPercentage = budgetPercentage(
              budgetAssignment.costElementId,
              budgetAssignment.budgetNet,
            );
            const parent = getParent(budgetAssignment.costElementId);
            const siblings = getSiblings(budgetAssignment.costElementId);
            const siblingSavedBudget = budgetAssignments
              .filter((x) => siblings.map((y) => y.costElementId).includes(x.costElementId))
              .reduce((sum, x) => sum + x.budgetNet, 0);
            const parentAndSiblingBudgetPercentage = budgetPercentage(
              parent?.costElementId ?? '',
              siblings.reduce((sum, x) => sum + x.budgetNet, 0) - siblingSavedBudget + (parent?.budgetNet ?? 0),
            );
            return parent !== undefined
              ? parentAndSiblingBudgetPercentage > 1 || parentAndSiblingBudgetPercentage < 0
              : elementBudgetPercentage > 1 || elementBudgetPercentage < 0;
          }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments, disabled, validBudget, budget]);

  useEffect(() => {
    updateBudgetAssignments?.({
      added: assignments.filter((x) => !budgetAssignments.find((y) => y.costElementId === x.costElementId)),
      updated: assignments
        .filter((x) =>
          budgetAssignments.find((y) => y.costElementId === x.costElementId && y.budgetNet !== x.budgetNet),
        )
        .map((x) => {
          const budgetAssignment = budgetAssignments.find((y) => y.costElementId === x.costElementId);
          return {
            budgetAssignmentId: budgetAssignment?.id ?? '',
            budgetNet: x.budgetNet,
          };
        }),
      deleted: budgetAssignments
        .filter((x) => !assignments.find((y) => y.costElementId === x.costElementId))
        .map((x) => x.id),
      totalBudget: budget,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments, budget]);

  useEffect(() => {
    if (setIsBudgetAssignmentPopoverOpen && popperElement) {
      setIsBudgetAssignmentPopoverOpen(true);
    } else if (setIsBudgetAssignmentPopoverOpen && !popperElement) {
      setIsBudgetAssignmentPopoverOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  return (
    <>
      {isFetching && <LoadingIndicator text={t('projectContract.budgetingLoadingCostElements') ?? ''} mode="overlay" />}

      {showTitle && <SlideOverTitle title={t('projectContract.budgeting')} />}
      {canBeBudgeted ? (
        <>
          <div
            className={cn('mt-0 bg-white relative', {
              'min-h-[56px] before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10':
                !assignments.length,
              'before:opacity-1': focused && !assignments.length,
              'before:opacity-0': !focused && !assignments.length,
            })}
          >
            <div
              className={classNames(
                'divide-slate-300 divide-y-2 relative before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10',
                {
                  'border-b-2': assignments.length > 0,
                  'before:opacity-1': focused,
                  'before:opacity-0': !focused,
                },
              )}
            >
              {assignments
                .sort((a, b) => getCostElementCode(a.costElementId).localeCompare(getCostElementCode(b.costElementId)))
                .map((budgetAssignment, i) => {
                  const elementBudgetPercentage = budgetPercentage(
                    budgetAssignment.costElementId,
                    budgetAssignment.budgetNet,
                  );
                  const parent = getParent(budgetAssignment.costElementId);
                  const siblings = getSiblings(budgetAssignment.costElementId);
                  const siblingSavedBudget = budgetAssignments
                    .filter((x) => siblings.map((y) => y.costElementId).includes(x.costElementId))
                    .reduce((sum, x) => sum + x.budgetNet, 0);
                  const parentAndSiblingBudgetPercentage = budgetPercentage(
                    parent?.costElementId ?? '',
                    siblings.reduce((sum, x) => sum + x.budgetNet, 0) - siblingSavedBudget + (parent?.budgetNet ?? 0),
                  );

                  return (
                    <BudgetAssignmentInput
                      key={`budgetAssignment-${i}`}
                      name={getCostElementDescription(budgetAssignment.costElementId)}
                      code={getCostElementCode(budgetAssignment.costElementId)}
                      budgetPercentage={
                        parent !== undefined
                          ? budgetPercentage(parent?.costElementId ?? '', budgetAssignment.budgetNet)
                          : elementBudgetPercentage
                      }
                      hasFormula={getCostElement(budgetAssignment.costElementId)?.hasFormula ?? false}
                      disabled={disabled}
                      value={budgetAssignment.budgetNet}
                      onChange={(value) => {
                        setAssignments((prev) => {
                          return prev.map((x) => {
                            if (x.costElementId === budgetAssignment.costElementId) {
                              return { ...x, budgetNet: value };
                            } else return x;
                          });
                        });
                      }}
                      isValidationValid={
                        parent !== undefined
                          ? parentAndSiblingBudgetPercentage <= 1 && parentAndSiblingBudgetPercentage >= 0
                          : elementBudgetPercentage <= 1 && elementBudgetPercentage >= 0
                      }
                      showValidation={
                        !disabled &&
                        (parent !== undefined
                          ? parentAndSiblingBudgetPercentage > 1 || parentAndSiblingBudgetPercentage < 0
                          : elementBudgetPercentage > 1 || elementBudgetPercentage < 0)
                      }
                      onDelete={() => {
                        setAssignments((prev) => {
                          return prev.filter((x) => x.costElementId !== budgetAssignment.costElementId);
                        });
                      }}
                      active={
                        !!selectedBudget &&
                        !!budgetAssignment.budgetAssignment?.id &&
                        selectedBudget === budgetAssignment.budgetAssignment?.id
                      }
                    />
                  );
                })}
            </div>
          </div>
          {!disabled && (
            <Popover>
              <div className="flex w-full justify-end items-center z-50 relative -mt-3 mr-4">
                <PopoverButton>
                  <div ref={setTargetElement}>
                    <div ref={popoverButtonRef}>
                      <AddButton />
                    </div>
                  </div>
                </PopoverButton>
              </div>
              <PopoverPanel
                portal
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
                className="z-20 flex h-[520px] w-[600px] truncate bg-gray-100 p-4 shadow-lg rounded"
              >
                <CostElementMultiSelect
                  costData={costData}
                  costElements={assignments.map(
                    (x) =>
                      flattenedCostElements?.find((y) => y.costElementId === x.costElementId)?.id ?? x.costElementId,
                  )}
                  budgetAssignments={budgetAssignments}
                  updateCostElements={(elementIds) => {
                    const costElementIds = elementIds.map(
                      (x) => flattenedCostElements?.find((y) => y.id === x)?.costElementId ?? x,
                    );

                    const added: CreateBudgetAssignmentPayload[] = [];
                    const deleted: string[] = [];

                    assignments.forEach((budgetAssignment) => {
                      if (!costElementIds.includes(budgetAssignment.costElementId)) {
                        deleted.push(budgetAssignment.costElementId);
                      }
                    });

                    costElementIds.forEach((costElementId) => {
                      if (!assignments.find((x) => x.costElementId === costElementId)) {
                        const costElement = getCostElement(costElementId);
                        added.push({
                          costElementId,
                          budgetNet: costElement ? getAvailableBudget(costElement) : 0,
                        });
                      }
                    });

                    setAssignments((prev) => {
                      return [...prev.filter((x) => !deleted.includes(x.costElementId)), ...added];
                    });
                  }}
                  showBudget
                  showFx
                  showControls
                  onlyWithCostElementId
                  onClose={() => popoverButtonRef.current?.click()}
                />
              </PopoverPanel>
            </Popover>
          )}
          <div className="flex flex-col items-end pr-12 -mt-3 pt-2 pb-4">
            <div className="border-y-2 border-slate-300 h-2 w-44 max-w-full mb-2" />
            <div className="text-xs text-slate-600">{t('projectContract.budgetSum')}</div>
            <div className="text-2xl font-bold">
              <FormattedCurrency amount={budget} />
            </div>
          </div>
        </>
      ) : (
        <div className="w-full flex items-center text-xs text-slate-600 mb-6">
          {cannotBeBudgetedMessage ? cannotBeBudgetedMessage : t('projectContract.connectedElementBudgeted')}
        </div>
      )}
    </>
  );
};
