import * as yup from 'yup';
import {InferType} from 'yup';
import { CalculationRuleGroupType, CalculationRuleType, InvoiceCalculationSchemeType, ReferenceType } from '@client/shared/api';

export const IcsNewFormValidationSchema = yup.object({
  isDefault: yup.boolean().required('validation.required'),
  name: yup.string().required('validation.required'),
  description: yup.string().optional().nullable(),
  type: yup.mixed<InvoiceCalculationSchemeType>().oneOf(['Cumulated', 'Single', 'AdvancePayment']).required('validation.required'),
});
export type IcsNewFormValidationValues = InferType<typeof IcsNewFormValidationSchema>;


export const IcsGroupNewFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  type: yup.mixed<CalculationRuleGroupType>().required('validation.required')
});
export type IcsGroupNewFormValidationValues = InferType<typeof IcsGroupNewFormValidationSchema>;

export const IcsDeductionNewFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  type: yup.mixed<CalculationRuleType>().required('validation.required'),
  vat: yup.number().optional().nullable(),
  percentage: yup.number().optional().nullable(),
  referenceType: yup.mixed<ReferenceType>().nullable().optional(),
  selectedValue: yup.string().nullable().optional(),
  calculationRuleGroupReference:
    yup.string().nullable().optional().when(['referenceType', 'type'], ([referenceType, type], schema) => {
      if (referenceType === 'CalculationRuleGroup' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  calculationRuleReference:
    yup.string().nullable().optional().when(['referenceType', 'type'], ([referenceType, type], schema) => {
      if (referenceType === 'CalculationRule' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  partialInvoiceReferenceType: yup.mixed<ReferenceType>().nullable().optional(),
  selectedPartialInvoiceValue: yup.string().nullable().optional(),
  partialInvoiceCalculationRuleReference:
    yup.string().nullable().optional().when(['partialInvoiceReferenceType', 'type'], ([partialInvoiceReferenceType, type], schema) => {
      if (partialInvoiceReferenceType === 'CalculationRule' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  partialInvoiceCalculationRuleGroupReference:
    yup.string().nullable().optional().when(['partialInvoiceReferenceType', 'type'], ([partialInvoiceReferenceType, type], schema) => {
      if (partialInvoiceReferenceType === 'CalculationRuleGroup' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  partialFinalInvoiceReferenceType: yup.mixed<ReferenceType>().nullable().optional(),
  selectedPartialFinalInvoiceValue: yup.string().nullable().optional(),
  partialFinalInvoiceCalculationRuleReference:
    yup.string().nullable().optional().when(['partialFinalInvoiceReferenceType', 'type'], ([partialFinalInvoiceReferenceType, type], schema) => {
      if (partialFinalInvoiceReferenceType === 'CalculationRule' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  partialFinalInvoiceCalculationRuleGroupReference:
    yup.string().nullable().optional().when(['partialFinalInvoiceReferenceType', 'type'], ([partialFinalInvoiceReferenceType, type], schema) => {
      if (partialFinalInvoiceReferenceType === 'CalculationRuleGroup' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  finalInvoiceReferenceType: yup.mixed<ReferenceType>().nullable().optional(),
  selectedFinalInvoiceValue: yup.string().nullable().optional(),
  finalInvoiceCalculationRuleReference:
    yup.string().nullable().optional().when(['finalInvoiceReferenceType', 'type'], ([finalInvoiceReferenceType, type], schema) => {
      if (finalInvoiceReferenceType === 'CalculationRule' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  finalInvoiceCalculationRuleGroupReference:
    yup.string().nullable().optional().when(['finalInvoiceReferenceType', 'type'], ([finalInvoiceReferenceType, type], schema) => {
      if (finalInvoiceReferenceType === 'CalculationRuleGroup' && type !== 'Subtotal') {
        return yup.string().required('validation.required')
      }
      return yup.string().nullable().optional()
    }),
  group: yup.string().required('validation.required')
})
export type IcsDeductionNewFormValidationValues = InferType<typeof IcsDeductionNewFormValidationSchema>;
