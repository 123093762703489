import {
  ApiPostActiveApiAccessApiArg,
  ApiPostActiveApiAccessApiResponse,
  ApiPostApiAccessApiArg,
  ApiPostApiAccessApiResponse,
  ApiRemoveApiAccessApiArg,
  ApiRemoveApiAccessApiResponse, ApiUpdateApiAccessApiArg, ApiUpdateApiAccessApiResponse
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiExternalApiEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetExternalApis: {
    providesTags: [ApiTagTypes.ExternalApis],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostApiAccess: {
    invalidatesTags: (
      _result: ApiPostApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiRemoveApiAccess: {
    invalidatesTags: (
      _result: ApiRemoveApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiRemoveApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiUpdateApiAccess: {
    invalidatesTags: (
      _result: ApiUpdateApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiUpdateApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiPostActiveApiAccess: {
    invalidatesTags: (
      _result: ApiPostActiveApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostActiveApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
}
