export type SortHeaderType = {
  asc: boolean | null,
  label: string,
  type: 'date' | 'number' | 'string' | 'custom'
  value: string
}
export const sortByProperty = <T>(list: Array<T>, sortBy: SortHeaderType) => {
  return list.sort((a, b) => {
    let valA = 0
    let valB = 0

    switch (sortBy.type) {
      case 'number': {
        valA = a[sortBy.value as keyof T] as number
        valB = b[sortBy.value as keyof T] as number
        break
      }
      case 'date': {
        valA = new Date(a[sortBy.value as keyof T] as string).getTime()
        valB = new Date(b[sortBy.value as keyof T] as string).getTime()
        break
      }
      case 'string': {
        const stringA = a[sortBy.value as keyof T] as string
        const stringB = b[sortBy.value as keyof T] as string
        if (sortBy.asc) {
          return stringA.localeCompare(stringB)
        } else {
          return stringB.localeCompare(stringA)
        }
      }
      default: {
        valA = 0
        valB = 0
      }
    }
    if (sortBy.asc) {
      return valA - valB
    } else {
      return valB - valA
    }
  })
}
