import { useState, useEffect } from 'react';
import { useApiGetExternalApisQuery, ExternalApiReadModel } from '@client/shared/api';
import { Button, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { SlideOver } from '@client/shared/toolkit';
import { ApiSlideOver } from './ApiSlideOver';

interface MarketplaceProps {
  searchText: string | undefined;
}

export const Marketplace = ({ searchText }: MarketplaceProps) => {
  const { t } = useTranslation();
  const [isApiSlideOverOpen, setIsApiSlideOverOpen] = useState(false);
  const [selectedApi, setSelectedApi] = useState<ExternalApiReadModel | null>(null);
  const { data: externalApis, isFetching } = useApiGetExternalApisQuery();

  const selectApi = (api: ExternalApiReadModel) => {
    setSelectedApi(api);
    setIsApiSlideOverOpen(true);
  };

  useEffect(() => {
    if (selectedApi !== externalApis?.find((x) => x.api.id === selectedApi?.api.id))
      setSelectedApi(externalApis?.find((x) => x.api.id === selectedApi?.api.id) ?? null);
  }, [externalApis, selectedApi]);

  return (
    <div className="m-5 grid grid-cols-2">
      {isFetching === true && <LoadingIndicator text={t('app.settingsUsersLoading') ?? ''} />}
      {externalApis
        ?.filter((x) => x.api.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
        .map((item: ExternalApiReadModel, i) => (
          <div key={i} className="m-5 flex">
            <div className="h-28 w-28 border rounded-md flex justify-center items-center text-xs text-slate-600">
              {item.api.name}
            </div>
            <div className="pl-5 pt-2 h-20 w-full">
              <div className="font-bold text-lg">
                {item.api.name}
                {item.api.isActive && <span className="ml-1 text-sm text-green-600">{t('app.ConnectActive')}</span>}
              </div>
              <div className="text-sm text-slate-400">{item.api.description}</div>

              <Button
                variant={item.api.isEnabled ? 'primary' : 'secondary'}
                className="mt-3"
                onClick={() => (item.api.isEnabled ? selectApi(item) : undefined)}
                disabled={!item.api.isEnabled}
              >
                {t('app.ConnectSetup')}
              </Button>
            </div>
          </div>
        ))}
      <SlideOver isOpen={isApiSlideOverOpen} onClose={() => setIsApiSlideOverOpen(false)}>
        <ApiSlideOver api={selectedApi} onClose={() => setIsApiSlideOverOpen(false)} />
      </SlideOver>
    </div>
  );
};
