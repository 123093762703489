import { ProjectReadModel, useApiGetProjectQuery, UnitSystem } from '@client/shared/api';
import { ProtectedRoute, ROUTES_CONFIG, useValidateProjectPermission } from '@client/shared/permissions';
import { Button, LoadingIndicator, SlideOver, SkyscrapersDottedIcon } from '@client/shared/toolkit';
import { formatLargeNumber, formatPercentage, formatUnit } from '@client/shared/utilities';
import { CogIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectLocationContainer } from './ProjectLocation';
import { ProjectMetadata } from './ProjectMetadata';
import { ProjectDetails } from './ProjectDetails';
import { ReportKpi } from './ReportKpi';
import { useLoadedProjectId, useLoadedVariantId, useUnitSystem } from '@client/project/store';
import classNames from 'classnames';
import { FormattedCurrency, getProjectThumbnailByCoverId } from '@client/project/shared';
import { EditProjectSlideOver, ProjectSettings } from '@client/project/edit';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ProjectIdentifierSectionProps {
  companyName: string;
  projectName: string;
  projectId: string;
  onOpenProjectSettings: () => void;
  multiProject: boolean;
}

enum ModalNames {
  NONE,
  EDIT,
}

const ProjectIdentifierSection = (props: ProjectIdentifierSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className="absolute bottom-0 left-6 bg-gray-100 h-18 w-10/12 lg:w-1/2 2xl:w-1/3 p-3 pb-1 flex flex-auto justify-evenly rounded-t-md">
      <div className="w-full">
        <div>
          <span className="font-bold text-xl">{props.companyName}&nbsp;|&nbsp;</span>
          <span className="font-light text-xl">{props.projectName}</span>
        </div>
        <span className="font-light text-xs">
          {t('app.editProjectId')}: {props.projectId}
        </span>
      </div>
      {props.multiProject === false && (
        <Button
          variant="primary"
          onClick={props.onOpenProjectSettings}
          className="m-auto mx-2 flex items-center space-x-1"
        >
          <CogIcon className="w-5 h-5" />
          <div className="whitespace-nowrap">{t('project.projectSettingsAction')}</div>
        </Button>
      )}
    </div>
  );
};

const DashboardHero = ({ thumbnail, thumbnailAlt }: { thumbnail: string, thumbnailAlt: string }) => (
  <div className="rounded-none w-full h-full bg-white">
    <LazyLoadImage
      className="w-full h-full object-cover"
      alt={thumbnailAlt}
      src={thumbnail}
    />
  </div>
);

const ProjectIcon = () => (
  <div className="absolute top-8 right-12 bg-white shadow-md shadow-slate rounded-full h-12 w-12 flex items-center justify-center ml-5">
    <SkyscrapersDottedIcon className="h-7" />
  </div>
);

interface DashboardContainerProps {
  multiProject: boolean;
}

export const DashboardContainer = ({ multiProject }: DashboardContainerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const unitSystem = useUnitSystem();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const { data: projectData, isFetching: isFetchingProject } = useApiGetProjectQuery(
    {
      projectId: loadedProjectId || '',
      unitSystem: unitSystem as UnitSystem,
    },
    { skip: loadedProjectId == null },
  );

  const project = projectData?.project as ProjectReadModel;
  const activeVariant = project?.calculationModels?.find((c) => c.id === (loadedVariantId ?? ''));

  const thumbnail = getProjectThumbnailByCoverId(project?.payload.id ?? '', project?.payload.name ?? '', project?.payload.coverPictureId, false);
  const [openModal, setOpenModal] = useState<ModalNames>(ModalNames.NONE);
  const readOnly = !useValidateProjectPermission(['PROJECT_WRITE'], project?.payload.id ?? '');

  const handleCloseModal = () => {
    setOpenModal(ModalNames.NONE);
  };

  const metadata = {
    address: {
      city: project?.payload.city ?? '',
      number: project?.payload.number ?? '',
      postalCode: project?.payload.postalCode ?? '',
      street: project?.payload.street ?? '',
    },
    avgRentPerSqMtr: formatUnit(
      activeVariant?.averageRentUnitPrice.value || 0,
      activeVariant?.averageRentUnitPrice.unit ?? '',
    ),
    avgSalePerSqMtr: formatUnit(
      activeVariant?.averageSaleUnitPrice.value || 0,
      activeVariant?.averageSaleUnitPrice.unit ?? '',
    ),
    bgf: formatUnit(activeVariant?.grossFloorValue.value, activeVariant?.grossFloorValue.unit ?? ''),
    companyName: project?.payload.tenantName ?? '',
    deliveryPhasesCount: activeVariant?.deliveryPhasesCount || 0,
    deviation: formatLargeNumber(activeVariant?.deviation ?? 0) as string,
    deviationPercent: activeVariant?.deviationPercent.value || '-',
    endDate: activeVariant?.calculationModelEndDate || '',
    gfz: activeVariant?.gfz ?? 0,
    grz: activeVariant?.grz ?? 0,
    irr: formatUnit(activeVariant?.calculatedIrr.value || 0, activeVariant?.calculatedIrr.unit ?? ''),
    ownCapitalRatio: formatUnit(activeVariant?.ownCapitalRatio.value ?? 0, activeVariant?.ownCapitalRatio.unit ?? ''),
    plannedCostAndRisk: formatLargeNumber(activeVariant?.totalCostAndRisk.value ?? 0) as string,
    plannedEarnings: formatLargeNumber(activeVariant?.totalEarnings.value ?? 0) as string,
    plannedCostUnit: formatUnit(
      activeVariant?.grossFloorEfficiency.value ?? 0,
      activeVariant?.grossFloorEfficiency.unit ?? '',
      { maxDigits: 0 },
    ),
    plot: formatUnit(activeVariant?.plot.value, activeVariant?.plot.unit ?? ''),
    projectId: project?.payload.projectId ?? '',
    projectName: project?.payload.name ?? '',
    roe: formatPercentage(activeVariant?.roe.value ?? 0),
    roi: formatPercentage(activeVariant?.roi.value || 0),
    startDate: activeVariant?.calculationModelStartDate || '',
    totalProfit: <FormattedCurrency amount={activeVariant?.totalProfit.value ?? 0} />,
    usageType: {
      residence: formatPercentage(
        activeVariant?.taxonomyOverview.usagePercentages?.find((r) => r.type === 'Residential')?.percentage.value,
      ),
      work: formatPercentage(
        activeVariant?.taxonomyOverview.usagePercentages?.find((r) => r.type === 'Office')?.percentage.value,
      ),
      rest: formatUnit(
        Math.abs(
          (activeVariant?.taxonomyOverview.usagePercentages?.find((r) => r.type === 'Residential')?.percentage.value ??
            0) *
            100 +
            (activeVariant?.taxonomyOverview.usagePercentages?.find((r) => r.type === 'Office')?.percentage.value ??
              0) *
              100 -
            100,
        ),
        '%',
      ),
    },
    latitude: project?.payload.latitude,
    longitude: project?.payload.longitude,
    pois: project?.payload.pois ?? [],
    unitSystem: project?.payload.unitSystem,
  };

  return (
    <>
      <div className="h-full bg-gray-100">
        <div
          className={classNames(
            'w-full h-52 2xl:h-60 scale-100 overflow-hidden bg-white-100 border-gray-100 relative',
            {
              'p-2 mt-2 pt-0 rounded-md': !multiProject,
            },
          )}
        >
          <DashboardHero thumbnail={thumbnail} thumbnailAlt={metadata.projectName} />
          <ProjectIcon />
          <ProjectIdentifierSection
            companyName={metadata.companyName}
            projectName={metadata.projectName}
            projectId={metadata.projectId}
            onOpenProjectSettings={() => {
              navigate(ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', project.payload.id ?? ''));
            }}
            multiProject={multiProject}
          />
        </div>
        <div className="flex flex-col px-6 mt-2 gap-x-3">
          <div className="flex flex-col lg:grid xl:grid lg:grid-cols-3 xl:grid-cols-8 2xl:grid-cols-7 3xl:grid-cols-3 gap-3 mb-5">
            {isFetchingProject ? (
              <LoadingIndicator mode="full-height" />
            ) : (
              <div className="order-0 col-span-full lg2:col-span-2 xl:col-span-4 2xl:col-span-3 3xl:col-span-1">
                <ProjectMetadata
                  bgf={metadata.bgf}
                  grz={metadata.grz}
                  plot={metadata.plot}
                  gfz={metadata.gfz}
                  avgRentPerSqMtr={metadata.avgRentPerSqMtr}
                  avgSalePerSqMtr={metadata.avgSalePerSqMtr}
                  roi={metadata.roi}
                  irr={metadata.irr}
                  roe={metadata.roe}
                  ownCapitalRatio={metadata.ownCapitalRatio}
                  startDate={metadata.startDate}
                  endDate={metadata.endDate}
                  address={metadata.address}
                  deliveryPhasesCount={metadata.deliveryPhasesCount}
                  totalProfit={metadata.totalProfit}
                  plannedCostAndRisk={metadata.plannedCostAndRisk}
                  plannedCostUnit={metadata.plannedCostUnit}
                  plannedEarnings={metadata.plannedEarnings}
                  deviation={metadata.deviation}
                  deviationPercent={metadata.deviationPercent}
                  usageType={metadata.usageType}
                  unitSystem={metadata.unitSystem}
                  projectId={project?.payload.id}
                />
              </div>
            )}

            <div className="order-1 lg2:order-2 xl:order-1 col-span-full xl:col-span-4 2xl:col-span-4 3xl:col-span-2">
              <ProjectLocationContainer
                header={t('project.projectInfoCardProjectLocationTitle')}
                location={metadata.address}
                latitude={metadata.latitude}
                longitude={metadata.longitude}
                pois={metadata.pois}
                multiProject={multiProject}
              />
              {false && <ProjectDetails />}
            </div>
            <ReportKpi />
          </div>
        </div>
      </div>
      {project && loadedProjectId && (
        <SlideOver isOpen={openModal === ModalNames.EDIT} variant="2xl" onClose={handleCloseModal}>
          <EditProjectSlideOver
            thumbnail={thumbnail}
            project={project}
            readOnly={readOnly}
            projectId={loadedProjectId}
            onClose={handleCloseModal}
          />
        </SlideOver>
      )}

      <Routes>
        <Route
          path={ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS}
              projectId={loadedProjectId}
            >
              <ProjectSettings />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to='' />} />
      </Routes>
    </>
  );
};
