import {
  ApiDeleteProjectConstructionPhaseApiArg,
  ApiDeleteProjectConstructionPhaseApiResponse,
  ApiDeleteProjectStatusApiArg,
  ApiDeleteProjectStatusApiResponse,
  ApiDeleteProjectTagApiArg,
  ApiDeleteProjectTagApiResponse,
  ApiDeleteProjectVatApiArg,
  ApiDeleteProjectVatApiResponse,
  ApiGetProjectApiArg,
  ApiGetProjectApiResponse,
  ApiPostCreateProjectApiArg,
  ApiPostCreateProjectApiResponse,
  ApiPostCreateProjectConstructionPhaseApiArg,
  ApiPostCreateProjectConstructionPhaseApiResponse,
  ApiPostCreateProjectStatusApiArg,
  ApiPostCreateProjectStatusApiResponse,
  ApiPostCreateProjectTagApiArg,
  ApiPostCreateProjectTagApiResponse,
  ApiPostCreateProjectVatApiArg,
  ApiPostCreateProjectVatApiResponse,
  ApiPostProjectValuationApiArg,
  ApiPostProjectValuationApiResponse,
  ApiPostUpdateProjectConstrutionPhaseApiArg,
  ApiPostUpdateProjectConstrutionPhaseApiResponse,
  ApiPostUpdateProjectMetadataApiArg,
  ApiPostUpdateProjectMetadataApiResponse,
  ApiPostUpdateProjectStatusApiArg,
  ApiPostUpdateProjectStatusApiResponse,
  ApiPostUpdateProjectVatApiArg,
  ApiPostUpdateProjectVatApiResponse,
  ApiPostUploadProjectCoverApiArg,
  ApiPostUploadProjectCoverApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiProjectEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetProject: {
    providesTags: (result: ApiGetProjectApiResponse, _error: FetchBaseQueryError, args: ApiGetProjectApiArg) => [
      { type: ApiTagTypes.Project, id: args.projectId },
      ...(result?.project?.calculationModels?.map((dto): { type: ApiTagTypes.CalculationModel; id: string } => ({
        type: ApiTagTypes.CalculationModel,
        id: dto.id,
      })) ?? []),
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostUploadProjectCover: {
    invalidatesTags: (
      _result: ApiPostUploadProjectCoverApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUploadProjectCoverApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            {
              type: ApiTagTypes.CompanyProjectImage,
              id: args.projectId,
            },
            ApiTagTypes.Projects,
          ]
        : [],
  },
  apiPostCreateProjectCopy: {
    invalidatesTags: [ApiTagTypes.Projects],
  },
  apiPostUpdateProjectMetadata: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectMetadataApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectMetadataApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.UserDefinedFields, id: args.projectId },
            ApiTagTypes.Projects,
          ]
        : [],
  },
  apiPostCreateProject: {
    invalidatesTags: (
      _result: ApiPostCreateProjectApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectApiArg,
    ) => {
      if (error == null) {
        if ((args.body as FormData).get('projectGroupId')) {
          return [
            { type: ApiTagTypes.Project, id: args.body.ProjectGroupId },
            ApiTagTypes.Projects,
            ApiTagTypes.TenantCatalog,
          ];
        }
        return [ApiTagTypes.Projects, ApiTagTypes.TenantCatalog];
      }
      return [];
    },
  },
  apiDeleteProject: {
    invalidatesTags: [ApiTagTypes.Projects, ApiTagTypes.TenantCatalog],
  },
  apiPostCreateProjectStatus: {
    invalidatesTags: (
      _result: ApiPostCreateProjectStatusApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectStatusApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostUpdateProjectStatus: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectStatusApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectStatusApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostCreateProjectConstructionPhase: {
    invalidatesTags: (
      _result: ApiPostCreateProjectConstructionPhaseApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectConstructionPhaseApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostUpdateProjectConstrutionPhase: {
    invalidatesTags: (
      result: ApiPostUpdateProjectConstrutionPhaseApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectConstrutionPhaseApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostCreateProjectVat: {
    invalidatesTags: (
      _result: ApiPostCreateProjectVatApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectVatApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostUpdateProjectVat: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectVatApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectVatApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiDeleteProjectStatus: {
    invalidatesTags: (
      _result: ApiDeleteProjectStatusApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectStatusApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiDeleteProjectConstructionPhase: {
    invalidatesTags: (
      _result: ApiDeleteProjectConstructionPhaseApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectConstructionPhaseApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiDeleteProjectVat: {
    invalidatesTags: (
      _result: ApiDeleteProjectVatApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectVatApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostCreateProjectTag: {
    invalidatesTags: (
      _result: ApiPostCreateProjectTagApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectTagApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiDeleteProjectTag: {
    invalidatesTags: (
      _result: ApiDeleteProjectTagApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectTagApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostProjectValuation: {
    invalidatesTags: (
      _result: ApiPostProjectValuationApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostProjectValuationApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.CalculationModel, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
          ]
        : [],
  },
};
