import cn from 'classnames';
import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { SortDownIcon } from '../icons';
import { Badge } from './Badges';

export interface PaginationProps {
  items: ListItemProps[];
  setShownItems: (items: ListItemProps[]) => void;
  amountPerPage: number;
}

export const Pagination = ({ items, setShownItems, amountPerPage }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const startIndex = (currentPage - 1) * amountPerPage;
    const endIndex = Math.min(startIndex + amountPerPage, items.length);
    setShownItems(items.slice(startIndex, endIndex));
  }, [currentPage, items, amountPerPage, setShownItems]);

  const getPageButtons = () => {
    const maxVisibleButtons = 8;
    const totalPages = Math.ceil(items.length / amountPerPage);
    const displayStart = Math.max(1, Math.min(currentPage - 3, totalPages - maxVisibleButtons + 1));
    const displayEnd = Math.min(totalPages, displayStart + maxVisibleButtons - 1);
    const buttons = [];

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={`page-${i}`}
            className={`px-2 py-1 rounded ${
              currentPage === i ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>,
        );
      }
    } else {
      if (displayStart > 1) {
        buttons.push(
          <button
            key={`page-1`}
            className={`px-2 py-1 rounded ${
              currentPage === 1 ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(1)}
          >
            1
          </button>,
          <span key="placeholder1">...</span>,
        );
      }

      for (let i = displayStart; i <= displayEnd; i++) {
        buttons.push(
          <button
            key={`page-${i}`}
            className={`px-2 py-1 rounded ${
              currentPage === i ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>,
        );
      }

      if (displayEnd < totalPages) {
        buttons.push(
          <span key="placeholder2">...</span>,
          <button
            key={`page-${totalPages}`}
            className={`px-2 py-1 rounded ${
              currentPage === totalPages ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </button>,
        );
      }
    }

    return buttons;
  };

  return (
    items.length > amountPerPage && (
      <div className="w-full flex justify-center gap-2 transition-all duration-200 mt-4">
        <button
          className={`px-2 py-1 rounded bg-gray-200 text-gray-800 ${
            currentPage === 1 ? 'opacity-60' : 'hover:opacity-80'
          }`}
          disabled={currentPage === 1}
          onClick={() => currentPage !== 1 && setCurrentPage(currentPage - 1)}
        >
          <ArrowLongLeftIcon className="w-4 h-4 fill-gray-800" />
        </button>
        {getPageButtons()}
        <button
          className={`px-2 py-1 rounded bg-gray-200 text-gray-800 ${
            currentPage === Math.ceil(items.length / amountPerPage) ? 'opacity-60' : 'hover:opacity-80'
          }`}
          disabled={currentPage === Math.ceil(items.length / amountPerPage)}
          onClick={() => currentPage !== Math.ceil(items.length / amountPerPage) && setCurrentPage(currentPage + 1)}
        >
          <ArrowLongRightIcon className="w-4 h-4 fill-gray-800" />
        </button>
      </div>
    )
  );
};

export interface ListHeaderProps extends PropsWithChildren {
  header?: ReactNode;
  icon?: ReactNode;
  title?: string;
  subtitle?: string | ReactNode;
  totalNumber?: string;
}

export const ListHeader = (props: ListHeaderProps) => {
  const { header, icon, title, subtitle, children, totalNumber } = props;
  return (
    <div className="mb-1.5">
      {header}
      {(icon || title) && (
        <div className="flex gap-3.5 items-center">
          {icon && React.cloneElement(icon as ReactElement, { className: 'w-6 h-6 flex-none' })}
          {(title || totalNumber) && (
            <div className="flex items-center gap-4">
              {title && <h3 className="font-bold text-2xl text-left">{title}</h3>}
              {totalNumber && (
                <div className="flex-none pt-2">
                  <Badge
                    text={totalNumber}
                    variant="custom"
                    className="bg-slate-300 text-white min-w-[33px] text-center"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {subtitle}
      {children}
    </div>
  );
};

export interface ListProps extends PropsWithChildren {
  listHeader?: ReactNode;
  className?: string;
  variant?: ListVariants;
  icon?: ReactNode;
  listTitle?: string;
  listSubtitle?: string;
  listHeaderChildren?: ReactNode;
  items?: ListItemProps[];
  emptyMessage?: string;
  sortHeader?: ReactNode;
  showLoadingIndicator?: boolean;
  loadingMessage?: string;
  updateLoading?: (loading: boolean) => void;
  showCounter?: boolean;
  collapsible?: boolean;
  showPagination?: boolean;
  amountPerPage?: number;
  showIndentMarker?: boolean;
  totalNumber?: string;
}

type ListVariants = 'default';

export const List = (props: ListProps) => {
  const {
    className,
    listHeader,
    icon,
    listTitle,
    listSubtitle,
    listHeaderChildren,
    items = [],
    children,
    emptyMessage,
    sortHeader,
    showLoadingIndicator = false,
    loadingMessage,
    updateLoading,
    showCounter = true,
    collapsible = false,
    showPagination = false,
    amountPerPage = 5,
    showIndentMarker = false,
    totalNumber,
  } = props;

  const { t } = useTranslation();
  const listRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shownItems, setShownItems] = useState<ListItemProps[]>([]);

  useEffect(() => {
    if (!listRef.current) return;
    setIsLoading(true);
    const resizeObserver = new ResizeObserver(() => {
      if ((listRef.current && listRef.current.querySelectorAll('li').length === items.length) || !items.length) {
        setTimeout(() => {
          if (listRef.current) {
            setIsLoading(false);
            if (updateLoading) {
              updateLoading(false);
            }
          }
        }, 200);
      }
    });
    resizeObserver.observe(listRef.current);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef.current, items.length]);

  /* const listHeaderTitle = useMemo(() => {
    return showCounter ? `${listTitle} (${items.length})` : listTitle;
  }, [showCounter, items.length, listTitle]); */

  useEffect(() => {
    if (!showPagination) {
      setShownItems(items);
    }
  }, [items, showPagination]);

  return (
    <div className={cn('', className)} ref={listRef}>
      {isLoading && showLoadingIndicator && (
        <LoadingIndicator text={loadingMessage ? loadingMessage : t('common.isLoading')} mode="inline" />
      )}
      {collapsible && shownItems.length > 0 ? (
        <Disclosure as="div" defaultOpen>
          {({ open }) => (
            <>
              <DisclosureButton as="div" className="w-full">
                <button className="flex items-center w-full gap-4">
                  <SortDownIcon
                    className={cn(
                      'text-slate-600 opacity-60 transition-transform duration-100 w-6 h-6 flex-none cursor-pointer',
                      {
                        '-rotate-90': !open,
                      },
                    )}
                  />
                  {(listTitle || listHeader || icon || listSubtitle || listHeaderChildren) && (
                    <ListHeader
                      header={listHeader}
                      icon={icon}
                      title={listTitle}
                      subtitle={listSubtitle}
                      totalNumber={showCounter ? totalNumber ?? items.length.toString() : undefined}
                    >
                      {listHeaderChildren}
                    </ListHeader>
                  )}
                </button>
              </DisclosureButton>
              <DisclosurePanel>
                {sortHeader}
                <div
                  style={{
                    minHeight:
                      showPagination && items.length > amountPerPage
                        ? amountPerPage * 64 + (childrenRef.current?.clientHeight ?? 0) + 'px'
                        : 'auto',
                  }}
                >
                  {shownItems.length > 0 && (
                    <ul className={cn('flex flex-col gap-2 mt-6', sortHeader ? 'md:mt-2' : '')}>
                      {shownItems.map((listItem, i) => (
                        <ListItem
                          key={`list-item-${listItem?.id ? listItem.id : i}`}
                          {...listItem}
                          showIndentMarker={showIndentMarker}
                        />
                      ))}
                    </ul>
                  )}
                  <div ref={childrenRef}>{children}</div>
                </div>

                {showPagination && (
                  <Pagination items={items} setShownItems={setShownItems} amountPerPage={amountPerPage} />
                )}
                {!shownItems.length && emptyMessage && <div className="mt-4">{emptyMessage}</div>}
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      ) : (
        <>
          {(listTitle || listHeader || icon || listSubtitle || listHeaderChildren) && (
            <ListHeader
              header={listHeader}
              icon={icon}
              title={listTitle}
              subtitle={listSubtitle}
              totalNumber={showCounter ? totalNumber ?? items.length.toString() : undefined}
            >
              {listHeaderChildren}
            </ListHeader>
          )}
          {sortHeader}
          <div
            style={{
              minHeight:
                showPagination && items.length > amountPerPage
                  ? amountPerPage * 64 + (childrenRef.current?.clientHeight ?? 0) + 'px'
                  : 'auto',
            }}
          >
            {shownItems.length > 0 && (
              <ul className={cn('flex flex-col gap-2 mt-6', sortHeader ? 'md:mt-2' : '')}>
                {shownItems.map((listItem, i) => (
                  <ListItem
                    key={`list-item-${listItem.id ? listItem.id : i}`}
                    {...listItem}
                    showIndentMarker={showIndentMarker}
                  />
                ))}
              </ul>
            )}
            <div ref={childrenRef}>{children}</div>
          </div>
          {showPagination && <Pagination items={items} setShownItems={setShownItems} amountPerPage={amountPerPage} />}
          {!shownItems.length && emptyMessage && <div className="mt-4">{emptyMessage}</div>}
        </>
      )}
    </div>
  );
};

export interface ListItemProps extends PropsWithChildren {
  id?: string;
  className?: string;
  borderColor?: string;
  onClick?: (e: React.MouseEvent) => void;
  shadow?: boolean;
  bgColor?: string;
  padding?: boolean;
  additionalContent?: ReactNode;
  as?: 'div' | 'li';
  listChildren?: ListItemProps[];
  child?: boolean;
  defaultOpen?: boolean;
  showIndentMarker?: boolean;
}

export const ListItem = (props: ListItemProps) => {
  const {
    borderColor,
    className,
    onClick,
    as = 'li',
    listChildren,
    child = false,
    id,
    defaultOpen = false,
    showIndentMarker = false,
  } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (buttonRef.current) {
      if (
        (defaultOpen && buttonRef.current.dataset.headlessuiState !== 'open') ||
        (!defaultOpen && buttonRef.current.dataset.headlessuiState === 'open')
      ) {
        buttonRef.current.click();
      }
    }
  }, [defaultOpen]);
  if (as === 'li') {
    return (
      <li
        className={cn(
          'w-full flex relative',
          {
            'cursor-pointer': onClick,
          },
          className,
        )}
      >
        {!listChildren && !child && borderColor && (
          <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-shrink-0', borderColor)} />
        )}
        {listChildren ? (
          <Disclosure as="div" defaultOpen={defaultOpen} className="w-full h-full" key={`list-item-disclosure-${id}`}>
            {({ open }) => (
              <>
                <DisclosureButton as="div" className="w-full h-full flex" ref={buttonRef}>
                  {borderColor && (
                    <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-shrink-0', borderColor)} />
                  )}
                  <ListItemContent {...props} open={open} child={child} />
                </DisclosureButton>
                <DisclosurePanel>
                  <ul className="pl-8">
                    {listChildren?.map((childItem, j) => (
                      <div className="flex pt-2 relative" key={`list-item-child-${j}`}>
                        {showIndentMarker && (
                          <div className="absolute top-0 -left-[15px] h-full">
                            <ListItemMarker isLast={j === listChildren.length - 1} />
                          </div>
                        )}
                        <div
                          className={cn(
                            'w-2 h-auto rounded-tl-md rounded-bl-md flex-shrink-0',
                            childItem.borderColor ?? borderColor,
                          )}
                        />
                        <ListItem {...childItem} borderColor={childItem.borderColor ?? borderColor} child />
                      </div>
                    ))}
                  </ul>
                </DisclosurePanel>
              </>
            )}
          </Disclosure>
        ) : (
          <ListItemContent {...props} />
        )}
      </li>
    );
  }

  return (
    <div
      className={cn(
        'w-full flex relative',
        {
          'cursor-pointer': onClick,
        },
        className,
      )}
    >
      {borderColor && <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-shrink-0', borderColor)} />}
      {listChildren && listChildren.length > 0 ? (
        <Disclosure as="div" defaultOpen={defaultOpen} className="w-full h-full">
          {({ open }) => (
            <>
              <DisclosureButton as="div" className="w-full h-full flex" ref={buttonRef}>
                <ListItemContent {...props} open={open} child />
              </DisclosureButton>
              <DisclosurePanel>
                <ul className="w-full bg-white rounded-br-md overflow-hidden">
                  {listChildren?.map((childItem, j) => <ListItem key={`list-item-child-${j}`} {...childItem} child />)}
                </ul>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      ) : (
        <ListItemContent {...props} />
      )}
    </div>
  );
};

interface ListItemContentProps extends ListItemProps {
  open?: boolean;
}

export const ListItemContent = (props: ListItemContentProps) => {
  const {
    children,
    bgColor = 'bg-white',
    shadow = true,
    onClick,
    padding = true,
    additionalContent,
    listChildren,
    open = false,
    child = false,
  } = props;
  return (
    <div
      className={cn('w-full flex relative', bgColor, {
        'rounded-tr-md': !child,
        'rounded-br-md': !open && !child,
        shadow: shadow,
      })}
    >
      {listChildren && listChildren.length > 0 && (
        <button className="absolute left-2 md:left-4 top-3.5 md:top-1/2 md:-translate-y-1/2 w-5 font-semibold text-[15px] leading-tight text-slate-500">
          <ChevronDownIcon
            className={cn('w-5 transform transition-transform font-bold', open ? '-rotate-180 duration-300' : '')}
          />
        </button>
      )}
      <div
        className={cn('flex w-full text-sm overflow-hidden truncate', {
          'py-2': padding,
          'px-3': !additionalContent,
          'pr-3 md:pr-0 pl-3': additionalContent,
          'pl-12': listChildren && listChildren.length > 0,
        })}
        onClick={onClick}
      >
        {children}
      </div>
      {additionalContent}
    </div>
  );
};

export interface ListTitleProps extends PropsWithChildren {
  className?: string;
  title?: ReactNode | string | null;
  color?: string;
  padding?: string;
}

export const ListTitle = (props: ListTitleProps) => {
  const { title, children, color = 'bg-primary', className, padding = 'py-4 px-6' } = props;
  return (
    <div
      className={cn(
        'w-full flex flex-wrap bg-white items-center justify-between relative rounded-t-md',
        padding,
        className,
      )}
    >
      <div className={cn('absolute -left-1 top-1/2 h-2/3 w-2  rounded -translate-y-1/2', color)} />
      {title && <h4 className="text-[22px] font-bold">{title}</h4>}
      {children}
    </div>
  );
};

export interface ListSortHeaderItemProps {
  className?: string;
  onClick?: () => void;
  label: string;
  asc: boolean | null;
  alignRight?: boolean;
  sortable?: boolean;
}

export const ListSortHeaderItem = (props: ListSortHeaderItemProps) => {
  const { className, onClick, label, asc, alignRight = true, sortable = true } = props;
  return (
    <span
      className={cn(
        'flex gap-2.5 items-center text-gray-500',
        alignRight ? 'justify-end' : '',
        sortable ? 'cursor-pointer' : '',
        className,
      )}
      onClick={sortable ? onClick : undefined}
    >
      {label}
      {sortable && (
        <SortDownIcon
          className={cn('flex-none w-7', {
            'rotate-180': asc || asc === null,
            'opacity-30': asc === null,
          })}
        />
      )}
    </span>
  );
};

export const ListItemMarker = ({ isLast }: { isLast: boolean }) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center relative h-full before:content-[""] before:min-h-[0.625rem] before:flex-none before:w-px before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 z-0 before:bg-slate-400',
        {
          'before:h-full': !isLast,
          'before:h-1/2': isLast,
        },
      )}
    >
      <div className="h-full">
        <div className="flex-none rounded-full w-2.5 h-2.5 border-2 bg-white z-10 border-slate-400 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 mt-[5px]" />
      </div>
    </div>
  );
};
