import {
  useApiGetProjectCompaniesQuery,
  useApiPostQuickCreateCompanyMutation,
  useApiPostCreatePrivatePersonMutation,
  useApiPostCreateCompanyBranchMutation,
  AiEvalValuesReadModel,
  useApiPostValidateIbanMutation,
  PersonGender,
  useApiPostGenerateNextTenantObjectCodeMutation,
} from '@client/shared/api';
import {
  ComboSelect,
  Button,
  Form,
  FormField,
  FormWatch,
  Modal,
  TextInput,
  ComboSelectOption,
  FormRefHandle,
  ComboSelectAdditionalOption,
  ToggleSwitch,
  AddButton,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isValidSWIFT, safeMutation } from '@client/shared/utilities';
import { CompanyAddFormValidationSchema, CompanyAddFormValidationValues } from '.';
import { useLoadedProjectId } from '@client/project/store';
import { MinusIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

interface AddCompanyModalProps {
  isOpen: boolean;
  onClose: (id?: string) => void;
  invoiceDocumentFileData?: AiEvalValuesReadModel | null;
  selectNewBranch?: (branch: string) => void;
  field?: 'contractor' | 'client';
}

export const AddCompanyModal = ({
  isOpen,
  onClose,
  invoiceDocumentFileData,
  field,
}: AddCompanyModalProps) => {
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<CompanyAddFormValidationValues>>(null);

  const loadedProjectId = useLoadedProjectId();
  const { data, isFetching } = useApiGetProjectCompaniesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId || !isOpen,
    },
  );

  const [postCreateCompany, { isLoading: isCreatingCompany }] = useApiPostQuickCreateCompanyMutation();
  const [postCreatePerson, { isLoading: isCreatingPerson }] = useApiPostCreatePrivatePersonMutation();
  const [postCreateBranch, { isLoading: isCreatingBranch }] = useApiPostCreateCompanyBranchMutation();
  const [postIbanMutation, { isLoading: isLoadingIbanValidation }] = useApiPostValidateIbanMutation();
  const [postNextCode, { isLoading: isLoadingNextCode }] = useApiPostGenerateNextTenantObjectCodeMutation();

  const [companyInput, setCompanyInput] = useState<string>('');
  const [companiesOptions, setCompaniesOptions] = useState<ComboSelectOption[]>([]);
  // const [privatePersonOptions, setPrivatePersonOptions] = useState<ComboSelectOption[]>([]);
  const [invalidIban, setInvalidIban] = useState<boolean>(false);
  const [type, setType] = useState<'Company' | 'Person'>('Company');
  const [newBranch, setNewBranch] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const isNewCompany = (company: string | undefined) => {
    return !data?.find((x) => x.id === company);
  };

  useEffect(() => {
    if (data) {
      setCompaniesOptions(
        data
          .filter((company) => company.type === 'Company')
          .map((company) => ({
            label: company.name,
            value: company.id,
          })),
      );
      // setPrivatePersonOptions(
      //   data
      //     .filter((company) => company.type === 'PrivatePerson')
      //     .map((company) => ({
      //       label: company.name,
      //       value: company.id,
      //     })),
      // );
    }
  }, [data]);

  const handleAddCompany = () => {
    const newCompanyOption = {
      label: companyInput,
      value: companyInput,
    };

    if (type === 'Company') {
      setCompaniesOptions((prevOptions) => [...prevOptions, newCompanyOption]);
    } else {
      // setPrivatePersonOptions((prevOptions) => [...prevOptions, newCompanyOption]);
    }
  };

  const checkIban = async (iban: string) => {
    try {
      await safeMutation(
        postIbanMutation,
        {
          body: {
            iban: iban,
          },
        },
        isLoadingIbanValidation,
      );
      setInvalidIban(false);
    } catch (e) {
      toast.dismiss();
      console.error(e);
      setInvalidIban(true);
    }
  };

  useEffect(() => {
    if (invoiceDocumentFileData?.contractorBankDetails?.iban && isOpen) {
      checkIban(invoiceDocumentFileData?.contractorBankDetails?.iban);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (formData: CompanyAddFormValidationValues) => {
    let branchCode = ''

    try{
      const branchCodeResponse = await safeMutation(
        postNextCode,
        {
          body: {
            tenantObjectType: 'Company',
            ownerObjectId: formData.company
          },
        },
        isLoadingNextCode,
      );

      branchCode = branchCodeResponse?.code ?? '';
    } catch (e) {
      console.error(e);
      branchCode = formData.branch ?? '';
    }

    try {
      if (formData.iban !== '') {
        await safeMutation(
          postIbanMutation,
          {
            body: {
              iban: formData.iban ?? '',
            },
          },
          isLoadingIbanValidation,
        );

        setInvalidIban(false);
      }
      if (isEmpty(formData.company) && isNewCompany(formData.company)) {
        try {
          if (type === 'Person') {
            const names = formData.company.split(' ');
            const response = await safeMutation(
              postCreatePerson,
              {
                body: {
                  person: {
                    firstName: names.length > 1 ? names.slice(0, -1).join(' ') : formData.company,
                    lastName: names.length > 1 ? names.slice(-1).join(' ') : formData.company,
                    gender: 'D' as PersonGender,
                    phone: formData.phone,
                    email: formData.email,
                  },
                  address: invoiceDocumentFileData
                    ? {
                        street: formData.street,
                        number: formData.number,
                        postalCode: formData.postalCode,
                        city: formData.city,
                        country: formData.country,
                      }
                    : null,

                  account: invoiceDocumentFileData && formData.iban
                    ? {
                        iban: formData.iban,
                        swift: formData.swift,
                      }
                    : null,
                },
              },
              isCreatingPerson,
            );

            onClose(response?.personId);
          } else {
            if (!(newBranch && isEmpty(formData.branch))) {
              const response = await safeMutation(
                postCreateCompany,
                {
                  body: {
                    company: {
                      name: formData.company ?? '',
                    },
                    branch: {
                      isMainBranch: true,
                      code: branchCode,
                      name: (newBranch ? formData.branch : `${formData.company} HQ`) ?? '',
                      phone: formData.phone,
                      email: formData.email,
                      address: invoiceDocumentFileData
                        ? {
                            street: formData.street,
                            number: formData.number,
                            postalCode: formData.postalCode,
                            city: formData.city,
                            country: formData.country,
                          }
                        : null,
                    },
                    account: invoiceDocumentFileData && formData.iban
                      ? {
                          iban: formData.iban,
                          swift: formData.swift,
                        }
                      : null,
                  },
                },
                isCreatingCompany,
              );
              onClose(response?.companyId);
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        if (formData.branch) {
          try {
            const response = await safeMutation(
              postCreateBranch,
              {
                companyId: formData.company ?? '',
                body: {
                  isMainBranch: true,
                  code: branchCode,
                  name: formData.branch,
                  phone: formData.phone,
                  email: formData.email,
                  address: invoiceDocumentFileData
                    ? {
                        street: formData.street,
                        number: formData.number,
                        postalCode: formData.postalCode,
                        city: formData.city,
                        country: formData.country,
                      }
                    : null,
                },
              },
              isCreatingBranch,
            );
            onClose(response?.companyBranchId);
          } catch (e) {
            console.log(e);
          }
        }
      }
    } catch (e) {
      console.log(e);
      setInvalidIban(true);
    }
  };

  const defaultFormValues = {
    company: '',
    code: '',
    branch: '',
    firstName: '',
    lastName: '',
    iban: invoiceDocumentFileData?.contractorBankDetails?.iban ?? '',
    swift: invoiceDocumentFileData?.contractorBankDetails?.bic ?? '',
    phone:
      (field === 'contractor' ? invoiceDocumentFileData?.contractorPhone : invoiceDocumentFileData?.customerNumber) ??
      '',
    email: field === 'contractor' ? invoiceDocumentFileData?.contractorEmail ?? '' : '',
    street:
      (field === 'contractor'
        ? invoiceDocumentFileData?.contractorAddress?.street
        : invoiceDocumentFileData?.customerAddress?.street) ?? '',
    number:
      (field === 'contractor'
        ? invoiceDocumentFileData?.contractorAddress?.number
        : invoiceDocumentFileData?.customerAddress?.number) ?? '',
    city:
      (field === 'contractor'
        ? invoiceDocumentFileData?.contractorAddress?.city
        : invoiceDocumentFileData?.customerAddress?.city) ?? '',
    postalCode:
      (field === 'contractor'
        ? invoiceDocumentFileData?.contractorAddress?.postalCode
        : invoiceDocumentFileData?.customerAddress?.postalCode) ?? '',
    country:
      (field === 'contractor'
        ? invoiceDocumentFileData?.contractorAddress?.country
        : invoiceDocumentFileData?.customerAddress?.country) ?? '',
  };

  return (
    <Modal
      variant="custom"
      className="w-[420px] max-h-[90vh] overflow-y-auto"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <Form<CompanyAddFormValidationValues>
        onSubmit={handleSubmit}
        defaultValues={defaultFormValues}
        validationSchema={CompanyAddFormValidationSchema}
        className="h-full flex flex-col justify-between min-h-0"
        ref={formRef}
      >
        <Modal.Header title={t('projectContract.createCompany')} description={t('projectContract.createNewCompany')} />
        <Modal.Content className="w-full flex flex-col justify-between overflow-y-auto mb-5">
          {(isFetching || isCreatingPerson || isCreatingCompany || isCreatingBranch || isLoadingIbanValidation) && (
            <LoadingIndicator text={t('projectContract.createCompanyLoading') ?? ''} mode="overlay" />
          )}

          <FormField name="company">
            {(control) => (
              <>
                <div className="mb-2 w-full flex justify-center">
                  <ToggleSwitch
                    name="type"
                    checked={type === 'Company'}
                    onLabel={t('projectContract.company')}
                    offLabel={t('projectContract.person')}
                    onChange={(value) => {
                      setCompanyInput('');
                      setType(value ? 'Company' : 'Person');
                      setNewBranch(false);
                      control.onChange('');
                    }}
                  />
                </div>
                {type === 'Company' ? (
                  <ComboSelect
                    options={companiesOptions}
                    label={t('projectContract.company')}
                    {...control}
                    setValue={(value) => {
                      setCompanyInput(value);
                    }}
                    nullable
                    additionalOptionOnClick={
                      companyInput !== '' && !companiesOptions.find((x) => x.label === companyInput)
                        ? () => {
                            handleAddCompany();
                            control.onChange(companyInput);
                          }
                        : undefined
                    }
                    additionalOption={
                      companyInput !== '' &&
                      !companiesOptions.find((x) => x.label === companyInput) && (
                        <ComboSelectAdditionalOption label={t('projectContract.addNewCompany')} />
                      )
                    }
                  />
                ) : (
                  // <ComboSelect
                  //   options={privatePersonOptions}
                  //   label={t('projectContract.person')}
                  //   {...control}
                  //   disabled={!!branch}
                  //   setValue={(value) => {
                  //     setCompanyInput(value);
                  //   }}
                  //   nullable
                  //   additionalOptionOnClick={
                  //     companyInput !== '' && !privatePersonOptions.find((x) => x.label === companyInput)
                  //       ? () => {
                  //           handleAddCompany();
                  //           control.onChange(companyInput);
                  //         }
                  //       : undefined
                  //   }
                  //   additionalOption={
                  //     companyInput !== '' &&
                  //     !companiesOptions.find((x) => x.label === companyInput) && (
                  //       <ComboSelectAdditionalOption label={t('projectContract.addNewPerson')} />
                  //     )
                  //   }
                  // />
                  <TextInput label={t('projectContract.person')} {...control} />
                )}
              </>
            )}
          </FormField>

          <FormWatch<CompanyAddFormValidationValues> fieldNames={['company']}>
            {({ company }) => (
              <>
                {isNewCompany(company) && type === 'Company' && (
                  <div className="w-full flex justify-end items-center ">
                    <Button
                      onClick={() => setNewBranch(!newBranch)}
                      variant="text"
                      hasPadding={false}
                      className="my-2 cursor-pointer flex items-center"
                    >
                      <span className="mr-2">{t('projectContract.branch')}</span>
                      {newBranch ? <MinusIcon className="w-5 h-5  text-gray-400" /> : <AddButton variant="secondary" />}
                    </Button>
                  </div>
                )}

                {(newBranch || !isNewCompany(company)) && type === 'Company' && (
                  <FormField name="branch">
                    {(control) => (
                      <TextInput
                        label={t('projectContract.branch')}
                        value={control.value}
                        onChange={control.onChange}
                        showValidation={submitted && (control.showValidation || control.value === '')}
                        isValidationValid={control.isValidationValid && control.value !== ''}
                        helperText={
                          submitted ? (control.value === '' ? t('validation.required') : control.helperText) : undefined
                        }
                      />
                    )}
                  </FormField>
                )}

                {invoiceDocumentFileData && (
                  <div className="mt-2 w-full">
                    {(invalidIban ||
                      (!isNewCompany(company) && type === 'Company') ||
                      newBranch ||
                      type === 'Person') && (
                      <FormField name="iban">
                        {(control) => (
                          <TextInput
                            label={t('app.companiesIban')}
                            value={control.value}
                            onChange={(value) => {
                              control.onChange(value);
                            }}
                            showValidation={(control.value !== '' && !invalidIban) || control.showValidation}
                            isValidationValid={
                              (control.value === '' || (control.value !== '' && !invalidIban)) &&
                              control.isValidationValid
                            }
                            helperText={
                              control.value !== '' && invalidIban ? t('app.companiesInvalidIban') : control.helperText
                            }
                          />
                        )}
                      </FormField>
                    )}
                    {((!isNewCompany(company) && type === 'Company') || newBranch || type === 'Person') && (
                      <>
                        <FormField name="swift">
                          {(control) => (
                            <TextInput
                              label={t('app.companiesSwift')}
                              value={control.value}
                              showValidation={
                                (control.value !== '' && !isValidSWIFT(control.value)) || control.showValidation
                              }
                              isValidationValid={
                                (control.value === '' || (control.value !== '' && isValidSWIFT(control.value))) &&
                                control.isValidationValid
                              }
                              helperText={
                                control.value !== '' && !isValidSWIFT(control.value)
                                  ? t('app.companiesInvalidSwift')
                                  : control.helperText
                              }
                              onChange={(value) => {
                                control.onChange(value);
                              }}
                            />
                          )}
                        </FormField>

                        <FormField name="phone">
                          {(control) => <TextInput label={t('common.phoneNumber')} {...control} />}
                        </FormField>
                        <FormField name="email">
                          {(control) => <TextInput label={t('common.email')} {...control} />}
                        </FormField>
                        <div className="flex">
                          <FormField name="street">
                            {(control) => <TextInput label={t('common.addressStreet')} {...control} />}
                          </FormField>
                          <FormField name="number">
                            {(control) => <TextInput label={t('common.addressHouseNumber')} {...control} />}
                          </FormField>
                        </div>
                        <div className="flex">
                          <FormField name="city">
                            {(control) => <TextInput label={t('common.addressCity')} {...control} />}
                          </FormField>
                          <FormField name="postalCode">
                            {(control) => <TextInput label={t('common.addressPostalCode')} {...control} />}
                          </FormField>
                        </div>
                        <FormField name="country">
                          {(control) => <TextInput label={t('common.addressCountry')} {...control} />}
                        </FormField>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </FormWatch>
        </Modal.Content>
        <Modal.Controls className="bg-white">
          <Button onClick={() => onClose()} variant="secondary">
            {t('common.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setSubmitted(true);
              formRef.current?.submitForm();
            }}
          >
            {t('common.save')}
          </Button>
        </Modal.Controls>
      </Form>
    </Modal>
  );
};
