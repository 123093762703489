type GenericElement = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export function getLevelledElements<T extends GenericElement>(
  data: T[],
  childrenKeys: string[],
  identifierKey: keyof T,
): { elementId: string; level: number }[] {
  const elements: { elementId: string; level: number }[] = [];

  const getLevel = (element: T, level: number) => {
    elements.push({ elementId: String(element[identifierKey]), level });

    childrenKeys.forEach((childrenKey) => {
      if (childrenKey in element) {
        (element[childrenKey] as T[]).forEach((child) => getLevel(child, level + 1));
      }
    });
  };

  data.forEach((item) => getLevel(item, 0));
  return elements;
}
