import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiAdminPostConvertContractsApiArg,
  ApiAdminPostConvertContractsApiResponse,
  ApiDeleteContractApiArg,
  ApiDeleteContractApiResponse,
  ApiDeleteContractDocumentApiArg,
  ApiDeleteContractDocumentApiResponse,
  ApiGetContractApiArg,
  ApiGetContractApiResponse,
  ApiGetContractsApiArg,
  ApiGetContractsApiResponse,
  ApiGetSelectableContractsApiArg,
  ApiGetSelectableContractsApiResponse,
  ApiPostContractDocumentsApiArg,
  ApiPostContractDocumentsApiResponse,
  ApiPostCopyContractApiArg,
  ApiPostCopyContractApiResponse,
  ApiPostCreateContractApiArg,
  ApiPostCreateContractApiResponse,
  ApiPostImportContractFileApiArg,
  ApiPostImportContractFileApiResponse,
  ApiPostPayContractApiArg,
  ApiPostPayContractApiResponse,
  ApiPostReopenContractApiArg,
  ApiPostReopenContractApiResponse,
  ApiPostSettleContractApiArg,
  ApiPostSettleContractApiResponse,
  ApiPostUpdateContractApiArg,
  ApiPostUpdateContractApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiContractEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetContracts: {
    providesTags: (_result: ApiGetContractsApiResponse, _error: FetchBaseQueryError, args: ApiGetContractsApiArg) => [
      {
        type: ApiTagTypes.Contracts,
        id: args.calculationModelId,
      },
      {
        type: ApiTagTypes.Contracts,
        id: args.projectId,
      },
      ApiTagTypes.Contracts,
    ],
  },
  apiGetContract: {
    providesTags: (result: ApiGetContractApiResponse, _error: FetchBaseQueryError, args: ApiGetContractApiArg) => {
      const tags = [
        { 
          type: ApiTagTypes.Contract, 
          id: args.contractId 
        },
        { 
          type: ApiTagTypes.Contract, 
          id: args.calculationModelId 
        },
      ];
      if (result?.invoices?.length) {
        result.invoices.forEach((invoice) => {
          tags.push({
            type: ApiTagTypes.InvoiceContract,
            id: invoice.id, // used for invalidating when invoice changed
          });
          if (invoice.calculationSchemeId) {
            tags.push({
              type: ApiTagTypes.CalculationSchemeInvoice,
              id: invoice.calculationSchemeId ?? '', // used for invalidating when invoice calculation scheme changed
            });
          }
        });
      }
      result?.invoiceCalculationSchemes.forEach((calculationScheme) => {
        tags.push({
          type: ApiTagTypes.CalculationSchemeInvoice,
          id: calculationScheme.invoiceCalculationScheme.invoiceCalculationSchemeId ?? '', // used for invalidating when contract calculation scheme changed
        });          
      })
      if (result?.contractor?.companyId) {
        tags.push({ type: ApiTagTypes.CompanyContract, id: result?.contractor?.companyId ?? '' });
      }
      if (result?.client?.companyId) {
        tags.push({ type: ApiTagTypes.CompanyContract, id: result?.client?.companyId ?? '' });
      }
      return tags;
    },
  },
  apiGetSelectableContracts: {
    providesTags: (_result: ApiGetSelectableContractsApiResponse, _error: FetchBaseQueryError, args: ApiGetSelectableContractsApiArg) => [
      {
        type: ApiTagTypes.Contracts,
        id: args.calculationModelId,
      },
      ApiTagTypes.Contracts,
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateContract: {
    invalidatesTags: (
      _result: ApiPostCreateContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.calculationModelId,
            },
          ]
        : [],
  },
  apiPostImportContractFile: {
    invalidatesTags: (
      _result: ApiPostImportContractFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostImportContractFileApiArg,
    ) =>
      error == null
        ? [
            { 
              type: ApiTagTypes.Contracts, 
              id: args.calculationModelId 
            },
            { 
              type: ApiTagTypes.Contract, 
              id: args.calculationModelId 
            },            
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.calculationModelId,
            },
          ]
        : [],
  },  
  apiDeleteContract: {
    invalidatesTags: (
      _result: ApiDeleteContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateContract: {
    invalidatesTags: (
      _result: ApiPostUpdateContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.ContractInvoice, id: args.contractId },
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.UserDefinedFields, id: args.contractId },
          ]
        : [],
  },
  apiPostCopyContract: {
    invalidatesTags: (
      _result: ApiPostCopyContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCopyContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostContractDocuments: {
    invalidatesTags: (
      _result: ApiPostContractDocumentsApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostContractDocumentsApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Contract, id: args.contractId }] : []),
  },
  apiDeleteContractDocument: {
    invalidatesTags: (
      _result: ApiDeleteContractDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteContractDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Contract, id: args.contractId }] : []),
  },
  apiPostSettleContract: {
    invalidatesTags: (
      _result: ApiPostSettleContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostSettleContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.ContractInvoice, id: args.contractId },
          ]
        : [],
  },
  apiPostPayContract: {
    invalidatesTags: (
      _result: ApiPostPayContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostPayContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.ContractInvoice, id: args.contractId },
          ]
        : [],
  },
  apiPostReopenContract: {
    invalidatesTags: (
      _result: ApiPostReopenContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostReopenContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.ContractInvoice, id: args.contractId },
          ]
        : [],
  },
  apiPostConvertContracts: {
    invalidatesTags: (
      _result: ApiAdminPostConvertContractsApiResponse,
      error: FetchBaseQueryError,
      args: ApiAdminPostConvertContractsApiArg,
    ) =>
      error == null
        ? [
            { 
              type: ApiTagTypes.Contracts, 
              id: args.body.projectId 
            },
            {
              type: ApiTagTypes.CostsCalculationModel,
              id: args.body.projectId,
            },
            { 
              type: ApiTagTypes.Invoices, 
              id: args.body.projectId 
            },
          ]
        : [],
  },
};
