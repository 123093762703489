import {
  DecoratedCard,
  Collapsible,
  useComponentDimensions,
  LoadingIndicator,
  PetitionIcon,
  ReceiptAndChangeIcon,
  LevelToggle,
} from '@client/shared/toolkit';
import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  useApiGetDetailReportQuery,
  DetailCatalogElement,
  DetailCommitment,
  DetailContract,
  DetailContractTitle,
  useApiGetDetailReportAxaQuery,
} from '@client/shared/api';
import {
  useLoadedProjectVariants,
  useLoadedProjectId,
  setExpandedDetailReportIds,
  useExpandedReportingIds,
} from '@client/project/store';
import { CalculationModelSelect } from './CalculationModelSelect';
import { ToggleButton } from '@client/shared/toolkit';
import { FormattedCurrency } from '@client/project/shared';
import { formatPercentage } from '@client/shared/utilities';
import { settings, useUi } from '@client/shared/store';
import { useTranslation } from 'react-i18next';
import { ReportDetailSortingSelect, ReportDetailSortingType } from './ReportDetailSortingSelect';
import { useDispatch } from 'react-redux';
import { getLevelledElements } from '../utils';

export const ReportDetails = () => {
  const { data: projectVariants } = useLoadedProjectVariants();
  const { t } = useTranslation();
  const activeVariant = projectVariants.find((x) => x.type === 'Version');
  const activeVariantId = activeVariant?.id;

  const ui = useUi();
  const user = ui.appUser;

  const projectId = useLoadedProjectId();
  const [sourceCalculationModelId, setSourceCalculationModelId] = useState<string | undefined>(activeVariantId);

  const [selectedSorting, setSelectedSorting] = useState<ReportDetailSortingType>('none');

  const { data: defaultData, isFetching } = useApiGetDetailReportQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: sourceCalculationModelId ?? '',
    },
    { skip: sourceCalculationModelId === undefined || selectedSorting !== 'none' },
  );

  const { data: sortedData, isFetching: isFetchingSortedData } = useApiGetDetailReportAxaQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: sourceCalculationModelId ?? '',
    },
    { skip: sourceCalculationModelId === undefined || selectedSorting !== 'DIN 276' },
  );

  const data = useMemo(() => {
    return selectedSorting === 'none' ? defaultData : sortedData;
  }, [selectedSorting, defaultData, sortedData]);

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const [open, setOpen] = useState(true);

  const levelledElements = useMemo(() => {
    const allElements = [...(data?.data.catalogElements ?? [])];

    return getLevelledElements(allElements, ['childElements', 'commitments', 'contracts', 'titles'], 'uid');
  }, [data]);

  const dispatch = useDispatch();

  const { Detail: expandedElements } = useExpandedReportingIds();

  const handleOnCollapse = (level: number) => {
    const elements = levelledElements.filter((x) => x.level <= level - 2);
    const expanded = elements.map((x) => x.elementId);
    dispatch(setExpandedDetailReportIds(expanded));
  };

  const columns = [
    {
      value: 'initialBudget',
      label: t('reporting.tableInitialBudget'),
      border: 'major',
      total: data?.data.total.availableBudget,
    },
    {
      value: 'contracts',
      label: t('reporting.tableContracts'),
      border: 'minor',
      total: data?.data.total.values?.mainContractValueNet,
    },
    {
      value: 'additions',
      label: t('reporting.tableAdditions'),
      border: 'minor',
      total: data?.data.total.values?.supplementValueNet,
    },
    {
      value: 'totalOrder',
      label: t('reporting.tableTotalOrder'),
      border: 'major',
      total: (data?.data.total.values?.supplementValueNet ?? 0) + (data?.data.total.values?.mainContractValueNet ?? 0),
    },
    {
      value: 'invoice',
      label: t('reporting.tableInvoice'),
      border: 'minor',
      total: data?.data.total.values?.invoiceValueNet,
    },
    {
      value: 'payment',
      label: t('reporting.tablePayment'),
      border: 'major',
      total: data?.data.total.values?.paymentValueNet,
    },
    {
      value: 'forecast',
      label: t('reporting.tableForecast'),
      border: 'none',
      total: data?.data.total.values?.forecastValueNet,
    },
    {
      value: 'difference',
      label: t('reporting.tableColumnDifference'),
      border: 'none',
      total: Math.abs((data?.data.total.availableBudget ?? 0) - (data?.data.total.values?.forecastValueNet ?? 0)),
    },
  ];

  const showFilter = useMemo(() => {
    return (
      user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631' ||
      settings.mainDomain === 'probis.test' ||
      settings.mainDomain === 'probis.dev' ||
      settings.mainDomain === 'probis.qa'
    );
  }, [user.tenant?.tenantId]);

  return (
    <DecoratedCard className="min-w-[1383px]">
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('reporting.reportDetails.fullTitle')}</div>
            <div className="pdf-export-hidden">
              <LevelToggle handleOnCollapse={handleOnCollapse} />
            </div>
          </div>
          <div className="flex items-center">
            {showFilter && (
              <div className="flex items-center mr-6">
                <div className="font-bold text-[15px] pr-6">{t('reporting.filterBy')}</div>
                <div className="flex space-x-0.5 items-center">
                  <ReportDetailSortingSelect
                    selectedSorting={selectedSorting}
                    onChange={(sorting: ReportDetailSortingType) => setSelectedSorting(sorting)}
                  />
                </div>
              </div>
            )}
            <div className="font-bold text-[15px] pr-6">{t('reporting.dataSource')}</div>
            <div className="flex space-x-0.5 items-center">
              <CalculationModelSelect
                selectedCalculationModelId={sourceCalculationModelId}
                className="w-52"
                onChange={(id) => setSourceCalculationModelId(id)}
              />
            </div>
          </div>
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full flex relative">
        {(isFetching || isFetchingSortedData) && (
          <LoadingIndicator text={t('reporting.loadingReport')} mode="overlay" />
        )}

        <div className="relative w-full" ref={wrapper}>
          <div
            className="w-2 my-4 rounded -ml-1 left-0 absolute bg-red-700"
            style={{ height: (dimensions.height ?? 15) - 32 }}
          />
          <div className="w-4/12 ml-4 mt-5 text-[22px] font-bold flex truncate">
            {data?.data.catalogElements && data?.data.catalogElements.length > 0 ? (
              <ToggleButton open={open} onClick={() => setOpen(!open)} />
            ) : (
              <div className="w-8 flex-none">&nbsp;</div>
            )}
            <div className="w-[80px] flex-none">&nbsp;</div>
            <div className="text-red-700">{t('reporting.reportDetails.groupCosts')}</div>
          </div>
          <div className="h-8 bg-white border-b border-b-slate-300 pb-1">
            <HeaderFooter columns={columns} />
          </div>
          <Collapsible open={open}>
            {data?.data.catalogElements.map((element, i) => (
              <DetailCostRow
                rowData={element}
                key={`report-cost-${element.code}-${i}`}
                expandedElements={expandedElements}
              />
            ))}
          </Collapsible>
          <div className="h-20 bg-white">
            <HeaderFooter columns={columns} isFooter />
          </div>
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};

interface Column {
  value: string;
  label: string;
  border: string;
  total?: number;
}

interface HeaderFooterProps {
  columns: Column[];
  isFooter?: boolean;
}

const HeaderFooter = ({ columns, isFooter = false }: HeaderFooterProps) => {
  const initialBudget = columns.find((x) => x.value === 'initialBudget')?.total ?? 0;
  const { t } = useTranslation();
  return (
    <div className="flex pr-4 pl-12 w-full h-full">
      <div className="flex items-end">
        <div
          className={classNames('h-full flex text-[11px] text-slate-500', {
            'items-start': isFooter,
            'items-end': !isFooter,
          })}
        >
          <div className="w-[80px] text-right px-2 truncate">{isFooter ? ' ' : t('reporting.tableColumnId')}</div>
          {isFooter ? (
            <div className="w-[350px] flex items-end h-11 text-[18px] font-bold text-red-700 truncate">
              {t('reporting.reportDetails.groupTotalInvestmentCosts')}
            </div>
          ) : (
            <div className="w-[350px] truncate">{t('reporting.tableColumnDescription')}</div>
          )}
        </div>
      </div>
      <div
        className={classNames('flex-grow h-full', {
          'pb-6': isFooter,
        })}
      >
        <div className="flex items-start justify-end pb">
          {columns?.map((column, index) => (
            <div
              className={classNames(
                'flex-grow basis-0 flex items-end justify-end text-right font-bold text-[15px] px-4 truncate',
                {
                  'h-11': isFooter,
                  'h-full': !isFooter,
                  'border-r-4 border-slate-300': column.border === 'major',
                  'border-r border-slate-300': column.border === 'minor',
                  'border-r-0': column.border === 'none',
                },
              )}
              key={`report-detail-column-${isFooter ? 'footer' : 'header'}-${index}`}
            >
              {isFooter ? (
                <div className="flex items-end">
                  {(column.value === 'forecast' || column.value === 'totalOrder') && (
                    <span
                      className={classNames('mr-2 mb-0.5 text-xxs', {
                        'text-red-700': column.total && column.total > initialBudget,
                        'text-green-700': column.total && column.total < initialBudget,
                        'text-slate-900': column.total === initialBudget,
                      })}
                    >
                      {formatPercentage(calculateDifference(column.total ?? 0, initialBudget), {
                        maxDigits: 0,
                      })}
                    </span>
                  )}
                  <FormattedCurrency amount={column.total} />
                </div>
              ) : (
                column.label
              )}
            </div>
          ))}
        </div>
        {isFooter && (
          <div className="flex items-start justify-end">
            {columns?.map((column) => (
              <div
                className="flex-grow basis-0 h-full border-b-4 border-double pb-2 ml-2"
                key={`report-detail-column-underline-${column.value}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const calculateDifference = (value: number | undefined, budget: number) => {
  if (!budget) return 1;
  if (!value) return 0;
  const difference = value - budget;
  const percentageDifference = difference / budget;
  return percentageDifference;
};

const DifferenceElement = ({ value, budget }: { value: number | undefined; budget: number }) => {
  const difference = calculateDifference(value, budget);

  return (
    <div
      className={classNames('flex justify-end items-end', {
        'text-red-700': difference > 0,
        'text-green-700': difference < 0,
        'text-slate-900': value === budget,
      })}
    >
      {!!value && !!budget && (
        <span className="mr-2 mb-0.5 text-xxs">
          {difference < 0 ? '' : '+'}
          {formatPercentage(difference, {
            maxDigits: difference > -0.99 ? 0 : 2,
          })}
        </span>
      )}
      <FormattedCurrency amount={value} />
    </div>
  );
};

interface DetailCostRowProps {
  rowData?: DetailCatalogElement;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const DetailCostRow = ({ columns, level = 0, rowData, expandedElements }: DetailCostRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.name}</>,
      <FormattedCurrency amount={rowData.availableBudget} />,
      <FormattedCurrency amount={rowData.values?.mainContractValueNet} />,
      <FormattedCurrency amount={rowData.values?.supplementValueNet} />,
      <DifferenceElement
        value={(rowData.values?.mainContractValueNet ?? 0) + (rowData.values?.supplementValueNet ?? 0)}
        budget={rowData.availableBudget}
      />,
      <FormattedCurrency amount={rowData.values?.invoiceValueNet} />,
      <FormattedCurrency amount={rowData.values?.paymentValueNet} />,
      <DifferenceElement value={rowData.values?.forecastValueNet} budget={rowData.availableBudget} />,
      <FormattedCurrency amount={Math.abs(rowData.availableBudget - (rowData.values?.forecastValueNet ?? 0))} />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailCatalogElement>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailCostRow}
      type="catalog"
      expandedElements={expandedElements}
    />
  );
};

interface DetailCommitmentRowProps {
  rowData?: DetailCommitment;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const DetailCommitmentRow = ({ columns, level = 0, rowData, expandedElements }: DetailCommitmentRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.name}</>,
      <FormattedCurrency amount={rowData.values?.budgetValue} />,
      <FormattedCurrency amount={rowData.values?.mainContractValueNet} />,
      <FormattedCurrency amount={rowData.values?.supplementValueNet} />,
      <DifferenceElement
        value={(rowData.values?.mainContractValueNet ?? 0) + (rowData.values?.supplementValueNet ?? 0)}
        budget={rowData.values?.budgetValue}
      />,
      <FormattedCurrency amount={rowData.values?.invoiceValueNet} />,
      <FormattedCurrency amount={rowData.values?.paymentValueNet} />,
      <DifferenceElement value={rowData.values?.forecastValueNet} budget={rowData.values?.budgetValue} />,
      <FormattedCurrency amount={Math.abs((rowData.values?.budgetValue ?? 0) - (rowData.values?.forecastValueNet ?? 0))} />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailCommitment>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailCommitmentRow}
      type="commitment"
      expandedElements={expandedElements}
    />
  );
};

interface DetailContractRowProps {
  rowData?: DetailContract;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const DetailContractRow = ({ columns, level = 0, rowData, expandedElements }: DetailContractRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <div className="flex items-center">
        <PetitionIcon />
        <div className={classNames('mx-2 relative w-[0.5625rem] flex-none')}>
          <div className="w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
          {rowData.titles.length > 0 && expandedElements.includes(rowData.uid) && (
            <div className="w-px h-8 absolute ml-1 bg-slate-400 z-0" />
          )}
        </div>
        {rowData.name}
      </div>,
      <FormattedCurrency amount={rowData.values?.budgetValue} />,
      <FormattedCurrency amount={rowData.values?.mainContractValueNet} />,
      <FormattedCurrency amount={rowData.values?.supplementValueNet} />,
      <DifferenceElement
        value={(rowData.values?.mainContractValueNet ?? 0) + (rowData.values?.supplementValueNet ?? 0)}
        budget={rowData.values?.budgetValue}
      />,
      <FormattedCurrency amount={rowData.values?.invoiceValueNet} />,
      <FormattedCurrency amount={rowData.values?.paymentValueNet} />,
      <DifferenceElement value={rowData.values?.forecastValueNet} budget={rowData.values?.budgetValue} />,
      <FormattedCurrency amount={Math.abs((rowData.values?.budgetValue ?? 0) - (rowData.values?.forecastValueNet ?? 0))} />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailContract>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailContractRow}
      type="contract"
      expandedElements={expandedElements}
    />
  );
};

interface DetailTitleRowProps {
  rowData?: DetailContractTitle;
  level?: number;
  columns?: React.ReactElement[];
  lastElement: boolean;
  expandedElements: string[];
}

const DetailTitleRow = ({ columns, level = 0, rowData, lastElement, expandedElements }: DetailTitleRowProps) => {
  if (rowData && !columns) {
    columns = [
      <div>{rowData.code}</div>,
      <div className="flex items-center">
        <ReceiptAndChangeIcon className="h-5 px-0.5" />
        <div className="mx-2 relative w-[0.5625rem] flex-none">
          <div className="w-px h-8 absolute ml-1 -mt-9 bg-slate-400 z-0" />
          <div className="w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
          {!lastElement && <div className="w-px h-8 absolute ml-1 bg-slate-400 z-0" />}
        </div>
        {rowData.name}
      </div>,
      <FormattedCurrency amount={rowData.values?.budgetValue} />,
      <FormattedCurrency amount={rowData.values?.mainContractValueNet} />,
      <FormattedCurrency amount={rowData.values?.supplementValueNet} />,
      <DifferenceElement
        value={(rowData.values?.mainContractValueNet ?? 0) + (rowData.values?.supplementValueNet ?? 0)}
        budget={rowData.values?.budgetValue}
      />,
      <FormattedCurrency amount={rowData.values?.invoiceValueNet} />,
      <FormattedCurrency amount={rowData.values?.paymentValueNet} />,
      <DifferenceElement value={rowData.values?.forecastValueNet} budget={rowData.values?.budgetValue} />,
      <FormattedCurrency amount={Math.abs((rowData.values?.budgetValue ?? 0) - (rowData.values?.forecastValueNet ?? 0))} />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailContractTitle>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailTitleRow}
      type="title"
      expandedElements={expandedElements}
    />
  );
};

interface ReportDetailsCollapsingRowProps<T> {
  rowData?: T & {
    childElements?: DetailCatalogElement[] | null;
    commitments?: DetailCommitment[];
    contracts?: DetailContract[];
    titles?: DetailContractTitle[];
    uid: string;
  };
  level?: number;
  columns?: React.ReactElement[];
  // FP: Using any here because different components are passed in as props
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  RowComponent: any;
  type: 'catalog' | 'commitment' | 'contract' | 'title';
  expandedElements: string[];
}

const ReportDetailsCollapsingRow = <T extends object>({
  columns,
  level = 0,
  rowData,
  RowComponent,
  type,
  expandedElements,
}: ReportDetailsCollapsingRowProps<T>) => {
  const hasChildren =
    (rowData?.childElements && rowData.childElements.length > 0) ||
    (rowData?.commitments && rowData.commitments.length > 0) ||
    (rowData?.contracts && rowData.contracts.length > 0) ||
    (rowData?.titles && rowData.titles.length > 0);

  const dispatch = useDispatch();

  return (
    <>
      <div
        className={classNames('flex items-center px-4 hover:bg-slate-50 transition-colors', {
          'bg-gray-50 font-bold border-b border-slate-300 text-[14px]': level === 0,
          'bg-white border-b border-slate-300 border-dotted text-[12px]': level > 0,
        })}
      >
        {hasChildren ? (
          <ToggleButton
            open={expandedElements.includes(rowData?.uid)}
            onClick={() =>
              dispatch(
                setExpandedDetailReportIds(
                  expandedElements.includes(rowData?.uid)
                    ? expandedElements.filter((x) => x !== rowData?.uid)
                    : [...expandedElements, rowData?.uid],
                ),
              )
            }
          />
        ) : (
          <div className="w-8">&nbsp;</div>
        )}
        <div className="flex flex-grow h-9 justify-center items-center">
          {columns?.map((column, index) => (
            <div
              key={index}
              className={classNames('h-full flex flex-col justify-center py-2', {
                'w-[80px] text-[12px] text-right px-2': index === 0,
                'w-[350px] text-[12px] truncate': index === 1,
                'flex-grow basis-0 min-w-[28px] text-right px-4': index > 1,
                'text-slate-500': type === 'title',
                'border-r-4 border-slate-300': index === 2 || index === 5 || index === 7,
                'border-r border-slate-300': index === 3 || index === 4 || index === 6,
                'border-r-0': index === 8,
              })}
            >
              {column}
            </div>
          ))}
        </div>
      </div>

      {hasChildren && (
        <Collapsible open={expandedElements.includes(rowData?.uid ?? '')}>
          {rowData.childElements?.map((child, index) => (
            <RowComponent key={index} rowData={child} level={level + 1} expandedElements={expandedElements} />
          ))}
          {rowData.commitments?.map((commitment, index) => (
            <DetailCommitmentRow
              key={index}
              rowData={commitment}
              level={level + 1}
              expandedElements={expandedElements}
            />
          ))}
          {rowData.contracts?.map((contract, index) => (
            <DetailContractRow key={index} rowData={contract} level={level + 1} expandedElements={expandedElements} />
          ))}
          {rowData.titles?.map((title, index) => (
            <DetailTitleRow
              key={index}
              rowData={title}
              level={level + 1}
              lastElement={rowData.titles ? index === rowData.titles.length - 1 : false}
              expandedElements={expandedElements}
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};
