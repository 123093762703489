import i18n from 'i18next';
import { settings } from '@client/shared/store';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend, { LocizeBackendOptions } from 'i18next-locize-backend';
import HttpBackend from 'i18next-http-backend';
import { setDefaultOptions, getDefaultOptions, Locale } from 'date-fns';
import { de, enGB, enUS, fr } from 'date-fns/locale';

export const supportedLanguages = ['en', 'en-gb',  'de', 'fr'];

const locizeOptions: LocizeBackendOptions = {
  projectId: 'ddcc9e2b-3e9e-4da0-889c-5fc3c2fba676',
  referenceLng: 'en',
};

// TODO: FP: move once we have a proper settings store and settings backed by the server
let appLocale: string;
let appLanguage: string;


export const getLocale = (language: string) => {
  let locale: Locale;
  switch (language) {
    case ('de'): {
      locale = de;
      break;
    }
    case ('fr'): {
      locale = fr;
      break;
    }
    case ('en-gb'): {
      locale = enGB;
      break;
    }
    default:
      locale = enUS;
  }
  return locale;
}

/**
 * Initialize the i18n instance.
 * If we are in dev mode, we will use the LocizeBackend to load translations from the locize service. Otherwise, we will use the HttpBackend to load translations from the assets folder.
 */
export const initI18n = () => {
  const browserLanguage = navigator.language.slice(0, 2);

  // set default date-fns locale
  setDefaultOptions({
    locale: getLocale(browserLanguage)
  });

  ({ code: appLocale } = (getDefaultOptions() as { locale?: { code: string } }).locale ?? { code: browserLanguage });
  appLanguage = browserLanguage;

  let backendOptions;

  // only load translations directly from locize if we are in dev mode
  if (settings.devMode) {
    i18n.use(LocizeBackend);
    backendOptions = locizeOptions;
  } else {
    i18n.use(HttpBackend);
    backendOptions = {
      loadPath: 'assets/locales/{{lng}}/{{ns}}.json',
    };
  }

  i18n.use(LanguageDetector).use(initReactI18next);

  i18n.init({
    defaultNS: 'client', // default namespace used if not passed to translation function
    ns: ['client'], // namespaces to load
    supportedLngs: supportedLanguages, // languages we are supporting
    returnNull: false, // FP: disable return null from translate function to avoid typing issues where string | undefined expected
    backend: backendOptions,
    fallbackLng: 'en', // language to use if translations in user language are not available.
    load: 'languageOnly', // language codes to lookup (e.g. languageOnly => 'en')
    debug: settings.devMode, // enable debug mode
    lng: browserLanguage, // language to use
  });
};

/**
 * This function sets the language and locale to be used by the i18n library and date-fns.
 * If the language is not supported, the default language is used.
 * @param language the language to use
 * @param locale the locale to use
 */
export const setLanguageAndLocale = async (language: string, locale?: string) => {
  const languageToUse = supportedLanguages.includes(language) ? language : 'en';
  const localeToUse = locale ?? languageToUse;

  setDefaultOptions({
    locale: getLocale(localeToUse)
  });
  
  ({ code: appLocale } = (getDefaultOptions() as { locale?: { code: string } }).locale ?? { code: localeToUse });
  appLanguage = languageToUse;

  await i18n.changeLanguage(languageToUse);
};

/**
 * Returns the current language and locale for application.
 * @return {object} An object containing the current language and locale.
 */
export const getLanguageAndLocale = () => {
  return {
    language: appLanguage,
    locale: appLocale,
  };
};

export { i18n };
