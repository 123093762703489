import { ShortInvoiceReadModel, useApiDeleteInvoiceMutation, useApiDeleteInvoiceByContractMutation } from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface InvoiceDeleteModalProps extends ModalOnCloseProps {
  invoice: ShortInvoiceReadModel;
}

export const InvoiceDeleteModal = ({ invoice, onClose }: InvoiceDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const [deleteInvoice, { isLoading : isDeleting }] = useApiDeleteInvoiceMutation();
  const [deleteInvoiceByContract, { isLoading : isDeletingByContract }] = useApiDeleteInvoiceByContractMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      if (invoice.contractId){
        await safeMutation(
          deleteInvoiceByContract,
          {
            invoiceId: invoice.id,
            contractId: invoice.contractId,
            projectId: loadedProjectId ?? '',
            calculationModelId: loadedVariantId ?? ''
          },
          isDeletingByContract
        );
      } else{
        await safeMutation(
          deleteInvoice,
          {
            invoiceId: invoice.id,
            projectId: loadedProjectId ?? '',
            calculationModelId: loadedVariantId ?? ''
          },
          isDeleting
        );
      }

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={t('projectControl.deleteTitle', { code: invoice.code })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isDeleting && <LoadingIndicator text={t('projectControl.deleteInvoiceLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectControl.invoiceDeleteHint')}</HintBox>
    </ConfirmModal>
  );
};
