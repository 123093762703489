import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiAdminCreateKlippaConfigurationsApiArg,
  ApiAdminCreateKlippaConfigurationsApiResponse,
  ApiAdminDeleteLicenseApiArg,
  ApiAdminDeleteLicenseApiResponse,
  ApiAdminDeleteLicenseGrantApiArg,
  ApiAdminDeleteLicenseGrantApiResponse,
  ApiAdminGetKlippaConfigurationsApiArg,
  ApiAdminGetKlippaConfigurationsApiResponse,
  ApiAdminGetLicenseGrantsApiArg,
  ApiAdminGetLicenseGrantsApiResponse,
  ApiAdminGetLicensesApiArg,
  ApiAdminGetLicensesApiResponse,
  ApiAdminGetTenantAuthProjectsApiArg,
  ApiAdminGetTenantAuthProjectsApiResponse,
  ApiAdminGetTenantAuthSubjectsApiArg,
  ApiAdminGetTenantAuthSubjectsApiResponse,
  ApiAdminGetTenantsApiArg,
  ApiAdminGetTenantsApiResponse,
  ApiAdminGetTenantWorkflowsApiArg,
  ApiAdminGetTenantWorkflowsApiResponse,
  ApiAdminPostActivateLicenseGrantApiArg,
  ApiAdminPostActivateLicenseGrantApiResponse,
  ApiAdminPostCopyProjectToTenantApiArg,
  ApiAdminPostCopyProjectToTenantApiResponse,
  ApiAdminPostCopyWorkflowApiArg,
  ApiAdminPostCopyWorkflowApiResponse,
  ApiAdminPostCreateLicenseApiArg,
  ApiAdminPostCreateLicenseApiResponse,
  ApiAdminPostCreateLicenseGrantApiArg,
  ApiAdminPostCreateLicenseGrantApiResponse,
  ApiAdminPostCreateTenantApiArg,
  ApiAdminPostCreateTenantApiResponse,
  ApiAdminPostCreateUserApiArg,
  ApiAdminPostCreateUserApiResponse,
  ApiAdminPostDeactivateLicenseGrantApiArg,
  ApiAdminPostDeactivateLicenseGrantApiResponse,
  ApiAdminPostEditSubdomainApiArg,
  ApiAdminPostEditSubdomainApiResponse,
  ApiAdminPostUpdateLicenseApiArg,
  ApiAdminPostUpdateLicenseApiResponse,
  ApiAdminPostUpdateLicenseGrantApiArg,
  ApiAdminPostUpdateLicenseGrantApiResponse,
  ApiAdminUpdateKlippaConfigurationsApiArg,
  ApiAdminUpdateKlippaConfigurationsApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiAdminEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiAdminGetLicenses: {
    providesTags: (
      _result: ApiAdminGetLicensesApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiAdminGetLicensesApiArg,
    ) => [{ type: ApiTagTypes.Licenses, id: 'LIST' }],
  },
  apiAdminGetLicenseGrants: {
    providesTags: (
      _result: ApiAdminGetLicenseGrantsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiAdminGetLicenseGrantsApiArg,
    ) => [{ type: ApiTagTypes.LicenseGrants, id: 'LIST' }],
  },  
  apiAdminGetTenants: {
    providesTags: (
      _result: ApiAdminGetTenantsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiAdminGetTenantsApiArg,
    ) => [{ type: ApiTagTypes.AdminTenants, id: 'LIST' }],
  },
  apiAdminGetTenantAuthSubjects: {
    providesTags: (
      _result: ApiAdminGetTenantAuthSubjectsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiAdminGetTenantAuthSubjectsApiArg,
    ) => [{ type: ApiTagTypes.AdminTenantUsers, id: 'LIST' }],
  },
  apiAdminGetTenantAuthProjects: {
    providesTags: (
      _result: ApiAdminGetTenantAuthProjectsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiAdminGetTenantAuthProjectsApiArg,
    ) => [{ type: ApiTagTypes.AdminTenantProjects, id: 'LIST' }],
  },
  apiAdminGetTenantWorkflows: {
    providesTags: (
      _result: ApiAdminGetTenantWorkflowsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiAdminGetTenantWorkflowsApiArg,
    ) => [{ type: ApiTagTypes.AdminWorkflows, id: 'LIST' }],
  },
  apiAdminGetKlippaConfigurations: {
    providesTags: (
      _result: ApiAdminGetKlippaConfigurationsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiAdminGetKlippaConfigurationsApiArg,
    ) => [{ type: ApiTagTypes.AdminKlippaConfigurations, id: 'LIST' }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiAdminPostCreateLicense: {
    invalidatesTags: (
      _result: ApiAdminPostCreateLicenseApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostCreateLicenseApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Licenses, id: 'LIST' }] : []),
  },
  apiAdminPostUpdateLicense: {
    invalidatesTags: (
      _result: ApiAdminPostUpdateLicenseApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostUpdateLicenseApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.Licenses, id: 'LIST' },
      { type: ApiTagTypes.LicenseGrants, id: 'LIST' }      
    ] : []),
  },
  apiAdminDeleteLicense: {
    invalidatesTags: (
      _result: ApiAdminDeleteLicenseApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminDeleteLicenseApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.Licenses, id: 'LIST' },
      { type: ApiTagTypes.LicenseGrants, id: 'LIST' }
    ] : []),
  },
  apiAdminPostCreateLicenseGrant: {
    invalidatesTags: (
      _result: ApiAdminPostCreateLicenseGrantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostCreateLicenseGrantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.LicenseGrants, id: 'LIST' }] : []),
  },
  apiAdminPostUpdateLicenseGrant: {
    invalidatesTags: (
      _result: ApiAdminPostUpdateLicenseGrantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostUpdateLicenseGrantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.LicenseGrants, id: 'LIST' }] : []),
  },
  apiAdminDeleteLicenseGrant: {
    invalidatesTags: (
      _result: ApiAdminDeleteLicenseGrantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminDeleteLicenseGrantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.LicenseGrants, id: 'LIST' }] : []),
  },
  apiAdminPostActivateLicenseGrant: {
    invalidatesTags: (
      _result: ApiAdminPostActivateLicenseGrantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostActivateLicenseGrantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.LicenseGrants, id: 'LIST' }] : []),
  },
  apiAdminPostDeactivateLicenseGrant: {
    invalidatesTags: (
      _result: ApiAdminPostDeactivateLicenseGrantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostDeactivateLicenseGrantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.LicenseGrants, id: 'LIST' }] : []),
  },
  apiAdminPostCreateTenant: {
    invalidatesTags: (
      _result: ApiAdminPostCreateTenantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostCreateTenantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.AdminTenants, id: 'LIST' }] : []),
  },
  apiAdminPostCreateUser: {
    invalidatesTags: (
      _result: ApiAdminPostCreateUserApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostCreateUserApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.AdminTenantUsers, id: 'LIST' }] : []),
  },
  apiAdminPostCopyProjectToTenant: {
    invalidatesTags: (
      _result: ApiAdminPostCopyProjectToTenantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostCopyProjectToTenantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.AdminTenantProjects, id: 'LIST' }] : []),
  },
  apiAdminPostEditSubdomain: {
    invalidatesTags: (
      _result: ApiAdminPostEditSubdomainApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostEditSubdomainApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.AdminTenants, id: 'LIST' }] : []),
  },
  apiAdminPostCopyWorkflow: {
    invalidatesTags: (
      _result: ApiAdminPostCopyWorkflowApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminPostCopyWorkflowApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.AdminWorkflows, id: 'LIST' }, ApiTagTypes.WorkflowActivities] : []),
  },
  apiAdminCreateKlippaConfigurations: {
    invalidatesTags: (
      _result: ApiAdminCreateKlippaConfigurationsApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminCreateKlippaConfigurationsApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.AdminKlippaConfigurations, id: 'LIST' }] : []),
  },
  apiAdminUpdateKlippaConfigurations: {
    invalidatesTags: (
      _result: ApiAdminUpdateKlippaConfigurationsApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAdminUpdateKlippaConfigurationsApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.AdminKlippaConfigurations, id: 'LIST' }] : []),
  },
};
