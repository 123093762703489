import {
  ApiGetBaseReportApiArg,
  ApiGetBaseReportApiResponse,
  ApiGetBugetReportApiArg,
  ApiGetBugetReportApiResponse,
  ApiGetComparisonReportApiArg,
  ApiGetComparisonReportApiResponse,
  ApiGetForecastReportApiArg,
  ApiGetForecastReportApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiReportingEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetBaseReport: {
    providesTags: (_result: ApiGetBaseReportApiResponse, _error: FetchBaseQueryError, args: ApiGetBaseReportApiArg) => [
      { type: ApiTagTypes.BaseReport, id: args.projectId },
    ],
  },
  apiGetBugetReport: {
    providesTags: (_result: ApiGetBugetReportApiResponse, _error: FetchBaseQueryError, args: ApiGetBugetReportApiArg) => [
      { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
    ],
  },
  apiGetForecastReport: {
    providesTags: (_result: ApiGetForecastReportApiResponse, _error: FetchBaseQueryError, args: ApiGetForecastReportApiArg) => [
      { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
    ],
  },
  apiGetComparisonReport: {
    providesTags: (_result: ApiGetComparisonReportApiResponse, _error: FetchBaseQueryError, args: ApiGetComparisonReportApiArg) => [
      { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
      { type: ApiTagTypes.ComparisonReport, id: args.targetId }
    ],
  },
};
