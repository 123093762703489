import cn from 'classnames';
import { SlideOverSortableListItemData } from './SlideOverSortableList';
import { Fragment } from 'react';

export interface SlideOverSortableListItemProps {
  className?: string;
  item: SlideOverSortableListItemData;
  gridCols: string;
  level?: number;
  earlyCompact?: boolean;
}

export const SlideOverSortableListItem = ({
  item,
  gridCols,
  className,
  level = 0,
  earlyCompact = false,
}: SlideOverSortableListItemProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center py-3 border-b last:border-b-0 gap-3 truncate relative',
        {
          'md:flex-row md:gap-0': !earlyCompact,
          'lg:flex-row lg:gap-0': earlyCompact,
        },
        className,
      )}
    >
      <div
        className={cn('w-full flex gap-3 whitespace-nowrap', {
          'md:w-1/3 md:flex-none md:pr-3 md:gap-6': !earlyCompact,
          'lg:w-1/3 lg:flex-none lg:pr-3 lg:gap-6': earlyCompact,
          'pl-[55px]': level === 1,
        })}
      >
        <div
          className={cn('hidden w-8 justify-center items-center flex-none', {
            'md:flex': !earlyCompact,
            'lg:flex': earlyCompact,
          })}
        >
          {item.icon}
        </div>
        <div
          className={cn('w-full flex flex-col gap-1 justify-center pr-10', {
            'md:pr-0': !earlyCompact,
            'lg:pr-0': earlyCompact,
          })}
        >
          <div className="text-lg leading-none font-bold truncate">{item.name}</div>
          {item.description && (
            <div className="text-[11px] leading-none text-gray-400 truncate">{item.description}</div>
          )}
        </div>
      </div>
      <div
        className={cn(
          'w-full grid gap-3 whitespace-nowrap',
          {
            'md:flex-1 md:justify-between md:pl-3': !earlyCompact,
            'lg:flex-1 lg:justify-between lg:pl-3': earlyCompact,
          },
          gridCols,
        )}
      >
        {item.cols?.map((col, i) => (
          <Fragment key={`list-item-${i}-${col.header}`}>
            <div
              className={cn(
                'flex flex-col gap-0.5 justify-center leading-tight text-[13px] text-gray-400 whitespace-break-spaces',
                {
                  'md:hidden': !earlyCompact,
                  'lg:hidden': earlyCompact,
                },
              )}
            >
              {col.header}
            </div>
            <div className="flex flex-col justify-center gap-0.5 leading-none text-right">
              <span>{col.value}</span>
              <span className="text-[10px] text-gray-400 font-light">{col.title}</span>
            </div>
          </Fragment>
        ))}
      </div>
      {item.contextMenu && (
        <div
          className={cn(
            'rotate-90 transform absolute -right-1 top-2 w-10 flex-none whitespace-nowrap flex items-center justify-end',
            {
              'md:rotate-0 md:transform-none md:right-auto md:top-auto md:relative': !earlyCompact,
              'lg:rotate-0 lg:transform-none lg:right-auto lg:top-auto lg:relative': earlyCompact,
            },
          )}
        >
          {item.contextMenu}
        </div>
      )}
    </div>
  );
};
