import { fork, put, takeEvery } from 'redux-saga/effects';
import { api } from '@client/shared/api';
import toast from 'react-hot-toast';
import { i18n } from '@client/shared/utilities';
import { setExpandedCompanyIds, TOGGLED_EXPANDED_IDS } from '@client/project/store';

function* onUserDataChange() {
  yield takeEvery([api.endpoints.apiPostUpdateUserName.matchFulfilled], function* () {
    toast.success(i18n.t('app.notificationUserNameChanged'));
    yield;

    yield put(
      api.endpoints.apiPostCheckSession.initiate(undefined, {
        // forceRefetch: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any
    );
  });

  yield takeEvery([api.endpoints.apiPostUpdateUserName.matchRejected], function* () {
    toast.error(i18n.t('app.notificationUserNameChangeFailed'));
    yield;
  });

  yield takeEvery([api.endpoints.apiPostUpdateUserEmail.matchFulfilled], function* () {
    toast.success(i18n.t('app.notificationEmailChanged'));
    yield;

    yield put(
      api.endpoints.apiPostCheckSession.initiate(undefined, {
        // forceRefetch: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any
    );
  });

  yield takeEvery([api.endpoints.apiPostUpdateUserEmail.matchRejected], function* () {
    toast.error(i18n.t('app.notificationEmailChangeFailed'));
    yield;
  });
}

function* onAvatarChange() {
  yield takeEvery(
    [
      api.endpoints.apiPostCreateTenantAvatar.matchFulfilled,
      api.endpoints.apiDeleteTenantAvatar.matchFulfilled,
      api.endpoints.apiPostCreateUserAvatar.matchFulfilled,
      api.endpoints.apiDeleteUserAvatar.matchFulfilled,
    ],
    function* () {
      yield put(
        api.endpoints.apiPostCheckSession.initiate(undefined, {
          // forceRefetch: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any
      );
    }
  );
}

function* onTenantDataChange() {
  yield takeEvery(
    [
      api.endpoints.apiPostUpdateTenantOwner.matchFulfilled,
      api.endpoints.apiPostUpdateTenantBillingAddress.matchFulfilled,
      api.endpoints.apiPostUpdateTenantNames.matchFulfilled,
    ],
    function* () {
      yield put(
        api.endpoints.apiPostCheckSession.initiate(undefined, {
          // forceRefetch: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any
      );
    }
  );
}

function* loadCompanyExpandedIds() {
  const serializedExpandedIds = localStorage.getItem(TOGGLED_EXPANDED_IDS);
  if (serializedExpandedIds) {
    const preselectedExpandedIds = JSON.parse(serializedExpandedIds)
    if (preselectedExpandedIds.Company && typeof preselectedExpandedIds.Company !== 'undefined') {
      yield put(setExpandedCompanyIds(preselectedExpandedIds.Company))
    }
  }
}

export function* settingsSaga() {
  yield fork(onUserDataChange);
  yield fork(onAvatarChange);
  yield fork(onTenantDataChange);
  yield fork(loadCompanyExpandedIds);
}
