import {
  Wizard,
  Button,
  Form,
  FormField,
  Modal,
  FormRefHandle,
  FileInput,
  TaskCompletedDottedIcon,
  WizardSlideHeader,
  WizardSlideContent,
  LoadingIndicator,
  WizardSelectListItemProps,
  WizardSelectList,
  CreateContractIcon,
  PaidBillDottedIcon,
  FormWatch,
  TextInput,
  ContractNumberIcon,
  NumberInput,
  EstimateIcon,
  AssignContractCustomIcon,
  DocumentDottedIcon,
  ComboSelect,
  ContactIcon,
  ComboSelectAdditionalOption,
  ComboSelectOption,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  InvoiceCreateFormValidationSchema,
  InvoiceCreateFormValidationValues,
} from '../InvoiceCreateFormValidationValues';
import { useLoadedProject, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import {
  ApiPostCreateContractApiArg,
  ApiPostCreateInvoiceByContractWithSourceDocumentApiArg,
  ApiPostCreateInvoiceByContractWithSourceDocumentApiResponse,
  ApiPostCreateInvoiceWithSourceDocumentApiArg,
  ApiPostCreateInvoiceWithSourceDocumentApiResponse,
  ContractReadModel,
  InvoiceType,
  ProbisErrorDataType,
  SelectContractInvoiceCalculationSchemeReadModel,
  SelectContractReadModel,
  UserDefinedFieldPayload,
  useApiGetDefaultValuesQuery,
  useApiGetProjectCompanyBranchesQuery,
  useApiGetSelectableContractsQuery,
  useApiPostCreateContractMutation,
  useApiPostCreateInvoiceByContractWithSourceDocumentMutation,
  useApiPostCreateInvoiceWithSourceDocumentMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
  useApiProjectGetUserDefinedFieldsDefinitionQuery,
} from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { InvoiceCreateAdditionalFields, InvoiceCreateWithContractSlides, InvoiceCreateWithNewContractSlides } from '.';
import { InvoiceCoverSheetEdit } from '../InvoiceTab';
import { addDays } from 'date-fns';
import { AddCompanyModal } from '../../AddCompanyModal';
import { EditUserDefinedFields } from '../..';

interface InvoiceCreateWizardProps {
  isOpen: boolean;
  onClose: () => void;
  contract?: ContractReadModel
  openInvoice?: (createdInvoiceId: string) => void
}

export type InvoiceCreateWizardVariant = 'noContract' | 'withContract' | 'newContract' | 'byContract';

export const InvoiceCreateWizard = ({ isOpen, onClose, contract, openInvoice }: InvoiceCreateWizardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: loadedProject, isLoading: isLoadingProject } = useLoadedProject();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: defaultValues, isFetching: isLoadingDefaultValues } = useApiGetDefaultValuesQuery();
  const [postContract, { isLoading: isCreatingContract }] = useApiPostCreateContractMutation();

  const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetSelectableContractsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !isOpen,
    },
  );

  const { data: branches, isFetching: isFetchingCompanies } = useApiGetProjectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId || !isOpen,
    },
  );

  const [postInvoice, { isLoading: isCreating }] = useApiPostCreateInvoiceWithSourceDocumentMutation();
  const [postInvoiceByContract, { isLoading: isCreatingByContract }] =
    useApiPostCreateInvoiceByContractWithSourceDocumentMutation();

  const formRef = useRef<FormRefHandle<InvoiceCreateFormValidationValues>>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const [getNextCode] = useApiPostGenerateNextProjectObjectCodeMutation();

  useEffect(() => {
    const getNextInvoiceCode = async () => {
      if (loadedProjectId && loadedVariantId) {
        const isGettingNextCode = false;
        const nextCodeResponse = await safeMutation(
          getNextCode,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: { projectObjectType: 'Invoice' }
          },
          isGettingNextCode,
        );
        if (typeof nextCodeResponse !== 'undefined') {
          formRef.current?.setValue('code', nextCodeResponse.code);
        }
      }
    }
    if (isOpen){
      getNextInvoiceCode();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const [currentStep, setCurrentStep] = useState(0);

  const [createdInvoice, setCreatedInvoice] = useState<string | undefined | null>(undefined);
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [codeError, setCodeError] = useState(false);

  const [wizardVariant, setWizardVariant] = useState<InvoiceCreateWizardVariant>('noContract');

  const [selectedContract, setSelectedContract] = useState<SelectContractReadModel | undefined>(undefined);
  const [budgetAssignment, setBudgetAssignment] = useState<string | undefined>(undefined);

  const [contractRequiredMessage, setContractRequiredMessage] = useState(false);

  // For new contracts
  const [contractCode, setContractCode] = useState('');
  const [contractName, setContractName] = useState('');
  const [createdContract, setCreatedContract] = useState<string | undefined>(undefined);

  // For invoicing party and recipient
  const [companiesOptions, setCompaniesOptions] = useState<ComboSelectOption[]>([]);
  const [isOpenAddCompanyModal, setIsOpenAddCompanyModal] = useState<boolean>(false);
  const [createCompanyField, setCreateCompanyField] = useState<'contractor' | 'client'>('contractor');
  const [createdCompany, setCreatedCompany] = useState<string | undefined>(undefined);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true)
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();

  const { data: userDefinedFieldsDefinitionResponse, isFetching: isLoadingUserDefinedFieldsDefinition } =
  useApiProjectGetUserDefinedFieldsDefinitionQuery({
    projectId: loadedProjectId ?? 'unset',
  });

const mandatoryProjectUserDefinedFieldDefinitions = useMemo(() => {
  if (userDefinedFieldsDefinitionResponse) {
    const defs = userDefinedFieldsDefinitionResponse.userDefinedFieldsDefinition;
    return defs.filter((def) => {
      return def.elementType === 'Invoice' && def.isRequired;
    });
  }
  return [];
}, [userDefinedFieldsDefinitionResponse]);

  const vat = useMemo(() => {
    return loadedProject?.project?.payload?.vat;
  }, [loadedProject?.project?.payload?.vat]);

  useEffect(() => {
    if (contract) {
      setWizardVariant('byContract');
    }
  }, [contract]);

  const handleCreateContract = async () => {
    setCodeError(false);
    try {
      const formData = new FormData();
      formData.append('Code', contractCode);
      formData.append('Name', contractName);
      formData.append('Vat', String(vat ?? 0));
      formData.append('Discount', String(0));

      if (defaultValues?.dueDateDeadline) {
        formData.append('DueDateDeadline', String(defaultValues.dueDateDeadline));
      }
      if (defaultValues?.cashDiscountDeadline) {
        formData.append('CashDiscountDeadline', String(defaultValues.cashDiscountDeadline));
      }

      const args = {
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedVariantId ?? '',
        body: formData,
      } as ApiPostCreateContractApiArg;

      const response = await safeMutation(postContract, args, isCreating);

      if (response?.contractId) {
        setCreatedContract(response?.contractId);
        formRef.current?.setValue('contractId', response?.contractId);
        // move to next page in wizard
        setCurrentStep(currentStep + 1);
      }
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    if (createdContract && fetchedContracts?.length) {
      const fetchedCreatedContract = fetchedContracts.find((c) => c.id === createdContract);
      setSelectedContract(fetchedCreatedContract);
    }
  }, [createdContract, fetchedContracts]);

  useEffect(() => {
    const options = branches?.map((branch) => {
      return {
        label: branch.companyName === branch.name ? branch.name : `${branch.companyName} - ${branch.name}`,
        value: branch.id,
      };
    });
    setCompaniesOptions(options ?? []);
  }, [branches]);

  const invoiceCalculationSchemes: SelectContractInvoiceCalculationSchemeReadModel[] = useMemo(() => {
    if (selectedContract?.invoiceCalculationSchemes.length) {
      return selectedContract.invoiceCalculationSchemes;
    } else if (contract?.invoiceCalculationSchemes.length) {
      return contract.invoiceCalculationSchemes.map((scheme) => {
        return {
          id: scheme.invoiceCalculationScheme.invoiceCalculationSchemeId,
          name: scheme.invoiceCalculationScheme.name,
          type: scheme.invoiceCalculationScheme.type,
        };
      }) as SelectContractInvoiceCalculationSchemeReadModel[];
    }
    return [];
  }, [selectedContract?.invoiceCalculationSchemes, contract?.invoiceCalculationSchemes]);

  useEffect(() => {
    if (wizardVariant !== 'noContract' && !selectedContract) {
      setContractRequiredMessage(true);
    } else {
      setContractRequiredMessage(false);
    }

    if (invoiceCalculationSchemes.length && formRef.current) {
      const values = formRef.current.getValues();
      if (values.type) {
        const foundSchemeByType = invoiceCalculationSchemes.find((scheme) => scheme.type === values.type);
        if (foundSchemeByType) {
          formRef.current.setValue('calculationSchemeId', foundSchemeByType.id);
        }
      }
    }
  }, [selectedContract, wizardVariant, invoiceCalculationSchemes]);

  useEffect(() => {
    if (createdCompany && formRef?.current && (branches?.length )) {
      const foundBranch = branches?.find((branch) => branch.id === createdCompany || branch.companyId === createdCompany);
      if (foundBranch) {
        if (createCompanyField === 'contractor') {
          formRef?.current?.setValue('invoicingPartyId', foundBranch.id);
        } else {
          formRef?.current?.setValue('invoiceRecipientId', foundBranch.id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCompany, branches]);

  const handleOnSlideChange = async (index: number) => {
    setContractRequiredMessage(false);
    setCodeError(false);

    const validationStartIndex = wizardVariant === 'byContract' ? 0 : wizardVariant === 'noContract' ? 1 : wizardVariant === 'withContract' ? 2 : 4;

    if (index > validationStartIndex) {
      if (formRef.current) {
        await formRef?.current.validateForm();
        const { isValid } = formRef.current.getState();
        if (!isValid) {
          await formRef.current.submitForm();
          await formRef.current.validateForm();
          const { isValid } = formRef.current.getState();
          if (isValid) {
            setCurrentStep(index);
          }
        }
        if (isValid) {
          setCurrentStep(index);
        }
      }
    } else {
      if (index === 2 && wizardVariant === 'withContract' && !selectedContract) {
        setContractRequiredMessage(true);
      } else {
        setCurrentStep(index);
      }
    }
  };
  const handleSubmit = async () => {
    setIsFormSubmitted(true)
    if (formRef.current && customFieldsAreValid) {
      const values = formRef.current.getValues();

      await handleCreateInvoice(values);
    }
  };

  const handleError = (e: unknown) => {
    const error = e as FetchBaseQueryError;
    const data = error.data as ProbisErrorDataType;
    if (data?.code === 'error.invoice.code_already_exists' || data?.code === 'error.contract.code_already_exists') {
      setCodeError(true);
      if (wizardVariant === 'byContract') {
        setCurrentStep(0);
      } else if (wizardVariant === 'withContract') {
        setCurrentStep(2);
      } else if (wizardVariant === 'noContract') {
        setCurrentStep(1);
      } else if (wizardVariant === 'newContract' && data.code === 'error.invoice.code_already_exists') {
        setCurrentStep(3);
      }
    }
  };

  const handleCreateInvoice = async (data: InvoiceCreateFormValidationValues) => {
    try {
      const formData = new FormData();
      formData.append('Code', data.code);
      formData.append('Claim', String(data.claim ?? 0));
      formData.append('Type', data.type);
      formData.append('Vat', String(data.vat ?? vat ?? 0));

      if (data.files && data.files.length > 0) {
        formData.append('InvoiceSourceDocument', data.files[0]);
      }

      if (data.calculationSchemeId) {
        formData.append('CalculationSchemeId', data.calculationSchemeId);
      }
      formData.append('ExternalCode', data.externalCode ?? '');
      formData.append(
        'InvoiceDate',
        data.invoiceDate ? formatDateOnly(new Date(data.invoiceDate)) : formatDateOnly(new Date()),
      );
      formData.append(
        'DateOfReceipt',
        data.dateOfReceipt ? formatDateOnly(new Date(data.dateOfReceipt)) : formatDateOnly(new Date()),
      );

      if (data.dueDate) {
        formData.append('DueDate', formatDateOnly(new Date(data.dueDate)));
      }
      if (data.cashDiscountDate) {
        formData.append('CashDiscountDate', formatDateOnly(new Date(data.cashDiscountDate)));
      }
      formData.append('Comment', data.comment ?? '');
      formData.append('UserDefinedFieldsPayload', JSON.stringify(udfUpdatePayload));

      let response:
        | ApiPostCreateInvoiceWithSourceDocumentApiResponse
        | ApiPostCreateInvoiceByContractWithSourceDocumentApiResponse
        | undefined = undefined;
      if (data.contractId || contract?.id) {
        const args = {
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? '',
          contractId: contract?.id ?? data.contractId,
          body: formData,
        } as ApiPostCreateInvoiceByContractWithSourceDocumentApiArg;

        response = await safeMutation(postInvoiceByContract, args, isCreatingByContract);
      } else {
        formData.append('InvoicingPartyId', data.invoicingPartyId ?? '');
        formData.append('InvoiceRecipientId', data.invoiceRecipientId ?? '');
        if (budgetAssignment) {
          formData.append('CostElementId', budgetAssignment);
        }
        const args = {
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? '',
          body: formData,
        } as ApiPostCreateInvoiceWithSourceDocumentApiArg;

        response = await safeMutation(postInvoice, args, isCreating);
      }

      if (response) {
        setCreatedInvoice(response.invoiceId);
      }

      // move to last page in wizard
      setCurrentStep(currentStep + 1);
    } catch (e) {
      handleError(e);
    }
  };

  const setVariant = (variant: InvoiceCreateWizardVariant) => {
    setWizardVariant(variant);
    formRef.current?.setValue('variant', variant);
    // need to delay, otherwise transition not working correctly
    setTimeout(() => {
      nextButtonRef?.current?.click();
    }, 100);
  };

  const availableContracts = useMemo(() => {
    return fetchedContracts?.filter((contract) => {
      return !contract.isPxContract;
    });
  }, [fetchedContracts]);

  const items: WizardSelectListItemProps[] = useMemo(() => {
    const listItems: WizardSelectListItemProps[] = [
      {
        title: t('projectControl.createWizard.withoutContract'),
        subtitle: t('projectControl.createWizard.withoutContractSubtitle'),
        icon: <DocumentDottedIcon className="w-full h-full" />,
        handleClick: () => {
          formRef.current?.setValue('contractId', undefined);
          setSelectedContract(undefined);
          setVariant('noContract');
        },
        active: wizardVariant === 'noContract',
      },
    ];

    if (availableContracts?.length) {
      listItems.push({
        title: t('projectControl.createWizard.withContract'),
        subtitle: t('projectControl.createWizard.withContractSubtitle'),
        icon: <AssignContractCustomIcon className="w-full h-full" />,
        handleClick: availableContracts?.length ? () => setVariant('withContract') : undefined,
        active: wizardVariant === 'withContract',
        disabled: !availableContracts?.length,
      });
    }

    listItems.push({
      title: t('projectControl.createWizard.newContract'),
      subtitle: t('projectControl.createWizard.newContractSubtitle'),
      icon: <CreateContractIcon className="w-full h-full" />,
      handleClick: () => {
        formRef.current?.setValue('contractId', undefined);
        setSelectedContract(undefined);
        setVariant('newContract');
      },
      active: wizardVariant === 'newContract',
    });

    return listItems;
  }, [t, wizardVariant, availableContracts?.length]);

  const defaultFormValues = useMemo(() => {
    const now = new Date();

    const cashDiscountDate = selectedContract?.cashDiscountDeadline
      ? formatDateOnly(addDays(now, selectedContract.cashDiscountDeadline))
      : contract?.cashDiscountDeadline
        ? formatDateOnly(addDays(now, contract.cashDiscountDeadline))
        : null;
    const dueDate = selectedContract?.dueDateDeadline
      ? formatDateOnly(addDays(now, selectedContract.dueDateDeadline))
      : contract?.dueDateDeadline
        ? formatDateOnly(addDays(now, contract.dueDateDeadline))
        : null;

    const defaultType = 'Single' as InvoiceType;
    let calculationSchemeId: string | undefined = undefined;
    const foundSchemeByType = invoiceCalculationSchemes.find((scheme) => scheme.type === defaultType);
    if (foundSchemeByType) {
      calculationSchemeId = foundSchemeByType.id;
    }

    return {
      code: '',
      claim: 0,
      type: 'Single' as InvoiceType,
      vat: vat,
      files: undefined,
      variant: 'noContract' as InvoiceCreateWizardVariant,
      dateOfReceipt: formatDateOnly(now),
      invoiceDate: formatDateOnly(now),
      dueDate: dueDate,
      cashDiscountDate: cashDiscountDate,
      externalCode: '',
      comment: '',
      invoicingPartyId: null,
      invoiceRecipientId: null,
      calculationSchemeId: calculationSchemeId
    };
  }, [
    selectedContract?.cashDiscountDeadline,
    selectedContract?.dueDateDeadline,
    contract?.cashDiscountDeadline,
    contract?.dueDateDeadline,
    invoiceCalculationSchemes,
    vat,
  ]);

  const reset = useCallback(() => {
    formRef.current?.resetForm(defaultFormValues);
    setCurrentStep(0);
    setCreatedInvoice(undefined);
    setIsCreateDisabled(true);
    setCodeError(false);
    setWizardVariant(contract ? 'byContract' : 'noContract');
    setSelectedContract(undefined);
    setBudgetAssignment(undefined);
    setContractRequiredMessage(false);
    setContractCode('');
    setContractName('');
    setCreatedContract(undefined);
  }, [defaultFormValues, contract]);

  const isNextDisabled = useCallback(
    (code?: string) => {
      if (wizardVariant === 'noContract') {
        return currentStep > 0 && !code;
      } else if (wizardVariant === 'withContract') {
        return (currentStep > 0 && !selectedContract) || (currentStep > 1 && !code);
      } else if (wizardVariant === 'newContract') {
        return (currentStep > 0 && (!contractCode || !contractName)) || (currentStep > 1 && !createdContract);
      }
      return false;
    },
    [wizardVariant, currentStep, selectedContract, contractCode, contractName, createdContract],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} onAfterLeave={reset}>
      {(isFetchingContracts || isLoadingDefaultValues || isFetchingCompanies || isLoadingProject  || isLoadingUserDefinedFieldsDefinition) &&
        !isCreatingContract &&
        !isCreating &&
        !isCreatingByContract && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      {(isCreating || isCreatingByContract) && (
        <LoadingIndicator text={t('projectControl.createWizard.createInvoiceLoadingIndicator')} mode="overlay-window" />
      )}
      {isCreatingContract && (
        <LoadingIndicator text={t('projectContract.wizardNewContractCreating')} mode="overlay-window" />
      )}
      <Form<InvoiceCreateFormValidationValues>
        validationSchema={InvoiceCreateFormValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        className="h-full"
      >
        <FormWatch<InvoiceCreateFormValidationValues>
          onChange={({ code }) => {
            setIsCreateDisabled(!code);
          }}
          fieldNames={['contractId', 'code']}
        >
          {({ contractId, code }) => (
            <Wizard
              currentStep={currentStep}
              onSlideChange={(step) => handleOnSlideChange(step)}
              className="w-[740px] h-[560px]"
            >
              <Wizard.Slides>
                {/* Select wizard variant  */}
                {!contract && (
                 <Wizard.Slide>
                    <WizardSlideHeader
                      title={t('projectContract.createNewInvoice')}
                      subTitle={t('projectControl.createWizard.subtitle')}
                    />
                    <WizardSlideContent>
                      <WizardSelectList
                        items={items}
                        grid
                        gridCols={items.length === 3 ? 'grid-cols-3' : 'grid-cols-2 max-w-[340px] mx-auto'}
                      />
                    </WizardSlideContent>
                  </Wizard.Slide>
                )}

                {/* Select contract or... */}
                {wizardVariant === 'withContract' && (
                  <Wizard.Slide centered={false}>
                    <InvoiceCreateWithContractSlides
                      error={contractRequiredMessage}
                      setSelectedContract={(contract) => {
                        setBudgetAssignment(undefined);
                        setSelectedContract(contract);
                      }}
                      selectedContract={selectedContract}
                    />
                  </Wizard.Slide>
                )}
                {/* ...create new contract  */}
                {wizardVariant === 'newContract' && (
                  <Wizard.Slide>
                    <InvoiceCreateWithNewContractSlides
                      contractCode={contractCode}
                      contractName={contractName}
                      setContractCode={(val) => {
                        if (val !== contractCode) {
                          setCodeError(false);
                        }
                        setContractCode(val);
                      }}
                      setContractName={setContractName}
                      codeError={codeError}
                    />
                  </Wizard.Slide>
                )}
                {/* success create new contract */}
                {wizardVariant === 'newContract' && (
                  <Wizard.Slide>
                    <WizardSlideHeader
                      icon={<TaskCompletedDottedIcon />}
                      title={t('projectContract.wizardNewContractComplete')}
                      subTitle={t('projectControl.createWizard.newContractCompleteDescription')}
                      fullHeight
                    />
                  </Wizard.Slide>
                )}

                {/* Invoice required fields  */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    icon={<PaidBillDottedIcon />}
                    title={t('projectContract.createNewInvoice')}
                    subTitle={t('projectControl.createWizard.slideRequiredFieldsDescription')}
                    fullHeight
                  />
                  <WizardSlideContent>
                    <div className="flex gap-1 items-start">
                      <FormField name="code">
                        {(control) => (
                          <TextInput
                            className="w-1/2 flex-1"
                            label={t('projectControl.invoiceCode')}
                            icon={<ContractNumberIcon className="h-6 w-6" />}
                            helperTextClassName="bg-transparent"
                            {...control}
                            isValidationValid={!codeError && control.isValidationValid}
                            showValidation={codeError || control.showValidation}
                            helperText={codeError ? t('error.invoice.code_already_exists') : control.helperText}
                            helperTextTruncate={false}
                          />
                        )}
                      </FormField>
                      <FormField name="claim">
                        {(control) => (
                          <NumberInput
                            className="w-1/2 flex-1"
                            label={t('projectControl.invoiceClaim')}
                            icon={<EstimateIcon className="h-6 w-6" />}
                            helperTextClassName="bg-transparent"
                            {...control}
                          />
                        )}
                      </FormField>
                    </div>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* Invoice Recipient and Invoicing Party if no contract selected  */}
                {wizardVariant === 'noContract' && (
                  <Wizard.Slide centered={false}>
                    <WizardSlideHeader
                      icon={<PaidBillDottedIcon />}
                      title={t('projectControl.createWizard.slideInvoicingPartyAndRecipientTitle')}
                      subTitle={t('projectControl.createWizard.slideInvoicingPartyAndRecipientDescription')}
                      fullHeight
                    />
                    <WizardSlideContent className="pt-6">
                      <FormField name="invoicingPartyId">
                        {(control) => (
                          <ComboSelect
                            label={t('projectControl.invoicingParty')}
                            icon={<ContactIcon className="h-6 w-6" />}
                            options={companiesOptions}
                            {...control}
                            nullable
                            additionalOptionOnClick={() => {
                              setIsOpenAddCompanyModal(true);
                              setCreateCompanyField('contractor');
                            }}
                            additionalOption={
                              <ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />
                            }
                          />
                        )}
                      </FormField>
                      <FormField name="invoiceRecipientId">
                        {(control) => (
                          <ComboSelect
                            label={t('projectControl.invoiceRecipient')}
                            icon={<ContactIcon className="h-6 w-6" />}
                            options={companiesOptions}
                            {...control}
                            nullable
                            additionalOptionOnClick={() => {
                              setIsOpenAddCompanyModal(true);
                              setCreateCompanyField('client');
                            }}
                            additionalOption={
                              <ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />
                            }
                          />
                        )}
                      </FormField>
                    </WizardSlideContent>
                  </Wizard.Slide>
                )}

                {/* Invoice type and ICS  */}
                <Wizard.Slide centered={false}>
                  <WizardSlideHeader
                    icon={<PaidBillDottedIcon />}
                    title={t('projectControl.createWizard.slideInvoiceBillingTitle')}
                    subTitle={
                      wizardVariant === 'noContract'
                        ? t('projectControl.createWizard.slideInvoiceBillingDescription')
                        : t('projectControl.createWizard.slideInvoiceBillingDescriptionWithContract')
                    }
                    fullHeight
                  />
                  <WizardSlideContent className="pt-6">
                    <InvoiceCoverSheetEdit
                      contractId={contract?.id ?? contractId}
                      invoiceCalculationSchemes={invoiceCalculationSchemes}
                      contractHasFinalInvoice={contract ? contract?.invoices.some((x) => x.type === 'Final') : selectedContract ? selectedContract.hasFinalInvoice : undefined}
                      defaultFormValues={defaultFormValues}
                      canEdit
                      inline={false}
                      formRef={formRef}
                      showTypeInput={!!selectedContract || !!contract}
                    />
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* Upload Source Document  */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    title={t('projectControl.createWizard.uploadDocument')}
                    subTitle={t('projectControl.createWizard.uploadDocumentDescription')}
                  />
                  <WizardSlideContent>
                    <FormField name="files">
                      {(control) => (
                        <FileInput
                          className="bg-white"
                          acceptedFileTypes={['application/pdf']}
                          multiple={false}
                          {...control}
                        />
                      )}
                    </FormField>
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* Additional fields  */}
                <Wizard.Slide centered={false}>
                  <WizardSlideHeader
                    title={t('projectControl.createWizard.slideAdditionalDataTitle')}
                    subTitle={t('projectControl.createWizard.slideAdditionalDataDescription')}
                  />
                  <WizardSlideContent>
                    <InvoiceCreateAdditionalFields
                      setBudgetAssignment={setBudgetAssignment}
                      formRef={formRef}
                      selectedContract={contract ?? selectedContract}
                    />
                  </WizardSlideContent>
                </Wizard.Slide>

                {/* UDFs  */}
                {mandatoryProjectUserDefinedFieldDefinitions.length > 0 && (
                  <Wizard.Slide centered={false}>
                    <WizardSlideHeader
                      title={t('app.wizardCustomFields')}
                      subTitle={t('app.wizardCustomFieldsDescription')}
                    />
                    <WizardSlideContent>
                      <EditUserDefinedFields
                        type="Invoice"
                        setUpdatePayload={setUdfUpdatePayload}
                        isSubmitted={isFormSubmitted}
                        updateIsValid={setCustomFieldsAreValid}
                        hasPadding={false}
                      />
                    </WizardSlideContent>
                  </Wizard.Slide>
                )}

                {/* Completed  */}
                <Wizard.Slide>
                  <WizardSlideHeader
                    icon={<TaskCompletedDottedIcon />}
                    title={t('projectControl.wizardNewInvoiceComplete')}
                    subTitle={t('projectControl.wizardNewInvoiceCompleteDescription')}
                    fullHeight
                  />
                </Wizard.Slide>
              </Wizard.Slides>
              <Wizard.Navigation>
                {({ count, isFirst, isLast, canGoPrevious, canGoNext, previous, next }) => (
                  <Modal.Controls
                    className="bg-white"
                  >
                    {(() => {
                      if (isLast || isFirst) {
                        return (
                          <Button variant="text" onClick={onClose}>
                            {t('common.close')}
                          </Button>
                        );
                      } else if (currentStep === 2 && wizardVariant === 'newContract') {
                        return (
                          <Button variant="text" onClick={() => setCurrentStep(0)}>
                            {t('common.cancel')}
                          </Button>
                        );
                      } else {
                        return (
                          <Button variant="text" disabled={!canGoPrevious} onClick={previous}>
                            {t('common.back')}
                          </Button>
                        );
                      }
                    })()}
                    {(() => {
                      if (isLast) {
                        return (
                          <Button
                            variant="primary"
                            onClick={() => {
                              onClose();
                              if (createdInvoice) {
                                if (contract && openInvoice) {
                                  openInvoice(createdInvoice);
                                } else {
                                  navigate(
                                    ROUTES_CONFIG.PROJECT_INVOICE_VIEW.path
                                      .replace(':id', loadedProjectId ?? '')
                                      .replace(':invoiceId', createdInvoice),
                                  );
                                }
                              }
                            }}
                          >
                            {t('projectControl.createWizard.goToCreatedInvoiceButton')}
                          </Button>
                        );
                      } else if (currentStep === count - 2) {
                        return (
                          <Button variant="primary" onClick={handleSubmit} disabled={isCreateDisabled}>
                            {t('common.create')}
                          </Button>
                        );
                      } else if (currentStep === 1 && wizardVariant === 'newContract') {
                        return (
                          <Button
                            variant="primary"
                            disabled={!canGoNext || isNextDisabled(code)}
                            onClick={handleCreateContract}
                          >
                            {t('projectControl.createWizard.createContract')}
                          </Button>
                        );
                      } else {
                        return (
                          <Button
                            variant="primary"
                            disabled={!canGoNext || isNextDisabled(code)}
                            onClick={next}
                            innerRef={nextButtonRef}
                          >
                            {t('common.next')}
                          </Button>
                        );
                      }
                    })()}
                  </Modal.Controls>
                )}
              </Wizard.Navigation>
            </Wizard>
          )}
        </FormWatch>
      </Form>
      <AddCompanyModal
        isOpen={isOpenAddCompanyModal}
        onClose={(branchId) => {
          setCreatedCompany(branchId);
          setIsOpenAddCompanyModal(false);
        }}
      />
    </Modal>
  );
};
