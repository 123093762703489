import {
  AuthSubjectReadModel,
  TenantUserState,
  useApiPostUpdateAuthSubjectMembershipMutation,
  useApiPostCreateUserInvitationMutation,
} from '@client/shared/api';
import {
  BadgeGroup,
  Badge,
  Modal,
  ContextMenuItem,
  ContextMenu,
  TrashIcon,
  LoadingIndicator
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useState } from 'react';
import { LockClosedIcon, LockOpenIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { UserDeleteModal } from './UserDeleteModal';
import { UserAvatar } from '../../UserAvatar';

interface UserRowProps {
  person: AuthSubjectReadModel;
  onEdit: (person: AuthSubjectReadModel) => void;
}

export const UserRow = ({ onEdit, person }: UserRowProps) => {
  const { t } = useTranslation();

  const [showRemove, setShowRemove] = useState<boolean>(false);
  const [inviteUser, { isLoading: isInviting }] = useApiPostCreateUserInvitationMutation();
  const [postStatus, { isLoading: isUpdatingStatus }] = useApiPostUpdateAuthSubjectMembershipMutation();

  const handleResendInvitation = async (email: string) => {
    try {
      await safeMutation(
        inviteUser,
        {
          body: {
            email: email,
          },
        },
        isInviting
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateStatus = async (status: TenantUserState) => {
    try {
      await safeMutation(
        postStatus,
        {
          subjectId: person.userId,
          body: {
            membership: status,
          },
        },
        isUpdatingStatus
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnCloseModal = async (confirm: boolean) => {
    if (confirm) {
      await handleUpdateStatus('Removed');
    }

    setShowRemove(false);
  };

  const getContextMenuItems = (user: AuthSubjectReadModel): ContextMenuItem[] => {
    const result = [];

    const optResend = {
      label: t('auth.inviteRenewal'),
      icon: <PaperAirplaneIcon />,
      onClick: () => handleResendInvitation(user.email),
    };

    const optActivate = {
      label: t('auth.activate'),
      icon: <LockOpenIcon />,
      onClick: () => handleUpdateStatus('Active'),
    };

    const optDisable = {
      label: t('auth.disable'),
      icon: <LockClosedIcon />,
      onClick: () => handleUpdateStatus('Disabled'),
    };

    const optRemove = {
      label: t('common.delete'),
      icon: <TrashIcon />,
      onClick: () => setShowRemove(true),
    };

    if (user.invitePending) {
      result.push(optResend);
      result.push(optRemove);
    } else {
      switch (user.membership) {
        case 'Active':
          result.push(optDisable);
          result.push(optRemove);
          break;
        case 'Disabled':
          result.push(optActivate);
          result.push(optRemove);
          break;
      }
    }

    return result;
  };

  return (
    <div className={classNames("flex w-full border-b border-gray-100 hover:bg-slate-100 items-center py-2",{
      'cursor-pointer': person.invitePending !== true
    })}>
      {(isUpdatingStatus || isInviting) && <LoadingIndicator mode="overlay-window" text={t('common.loading')} />}
      <div className="w-16 flex-none flex items-center justify-center" onClick={() => (person.invitePending !== true ? onEdit(person) : undefined)}>
        <UserAvatar
          userId={person.userId}
          userName={person.name}
          lastUpdate={person.lastUpdate}
          hasAvatar={person.hasAvatar}
          classNames={classNames('w-10 h-10', { 'bg-slate-300': person.membership === 'Disabled' })}
        />
      </div>
      <div className="flex items-center flex-none w-[calc(100%_-_4rem_-_2rem)]" onClick={() => (person.invitePending !== true ? onEdit(person) : undefined)}>
        <div className="w-4/12 flex-none flex flex-col min-w-0 overflow-hidden">
          <div className="font-bold">{person.name !== ' ' ? person.name : person.email}</div>
          <div className="text-sm text-gray-500 truncate max-w-[200px]">{person.email}</div>
        </div>
        <div className="w-3/12 flex-none">
          <BadgeGroup>
            <>
              {person.isTenantOwner && <Badge variant="danger" text={t('app.settingsBadgeOwner')} />}
              {person.roles.assigned.map((role, index) => (
                <Badge variant="info" text={role.name} key={index} />
              ))}
              {person.roles.inherited.length > 0 && (
                <div className="flex flex-row border rounded-full border-gray-500 text-gray-500 text-xs pl-1 pr-2">
                  <ArrowDownIcon className="w-4" />
                  {t('auth.inheritedRoles')}
                </div>
              )}
            </>
          </BadgeGroup>
        </div>
        <div className="w-3/12 flex flex-wrap items-center font-bold flex-none">
          {person.groups?.map((group, index) => (
            <div key={index} className="flex">
              {index !== 0 && <div className="mx-1">{'|'}</div>}
              {group.name}
            </div>
          ))}
        </div>
        <div className="w-2/12 flex-none pl-2">
          {!person.isTenantOwner && !person.invitePending && (
            <div className="flex flex-row">
              {person.membership === 'Active' && (
                <div className="flex flex-row truncate">
                  <div className="w-2 h-2 mt-2 mr-2 rounded-full align-center bg-green-500 flex-none" />
                  <div className="truncate">{t('auth.active')}</div>
                </div>
              )}
              {person.membership === 'Disabled' && (
                <div className="flex flex-row truncate">
                  <div className="w-2 h-2 mt-2 mr-2 rounded-full align-center bg-gray-500 flex-none" />
                  <div className="truncate">{t('auth.disabled')}</div>
                </div>
              )}
            </div>
          )}

          {!person.isTenantOwner && person.invitePending && (
            <div className="flex flex-row flex-none truncate">
              <div className="w-2 h-2 mt-2 mr-2 rounded-full align-center bg-yellow-500 flex-none" />
              <div className="truncate">{t('auth.pending')}</div>
            </div>
          )}
        </div>
      </div>

      <div className="w-8 flex-none flex text-gray-500 hover:text-black justify-end">
        <ContextMenu items={getContextMenuItems(person)} />
      </div>

      <Modal isOpen={showRemove} onClose={() => setShowRemove(false)} variant="small">
        <UserDeleteModal name={person.name} onClose={(confirm) => handleOnCloseModal(confirm)} />
      </Modal>
    </div>
  );
};
