import {
  Button,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
  Card,
  AddSmallIcon,
  PencilIcon, LoadingIndicator
} from '@client/shared/toolkit';
import {
  ExternalApiReadModel,
  ApiAccessReadModel,
  useApiPostActiveApiAccessMutation,
  useApiPostTestApiAccessMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AddAccessItemModal } from './AddAccessItemModal';
import { safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';
import toast from 'react-hot-toast';

interface ApiSlideOverProps extends SlideOverOnCloseProps {
  api: ExternalApiReadModel | null;
}

export const ApiSlideOver = ({ api, onClose }: ApiSlideOverProps) => {
  const { t } = useTranslation();
  const [isAddAccessItemModalOpen, setIsAddAccessItemModalOpen] = useState(false);
  const [selectedApiAcessItem, setSelectedApiAcessItem] = useState<ApiAccessReadModel | undefined>(undefined);
  const [postActiveAccessItem, { isLoading: isActivating }] = useApiPostActiveApiAccessMutation();
  const [postTestApiAccess, { isLoading: isTesting }] = useApiPostTestApiAccessMutation();

  const editAcessItem = (apiAccessItem: ApiAccessReadModel) => {
    setSelectedApiAcessItem(apiAccessItem);
    setIsAddAccessItemModalOpen(true);
  };

  const activateAcessItem = async (apiAccessItem: ApiAccessReadModel) => {
    try {
      await safeMutation(
        postActiveAccessItem,
        {
          apiAccessId: apiAccessItem.id ?? '',
        },
        isActivating,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const testAccessItem = async (apiAccessItem: ApiAccessReadModel) => {
    try {
      await safeMutation(
        postTestApiAccess,
        {
          accessId: apiAccessItem.id,
        },
        isTesting,
      );
      toast.success(t('app.ConnectTestSuccess'));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {(isActivating || isTesting) && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <SlideOver.Header title={`${api?.api.name} API`} backgroundClassName="bg-sky-900" onClose={onClose} />
      <SlideOver.Content className="p-8 overflow-y-scroll">
        <div className="mb-5 text-3xl font-bold text-slate-500 w-full text-center"></div>

        {api?.accessItems && api?.accessItems.length > 0 ? (
          <>
            {api?.accessItems
              .slice()
              .sort((a, b) => (b.isActive ? 1 : -1))
              .map((item, i) => (
                <div key={i}>
                  <Card className={classNames('relative my-2')}>
                    <div className="pr-2">
                      <div className="right-5 absolute">
                        <PencilIcon
                          className="hover:opacity-70 cursor-pointer w-5"
                          onClick={() => editAcessItem(item)}
                        />
                      </div>
                      <div className="pr-4 break-words">
                        {item.isActive && (
                          <div className="mb-2 font-bold text-center text-green-600">{t('app.ConnectActive')}</div>
                        )}
                        {api?.api?.needsApiUrl && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectApiUrl')}:</div>
                            <div className="w-2/3 pl-1">{item.apiUrl}</div>
                          </div>
                        )}
                        {api?.api?.needsApiKey && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectApiKey')}:</div>
                            <div className="w-2/3 pl-1">{item.apiKey}</div>
                          </div>
                        )}
                        {api?.api?.needsUserName && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectUserName')}:</div>
                            <div className="w-2/3 pl-1">{item.userName}</div>
                          </div>
                        )}
                        {api?.api?.needsCustomConfig && (
                          <div className="flex">
                            <div className="w-1/3 font-bold">{t('app.ConnectCustomConfig')}:</div>
                            <div className="w-2/3 pl-1">{item.customConfig}</div>
                          </div>
                        )}
                      </div>
                      {!item.isActive && (
                        <div className="w-full flex justify-center mt-4">
                          <Button variant="primary" onClick={() => activateAcessItem(item)} className="mx-2">
                            {t('app.ConnectActivateItem')}
                          </Button>
                        </div>
                      )}
                    </div>
                    <div>
                      {api.api.apiIsTestable && (
                        <Button variant="secondary" onClick={() => testAccessItem(item)} className="mt-2">
                          {t('app.ConnectTest')}
                        </Button>
                      )}
                    </div>
                  </Card>
                </div>
              ))}
            <div
              className="flex justify-end h-32 -mt-6 pr-2 relative z-50"
              onClick={() => setIsAddAccessItemModalOpen(true)}
            >
              <AddSmallIcon className="bg-gray-700 text-white rounded-full w-10 h-10 p-3 hover:scale-110 transition-transform duration-200 cursor-pointer" />
            </div>
          </>
        ) : (
          <div className="mb-10 w-full flex justify-center">
            <Button variant="primary" onClick={() => setIsAddAccessItemModalOpen(true)}>
              {t('app.ConnectAddAccessItem')}
            </Button>
          </div>
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" onClick={() => onClose(false)}>
          {t('common.close')}
        </Button>
      </SlideOver.Controls>
      <Modal
        isOpen={isAddAccessItemModalOpen}
        onClose={() => {
          setSelectedApiAcessItem(undefined);
          setIsAddAccessItemModalOpen(false);
        }}
      >
        <AddAccessItemModal
          api={api?.api}
          apiAccessItem={selectedApiAcessItem}
          onClose={() => {
            setSelectedApiAcessItem(undefined);
            setIsAddAccessItemModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
