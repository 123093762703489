import { BaseSelectOption, SelectedCustomIcon, UnselectedCustomIcon } from '@client/shared/toolkit';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Portal } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useMemo, useState } from 'react';

export type ReportDetailSortingType = 'none' | 'DIN 276';

interface ReportDetailSortingSelectProps {
  className?: string;
  selectedSorting?: ReportDetailSortingType;
  onChange?: (sorting: ReportDetailSortingType) => void;
}

export const ReportDetailSortingSelect = ({ className, selectedSorting, onChange }: ReportDetailSortingSelectProps) => {
  const { t } = useTranslation();

  const handleSelectSorting = (sorting: ReportDetailSortingType) => {
    onChange?.(sorting);
  };

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const sortingOptions: BaseSelectOption[] = useMemo(() => {
    return [
      {
        label: t('reporting.filterByNone'),
        value: 'none',
      },
      {
        label: t('reporting.filterByDin276CustomField'),
        value: 'DIN 276',
      },
    ];
  }, [t])

  const selectedSortingLabel = useMemo(() => {
    return sortingOptions.find((option) => option.value === selectedSorting)?.label ?? t('reporting.sortByDefault')
  }, [sortingOptions, selectedSorting, t])

  return (
    <Listbox
      as="div"
      className={cn(
        'w-full h-12 flex items-center text-sm font-medium text-gray-600 bg-gray-400 bg-opacity-10 rounded-full hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        className,
      )}
    >
      <div ref={setTargetElement} className="w-full px-6">
        <ListboxButton className="flex w-full">
          <div className="flex-grow truncate">
            <div className="text-left text-xs text-gray-600 -mb-1 font-normal">{t('reporting.filterBy')}</div>
            <div className="text-left font-bold pr-3 text-[15px] truncate">{selectedSortingLabel}</div>
          </div>
          <ChevronDownIcon className="w-5 h-5 my-auto text-gray-600 flex-shrink-0" />
        </ListboxButton>
      </div>
      <Portal>
        <div ref={setPopperElement} style={{ ...styles.popper }} {...attributes.popper} className="z-50">
          <ListboxOptions className="absolute right-0 mt-4 w-96 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="divide-y divide-gray-100">
              {sortingOptions.map((option, i) => (
                <ListboxOption key={`sorting-option-${i}`} value={option}>
                  {({ focus }) => (
                    <div
                      onClick={() => handleSelectSorting(option.value as ReportDetailSortingType)}
                      className={cn('text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm', {
                        'bg-gray-100': focus,
                      })}
                    >
                      <div className="grow text-left w-7/12 pr-3 my-auto">
                        <div className="text-[15px] font-bold">
                          {option.label}
                          <span className="font-normal text-gray-600 text-[15px]"></span>{' '}
                        </div>
                      </div>
                      <div className="w-12 text-right my-auto">
                        {selectedSorting === option.value ? (
                          <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                        ) : (
                          <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                        )}
                      </div>
                    </div>
                  )}
                </ListboxOption>
              ))}
            </div>
          </ListboxOptions>
        </div>
      </Portal>
    </Listbox>
  );
};
