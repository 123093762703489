import { useLoadedProjectId, useLoadedVariantId, useToggledCostFilters } from '@client/project/store';
import {
  BudgetAssignmentReadModel,
  CalculationModelCostsQueryResponse,
  CostCatalogElementDto,
  CostElementDto,
} from '@client/shared/api';
import { Button, CheckBox, TextHighlighter, TriangleIcon } from '@client/shared/toolkit';
import { formatLargeNumber, formatNumber } from '@client/shared/utilities';
import classNames from 'classnames';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { UserDefinedFieldsSearch } from '.';

const getAvailableBudget = (costElement: CostCatalogElementDto | CostElementDto) => {
  if (costElement && 'availableBudget' in costElement) {
    return costElement.availableBudget ?? 0;
  } else if (costElement && 'modelValues' in costElement && 'availableBudget' in costElement.modelValues) {
    return costElement.modelValues.availableBudget ?? 0;
  } else if (costElement && 'totalValue' in costElement) {
    return costElement.totalValue ?? 0;
  } else if (costElement && 'modelValues' in costElement) {
    return costElement.modelValues.total ?? costElement.modelValues.effectiveValue ?? 0;
  } else {
    return 0;
  }
};

interface CostElementMultiSelectProps {
  costData: CalculationModelCostsQueryResponse | undefined;
  costElements: string[];
  updateCostElements: (costElementIds: string[]) => void;
  budgetAssignments?: BudgetAssignmentReadModel[];
  showBudget?: boolean;
  showFx?: boolean;
  showVat?: boolean;
  showControls?: boolean;
  onlyWithCostElementId?: boolean;
  onClose: () => void;
  cascading?: boolean;
}

export const CostElementMultiSelect = ({
  costData,
  costElements,
  updateCostElements,
  budgetAssignments,
  showBudget,
  showFx,
  showVat,
  showControls,
  onlyWithCostElementId,
  onClose,
  cascading = false,
}: CostElementMultiSelectProps) => {
  const [selectedCostElements, setSelectedCostElements] = useState<string[]>(costElements);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const toggledCostFilters = useToggledCostFilters();

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  useEffect(() => {
    if (!showControls && selectedCostElements !== costElements) {
      updateCostElements(selectedCostElements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCostElements]);

  const addOrRemoveExpandedId = (elementId: string) => {
    if (expandedIds.includes(elementId)) {
      setExpandedIds((prev) => prev.filter((x) => x !== elementId));
    } else {
      setExpandedIds((prev) => [...prev, elementId]);
    }
  };

  const searchCostElements = (costElements: (CostElementDto | null | undefined)[], searchValue: string): string[] => {
    if (!costElements) return [];
    else {
      const costElementIds: string[] = [];
      costElements.forEach((costElement) => {
        if (!costElement) return;
        if (
          toggledCostFilters.length > 0
            ? searchResults.length > 0 && searchResults.includes(costElement.elementId)
            : costElement.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
              (showVat && costElement.vat && costElement.vat.toString().includes(searchValue))
        ) {
          costElementIds.push(costElement.elementId);
        }
      });
      return costElementIds;
    }
  };

  const searchCostGroupElements = (costElements: CostCatalogElementDto[], searchValue: string): string[] => {
    if (!costElements) return [];
    else {
      const costElementIds: string[] = [];

      costElements.forEach((costElement) => {
        if (
          toggledCostFilters.length > 0
            ? searchResults.length > 0 && costElement.costElementId && searchResults.includes(costElement.costElementId)
            : costElement.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
              costElement.code?.toLowerCase().includes(searchValue.toLowerCase()) ||
              (showVat && costElement.modelValues.vat && costElement.modelValues.vat.toString().includes(searchValue))
        ) {
          costElementIds.push(costElement.id ?? '');
        }
        if (costElement.children.length > 0) {
          const childCostElementIds = searchCostGroupElements(costElement.children, searchValue);
          if (childCostElementIds.length > 0) {
            costElementIds.push(costElement.id ?? '');
          }
          costElementIds.push(...childCostElementIds);
        }
        if (costElement.costElements.length > 0) {
          const childCostElementIds = searchCostElements(
            costElement.costElements.map((x) => x.modelCostElement),
            searchValue,
          );
          if (childCostElementIds.length > 0) {
            costElementIds.push(costElement.id ?? '');
          }
          costElementIds.push(...childCostElementIds);
        }
      });
      return costElementIds.filter((x) => x !== '');
    }
  };

  const matchingCostElements = useMemo(() => {
    if (searchValue.length === 0) return [];

    const catalogElements = costData?.payload.catalogElements ?? [];
    return searchCostGroupElements(catalogElements, searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, costData?.payload.catalogElements]);

  useEffect(() => {
    setExpandedIds(matchingCostElements);
  }, [matchingCostElements]);

  const getAllShownCostElements = (costElements: CostCatalogElementDto[], level = 0): string[] => {
    const shownCostElements: string[] = [];

    costElements.forEach((costElement) => {
      if (onlyWithCostElementId && !costElement.costElementId) return;
      shownCostElements.push(costElement.id);
      if (costElement.costElements.length > 0 && expandedIds.includes(costElement.id)) {
        shownCostElements.push(
          ...costElement.costElements.filter((x) => x.modelCostElement).map((x) => x.modelCostElement?.elementId ?? ''),
        );
      }
      if (costElement.children.length > 0 && expandedIds.includes(costElement.id)) {
        shownCostElements.push(...getAllShownCostElements(costElement.children, level + 1));
      }
    });
    return shownCostElements;
  };

  const recursiveAddOrRemoveChildren = (costElement: CostCatalogElementDto, action: 'add' | 'remove') => {
    if (action === 'add') {
      setSelectedCostElements((prev) => [...prev, costElement.id]);
      if (costElement.costElements.length > 0) {
        setSelectedCostElements((prev) => [
          ...prev,
          ...costElement.costElements.filter((x) => x.modelCostElement).map((x) => x.modelCostElement?.elementId ?? ''),
        ]);
      }
    } else if (action === 'remove') {
      setSelectedCostElements((prev) => prev.filter((x) => x !== costElement.id));
      if (costElement.costElements.length > 0) {
        setSelectedCostElements((prev) =>
          prev.filter((x) => !costElement.costElements.find((y) => y.modelCostElement?.elementId === x)),
        );
      }
    }
    
    if (costElement.children.length > 0) {
      costElement.children.forEach((child) => {
        recursiveAddOrRemoveChildren(child, action);
      });
    }
  };

  const renderCostElement = (costElement: CostElementDto, lastIdx: boolean) => {
    const restBudget = showBudget
      ? Math.round(
          ((budgetAssignments?.find((x) => x.costElementId === costElement.elementId)?.budgetNet ?? 0) +
            getAvailableBudget(costElement)) *
            100,
        ) / 100
      : 0;

    return (
      <div className="w-full flex">
        <div className="w-4 mr-2" />
        <div className="bg-white w-full p-2 flex items-center border-t">
          <div className="w-1/6" />
          <div className="w-5/6 flex justify-between items-center">
            <div className="pl-2 flex items-center ">
              <span className="w-2 mr-2 flex justify-center items-center">
                <div className="relative w-2 flex-none">
                  {lastIdx ? (
                    <div className="w-px h-5 absolute ml-1 -mt-5 bg-slate-400 z-0" />
                  ) : (
                    <div className="w-px h-10 absolute ml-1 -mt-5 bg-slate-400 z-0" />
                  )}
                  <div className="w-[0.5625rem] h-[0.5625rem] absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
                </div>
              </span>
              <span className="w-72 truncate text-ellipsis text-slate-600 h-full">
                <TextHighlighter
                  text={costElement.description ? costElement.description : t('projectCalculate.unnamedElement')}
                  highlighted={searchValue
                    .split(',')
                    .filter((x) => x !== '')
                    .map((x) => x.trim())}
                />
                {showVat && costElement.vat && (
                  <TextHighlighter
                    text={` (${costElement.vat}%)`}
                    highlighted={searchValue
                      .split(',')
                      .filter((x) => x !== '')
                      .map((x) => x.trim())}
                  />
                )}
                {showFx && costElement.hasFormula && <sup className="font-normal italic text-slate-600"> &nbsp;fx</sup>}
              </span>
            </div>

            <div className="flex">
              {showBudget && (
                <div className="bg-slate-400 rounded-full px-2 text-sm text-white font-bold mr-2">
                  {restBudget.toString().length > 6 ? formatLargeNumber(restBudget) : formatNumber(restBudget)}
                </div>
              )}
              <CheckBox
                checked={selectedCostElements.includes(costElement.elementId)}
                onChange={(value) => {
                  if (value) {
                    setSelectedCostElements((prev) => [...prev, costElement.elementId ?? '']);
                  } else {
                    setSelectedCostElements((prev) => prev.filter((x) => x !== costElement.elementId));
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCostGroupElement = (costGroupElement: CostCatalogElementDto, level: number) => {
    if (onlyWithCostElementId && !costGroupElement.costElementId) return null;
    const elementId = costGroupElement.id;

    const restBudget = showBudget
      ? Math.round(
          (budgetAssignments?.find((x) => x.costElementId === costGroupElement.costElementId)?.budgetNet ??
            0 + getAvailableBudget(costGroupElement)) * 100,
        ) / 100
      : 0;

    const isExpanded = expandedIds.includes(elementId);

    return (
      <div>
        <div className="flex items-center divide-y">
          {costGroupElement.children.length > 0 || costGroupElement.costElements.length > 0 ? (
            <div
              className="flex items-center justify-center hover:bg-gray-100 cursor-pointer mr-2 w-4 h-4"
              onClick={() => addOrRemoveExpandedId(elementId ?? '')}
            >
              {isExpanded ? (
                <TriangleIcon className="w-3 h-3 text-gray-500 rotate-90" />
              ) : (
                <TriangleIcon className="w-3 h-3 text-gray-500" />
              )}
            </div>
          ) : (
            <div className="mr-2 w-4 h-4" />
          )}
          <div
            className={classNames('bg-white w-full p-2 flex items-center truncate text-ellipsis', {
              'font-bold': level === 0,
            })}
          >
            <span className="w-1/6 truncate">
              <TextHighlighter
                text={costGroupElement.code ?? ''}
                highlighted={searchValue
                  .split(',')
                  .filter((x) => x !== '')
                  .map((x) => x.trim())}
              />
            </span>

            <div className="pl-2 w-5/6 flex justify-between items-center">
              <span className="w-2 mr-2 flex justify-center items-center">
                <div
                  className={classNames('relative w-2 flex-none', {
                    invisible:
                      !isExpanded ||
                      costGroupElement.costElements.length === 0 ||
                      (searchValue !== '' &&
                        !costGroupElement.costElements.some((x) =>
                          matchingCostElements.includes(x.modelCostElement?.elementId ?? ''),
                        )),
                  })}
                >
                  <div className="w-px h-8 absolute ml-1 bg-slate-400 z-0" />
                  <div className="w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
                </div>
              </span>
              <span className="truncate w-72 text-ellipsis ">
                <TextHighlighter
                  text={
                    costGroupElement.description ? costGroupElement.description : t('projectCalculate.unnamedElement')
                  }
                  highlighted={searchValue
                    .split(',')
                    .filter((x) => x !== '')
                    .map((x) => x.trim())}
                />
                {showVat && costGroupElement.modelValues.vat && (
                  <TextHighlighter
                    text={` (${costGroupElement.modelValues.vat}%)`}
                    highlighted={searchValue
                      .split(',')
                      .filter((x) => x !== '')
                      .map((x) => x.trim())}
                  />
                )}

                {showFx && costGroupElement.hasFormula && (
                  <sup className="font-normal italic text-slate-600"> &nbsp;fx</sup>
                )}
              </span>
              <div className="flex-grow flex justify-end">
                {showBudget && (
                  <div className="bg-slate-400 rounded-full px-2 text-sm text-white font-bold mr-2">
                    {restBudget.toString().length > 6 ? formatLargeNumber(restBudget) : formatNumber(restBudget)}
                  </div>
                )}
                <CheckBox
                  checked={selectedCostElements.includes(elementId)}
                  onChange={(value) => {
                    if (value) {
                      cascading
                        ? recursiveAddOrRemoveChildren(costGroupElement, 'add')
                        : setSelectedCostElements((prev) => [...prev, elementId ?? '']);
                    } else {
                      cascading
                        ? recursiveAddOrRemoveChildren(costGroupElement, 'remove')
                        : setSelectedCostElements((prev) => prev.filter((x) => x !== elementId));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {isExpanded && (searchValue !== '' ? matchingCostElements.includes(elementId) : true) && (
          <>
            {costGroupElement.costElements.length > 0 &&
              costGroupElement.costElements
                .filter(
                  (costElement) =>
                    costElement.modelCostElement &&
                    (searchValue !== '' ? matchingCostElements.includes(costElement.modelCostElement.elementId) : true),
                )
                .map((costElement, i) => (
                  <div key={`costElement-${i}`}>
                    {costElement.modelCostElement &&
                      renderCostElement(
                        costElement.modelCostElement,
                        i + 1 ===
                          costGroupElement.costElements.filter((x) =>
                            searchValue !== ''
                              ? matchingCostElements.includes(x.modelCostElement?.elementId ?? '')
                              : true,
                          ).length,
                      )}
                  </div>
                ))}
            {costGroupElement.children.length > 0 &&
              costGroupElement.children
                .filter((costElement) => (searchValue !== '' ? matchingCostElements.includes(costElement.id) : true))
                .map((costElement, i) => (
                  <div key={`costGroupElement-${costElement.id}-${i}`}>
                    {renderCostGroupElement(costElement, level + 1)}
                  </div>
                ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="flex flex-col h-full justify-between w-full"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          updateCostElements(selectedCostElements);
          onClose();
        }
      }}
      tabIndex={0}
    >
      <div className="w-full pl-6 mb-2">
        <UserDefinedFieldsSearch
          searchValue={searchValue}
          updateSearchResults={(result: string[]) => {
            setSearchResults(result);
          }}
          handleSearchValueUpdate={(val) => {
            setSearchValue(val);
          }}
          udfElementTypes={['Cost']}
          searchDisabled={!loadedVariantId || !loadedProjectId}
          filterStore="Cost"
        />
      </div>
      <div className="overflow-y-auto h-full">
        {costData &&
          costData.payload.catalogElements &&
          costData.payload.catalogElements
            .filter((x) => x.id !== '00000000-0000-0000-0000-000000000000')
            .map((costElement, i) => {
              if (searchValue !== '' ? matchingCostElements.includes(costElement.id) : true)
                return (
                  <div key={`costGroupElement-${costElement.id}-${i}`}>{renderCostGroupElement(costElement, 0)}</div>
                );
              else return null;
            })}
      </div>

      {showControls && (
        <div className="flex justify-between items-center h-18 px-5 pt-2 border-t">
          <Button
            variant="text"
            className="mr-5"
            onClick={() =>
              setSelectedCostElements(
                getAllShownCostElements(
                  costData?.payload.catalogElements.filter((x) => x.id !== '00000000-0000-0000-0000-000000000000') ??
                    [],
                ),
              )
            }
          >
            {t('projectContract.budgetingSelectAll')}
          </Button>
          <div className="flex">
            <Button variant="secondary" className="mr-5" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateCostElements(selectedCostElements);
                onClose();
              }}
            >
              {t('common.select')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
