import { AuthGroupReadModel } from '@client/shared/api';
import { ArrowDownIcon, FolderIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Badge, BadgeGroup } from '@client/shared/toolkit';
import { UserAvatar } from '../../UserAvatar';

interface GroupRowProps {
  group: AuthGroupReadModel;
  handleEdit: (group: AuthGroupReadModel) => void;
}

export const GroupRow = ({ group, handleEdit }: GroupRowProps) => {
  return (
    <div>
      <div
        className="w-full flex flex-row py-2 text-gray-500 hover:text-black hover:bg-gray-100 cursor-pointer"
        onClick={() => handleEdit(group)}
      >
        <div className="flex mx-3">
          <FolderIcon className="w-6 " />
        </div>

        <div className="w-5/12 text-black">
          <div className="flex items-center">
            <div className="text-base font-bold text-gray-900">{group.name}</div>
            {group.comment && <div className="ml-2 text-base text-gray-500"> - {group.comment}</div>}
          </div>
          <div className="flex flex-row mt-1">
            <BadgeGroup>
              <>
                {group.roles.assigned.map((role, index) => (
                  <Badge variant="info" text={role.name} key={index} />
                ))}
                {group.roles.inherited.length > 0 && (
                  <div className="flex flex-row border rounded-full border-gray-500 text-gray-500 text-xs pl-1 pr-2">
                    <ArrowDownIcon className="w-4" />
                    Inherited roles
                  </div>
                )}
              </>
            </BadgeGroup>
          </div>
        </div>

        <div className="flex w-5/12 items-center text-black">
          {group.subjects.map((subject, index) => (
            <UserAvatar
              key={index}
              userId={subject.userId}
              userName={subject.name}
              lastUpdate={subject.lastUpdate}
              hasAvatar={subject.hasAvatar}
              classNames={classNames('h-10 w-10 -mr-6 bg-slate-400 even:bg-slate-600')}
            />
          ))}
        </div>

        <div className="flex w-2/12 items-center">
          <Badge variant="info" text={group.assignedProjectCount.toString()} className="px-4" />
        </div>
      </div>
    </div>
  );
};
