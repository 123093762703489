import * as yup from 'yup';
import { ContractTitleState, ContractTitleType } from '@client/shared/api';
import { InferType } from 'yup';

export const ContractTitleEditFormValidationSchema = yup.object({
  id: yup.string().optional(),
  contractId: yup.string().required('validation.required'),
  code: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  description: yup.string().optional(),
  type: yup.mixed<ContractTitleType>().oneOf(['MainContract', 'Supplement', 'Reserve']).required('validation.required'),
  state: yup
    .mixed<ContractTitleState>()
    .when('type', ([type], schema) => {
      if (type === 'MainContract') {
        return yup
          .mixed<ContractTitleState>()
          .oneOf(['Expected', 'Optional', 'Commissioned'])
          .required('validation.required')
      }
      else if (type === 'Supplement') {
        return yup
          .mixed<ContractTitleState>()
          .oneOf(['Expected', 'Announced', 'Budgeted', 'Received', 'Approved', 'Commissioned', 'Rejected', 'Canceled'])
          .required('validation.required')
      }
      else if (type === 'Reserve') {
        return yup
          .mixed<ContractTitleState>()
          .oneOf(['Reserve'])
          .required('validation.required')
      }
      return schema
    })
    .required('validation.required'),
  // state: yup.mixed<ContractTitleState>().oneOf(['Expected', 'Announced', 'Budgeted', 'Optional', 'Received', 'Approved', 'Commissioned', 'Rejected', 'Canceled']).required('validation.required'),
  vat: yup
    .number()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => (value as number) >= 0 && (value as number) <= 100)
    .required('validation.required'),

  discount: yup
    .number()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => (value as number) >= 0 && (value as number) <= 100)
    .required('validation.required'),
  offer: yup.number().required('validation.required'),
  approvedOffer: yup.number().required('validation.required'),
  contractValue: yup.number().required('validation.required'),
  adjustmentValue: yup.number().required('validation.required'),
  forecastValue: yup.number().required('validation.required'),
  budget: yup.number().required('validation.required'),
});

export type ContractTitleEditFormValidationValues = InferType<typeof ContractTitleEditFormValidationSchema>;
