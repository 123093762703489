export enum ApiTagTypes {
  AdminTenantProjects,
  AdminTenants,
  AdminTenantUsers,
  AdminWorkflows,
  AdminKlippaConfigurations,
  AuthGroups,
  AuthRoles,
  AuthSubjects,
  BaseReport,
  BudgetReport,
  CalculateElements, // invalidate for all get cost, risk, earnings, financing element for a calculation model
  CalculationModel,
  CalculationModelDeliveryPhases,
  CalculationModelHistory,
  CalculationModelMilestones,
  CalculationSchemeInvoice,
  CatalogTemplates,
  Cluster,
  Companies,
  Company,
  CompanyBranches,
  CompanyContract,
  CompanyInvoice,
  ComparisonReport,
  CompanyProjectImage, // invalidation of project image in project assignments of a company branch
  CompanyTenantTrust,
  Contract,
  Contracts,
  ContractInvoice,
  ContractTitleDocuments,
  CostsCalculationModel,
  CostElement,
  DefaultValues,
  EarningsCalculationModel,
  EarningsElement,
  EmissionYearlyDistributions,
  EuriborRates,
  ExternalApis,
  FinancingCalculationModel,
  FinancingElement,
  FinancingElementsMetadata,
  FinancingPurposeOverview,
  ForecastReport,
  FormulaElements, // TODO invalidation tag for costs, risks, and earnings elements that have formula
  Imports,
  Invoice,
  InvoiceCalculationSample,
  InvoiceContract,
  InvoiceDocuments,
  InvoiceDocumentResult,
  Invoices,
  Licenses,
  LicenseGrants,
  Plot,
  Plots,
  Portfolio,
  Project,
  ProjectInvoiceCoverSheets,
  Projects,
  RentDeal,
  Requirement,
  RisksCalculationModel,
  RiskElement,
  SellDeal,
  Tasks,
  Taxonomy,
  TaxonomyCostItems,
  TaxonomyItem,
  TenantCatalog,
  TenantInvoiceCoverSheets,
  Tenants,
  TenantTrusts,
  UserAvatar,
  UserDefinedFieldDefinition,
  UserDefinedFields,
  Workflow,
  WorkflowActivities
}

export const API_TAG_TYPES: string[] = Object.values(ApiTagTypes).map((value) => value as string);
