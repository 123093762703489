import { setSession, settings } from '@client/shared/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { fork, put, takeEvery } from 'redux-saga/effects';
import {
  api,
  ApiPostLoginApiResponse,
  ApiPostCreateUserInvitationApiResponse,
  ApiPostCheckSessionApiResponse,
} from '@client/shared/api';
import { removeCookie, setCookie } from 'typescript-cookie';
import { appError, login, logout } from '@client/shared/store';
import { parseDomain, wait, i18n, setLanguageAndLocale } from '@client/shared/utilities';
import toast from 'react-hot-toast';
import { Userpilot } from 'userpilot';

function* clearCacheOnLogout() {
  yield takeEvery(logout, function* () {
    removeCookie('session_id', {
      domain: settings.mainDomain,
      path: '/',
    });

    yield put(api.util.resetApiState());
  });
}

function* onUserLoggedOut() {
  // react to certain known calls and ensure that the user is logged out
  yield takeEvery(
    [
      api.endpoints.apiPostLogout.matchFulfilled,
      api.endpoints.apiPostLogoutSessions.matchFulfilled,
      api.endpoints.apiPostLogin.matchRejected,
      api.endpoints.apiPostCheckSession.matchRejected,
    ],
    function* () {
      yield put(logout());
    }
  );
}

function* onUserAuthenticated() {
  yield takeEvery(
    [api.endpoints.apiPostLogin.matchFulfilled],
    function* (action: PayloadAction<ApiPostLoginApiResponse>) {
      setCookie('session_id', action.payload.sessionToken, {
        domain: settings.mainDomain,
        sameSite: 'Lax',
        path: '/',
        // measured in days: https://github.com/carhartl/typescript-cookie
        expires: 30,
      });

      yield put(setSession(action.payload.sessionToken));
    }
  );
}

function* onCheckSessionAuthenticated() {
  yield takeEvery(
    [api.endpoints.apiPostCheckSession.matchFulfilled],
    function* (action: PayloadAction<ApiPostCheckSessionApiResponse>) {
      const tenants = action.payload.tenants ?? [];

      // the user is not active on any tenant, we cannot continue
      if (tenants.length === 0) {
        yield put(appError("You currently don't have access to any tenant, logging out"));
        yield wait(500);
        yield put(logout());
        window.location.reload();
      }

      const selectedTenant = action.payload.tenant ?? action.payload.tenants[0];

      const searchParams = new URLSearchParams(window.location.search);
      const redirectTarget = searchParams.get('_r') ?? '';

      // calculate a fallback target from tenant-session
      const fallbackTarget = `${window.location.protocol}//${selectedTenant?.subdomain}.${settings.mainDomain}:${window.location.port}`;

      const fallback = function* () {
        yield put(appError(`Redirecting to ${fallbackTarget}/`));
        setTimeout(() => {
          window.location.href = fallbackTarget + redirectTarget;
          // alert(fallbackTarget);
          return;
        }, 2000);
      };

      const domain = parseDomain(window.location.hostname);

      if (domain.subdomain === undefined && window.location.pathname.includes('/auth/register-trusted-company')) {
        yield put(appError(`Redirecting to ${fallbackTarget}/`));
        setTimeout(() => {
          window.location.href = fallbackTarget + window.location.pathname;
          return;
        }, 2000);
        return;
      }

      if (domain.depth !== 3) {
        yield fallback();
        return; 
      }

      if (domain.subdomain === 'admin') {
        if (!action.payload.user.superuser) {
          yield fallback();
          return;
        }
      } else if (selectedTenant?.subdomain !== domain.subdomain) {
        yield fallback(); 
        return;
      }

      yield put(
        login({
          userId: action.payload.user?.userId,
          firstName: action.payload?.user?.firstName ?? '',
          lastName: action.payload?.user?.lastName ?? '',
          email: action.payload?.user?.email ?? '',
          hasAvatar: action.payload?.user?.hasAvatar,
          lastUpdate: action.payload?.user?.lastUpdate,
          language: action.payload?.user?.language ?? undefined,
          tenant: selectedTenant,
          tenants: tenants,
          permissions: action.payload?.tenantPermissions ?? undefined,
        })
      );

      const userLanguage = action.payload.user.language;
      if (userLanguage) {
        yield setLanguageAndLocale(userLanguage);
      }

      //TODO: hopefully remove this again when we decide against userpilot
      Userpilot.identify(action.payload.user.userId, {
        email: action.payload.user.email,
        name: `${action.payload.user.firstName} ${action.payload.user.lastName}`,
      });

      yield true;
    }
  );
}

function* onInvitationChange() {
  yield takeEvery(
    [api.endpoints.apiPostCreateUserInvitation.matchFulfilled],
    function* (_: PayloadAction<ApiPostCreateUserInvitationApiResponse>) {
      toast.success(i18n.t('auth.notificationInvitationSent'));
      yield;
    }
  );
}

export const authSaga = function* () {
  yield fork(onUserAuthenticated);
  yield fork(onCheckSessionAuthenticated);
  yield fork(onUserLoggedOut);
  yield fork(clearCacheOnLogout);
  yield fork(onInvitationChange);
};
